// @ts-nocheck
// ... React Imports ...
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * @description `Component`: The GroupPopup component is a React component that renders a popup for adding a new group, including group name and description inputs, and a "Create" button.
 * @param {Boolean} groupListPopup Displays popup if the state is true.
 * @param {Boolean} groupList Displays popup if project list is active.
 * @param {Object} newGroupDescription Captures the group description when a user enters it in the form.
 * @param {Object} newGroupName Captures the group name when a user enters it in the form.
 * @param {Function} handleGroupCreation Creates a new group and updates the list.
 * @param {Number} userId User ID
 * @returns
 */

const GroupPopup = ({
  groupListPopup,
  groupList,
  newGroupDescription,
  newGroupName,
  handleGroupCreation,
  userId,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {groupList && groupListPopup && (
        <div className="projectDashboard_popup-container">
          <div className="projectDashboard_popup">
            <p id="projectDashboard_popup-heading">{t("main.add-new-group")}</p>
            <form id="projectDashboard_form">
              <div className="projectDashboard_form-input">
                <label>{t("main.enter-group-name")}:</label>
                <input
                  id="popup-input"
                  required
                  type="text"
                  name="name"
                  maxLength="50"
                  ref={newGroupName}
                  placeholder={t("main.add-group-name")}
                />
              </div>
              <div className="projectDashboard_form-input">
                <label>{t("main.enter-group-description")}</label>
                <textarea
                  id="popup-input"
                  required
                  name="location"
                  maxLength="180"
                  ref={newGroupDescription}
                  placeholder={t("main.add-description")}
                  rows={1}
                ></textarea>
              </div>
              <div className="projectDashboard_form-button-container">
                <div
                  className="projectDashboard_form-button"
                  type="submit"
                  value="Submit"
                  onClick={(e) => {
                    handleGroupCreation(e, userId);
                  }}
                >
                  <a>{t("main.add-group")}</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupPopup;
