// @ts-nocheck
/**
 * Functional component: sectionView.jsx
 *
 * Author: Ankit Jangid
 */
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import {
  Cartesian3,
  Matrix4,
  Cartographic,
  Cesium3DTileset,
  Model,
  Math,
} from "cesium";
import jwt_decode from "jwt-decode";

/**
 * @description `Component`: Functional component that saves geolocation attributes into tileset.json.
 * @param {*} param0
 * @returns
 */
const SaveGeolocation = ({ viewer, selectedResource, resourceObj }) => {
  const saveLocation = () => {
    const jwtToken = secureLocalStorage.getItem("token");

    const user_id = jwt_decode(jwtToken).user_id;

    let updatedAsset = new FormData();
    let isFileTileset = true;
    let fileUrl = resourceObj.url;

    if (fileUrl.includes("gltf")) {
      isFileTileset = false;
      fileUrl = fileUrl.replace(/\.gltf/g, ".czml");
    }
    // const outputString = resourceObj.url.replace(/\.gltf/g, '.czml');
    console.log("RS URL : ", fileUrl);
    async function getTileset() {
      try {
        const response = await fetch(fileUrl, {
          method: "GET",
          headers: resourceObj.headers,
        });
        if (!response.ok) {
          throw new Error("Error while fetching Tileset");
        }
        const data = await response.json();
        console.log("DATA : ", data);

        if (isFileTileset) {
          // .. Update the tilesetString with current tileset primitive geolocation values ..
          for (let i = 0; i < viewer.scene.primitives._primitives.length; i++) {
            // console.log("Elements : ", viewer.scene.primitives._primitives[i]);
            if (
              viewer.scene.primitives._primitives[i] instanceof Cesium3DTileset
            ) {
              console.log(" THIS : ", viewer.scene.primitives._primitives[i]);
            }
          }
          const tilesetPrimitive = viewer.scene.primitives._primitives[0];

          // .. Update georeferenced flag ..
          data.extras.georeferenced = true;

          // .. Set position ..
          var position = new Cartesian3();
          Matrix4.getTranslation(tilesetPrimitive.modelMatrix, position);
          const cartographicPosition = Cartographic.fromCartesian(position);
          console.log("POS : ", cartographicPosition);

          data.extras.position.latitude =
            tilesetPrimitive.extras.position.latitude;
          data.extras.position.longitude =
            tilesetPrimitive.extras.position.longitude;
          data.extras.position.height = tilesetPrimitive.extras.position.height;
          console.log(tilesetPrimitive.extras.position.height);

          // .. Set orientation ..
          data.extras.hpr.h = tilesetPrimitive.extras.hpr.h;
          data.extras.hpr.p = tilesetPrimitive.extras.hpr.p;
          data.extras.hpr.r = tilesetPrimitive.extras.hpr.r;

          // .. Set scale ..
          data.extras.scale.x = tilesetPrimitive.extras.scale.x;
          data.extras.scale.y = tilesetPrimitive.extras.scale.y;
          data.extras.scale.z = tilesetPrimitive.extras.scale.z;

          const tilesetString = JSON.stringify(data);

          // .. Prepare and POST tileset.json ..
          const blob = new Blob([tilesetString]);

          updatedAsset.append("id", selectedResource.id);
          updatedAsset.append("file", blob, "tileset.json");
        } else {
          console.log("WIll need to get gltf properties");
          for (let i = 0; i < viewer.scene.primitives._primitives.length; i++) {
            console.log("Elements : ", viewer.scene.primitives._primitives[i]);
            if (viewer.scene.primitives._primitives[i] instanceof Model) {
              console.log(" THIS : ", viewer.scene.primitives._primitives[i]);
            }
          }
          const modelPrimitive = viewer.scene.primitives._primitives[0];
          var position = new Cartesian3();
          Matrix4.getTranslation(modelPrimitive.modelMatrix, position);
          const cartographicPosition = Cartographic.fromCartesian(position);
          console.log("POS : ", Math.toDegrees(
            cartographicPosition.latitude
          ));

          data[1].position.cartographicDegrees[0] = Math.toDegrees(
            cartographicPosition.longitude
          );
          data[1].position.cartographicDegrees[1] = Math.toDegrees(
            cartographicPosition.latitude
          );
          data[1].position.cartographicDegrees[2] = cartographicPosition.height
          const scaling = new Cartesian3();
          Matrix4.getScale(modelPrimitive.modelMatrix, scaling);
          console.log("SCALE # : ", scaling)
          data[1].scale = scaling;

          data[1].orientation = modelPrimitive._modelResources[0]

          console.log(data[1])
          const modelString = JSON.stringify(data);
          console.log("MODEL POS : ", modelPrimitive._modelResources[0])
          
          // .. Prepare and POST tileset.json ..
          const blob = new Blob([modelString]);
          console.log("SR : ", selectedResource)
          updatedAsset.append("id", selectedResource.id);
          updatedAsset.append("file", blob, selectedResource.asset_name + ".czml");
          // console.log("CMZ L: ", upda)
        }

        axios
          .post(
            process.env.REACT_APP_API + "/project/save_asset/" + user_id,
            updatedAsset,

            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "JWT " + jwtToken,
              },
            }
          )

          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.error("Error : ", error);
          });
      } catch (error) {
        console.log("COULDN'T GET TILESET");
      }
    }
    getTileset();
  };
  return (
    <>
      <div
        onClick={() => {
          saveLocation();
        }}
        className="save_button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <mask
            id="mask0_2588_611"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2588_611)">
            <path
              d="M21 7V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H17L21 7ZM19 7.85L16.15 5H5V19H19V7.85ZM12 18C12.8333 18 13.5417 17.7083 14.125 17.125C14.7083 16.5417 15 15.8333 15 15C15 14.1667 14.7083 13.4583 14.125 12.875C13.5417 12.2917 12.8333 12 12 12C11.1667 12 10.4583 12.2917 9.875 12.875C9.29167 13.4583 9 14.1667 9 15C9 15.8333 9.29167 16.5417 9.875 17.125C10.4583 17.7083 11.1667 18 12 18ZM6 10H15V6H6V10Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
    </>
  );
};

export default SaveGeolocation;
