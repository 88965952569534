import React, { useState,useEffect} from "react";
import "./addTenants.css";

export default function AddTenants(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUsername] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [success, setSuccess] = useState("");
  
  //const [userLevel, setUserLevel] = useState("Tenant");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("New tenant");
    const tenantCredentials = JSON.stringify({
      email: email,
      user_level: "Tenant",
      username: userName,
      created_by: props.userId, // extract from token
      password: password,
      re_password: password,
    });

    console.log("Creds : ", tenantCredentials);

    fetch(process.env.REACT_APP_API + "/auth/users/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: tenantCredentials,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json)
        if(json.email == 'master account with this email already exists.'){
          console.log('if')
          setError(json.email[0])
          setPasswordError('')  
        }

        else if(json.password){
          console.log('elif')
           setPasswordError(json.password[0])
           setError('')
        }

        else {
          console.log('else')
          setError('')
          setPasswordError('')
          setSuccess("Tenant was added successsfully!")

          setTimeout(() => {
            setSuccess('');
          }, 4000);
          
        }
        console.log(json);
      });
  };

  // const handleLevelChange = (e) => {
  //   console.log(e.target.value);
  //   setUserLevel(e.target.value);
  // };

  return (
    <>
      <h3>New Tenant</h3>
      <div className="tenantCredentials">
        <form autoComplete="off" onSubmit={handleSubmit}>
          <label>
            Email :
            <br />
            <input
              type="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <br />
          <label>
            Username :
            <br />
            <input
              type="text"
              autoComplete="off"
              value={userName}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
          <br />
          {/* <label>
            User Level :
            <select onChange={handleLevelChange}>
              <option value="Tenant">Tenant</option>
              <option value="Editor">Editor</option>
              <option value="Viewer">Viewer</option>
            </select>
          </label>
          <br /> */}
          <label>
            Password :
            <br />
            <input
              type="password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <br />
          <p id="tenantErrorMsg">{error}</p>
          <p id="tenantErrorMsg">{passwordError}</p>
          <p id="tenantErrorMsg">{success}</p>
          <button className="submit-btn" type="submit">
            {" "}
            Add Tenant{" "}
          </button>
        </form>
      </div>
    </>
  );
}
