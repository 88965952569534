// @ts-nocheck
import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import "./login.css";
import jwt_decode from "jwt-decode";
import logo from "../../assets/3D_logo.png";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

/**
 * Authentication Component
 * @description 'Page' :  Component that manages user authentication, login attempts, and role-based navigation in the application, featuring temporary lockout for multiple failed login attempts.
 * @category Authentication
 * @returns {JSX.Element} Rendered login page.
 */
export default function Login() {
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [lockoutExpiration, setLockoutExpiration] = useState(0);
  const [passwordType, setPasswordType] = useState("password");
  const jwtToken = secureLocalStorage.getItem("token");

  /**
   * Handles the form submission when the user attempts to log in.
   * Performs API request, handles responses, and manages lockout functionality.
   */
  function handleSubmit() {
    fetch(process.env.REACT_APP_API + "/auth/jwt/create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        var y = document.getElementById("loginErrorMessage");

        if (
          json.user_level == "VisioAdmin" ||
          json.user_level == "Tenant" ||
          json.user_level == "Editor" ||
          json.user_level == "Viewer"
        ) {
          (" ");
        } else {
          y.style.display = "flex";
          setTimeout(() => {
            setIsFormDisabled(true);
            y.style.display = "none";
          }, 2000);
        }

        if (json.access) {
          const token = jwt_decode(json.access);
          const user_id = token.user_id;
          secureLocalStorage.setItem("token", json.access);
          secureLocalStorage.setItem("userLevel", json.user_level);

          if (json.user_level) {
            const userLevel = json.user_level;
            switch (userLevel) {
              case "VisioAdmin":
                navigation("/adminDashboard", {
                  replace: false,
                  state: { userLevel: userLevel, userId: user_id },
                });
                break;
              case "Tenant":
                // userLevel to be retrieved from secureLocalStorage same for userId?
                navigation("/projectDashboard", {
                  replace: false,
                  state: { userLevel: userLevel, userId: user_id },
                });
                break;
              case "Editor":
                navigation("/projectDashboard", {
                  replace: false,
                  state: { userLevel: userLevel, userId: user_id },
                });
                break;
              case "Viewer":
                navigation("/projectDashboard", {
                  replace: false,
                  state: { userLevel: userLevel, userId: user_id },
                });
                break;
            }
          }
          // console.log(json);
        } else if (json.detail) {
          console.log("JSON contains error : ", json.detail);
          setAttempts(attempts + 1);
        } else {
          navigation("*");
          console.log("Invalid JSON response");
        }
      })
      .catch((error) => {
        console.log("Network Error : ", error);
        navigation("/networkError");
      });

    if (attempts >= 5) {
      const lockoutMinutes = 5;
      const lockoutExpiryTime = new Date().getTime() + lockoutMinutes * 60000;

      setLockoutExpiration(lockoutExpiryTime);
      localStorage.setItem("lockoutExpiration", lockoutExpiryTime);
      setEmail("")
      setPassword("")
    }
    setIsFormDisabled(true);
  }

  useEffect(() => {
    // Checks if there is a lockout expiration timestamp in storage
    const storedLockoutExpiration = localStorage.getItem("lockoutExpiration");

    if (storedLockoutExpiration) {
      const currentTimestamp = new Date().getTime();

      if (currentTimestamp < parseInt(storedLockoutExpiration, 10)) {
        // Lockout is still active, updates the state accordingly
        setLockoutExpiration(parseInt(storedLockoutExpiration, 10));
        setIsFormDisabled(true);
      } else {
        // Lockout has expired, removes the stored lockout data and enables the form
        localStorage.removeItem("lockoutExpiration");
        setIsFormDisabled(false);
      }
    }
  }, []);

  /**
  * Handles the "Enter" keypress event, triggering form submission if conditions are met.
  * @param {object} event - The keypress event object
  */
  const handleKeyPress = (event) => {
    if (
      event.key === "Enter" &&
      email.trim() !== "" &&
      password.trim() !== ""
    ) {
      event.preventDefault();
      handleSubmit();
    }
  };

  /**
   * Handles changes in the email input field, updating the state and form's disabled status.
   * @param {object} e - The input change event object
   */
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    setIsFormDisabled(newEmail === "" || password === "");
  };

  /**
   * Handles changes in the password input field, updating the state and form's disabled status.
   * @param {object} e - The input change event object
   */
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    setIsFormDisabled(email === "" || newPassword === "");
  };

  /**
   * Toggles the password visibility in the password input field.
   * Switches between 'password' and 'text' input types.
   */
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const style = { color: "white" };

  return (
    <>
      <div className="loginPage_main-container">
        <div className="loginPage_left-image"></div>
        <div className="loginPage_right-content">
          <div className="loginPage_right-content-logo">
            <h1>3</h1>
            <img id="header_logo" src={logo} />
            <h1>Twin</h1>
          </div>
          <div className="loginPage_right-content-form">
            <p id="loginPage_welcome-back">Welcome</p>
            <p id="loginPage_welcome-back-para">
              Welcome! Please enter your details to continue{" "}
            </p>
            <div className="loginPage_email">
              <input
                id="loginPage_email-input"
                type="email"
                value={email}
                placeholder="Email:"
                onChange={handleEmailChange}
                onKeyPress={handleKeyPress}
                required
                maxLength={320}
              />
            </div>
            <div className="loginPage_password">
              <input
                type={passwordType}
                id="loginPage_input"
                value={password}
                placeholder="Password:"
                onChange={handlePasswordChange}
                onKeyPress={handleKeyPress}
                required
                maxLength={30}
              />
              <h3 id="input-image" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <AiOutlineEyeInvisible style={style} />
                ) : (
                  <AiOutlineEye style={style} />
                )}
              </h3>
            </div>
            <div className="loginPage_login-msg-container">
              <div className="loginPage_error-msg" id="loginErrorMessage">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <mask
                    id="mask0_2187_518"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2187_518)">
                    <path
                      d="M10 14.1667C10.2361 14.1667 10.434 14.0868 10.5938 13.9271C10.7535 13.7674 10.8333 13.5694 10.8333 13.3333C10.8333 13.0972 10.7535 12.8993 10.5938 12.7396C10.434 12.5799 10.2361 12.5 10 12.5C9.76389 12.5 9.56597 12.5799 9.40625 12.7396C9.24653 12.8993 9.16667 13.0972 9.16667 13.3333C9.16667 13.5694 9.24653 13.7674 9.40625 13.9271C9.56597 14.0868 9.76389 14.1667 10 14.1667ZM9.16667 10.8333H10.8333V5.83333H9.16667V10.8333ZM6.875 17.5L2.5 13.125V6.875L6.875 2.5H13.125L17.5 6.875V13.125L13.125 17.5H6.875ZM7.58333 15.8333H12.4167L15.8333 12.4167V7.58333L12.4167 4.16667H7.58333L4.16667 7.58333V12.4167L7.58333 15.8333Z"
                      fill="#FF3F3F"
                    />
                  </g>
                </svg>
                <p>No active account found with the given credentials</p>
              </div>
              <div>
                <p
                  id="loginPage_forgot-password"
                  onClick={() => {
                    navigation("/forgotPassword", {
                      replace: false,
                    });
                  }}
                >
                  Forgot Password ?
                </p>
              </div>
            </div>
            {lockoutExpiration && new Date().getTime() < lockoutExpiration ? (
              <p id="attempt">
                Maximum login attempts reached.
                <br /> Please try again later.
              </p>
            ) : (
              <div className="loginPage_login-btn-container">
                <div
                  className={`loginPage_login-btn ${isFormDisabled ? "disabled-btn" : ""
                    }`}
                  onClick={handleSubmit}
                  disabled={isFormDisabled}
                >
                  <a>Log In</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
