// @ts-nocheck
import React, { useState, useEffect } from "react";
import "./viewTenant.css";

export default function ViewTenants() {
  const [tenantList, setTenantList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState();
  const [tenantInfo, setTenantInfo] = useState({});
  const [deleteTenant, setDeleteTenant] = useState('');

  useEffect(() => {
    fetchTenantList();
  }, []);

  const fetchTenantList = () => {
    console.log("Fetching tenant list");
    fetch(process.env.REACT_APP_API + "/visioAdmin/get_tenant_details", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setTenantList(json);
        console.log(json);
      });
  };

  const handleClick = (tenant) => {
    console.log(`You clicked ${tenant.id}`);
    setSelectedTenant(tenant.id);
    tenantDetails(tenant.id);

    // Call your function here
  };

  const handleRemove = () => {
    fetch(
      process.env.REACT_APP_API + "/accounts/delete_account/" + selectedTenant,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setDeleteTenant(json.status)
        setTimeout(() => {
          setDeleteTenant('');
        }, 3000);
        fetchTenantList();
        console.log("Delete resp : ", json);
      });
    // Call your function here
  };

  const tenantDetails = (tenantID) => {
    fetch(
      process.env.REACT_APP_API + "/visioAdmin/get_tenant_details/" + tenantID,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log("Tenant Details : ", json);
        setTenantInfo(json[0]);
      });
  };

  return (
    <>
      <h3>Tenant List</h3>
      <div className="tenant-list">
        <ul
          className="ul-list"
          style={{ height: `${tenantList.length * 20}px` }}
        >
          {tenantList.map((tenant) => (
            <li key={tenant.id} onClick={() => handleClick(tenant)}>
              {tenant.username}
            </li>
          ))}
        </ul>
        <p id="deleteTenant">{deleteTenant}</p>
        <div className="action-btns">
          <button className="fetch-list" onClick={fetchTenantList}>
            Fetch List
          </button>
          <button className="tenant-delete" onClick={handleRemove}>
            Delete {tenantInfo.username}{" "}
          </button>
        </div>
      </div>

      <div className="tenant-details">
        <h3>Tenant Details : </h3>
        <ul>
          <li>
            ID : <br /> <span className="tenant-span">{tenantInfo.id}</span>
          </li>
          <li>
            Created by: <br /> <span className="tenant-span">{tenantInfo.created_by}</span>{" "}
          </li>
          <li>
            Created on: <br /> <span className="tenant-span">{tenantInfo.created_on}</span>
          </li>
          <li>
            Email: <br /> <span className="tenant-span">{tenantInfo.email}</span>{" "}
          </li>
          <li>
            User level: <br /> <span className="tenant-span">{tenantInfo.user_level}</span>{" "}
          </li>
          <li>
            Username: <br /> <span className="tenant-span">{tenantInfo.username}</span>{" "}
          </li>
        </ul>
      </div>
    </>
  );
}
