// @ts-nocheck
/* eslint-disable no-unused-vars */
import {
  JulianDate,
  Math,
  Ellipsoid,
  Cartesian3,
  Entity,
  Matrix4,
  Quaternion,
  Cesium3DTileset,
  Model,
} from "cesium";

/**
 * Converts cartesian3 co-ordinates into cartographic co-ordinates to be stored in czml packet.
 * @param   {Cartesian3} cartesian3 - A 3D Cartesian point (x,y,z)
 * @returns {cartographic} - A position defined by longitude, latitude, and height.
 */
function cartesianAsCartographicDegrees(cartesian3) {
  const cartographic = Ellipsoid.WGS84.cartesianToCartographic(cartesian3);
  const lon = Math.toDegrees(cartographic.longitude);
  const lat = Math.toDegrees(cartographic.latitude);
  const cartographicDegrees = [lon, lat, cartographic.height];
  // @ts-ignore
  return cartographicDegrees;
}


/**
 * Provides a utility for creating CZML (Cesium Language) packets. The class offers methods to add entities, encode positions, orientations, and other properties, and generate a JSON representation of the CZML document.
 * @description  Class to create DocumentWriter Object
 * @category Editor
 */
class DocumentWriter {
  constructor() {
    /**
     * Version counter for CZML packet
     * @property {number} counter Version counter
     */
    this.counter = 0;

    /**
     * Consolidates packets in one document
     * @property {Object} document Consolidated packets object.
     */

    this.document = {
      id: "document",
      version: "1.0",
      vt_json_name: "",
      project_properties: {},
    };

    /**
     * Individual packets containing entities
     * @property {Object} document CZML Packet
     */
    this.packets = [];
  }

  czmlDetails(czmlName) {
    this.document.vt_czml_name = czmlName;
  }

  /**
   * Add entity data in packets
   * @property {function} addResource checks for the presence of specifc properties in the Entity object subsequently processes accordingly.
   * @param {Entity} resource Cesium Entity object.
   * @property {function} addResource checks for the presence of specifc properties in the Entity object subsequently processes accordingly.
   * @param {Entity} resource Cesium Entity object.
   * @property {function} addResource checks for the presence of specifc properties in the Entity object subsequently processes accordingly.
   * @param {Entity} resource Cesium Entity object.
   * @returns void
   */

  addResource(resource, imageUrls) {
    // console.log("Resource : ", resource);
    let packet = {};

    // Check if resource is of instance Entity and is a preset point
    if (
      resource instanceof Entity &&
      resource.id.toString().startsWith("PSPoint")
    ) {
      var { position, name, id, billboard, properties } = resource;
      // Setting position of the preset point
      var cartesianPosition = position.getValue(new JulianDate.now());
      packet.position = {
        cartesian: [
          cartesianPosition.x,
          cartesianPosition.y,
          cartesianPosition.z,
        ],
      };

      // Setting the billboard properties
      packet.billboard = {
        image: billboard.image.getValue(new JulianDate.now()),
        disableDepthTestDistance: billboard.disableDepthTestDistance.getValue(
          new JulianDate.now()
        ),
      };

      // Setting the orientation properties
      packet.orientation = {
        direction: properties.getValue(JulianDate.now()).direction,
        up: properties.getValue(JulianDate.now()).up,
      };

      // ..Setting time info ..

      packet.presetStartTime = properties.getValue(JulianDate.now()).presetStartTime

      // Setting the name and id of the preset point
      packet.name = name;
      packet.id = id;
    }

    // Check if resource is an instance of Entity and is an annotation
    else if (resource instanceof Entity && resource.id.startsWith("ANNT")) {
      // Destructuring and appending the required properties from the entity object
      const {
        position,
        name,
        id,
        annColor,
        annDescription,
        annCreatedDate,
        descImagePath,
        imageId,
        imageUrl,
        availability,
        assetId,
        isAssetBound
      } = resource;
      var cartesianPosition = position.getValue(new JulianDate.now());
      packet = {
        name,
        id,
        annColor,
        annDescription,
        annCreatedDate,
        imageUrl,
        imageId,
        assetId,
      };

      if (isAssetBound)
        packet.isAssetBound = isAssetBound;

      // Setting the availablity of the annotation
      packet.availability = availability?.get(0)?.toString();

      // Setting the position of the annotation
      packet.position = {
        cartesian: [
          cartesianPosition.x,
          cartesianPosition.y,
          cartesianPosition.z,
        ],
      };

      // Setting the DOM element corresponding to the annotation icon
      packet.annotationIcon = document.getElementById(id).outerHTML;

      // Setting the image urls and imageId for newly created annotations
      if (imageId === undefined && descImagePath) {
        const image = imageUrls.find((image) => image.entityId === id);
        packet.imageUrl = image.imageUrl + "/" + image.imageName;
        packet.imageId = image.imageId;
      }
    }

    else if (resource instanceof Entity && resource.id.startsWith("RTA")) {
      const {
        position,
        name,
        id,
        originalLabel,
        selectedKey,
        entityType,
        entityId
      } = resource;
      var cartesianPosition = position.getValue(new JulianDate.now());
      packet = {
        name,
        id,
        originalLabel,
        selectedKey,
        entityType,
        entityId
      };

      packet.position = {
        cartesian: [
          cartesianPosition.x,
          cartesianPosition.y,
          cartesianPosition.z,
        ],
      };

      packet.annotationIcon = document.getElementById(id).outerHTML;
    }

    // Check if resource is a instance of Cesium3DTileset and is 3D tile
    else if (
      resource instanceof Cesium3DTileset &&
      resource.extras.id.toString().startsWith("TS")
    ) {
      // console.log(" TILESET PACKET : ", resource);
      const { _resource, modelMatrix, extras } = resource;

      packet.id = extras.id;

      // .. Split out relative path from resource url ..
      let relativePath;
      const match = resource._resource.url.match(/get_url\/(.*)/);
      match ? (relativePath = match[1]) : console.log("Invalid url");
      relativePath.split("?")[0];
      // console.log(" TS URL IN CZML : ", relativePath.split("?")[0]);

      packet.tileset = {
        uri: relativePath.split("?")[0],
      };

      if (resource.extras.georeferenced !== "cgml") {
        const position = new Cartesian3();
        Matrix4.getTranslation(modelMatrix, position);
        packet.position = {
          cartographicDegrees: cartesianAsCartographicDegrees(position),
        };

        // .. Log Scale ..

        packet.scale = extras.scale;

        packet.hpr = extras.hpr;

        // TODO : try to use extras.ori_quaternion directly, oprtimize similarly across
        packet.orientation = {
          unitQuaternion: [
            extras.ori_quaternion?.x,
            extras.ori_quaternion?.y,
            extras.ori_quaternion?.z,
            extras.ori_quaternion?.w,
          ],
        };

        // ! DEPRECATED
        // packet.assetConclusion = extras.assetConclusion;

        // Asset availability
        packet.assetAvailability = extras.assetAvailability;
        // console.log('asset availability ', extras.assetAvailability);

        // .. ClippingPlanes ..
        // console.log(" PACKET : ", extras)
        // console.log(" Resource : ", resource)

        // console.log(" CONFIRMING CP : ", extras.clippingPlanes)
        packet.clippingPlanes = extras.clippingPlanes;
      } else {
        packet.georeferenced = "cgml";
      }
    }

    // ... Check if resource is a Model and is id'd as GL ...
    if (resource instanceof Model && resource._id.toString().startsWith("GL")) {
      const { _credit, _modelMatrix, _modelResources, _minimumPixelSize } =
        resource;
      packet.id = resource._id;

      packet.name = _credit._html;

      const position = new Cartesian3();
      Matrix4.getTranslation(_modelMatrix, position);
      packet.position = {
        cartographicDegrees: cartesianAsCartographicDegrees(position),
      };

      // . Split out relative path from resource url .
      let relativePath;
      const match = resource._resource.url.match(/get_url\/(.*)/);
      match ? (relativePath = match[1]) : console.log("Invalid url");
      relativePath.split("?")[0];

      // .. Scale ..
      const modelScale = new Cartesian3();
      Matrix4.getScale(_modelMatrix, modelScale);

      packet.model = {
        uri: relativePath.split("?")[0],
        minimumPixelSize: _minimumPixelSize,
        scale: {
          x: modelScale.x,
          y: modelScale.y,
          z: modelScale.z,
        },
      };

      // .. Log Orientation ..
      packet.hpr = {
        h: _modelResources[0].hpr.h,
        p: _modelResources[0].hpr.p,
        r: _modelResources[0].hpr.r,
      };

      // packet.assetConclusion = _modelResources.assetConclusion;
      packet.assetAvailability = _modelResources.assetAvailability;
    }

    /**
     * Push the generated packets into to packets array.
     */
    this.packets.push(packet);
    // console.log("Packets generated : ");
    // console.log(packet);
  }

  /**
   * Combines the CZML document and packets into a JSON array.
   * @method
   * @returns {Object[]}  An array of JSON objects representing the CZML document and packets.
   */
  toJSON() {
    return [this.document, ...this.packets];
  }
}

export default DocumentWriter;
