// @ts-nocheck
import AnnotationsList from "../../features/AnnotationsList";
import useAnnotations from "../../features/useAnnotations";

/**
 * Functional component for handling annotations in viewer mode
 * @param { Viewer } viewer Cesium Viewer
 * @returns { JSX.Element } Annotation viewer component
 */
const AnnotationsViewer = ({ viewer }) => {
    /* Gets the list of annotations from the custom useAnnotations hook */
    const { annotationsList } = useAnnotations(viewer);

    /* Displays the annotation description on click of the respective annotation from the list */
    const displayAnnotationDescription = (annotation) => {
        const descriptionElement = document.getElementById(annotation.id).lastChild;
        descriptionElement.style.visibility = descriptionElement.style.visibility === 'hidden' ? 'visible' : 'hidden';
        viewer.flyTo(annotation);
    };

    return (
        <>
            <AnnotationsList viewer={viewer} displayAnnotationDescription={displayAnnotationDescription} annotationsList={annotationsList} placeholderText="No annotations added" isViewer={true} />
        </>
    )

}

export default AnnotationsViewer;