// @ts-nocheck
import Calendar from "react-calendar";
import MilestonesList from "../../features/MilestonesList";
import useMilestones from "../../features/useMilestones"
import { useState } from "react";
import { JulianDate } from "cesium";

/**
 * Functional component for handling milestones in viewer mode
 * @param { Viewer } viewer Cesium Viewer
 * @returns { JSX.Element } Milestones viewer component
 */
const MilestonesViewer = ({ viewer }) => {
    /* Getting the list of milestones from useMilestones custom hook */
    const { milestoneList } = useMilestones(viewer);
    const [date, setDate] = useState(new Date());

    /* Updates cesium clock */
    const updateCesiumClock = (date) => {
        console.log("DATE : ", date);
        viewer.clock.currentTime = JulianDate.fromDate(new Date(date));
        console.log(date);
        setDate(new Date(date.substring(0, date.indexOf("T"))));
        console.log(date.substring(0, date.indexOf("T")));
    };
    return (
        <>
            <MilestonesList milestoneList={milestoneList} updateCesiumClock={updateCesiumClock} isViewer={true} />
        </>
    )
}

export default MilestonesViewer;