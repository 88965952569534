// @ts-nocheck
/**
 * Function: resourceLoader.js
 *
 * Author: Ankit Jangid
 */

/**
 * @description `Function`: Analyzes a selected resource or project and generates essential resource information.
 * It identifies the resource type and returns values for resourceUrl, resourceType, and
 * gltfPath based on the specific scenario. This function is useful for dynamically managing
 * and loading geospatial and 3D resources in applications.
 * @param {*} selectedResource
 * @returns
 */
const resourceLoader = (selectedResource) => {
  // .. Variables ..
  let relativePath;
  let extension;
  let resourceUrl;
  let resourceType;
  let gltfPath;

  // .. Is selectedResource an asset ..
  if (selectedResource.asset_name) {
    if (selectedResource.status === 65) {
      relativePath =
        selectedResource.asset_input_url +
        "/" +
        selectedResource.asset_name +
        ".czml";
      gltfPath =
        process.env.REACT_APP_ASSET_API +
        selectedResource.asset_input_url +
        "/" +
        selectedResource.asset_name +
        "." +
        selectedResource.input_file_type;
      resourceType = "georefGLTF";
    } else if (selectedResource.status === 63) {
      relativePath =
        selectedResource.asset_output_url +
        "/" +
        selectedResource.asset_name +
        ".czml";
      gltfPath =
        process.env.REACT_APP_ASSET_API +
        selectedResource.asset_output_url +
        "/" +
        selectedResource.asset_name +
        "." +
        selectedResource.output_file_type;
      resourceType = "georefGLTF";
    }
    // .. Native 3D tile asset ..
    else if (selectedResource.input_file_type === "3D Tiles") {
      relativePath = selectedResource.asset_input_url;
      resourceType = "3D Tile";
    } else if (selectedResource.status === 64) {
      relativePath = selectedResource.asset_output_url;
      resourceType = "3D Tile";
    } else {
      relativePath = // root/users-test/userLevel-ID/assets/assetType/assetFolder/primaryFile
        selectedResource.asset_input_url +
        "/" +
        selectedResource.asset_name +
        "." +
        selectedResource.input_file_type;
    }

    // .. Split file extension from asset relative path ..
    extension = relativePath.split(".").pop();
    resourceUrl = process.env.REACT_APP_ASSET_API + relativePath;

    // .. Is selectedResource a project ..
  } else if (selectedResource.project_name) {
    extension = "czml";
    resourceType = "Project";
    resourceUrl = process.env.REACT_APP_ASSET_API;
  }
  return { resourceUrl, resourceType, gltfPath };
};

export default resourceLoader;
