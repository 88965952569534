// @ts-nocheck

/**
 * Functional component for viewing the list of preset points
 * @param { Array } presetList List of preset points to display
 * @param { Function } flyToPresetPoint Function to fly to preset point
 * @param { Boolean } isViewer Indicates whether list is displayed in viewer or editor mode
 * @param { String } placeholderText Text to display when there are no preset points
 * @returns { JSX.Element } Preset Points list component
 */
const PresetPointsList = ({ presetList, flyToPresetPoint, isViewer, placeholderText }) => {
  return (
    <div className={`panel-container scrollable-container ${isViewer ? 'fixed-height-preset-list-viewer' : 'fixed-height-preset-list'}`}>
      <table className="presetPoint-list">
        <tbody className="presetPoint-body">
          <tr>
            <th className="panel-subhead">Preset Name</th>
            <th className="panel-subhead">Point</th>
          </tr>
          <hr />
          {presetList.length > 0 ? presetList.map((presetPoint, index) => {
            return (
              <tr
                key={index}
                className="presetPoint-row"
                onClick={() => flyToPresetPoint(presetPoint)}
              >
                <td className={`${isViewer ? 'panel-list-name-viewer' : 'panel-subhead'} panel-text`}>{presetPoint.name}</td>
                <td>
                  <svg width="19px" height="19px">
                    <g>
                      <circle cx="9.5" cy="9.5" r="9.5" fill="#FC82FF" />
                      <text
                        x="9.5" y="9.5"
                        style={{ fontSize: '12px', textAnchor: 'middle', alignmentBaseline: 'middle', stroke: 'black' }}
                      >
                        {index + 1}
                      </text>
                    </g>
                  </svg>
                </td>
              </tr>
            );
          }) :
            <div className="empty-preset-list placeholder-text">
              {placeholderText}
            </div>}
        </tbody>
      </table>
    </div>
  )
}

export default PresetPointsList