// @ts-nocheck
/**
 * Functional component: sectionView.jsx
 *
 * Author: Ankit Jangid
 */

import Scaling from "./components/geolocation/scaling";
import Localize from "./components/geolocation/localize";
import Orientation from "./components/geolocation/orientation";
import "./geolocation.css";
import { Cesium3DTileset, Entity, Model } from "cesium";

/**
 * @description `Component`: Functional component : Displays localization, scaling, and orientation adjustments
 * based on the selected resource. Allows users to save geolocation changes when applicable, depending on the resource type.
 * @param { Viewer } viewer Cesium Viewer
 * @param { Object } resourceInFocus Asset in focus (via user click or recent load)
 * @param { Resource } resourceObj Cesium Resource object for resource loading (contains URL and Headers)
 * @param { Object } selectedResource Initial asset that was selected from Dashboard and loaded to Viewer
 * @param { Boolean } isVisible Sidebar visibility
 * @param { String } resourceType Type of selectedResource.
 * @returns { JSX.Element } Geolocation UI
 */
const Geolocate = ({
  viewer,
  resourceInFocus,
  resourceObj,
  selectedResource,
  isVisible,
  resourceType,
}) => {
  // console.log("Resource in focus : ", resourceInFocus);
  return (
    <>
      {isVisible && (
        <div className="geolocation_container">
          <div className="panel-container panel-main-section">
            {/* Check if resourceInFocus is an instance of Cesium3DTileset and if tileset has georeferenced property with value "cgml"*/}
            {resourceInFocus instanceof Cesium3DTileset ? (
              resourceInFocus.extras.georeferenced !== "cgml" ? (
                <>
                  {/* <h4 className="panel-subhead">Position</h4> */}
                  <div className="geolocation_inputContainer">
                    <Localize
                      resourceInFocus={resourceInFocus}
                      viewer={viewer}
                    />
                  </div>
                  {/* <h4 className="panel-subhead">Scale & Orientation</h4> */}
                  <div className="geolocation_inputContainer">
                    <Scaling
                      resourceInFocus={resourceInFocus}
                      viewer={viewer}
                    />
                  </div>
                  <div className="geolocation_inputContainer">
                    {/* <Scaling resourceInFocus={resourceInFocus} viewer={viewer} /> */}
                    <Orientation
                      resourceInFocus={resourceInFocus}
                      viewer={viewer}
                    />
                  </div>
                </>
              ) : (
                <p className="geolocation_citygml">
                  * CityGMLs cannot be modified
                </p>
              )
            ) : resourceInFocus instanceof Model ? (
              <>
                {/* <h4 className="panel-subhead">Position</h4> */}
                <div className="geolocation_inputContainer">
                  <Localize resourceInFocus={resourceInFocus} viewer={viewer} />
                </div>
                {/* <h4 className="panel-subhead">Scale & Orientation</h4> */}
                <div className="geolocation_inputContainer">
                  <Scaling resourceInFocus={resourceInFocus} viewer={viewer} />
                </div>
                <div className="geolocation_inputContainer">
                  {/* <Scaling resourceInFocus={resourceInFocus} viewer={viewer} /> */}
                  <Orientation
                    resourceInFocus={resourceInFocus}
                    viewer={viewer}
                  />
                </div>
              </>
            ) : resourceInFocus instanceof Entity ? (
              <p className="geolocation_citygml">* Entity cannot be modified</p>
            ) : (
              <p className="geolocation_citygml">* Select asset to modify</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Geolocate;
