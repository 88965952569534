// @ts-nocheck
// ... React Imports ...
import React, { useState } from 'react'
import { Bar, Line } from 'react-chartjs-2';

/**
 * @description This component represents the second device panel and includes functionalities related to selecting and displaying telemetry data for a second device.
 * @param {Array} secondDevice - Array of selected devices for the second panel.
 * @param {function} handleSecondChart - Function to handle chart selection in the second panel.
 * @param {function} setSecondValue - Function to set the second value.
 * @param {function} setSecondSelectedKey - Function to set the second selected key.
 * @param {Array} secondDeviceDataPoints - Array of data points for the second device panel.
 * @param {Array} secondDataPointValues - Array of data point values for the second panel.
 * @param {boolean} checked2 - Boolean indicating whether the second panel is checked.
 * @param {function} handleSecondTelemetry - Function to handle telemetry for the second panel.
 * @param {boolean} barChart - Boolean indicating whether to display a bar chart.
 * @param {boolean} lineChart - Boolean indicating whether to display a line chart.
 * @param {Array} secondKey - Array of selected keys for the second panel.
 * @param {string} secondValue - The current value for the second panel.
 * @param {function} handleSecondSelectedKey - Function to handle the selected key in the second panel.
 * @param {string} secondSelectedKey - The selected key in the second panel.
 * @param {boolean} secondBarChart - Boolean indicating whether to display a bar chart in the second panel.
 * @param {boolean} secondLineChart - Boolean indicating whether to display a line chart in the second panel.
 * @param {string} token - The authorization token.
 * @param {function} setChecked1 - Function to set the checked status for the first panel.
 * @param {function} setSecondEntityType - Function to set the entity type for the second panel.
 * @param {function} setSecondEntityId - Function to set the entity ID for the second panel.
 * @param {function} setSelectedCriteria - Function to set selected criteria.
 * @param {string} selectedCriteria - The selected criteria.
 * @param {string} secondEntityId - The entity ID for the second panel.
 * @param {function} setSecondKey - Function to set the keys for the second panel.
 * @category Devices
 * @returns {JSX.Element} Rendered second device panel.
 */
const SecondDevicePanel = ({ secondDevice, handleSecondChart, setSecondValue, setSecondSelectedKey, secondDeviceDataPoints, secondDataPointValues, checked2, handleSecondTelemetry, barChart, lineChart, secondKey, secondValue, handleSecondSelectedKey, secondSelectedKey, secondBarChart, secondLineChart, token, setChecked1, setSecondEntityType, setSecondEntityId, setSelectedCriteria, selectedCriteria, setSecondKey }) => {
    // State to store device data and active status for the second panel.
    const [secondDeviceData, setSecondDeviceData] = useState([]);
    const [deviceActive, setDeviceActive] = useState(false);

    /**
     * Fetches and handles the display of devices from Thingspad in the second device panel.
     */
    const handleSecondDevice = () => {
        fetch("https://dev.thingspad.io/api/tenant/deviceInfos?pageSize=30&page=0", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (Array.isArray(json.data)) {
                    const sortedData = json.data.sort((a, b) => {
                        return a.name.localeCompare(b.name, undefined, {
                            sensitivity: "base"
                        })
                    });
                    setChecked1(false)
                    setSecondDeviceData(sortedData)
                }
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    }

    /**
    * Sets the device data for the second selected device.
    * @param {Object} event - Event object.
    */
    const handleSecondDeviceChange = (event) => {
        const deviceName = event.target.value;
        secondDevice.push(deviceName)
        const clickedDevice = secondDeviceData.find((device) => device.name === deviceName);

        if (clickedDevice) {
            setSecondEntityType(clickedDevice.id.entityType);
            setSecondEntityId(clickedDevice.id.id);
            setDeviceActive(true);
            handleSecondTelemetry(token)
        }
        setSelectedCriteria(selectedCriteria)
        setChecked1(false)
        if (secondDevice.length > 0) {
            setSecondValue("")
            setSecondKey("")
            setSecondSelectedKey("")
            setSelectedCriteria("")
        }
    };

    /* Sets the data points for the second bar graph */
    const secondLabel = secondDeviceDataPoints

    // Options for the second bar chart.
    const secondBarOptions = {
        plugins: {
            legend: {
                display: false,
            }
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            },
            y: {
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            }
        }
    };

    // Data for the second bar chart.
    const secondBardata = {
        labels: secondLabel,
        datasets: [
            {
                label: '',
                data: secondDataPointValues,
                backgroundColor: '#F57600',
            },
        ],
    };

    // Data for the second line chart.
    const secondLineData = {
        labels: secondLabel,
        datasets: [{
            label: '',
            data: secondDataPointValues,
            fill: false,
            borderColor: '#FF5E03',
            tension: 0.1
        }]
    };

     // Options for the second line chart.
    const secondLineOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            },
            y: {
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            }
        }
    };


    return (
        <div>
            {checked2 && <div>
                <div className='devices-flex'>
                    <div className='devices-sensor-name-two' onClick={handleSecondDevice} onChange={handleSecondDeviceChange}>
                        <select className='devices-sensor-name-dropdown' id='sensor-dropdown'>
                            {secondDeviceData && secondDeviceData.length > 0 ? (
                                secondDeviceData.map((device) => (
                                    <option key={device.id.id}>{device.name}</option>
                                ))
                            ) : (<option>Device Name</option>)
                            }
                        </select>
                    </div>
                    <div className='devices-availability-container-two'>
                        {deviceActive ? (<div className='devices-availability-active devices-flex'>
                            <p>Active</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                <circle cx="2" cy="2" r="2" fill="#00D916" />
                            </svg>
                        </div>) : (<div className='devices-availability devices-flex'>
                            <p>NA</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                <circle cx="2" cy="2" r="2" fill="#9D9D9D" />
                            </svg>
                        </div>)}
                    </div>
                </div>
                <div className='devices-flex'>
                    <div className='devices-sensor-data-dropdown'>
                        <select className='devices-sensor-name-dropdown' id='sensor-dropdown' onChange={handleSecondSelectedKey}>
                            {secondKey && secondKey.length > 0 ? (
                                secondKey.map((device) => (
                                    <option key={device}>{device}</option>
                                ))
                            ) : (<option>Value Name</option>)
                            }
                        </select>
                    </div>
                    <div className='devices-sensor-live-data devices-flex'>
                        {secondValue ? (
                            <p>{secondValue}</p>
                        ) : (
                            <p>LIVE data pending</p>
                        )}
                    </div>
                </div>
                <div className='devices-flex-space-between'>
                    {(barChart || lineChart) && < div className='devices-history-container devices-flex'>
                        <p>{secondSelectedKey && secondSelectedKey.charAt(0).toUpperCase() + secondSelectedKey.slice(1)} History</p>
                        <div className='telemetry-history-dropdown'>
                            <select className='history-dropdown' onChange={handleSecondChart}>
                                <option>Last 1 second</option>
                                <option>Last 10 seconds</option>
                                <option selected="selected">Last 30 seconds</option>
                                <option>Last 1 minute</option>
                                <option>Last 30 minutes</option>
                                <option>Last 1 hour</option>
                                <option>Last 5 hours</option>
                                <option>Last 12 hours</option>
                                <option>Last 1 day</option>
                                <option>Last 2 days</option>
                                <option>Last 3 days</option>
                            </select>
                        </div>
                    </div>}
                </div>
                {secondBarChart && <Bar options={secondBarOptions} data={secondBardata} />}
                {secondLineChart && <Line options={secondLineOptions} data={secondLineData} />}
            </div>
            }
        </div>
    )
}

export default SecondDevicePanel