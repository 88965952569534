// @ts-nocheck
/**
 * Functional component: sectionView.jsx
 *
 * Author: Ankit Jangid
 */

import React, { useState } from "react";
import { useEffect } from "react";
import {
  Cesium3DTileset,
  Model,
  ClippingPlaneCollection,
  Cartesian3,
  ClippingPlane,
  Matrix4,
} from "cesium";
import "./sectionView.css";

/**
 * @description  `Component`: Enables the adjustment of a section plane's position and depth along different axes,
 * providing a dynamic way to explore 3D models within the viewer. Users can control the section plane through radio buttons
 * and sliders, enhancing the visualization experience.
 * @param { Viewer } viewer Cesium Viewer
 * @param { Object } resourceInFocus Asset in focus (via user click or recent load)
 * @param { Resource } resourceObj Cesium Resource object for resource loading (contains URL and Headers)
 * @param { Boolean } isVisible Sidebar visibility
 * @returns { JSX.Element } Geolocation UI
 */
const SectionView = ({ viewer, resourceInFocus, isVisible }) => {
  // ... State hook declarations ...

  // .. Distance at which the clipping plane is to be rested ..
  const [clipDistance, setClipDistance] = useState(0);

  // .. Bounding sphere radius of the Tileset or Model, used for determining section slider range ..
  const [boundingSphereRadius, setBoundingSphereRadius] = useState(0);

  // .. Setting of active clipping section plane ..
  const [activeSectionPlane, setActiveSectionPlane] = useState(null);

  // .. Function to update updateSectionPlane variable to input planeDirection ..
  const updateSectionPlane = (planeDirection) => {
    setActiveSectionPlane(planeDirection);
  };

  // .. Update the boundingSphereRadius state when resourceInFocus changes ..
  useEffect(() => {
    // .. Reset plane and slider value ..
    setActiveSectionPlane(null);
    setClipDistance(0);

    if (resourceInFocus) {
      try {
        if (resourceInFocus instanceof Cesium3DTileset) {
          const finalBoundingSphere =
            resourceInFocus.root.boundingVolume.boundingSphere.radius /
            resourceInFocus.extras.scale.x;
          setBoundingSphereRadius(finalBoundingSphere);
        } else if (resourceInFocus instanceof Model) {
          setBoundingSphereRadius(resourceInFocus._boundingSphere.radius);
        }
      } catch (error) {
        console.log("Error reading some property of the resourceInFocus");
      }
    }
  }, [resourceInFocus]);

  // .. Dynamically updating the clipping distance for respective section plane with change in clipDistance ..
  useEffect(() => {
    try {
      if (activeSectionPlane === "YZ") {
          resourceInFocus._clippingPlanes.modelMatrix = Matrix4.fromTranslation(new Cartesian3(clipDistance, 0.0, 0.0))
      } else if (activeSectionPlane === "XY") {
          resourceInFocus._clippingPlanes.modelMatrix = Matrix4.fromTranslation(new Cartesian3(0.0, clipDistance, 0.0))
      } else if (activeSectionPlane === "XZ") {
          resourceInFocus._clippingPlanes.modelMatrix = Matrix4.fromTranslation(new Cartesian3(0.0, 0.0, clipDistance))
      }
    } catch (error) {
      console.log(" ERROR : ", error);
    }
  }, [clipDistance]);

  // .. Updates clipDistance based on slider event ..
  const handleSectionSlider = (event) => {
    const newDistance = parseFloat(event.target.value);
    setClipDistance(newDistance);
    if(resourceInFocus instanceof Cesium3DTileset){
      resourceInFocus._extras.clippingPlanes.clipDistance = newDistance;
    }
    
  };

  // .. Adds clipping plane to resourceInFocus based on selected plane ..
  const handlePlaneChange = (event) => {
    try {
      // . Set the selected plane as active ..
      setActiveSectionPlane(event.target.value);
      const plane = event.target.value;
      let planeDirection = {
        x: null,
        y: null,
        z: null,
      };

      if (plane === "XY") {
        // . Lateral A plane .
        updateSectionPlane("XY");
        planeDirection.x = 0.0;
        planeDirection.y = 1.0;
        planeDirection.z = 0.0;
      }
      // . Lateral B plane .
      else if (plane === "YZ") {
        updateSectionPlane("YZ");
        planeDirection.x = 1.0;
        planeDirection.y = 0.0;
        planeDirection.z = 0.0;
      }
      // . Depth plane .
      else if (plane === "XZ") {
        updateSectionPlane("XZ");
        planeDirection.x = 0.0;
        planeDirection.y = 0.0;
        planeDirection.z = -1.0;
      }

      // .. Adding clippingPlane to appropriate orientation resourceInFocus ..
      const clippingPlanes = new ClippingPlaneCollection({
        planes: [
          new ClippingPlane(
            new Cartesian3(
              planeDirection.x,
              planeDirection.y,
              planeDirection.z
            ),
            20.0
          ),
        ],
        edgeWidth: 2.0,
      });

      if(resourceInFocus instanceof Cesium3DTileset){
        resourceInFocus.clippingPlanes = clippingPlanes;
        resourceInFocus.extras.clippingPlanes = {
          planeDirection: plane,
        };
      } else if(resourceInFocus instanceof Model){
 
        console.log("Section view for GLTF is not supported yet")

        // .. TODO : Look how to set clippingPlanes for a loaded Model (gltf) ..
        // resourceInFocus._clippingPlanes = clippingPlanes;
        
      }
      
    } catch (error) {
      console.log("ERR : ", error);
    }
  };



  return (
    <>
      {isVisible && (
        <div className="sectionView_container">
          <div className="panel-container panel-main-section">
            <div className="panel-inputGroup">
              <div className="sectionView-planes">
                <label className="sectionView-radioGroup">
                  <input
                    type="radio"
                    name="plane"
                    value="XY"
                    checked={activeSectionPlane === "XY"}
                    onChange={handlePlaneChange}
                  />
                  Lateral A
                </label>
                <br />

                <label className="sectionView-radioGroup">
                  <input
                    type="radio"
                    name="plane"
                    value="YZ"
                    checked={activeSectionPlane === "YZ"}
                    onChange={handlePlaneChange}
                  />
                  Lateral B
                </label>
                <br />

                <label className="sectionView-radioGroup">
                  <input
                    type="radio"
                    name="plane"
                    value="XZ"
                    checked={activeSectionPlane === "XZ"}
                    onChange={handlePlaneChange}
                  />
                  Depth
                </label>
              </div>
              <br />
              <label
                className="panel-subhead panel-text panel-text-center"
                htmlFor="heading"
              >
                Traverse
              </label>
              <input
                name="heading"
                type="range"
                disabled={activeSectionPlane ? false : true}
                min={-boundingSphereRadius}
                max={boundingSphereRadius}
                // step="0.01"
                value={clipDistance}
                onChange={handleSectionSlider}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SectionView;
