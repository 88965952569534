// @ts-nocheck
// ... React Imports ...
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * @description `Component`: The UserPopup component is a React component that renders a popup for adding a new user, including user name, email and user level inputs, and a "Create" button.
 * @param {Boolean} userListPopup Displays popup if the state is true.
 * @param {Boolean} userList Displays popup if user list is active.
 * @param {Object} newUsername Captures the username when a user enters it in the form.
 * @param {Object} newEmail Captures the email when a user enters it in the form.
 * @param {Object} userLevelDropdown Captures the user level when a user selects from the dropdown.
 * @param {Function} handleUserCreation Creates a new user and updates the list.
 * @param {Number} userId User ID.
 * @category Project Dashboard
 * @returns Renders User Popup.
 */
const UserPopup = ({
  userListPopup,
  setNewEmail,
  setNewUsername,
  userList,
  userToProject,
  userToGroup,
  newUsername,
  newEmail,
  userLevelDropdown,
  handleUserCreation,
  userId,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {userList && userListPopup && (
        <div className="projectDashboard_user-list-popup-container">
          <div
            className={
              userToGroup || userToProject
                ? "projectDashboard_user-list-upper-popup-container-second"
                : "projectDashboard_user-list-upper-popup-container-first"
            }
          >
            <div className="projectDashboard_user-popup">
              <div className="projectDashboard_user-email">
                <div className="projectDashboard_user-name">
                  <label id="popup-label">{t("main.username")}</label>
                  <input
                    id="user-input-popup"
                    type="text"
                    placeholder={t("main.add-username")}
                    maxLength="30"
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="projectDashboard_email">
                  <label id="popup-label">{t("main.email")}</label>
                  <input
                    id="email-input-popup"
                    type="text"
                    placeholder={t("main.add-email")}
                    maxLength="320"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="projectDashboard_user-email">
                <div className="projectDashboard_email">
                  <label id="popup-label">{t("main.user-level")}</label>
                  <select
                    name="language"
                    id="user-level"
                    ref={userLevelDropdown}
                  >
                    <option value="Viewer">{t("main.viewer")}</option>
                    <option value="Editor">{t("main.editor")}</option>
                  </select>
                </div>
              </div>
              <div className="projectDashboard_add-user-btn-container">
                <div
                  className={
                    newUsername === "" || newEmail === ""
                      ? "projectDashboard_add-user-btn disabled"
                      : "projectDashboard_add-user-btn"
                  }
                  onClick={(e) => {
                    handleUserCreation(e, userId);
                  }}
                >
                  <p>{t("main.add-user")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPopup;
