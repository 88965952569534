// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect } from "react";

// ... Cesium Imports ...
import {
  Cartesian3,
  Matrix4,
  Transforms,
  HeadingPitchRoll,
  JulianDate,
  Entity,
  Cesium3DTileset,
  Viewer,
  Model,
  Math,
} from "cesium";

/**
 * Function for controlling the orientation (heading, pitch, and roll) of a 3D model or tileset within a Cesium viewer. Angles can be adjusted using input fields, providing an interactive way to change the 3D object's orientation in real-time.
 * @param {Object} resourceInFocus Resource selected
 * @returns
 */
const Orientation = ({ resourceInFocus }) => {
  // ... Hooks ...
  const [heading, setHeading] = useState(0);
  const [pitch, setPitch] = useState(0);
  const [roll, setRoll] = useState(0);

  /**
   * Function to update modelMatrix of the tileset
   * @param {modelMatrix} tilesetModelMatrix
   * @param {Object} options
   * @returns updateModelMatrix
   */
  const updateModelMatrix = (tilesetModelMatrix, options) => {
    const position = options.position || new Cartesian3();
    options.position
      ? console.log("GOT POSITION")
      : Matrix4.getTranslation(tilesetModelMatrix, position);
    console.log("POS IN SCALE : ", position);

    Matrix4.getTranslation(tilesetModelMatrix, new Cartesian3());
    const orientation =
      options.orientation ||
      Transforms.headingPitchRollQuaternion(
        position,
        new HeadingPitchRoll.fromDegrees(0, 0, 0)
      );

    const scale = options.scale || new Cartesian3();
    options.scale
      ? console.log("Scale provided")
      : Matrix4.getScale(tilesetModelMatrix, scale);

    const updateModelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
      position,
      orientation,
      scale
    );

    return updateModelMatrix;
  };

  /**
   * Function to update the hpr values for resource in focus. ( gets called on each change in input values )
   * @param {Number} heading Heading in degrees
   * @param {Number} pitch Pitch in degrees
   * @param {Number} roll Roll in degrees
   */
  const updateHPR = (heading, pitch, roll) => {
    function degreesToRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    // .. Convert degrees to radians ..
    const headingRadians = degreesToRadians(heading);
    const pitchRadians = degreesToRadians(pitch);
    const rollRadians = degreesToRadians(roll);

    // .. Resource primtive type check ..
    if (resourceInFocus instanceof Model) {
      const position = new Cartesian3();
      Matrix4.getTranslation(resourceInFocus.modelMatrix, position);

      // . Calculate the new orientation quaternion .
      const orientation = Transforms.headingPitchRollQuaternion(
        position,
        new HeadingPitchRoll(headingRadians, pitchRadians, rollRadians)
      );

      const scaling = new Cartesian3();
      Matrix4.getScale(resourceInFocus.modelMatrix, scaling);
      const newModelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
        position,
        orientation,
        scaling
      );
      resourceInFocus.modelMatrix = newModelMatrix;
      resourceInFocus._modelResources = [
        {
          hpr: {
            h: Math.toDegrees(headingRadians),
            p: Math.toDegrees(pitchRadians),
            r: Math.toDegrees(rollRadians),
          },
        },
      ];
    } else if (resourceInFocus instanceof Cesium3DTileset) {
      // .. Geolocation info is retrievable through extras too ..
      // . Get the current position .
      const position = new Cartesian3();
      Matrix4.getTranslation(resourceInFocus.modelMatrix, position);

      // . Calculate the new orientation quaternion .
      const orientation = Transforms.headingPitchRollQuaternion(
        position,
        new HeadingPitchRoll(headingRadians, pitchRadians, rollRadians)
      );

      //  . Get the current scaling .
      const scaling = new Cartesian3();
      Matrix4.getScale(resourceInFocus.modelMatrix, scaling);

      const newModelMatrixOrientation = updateModelMatrix(
        resourceInFocus.modelMatrix,
        {
          orientation: orientation,
        }
      );
      // . Update orientation in extras .
      resourceInFocus.extras.hpr.h = heading;
      resourceInFocus.extras.hpr.p = pitch;
      resourceInFocus.extras.hpr.r = roll;
      resourceInFocus.modelMatrix = newModelMatrixOrientation;

      // . Update the unitQuaternion in extras .
      console.log(orientation);
      resourceInFocus.extras.ori_quaternion = orientation;
    }
  };

  /**
   * Event callback function for handling heading input change
   * @param {*} event input event (heading)
   */
  const handleHeadingChange = (event) => {
    const newHeading = parseFloat(event.target.value);
    setHeading(newHeading);
    updateHPR(newHeading, pitch, roll);
  };

  /**
   * Event callback function for handling pitch input change
   * @param {*} event input event (pitch)
   */
  const handlePitchChange = (event) => {
    const newPitch = parseFloat(event.target.value);
    setPitch(newPitch);
    updateHPR(heading, newPitch, roll);
  };

  /**
   * Event callback function for handling roll input change
   * @param {*} event input event (roll)
   */
  const handleRollChange = (event) => {
    const newRoll = parseFloat(event.target.value);
    setRoll(newRoll);
    updateHPR(heading, pitch, newRoll);
  };

  // . useEffect hook that triggers everytime a new resource in selected by user .
  useEffect(() => {
    if (resourceInFocus instanceof Model) {
      const { hpr } = resourceInFocus._modelResources[0];
      console.log(hpr.h, hpr.p, hpr.r);
      setHeading(hpr.h);
      setPitch(hpr.p);
      setRoll(hpr.r);
    } else if (resourceInFocus instanceof Cesium3DTileset) {
      if (resourceInFocus.extras.hpr) {
        setHeading(resourceInFocus.extras.hpr.h);
        setPitch(resourceInFocus.extras.hpr.p);
        setRoll(resourceInFocus.extras.hpr.r);
        console.log(" HPR SET : ", resourceInFocus.extras.hpr.h);
      } else {
        console.log("TS does not container HPR attributes");
      }
    }
  }, [resourceInFocus]);

  // ... JSX return ...
  // .. returns Heading, Pitch and Roll sliders and input fields ..
  return (
    <div>
      <div className="panel-inputGroup">
        <div className="geolocation_2inputGroup">
          <label className="panel-inputLabel panel-text" htmlFor="heading">
            Heading:
          </label>
          <input
            name="heading"
            type="number"
            min="-180"
            max="180"
            step="1"
            value={heading}
            className="geolocation_orientationInput"
            onChange={handleHeadingChange}
          />
        </div>
        <input
          name="heading"
          type="range"
          min="-180"
          max="180"
          step="1"
          value={heading}
          onChange={handleHeadingChange}
        />
      </div>

      <div className="panel-inputGroup">
        <div className="geolocation_2inputGroup">
          <label className="panel-inputLabel panel-text" htmlFor="pitch">
            Pitch:
          </label>
          <input
            name="pitch"
            type="number"
            min="-180"
            max="180"
            step="1"
            value={pitch}
            className="geolocation_orientationInput"
            onChange={handlePitchChange}
          />
        </div>
        <input
          name="pitch"
          type="range"
          min="-180"
          max="180"
          step="1"
          value={pitch}
          onChange={handlePitchChange}
        />
      </div>
      <div className="panel-inputGroup">
        <div className="geolocation_2inputGroup">
          <label className="panel-inputLabel panel-text" htmlFor="roll">
            Roll:
          </label>
          <input
            name="roll"
            type="number"
            min="-180"
            max="180"
            step="1"
            value={roll}
            className="geolocation_orientationInput"
            onChange={handleRollChange}
          />
        </div>
        <input
          name="roll"
          type="range"
          min="-180"
          max="180"
          step="1"
          value={roll}
          onChange={handleRollChange}
        />
      </div>
    </div>
  );
};

export default Orientation;
