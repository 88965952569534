// @ts-nocheck
/**
 * Function: `loadCZMLGLTF.js`
 *
 *Description:
 * An async function for loading and displaying 3D models in a Cesium viewer using CZML data.
 * It fetches CZML content, manages model IDs, and loads models with specified properties like position and orientation.
 *
 * Author: Ankit Jangid
 *
 */

import {
  Cartesian3,
  Math,
  Transforms,
  HeadingPitchRoll,
  Matrix4,
  Model,
  Credit,
  Cesium3DTileset,
} from "cesium";

/**
 * An async function for loading and displaying 3D models in a Cesium viewer using CZML data. It fetches CZML content, manages model IDs, and loads models with specified properties like position and orientation
 * @param {*} viewer Cesium Viewer
 * @param {*} resourceObj Cesium Resource object for resource loading
 * @param {*} gltfURL Absolute path to GLTF
 * @param {*} handleResourceInFocus In focus resource handler
 * @param {*} resourceToLoad GLTF selected from asset list.
 */
const loadCZMLGLTF = async (
  viewer,
  resourceObj,
  gltfURL,
  resourceToLoad,
  handleResourceInFocus
) => {
  viewer.scene.primitives._primitives =
    viewer.scene.primitives._primitives.filter((primitive) => {
      if (primitive instanceof Cesium3DTileset || primitive instanceof Model) {
        return false; // Exclude this primitive from the filtered array
      }
      return true; // Keep this primitive in the filtered array
    });

  console.log(" RS TO LOAD : ", resourceToLoad);
  try {
    // .. Get the gltf.czml file ..
    const response = await fetch(resourceObj.url, {
      method: "GET",
      headers: resourceObj.headers,
    });
    if (!response.ok) {
      throw new Error("Error while fetching Tileset");
    }
    const data = await response.json();
    console.log("DATA : ", data);

    const gltfObj = data[1];

    // .. Check if Model with same URL exists and ID them accordingly ..
    const numOfPrimitives = viewer.scene.primitives._primitives.length;
    let gltfId = 1;
    for (let i = 0; i < numOfPrimitives; i++) {
      if (viewer.scene.primitives._primitives[i] instanceof Model) {
        if (viewer.scene.primitives._primitives[i]._id == "GL_" + gltfId) {
          gltfId++;
        } else {
          viewer.scene.primitives._primitives[i]._id = "GL_" + gltfId;
          gltfId++;
        }
      }
    }

    // .. As cesium cannot apply flyTo method to Model primitive, camera position for the Model has to be declared explicitly ..
    // .. TODO : Calculate and apply appropriate orientation for camer too ..
    const camPosition = Cartesian3.fromDegrees(
      gltfObj.position.cartographicDegrees[0],
      gltfObj.position.cartographicDegrees[1],
      100000
    );

    // TODO : Retrieve GLTF attributes from czml instead
    const position = Cartesian3.fromDegrees(
      gltfObj.position.cartographicDegrees[0],
      gltfObj.position.cartographicDegrees[1],
      gltfObj.position.cartographicDegrees[2]
    );

    let orientation;
    let heading;
    let pitch;
    let roll;
    // .. Use the fetched gltf obj to set properties for the gltf to be loaded ..
    // .. Load the GLTF with 0 degree HPR values ..
    if (gltfObj.orientation?.hpr) {
      heading = Math.toRadians(gltfObj.orientation.hpr.h);
      pitch = Math.toRadians(gltfObj.orientation.hpr.p);
      roll = Math.toRadians(gltfObj.orientation.hpr.r);
    } else {
      heading = Math.toRadians(0);
      pitch = Math.toRadians(0);
      roll = Math.toRadians(0);
    }

    orientation = Transforms.headingPitchRollQuaternion(
      position,
      new HeadingPitchRoll(heading, pitch, roll)
    );

    let scaling;

    if (gltfObj.scale?.x) {
      scaling = new Cartesian3(
        gltfObj.scale.x,
        gltfObj.scale.y,
        gltfObj.scale.y
      );
    } else {
      scaling = new Cartesian3(1, 1, 1);
    }

    // .. Load Model ..
    try {
      resourceObj.url = gltfURL;
      const modelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
        position,
        orientation,
        scaling
      );

      console.log("MODEL NAME : ", resourceToLoad.asset_name);
      const newModel = await Model.fromGltfAsync({
        id: "GL_" + gltfId,
        credit: resourceToLoad.asset_name,
        url: resourceObj,
        modelMatrix: modelMatrix,
        // minimumPixelSize: 128,
      });
      viewer.scene.primitives.add(newModel);

      // .. Add orientation into modelResources member of Model ..
      newModel._modelResources[0] = gltfObj.orientation;

      // TODO CHECK BELOW : ?
      // .. Add assetConlusion into modelResources member of Model ..
      // newModel.assetConclusion = gltfObj.assetConclusion;

      viewer.camera.flyTo({
        destination: camPosition,
        duration: 2,
      });
      // .. Set the loaded model to focus ..
      if (handleResourceInFocus) {
        handleResourceInFocus(newModel);
      }
    } catch (error) {
      console.log(`Failed to load model. ${error}`);
    }
  } catch (error) {
    console.log(" ERROR : ", error);
  }
};

export default loadCZMLGLTF;
