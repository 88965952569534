// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect } from "react";

// ... Cesium Imports ...
import {
  Cartesian3,
  Matrix4,
  Transforms,
  HeadingPitchRoll,
  Cesium3DTileset,
  Entity,
  JulianDate,
  Model,
} from "cesium";

/**
 * Functional component that updates asset's scale value based on DOM input (sliders / input) (uniform scaling)
 * @param {Viewer} viewer Cesium Viewer
 * @param {Object} resourceInFocus Resource in focus in viewer
 * @returns {JSX.Element} Scaling slider and input box.
 */
const Scaling = ({ viewer, resourceInFocus }) => {
  // ... Hooks ...
  const [scale, setScale] = useState(1);

  /**
   * Function to update modelMatrix of the tileset
   * @param {modelMatrix} tilesetModelMatrix
   * @param {*} options
   * @returns updatedModelMatrix
   */
  const updateModelMatrix = (tilesetModelMatrix, options) => {
    const position = options.position || new Cartesian3();
    options.position
      ? console.log("position present")
      : Matrix4.getTranslation(tilesetModelMatrix, position);

    Matrix4.getTranslation(tilesetModelMatrix, new Cartesian3());
    const orientation =
      options.orientation ||
      Transforms.headingPitchRollQuaternion(
        position,
        HeadingPitchRoll.fromDegrees(
          resourceInFocus.extras.hpr.h,
          resourceInFocus.extras.hpr.p,
          resourceInFocus.extras.hpr.r
        )
      );

    const scale = options.scale || new Cartesian3();
    options.scale
      ? console.log("scale present")
      : Matrix4.getScale(tilesetModelMatrix, scale);

    const updatedModelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
      position,
      orientation,
      scale
    );

    return updatedModelMatrix;
  };

  /**
   * Event callback function for handling scale input change
   * @param {*} event
   */
  const handleScaleChange = (event) => {
    const newScale = parseFloat(event.target.value);
    setScale(newScale);
    if(newScale >= 0){
      if (resourceInFocus instanceof Cesium3DTileset) {
        const newModelMatrixOrientation = updateModelMatrix(
          resourceInFocus.modelMatrix,
          {
            scale: new Cartesian3(newScale, newScale, newScale),
          }
        );
        // . update scale in extras .
        resourceInFocus.extras.scale.x = newScale;
        resourceInFocus.extras.scale.y = newScale;
        resourceInFocus.extras.scale.z = newScale;
  
        // . update modelMatrix .
        resourceInFocus.modelMatrix = newModelMatrixOrientation;
      } else if (resourceInFocus instanceof Model) {
        const position = new Cartesian3();
        Matrix4.getTranslation(resourceInFocus.modelMatrix, position);
        // . Calculate the new orientation quaternion .
        var orientation = Transforms.headingPitchRollQuaternion(
          position,
          new HeadingPitchRoll.fromDegrees(
            resourceInFocus._modelResources[0].hpr.h,
            resourceInFocus._modelResources[0].hpr.p,
            resourceInFocus._modelResources[0].hpr.r
          )
        );
        const scaling = new Cartesian3(newScale, newScale, newScale);
        const newModelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
          position,
          orientation,
          scaling
        );
        resourceInFocus.modelMatrix = newModelMatrix;
      }
    }
    
  };

  // . useEffect hook that triggers everytime a new resource in selected by user .
  useEffect(() => {
    if (resourceInFocus instanceof Model) {
      const scaling = new Cartesian3();
      Matrix4.getScale(resourceInFocus.modelMatrix, scaling);
      setScale(scaling.x);
    } else if (resourceInFocus instanceof Cesium3DTileset) {
      setScale(resourceInFocus.extras.scale.x);
    }
  }, [resourceInFocus]);

  // ... JSX Return ...
  // . Scaling slider and input field .
  return (
    <div className="panel-inputGroup">
      <div className="geolocation_2inputGroup">
        <label className="panel-inputLabel panel-text" htmlFor="scaleInput">
          Scale:{" "}
        </label>
        <input
          type="number"
          id="scaleInput"
          min="0.001"
          step="0.001"
          value={scale}
          className="geolocation_orientationInput"
          onChange={handleScaleChange}
        />
      </div>
      {/* <input
        type="range"
        id="scaleInput"
        min="0.001"
        step="0.001"
        max="10"
        value={scale}
        onChange={handleScaleChange}
      /> */}
    </div>
  );
};

export default Scaling;
