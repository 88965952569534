// @ts-nocheck
/**
 * Functional component: sectionView.jsx
 *
 * Author: Ankit Jangid + Kavish Bamboli
 */

// ... React Imports ...
import React, { useEffect, useState } from "react";

// ... Cesium Imports ...
import {
  JulianDate,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  defined,
} from "cesium";

// ... Misc ...
import SectionView from "../editor/editorFunctions/sectionView/sectionView";
import TreeView from "../features/TreeView";
import AnnotationsViewer from "./viewerFunctions/AnnotationsViewer";
import MilestonesViewer from "./viewerFunctions/MilestonesViewer";
import PresetPointsViewer from "./viewerFunctions/PresetPointsViewer";
import "./viewerInterface.css";
import TimelineViewer from "../features/TimelineViewer";
import Devices from "../editor/editorFunctions/devices/devices";

/**
 * @description `Component`: Lists assets, allows visibility control, and offers easy navigation to view them.
 * Users can switch between viewer features.
 * @param { Viewer } viewer Cesium Viewer
 * @param { Number } viewerFeature Viewer Feature index selected in VisioWindow
 * @param { Object } resourceInFocus Asset in focus (via user click or recent load)
 * @param { Boolean } refreshData State that changes upon new asset addition from AssetDropDown
 * @param { Boolean } isVisible Sidebar visibility
 * @returns { JSX.Element } Viewer Feature Components
 */
const ViewerInterface = ({
  viewer,
  viewerFeature,
  resourceInFocus,
  setViewerFeature,
  isVisible,
  deviceList,
  token,
  packetElementKey,
  packetElementId,
  handleProject,
  project,
  sendMessage,
  websocketData,
  selectedLabel,
  labelKey,
  labelValue
}) => {
  /* Function enabling flying to preset point on click of the same */
  const flyToPresetPoint = (presetPoint) => {
    viewer.scene.camera.flyTo({
      destination: presetPoint.position.getValue(JulianDate.now()),
      orientation: {
        direction: presetPoint.properties.getValue(JulianDate.now()).direction,
        up: presetPoint.properties.getValue(JulianDate.now()).up,
      },
    });
  };

  useEffect(() => {
    /* Click handler to check if user has clicked on preset point in the viewer */
    const clickHandler = new ScreenSpaceEventHandler(viewer.scene.canvas);
    clickHandler.setInputAction(function (click) {
      const pickedObject = viewer.scene.pick(click.position);
      if (defined(pickedObject)) {
        if (pickedObject?.id?.id?.toString().startsWith("PSPoint"))
          flyToPresetPoint(pickedObject.id);
      }
    }, ScreenSpaceEventType.LEFT_CLICK);

    var element = document.getElementById("visioContainer");
    var duplicateViewerElement = document.getElementById(
      "duplicateViewerContainer"
    );

    const movementHandler = new ScreenSpaceEventHandler(viewer.scene.canvas);
    movementHandler.setInputAction(function (movement) {
      const pickedObject = viewer.scene.pick(movement.endPosition);

      /* Checking if mouse hover on a preset point entity. If so changing cursor style */
      if (
        defined(pickedObject) &&
        pickedObject?.id?.id?.toString().startsWith("PSPoint")
      ) {
        element.style.cursor = "pointer";
        if (duplicateViewerElement)
          duplicateViewerElement.style.cursor = "pointer";
      } else {
        element.style.cursor = "default";
        if (duplicateViewerElement)
          duplicateViewerElement.style.cursor = "default";
      }
    }, ScreenSpaceEventType.MOUSE_MOVE);

    /* Destorying event handlers */
    return () => {
      clickHandler.destroy();
      movementHandler.destroy();
    };
  }, []);

  return (
    <>
      {viewerFeature !== -1 && viewerFeature !== 6 && (
        <div className="panel-container panel-title">
          <h2 className="panel-heading panel-text">
            {viewerFeature === 0
              ? "Assets"
              : viewerFeature === 1
                ? "Annotations"
                : viewerFeature === 2
                  ? "Construction Schedule"
                  : viewerFeature === 3
                    ? "Preset Views"
                    : viewerFeature === 4
                      ? "Section View"
                      : ""}
          </h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="panel-close-icon"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            onClick={() => setViewerFeature(-1)}
          >
            <mask
              id="mask0_2148_1048"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="25"
            >
              <rect y="0.0175781" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2148_1048)">
              <path
                d="M6.4 19.0176L5 17.6176L10.6 12.0176L5 6.41758L6.4 5.01758L12 10.6176L17.6 5.01758L19 6.41758L13.4 12.0176L19 17.6176L17.6 19.0176L12 13.4176L6.4 19.0176Z"
                fill="#9EA93F"
              />
            </g>
          </svg>
        </div>
      )}
      {viewerFeature === 0 ? (
        <div className="viewer_tree">
          <TreeView viewer={viewer} handleProject={handleProject} />
        </div>
      ) : viewerFeature === 1 ? (
        <div className="viewer_annotation">
          <AnnotationsViewer viewer={viewer} />
        </div>
      ) : viewerFeature === 2 ? (
        <div className="viewer_time">
          <MilestonesViewer viewer={viewer} />
        </div>
      ) : viewerFeature === 3 ? (
        <div className="viewer_camera">
          <PresetPointsViewer viewer={viewer} />
        </div>
      ) : viewerFeature === 4 ? (
        <div className="viewer_sectionn">
          <SectionView
            viewer={viewer}
            resourceInFocus={resourceInFocus}
            isVisible={isVisible}
          />
        </div>
      ) : (
        <></>
      )}
      {(deviceList) && <Devices token={token} labelValue={labelValue} labelKey={labelKey} selectedLabel={selectedLabel} websocketData={websocketData} sendMessage={sendMessage} viewer={viewer} project={project} packetElementKey={packetElementKey} packetElementId={packetElementId} />}
    </>
  );
};

export default ViewerInterface;
