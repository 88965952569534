import React from 'react'
import { useTranslation } from "react-i18next";

const Lists = ({ projectList, assetList, userList, groupList, deviceList, showUserList, handleSetActive }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="projectDashboard_lower-heading-right">
                <p
                    id="project"
                    onClick={(e) => handleSetActive(e.target.id)}
                    className={projectList ? "active-tab" : "inactive"}
                >
                    {t("main.projects")}
                </p>
                <p
                    id="asset"
                    onClick={(e) => handleSetActive(e.target.id)}
                    className={assetList ? "active-tab" : "inactive"}
                >
                    {t("main.assets")}
                </p>
                {showUserList && <p
                    id="user"
                    onClick={(e) => handleSetActive(e.target.id)}
                    className={userList ? "active-tab" : "inactive"}
                >
                    {t("main.users")}
                </p>}
                {showUserList && <p
                    id="group"
                    onClick={(e) => handleSetActive(e.target.id)}
                    className={groupList ? "active-tab" : "inactive"}
                >{t("main.groups")}</p>}
                <p
                    id="device"
                    onClick={(e) => handleSetActive(e.target.id)}
                    className={deviceList ? "active-tab" : "inactive"} >
                    {t("main.thingspad")}
                </p>
            </div>
        </div>
    )
}

export default Lists