// @ts-nocheck
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import secureLocalStorage from "react-secure-storage";

export default function Testing() {
  console.log("TESTING COM");
  const jwtToken = secureLocalStorage.getItem("token");
  const userId = jwt_decode(jwtToken).user_id;
  console.log("TOKEN : ", jwtToken);
  const fetchAccountDetails = () => {
    fetch(
      process.env.REACT_APP_API + "/accounts/master_account_details/" + 1003,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log("JSON", json);
        if (json.code == 100) {
          const userDetails = {
            username: json.data[0].username,
            email: json.data[0].email,
          };
          console.log(userDetails.username);
        } else if (json.code == 51) {
          console.log("Authentication OK, but item not found");
        }
      });
  };

  useEffect(() => {
    fetchAccountDetails();
  });
}
