// @ts-nocheck
// ... React Imports ...
import React from 'react'

/**
 * @description `DeleteTenantPopup`: Represents a confirmation popup for deleting a tenant in the admin dashboard.
 * @param {Function} setShowDeleteTenantPopup A function to control the visibility of the delete tenant popup.
 * @param {Function} handleDeleteTenant A function to handle the tenant deletion.
 * @param {boolean} showDeleteTenantPopup Indicates whether the delete tenant popup should be displayed.
 * @returns 
 */
const DeleteTenantPopup = ({ setShowDeleteTenantPopup, handleDeleteTenant, showDeleteTenantPopup }) => {

  /**
  * Closes the delete tenant confirmation popup.
  */
  const closeDeletePopup = () => {
    setShowDeleteTenantPopup(false)
  }

  return (
    <div>
      {showDeleteTenantPopup && <div className='confirm-delete-popup'>
        <p>Are you sure you want to delete the selected tenant ?</p>
        <div className="delete-btn-container">
          <div id="delete-btn-cancel" onClick={closeDeletePopup}>Cancel</div>
          <div id="confirm-delete-btn" onClick={handleDeleteTenant}>Delete</div>
        </div>
      </div>}
    </div>
  )
}

export default DeleteTenantPopup