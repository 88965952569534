// @ts-nocheck
/**
 * Component: `visioWindow.jsx`
 *
 * Author: Ankit Jangid
 */

import { Cartesian3, Cesium3DTileset, Matrix4, Model } from "cesium";
import useAssetsList from "./useAssetsList";
import { useEffect } from "react";

/**
 * @description `Component`: Functional component to display viewer assets. provides functionalities for toggling asset visibility,
 * flying the camera to selected assets, and removing assets from the viewer. Users can interact with the assets
 * through checkboxes and related actions.
 * @param { Viewer } viewer Cesium viewer
 * @returns { JSX.Element } Tree view component
 */
const TreeView = ({ viewer, handleProject }) => {
  const { assetList, setAssetList } = useAssetsList(viewer);

  /**
   * Toggles visibility of asset selected from Asset List in DOM
   * @param { Object } asset Name and ID (key value pair) of asset
   */
  const toggleVisibility = (asset) => {
    // . Lazy logic for toggling show property and flyto too .
    for (const primitive of viewer.scene.primitives._primitives) {
      if (
        primitive instanceof Cesium3DTileset &&
        primitive.extras.id == asset.id &&
        !primitive._hasMixedContent
      ) {
        primitive.show = !primitive.show;
      } else if (
        primitive instanceof Model &&
        primitive.id == asset.id &&
        !primitive.releaseGltfJson
      ) {
        primitive.show = !primitive.show;
      }
    }

    setAssetList((prevData) => {
      var newData = [...prevData];
      newData = newData.map((item) => {
        if (item.id === asset.id) return { ...item, show: !item.show };
        return item;
      });
      return newData;
    });
  };

  /**
   * Flies camera to asset selectes in Asset List in DOM
   * @param {*} resource
   */
  const flyToAsset = (resource) => {
    handleProject(resource)
    console.log(resource)
    for (const primitive of viewer.scene.primitives._primitives) {
      if (
        primitive instanceof Cesium3DTileset &&
        primitive.extras.id == resource.id &&
        !primitive._hasMixedContent
      ) {
        viewer.flyTo(primitive);
      } else if (
        primitive instanceof Model &&
        primitive.id == resource.id &&
        !primitive.releaseGltfJson
      ) {
        //flyto to de refined for GLTFs
        var position2 = new Cartesian3();
        Matrix4.getTranslation(primitive.modelMatrix, position2);
        var options = {
          destination: position2,
          duration: 2,
        };
        viewer.camera.flyTo(options);
      }
    }
  };

  /**
   * Removes asset from Viewer (TO BE OPTIMIZED)
   * @param {*} resource
   */
  const removeAsset = (resource) => {
    /**
     * Filtering instead of using cesiums "primitive.remove()" API as it fails to work for Model Primitives
     */
    viewer.scene.primitives._primitives =
      viewer.scene.primitives._primitives.filter((primitive) => {
        if (
          primitive instanceof Cesium3DTileset &&
          primitive.extras.id == resource.id &&
          !primitive._hasMixedContent
        ) {
          return false;
        } else if (
          primitive instanceof Model &&
          primitive.id == resource.id &&
          !primitive.releaseGltfJson
        ) {
          return false;
        } else {
          return true;
        }
      });

    setAssetList((prevData) => {
      var newData = [...prevData];
      newData = newData.filter((asset) => asset.id !== resource.id);
      return newData;
    });
  };

  return (
    <>
      <div className="panel-container panel-main-section">
        <h4 className="panel-subhead"></h4>
        <hr />

        <ul className="panel-subhead panel-text">
          {Array.isArray(assetList) &&
            assetList.map((resource, index) => (
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
                key={resource.id}
              >
                <input
                  checked={resource.show}
                  type="checkbox"
                  onChange={() => toggleVisibility(resource)}
                />
                <li onClick={() => flyToAsset(resource)} key={index}>
                  {resource.name} {resource.id} {resource.show}
                </li>

                <svg
                  onClick={() => {
                    removeAsset(resource);
                  }}
                  style={{ marginLeft: "auto" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <mask
                    id="mask0_2605_1154"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="21"
                    height="21"
                  >
                    <rect width="21" height="21" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2605_1154)">
                    <path
                      d="M6.12891 18.375C5.64766 18.375 5.23568 18.2036 4.89297 17.8609C4.55026 17.5182 4.37891 17.1062 4.37891 16.625V5.25H3.50391V3.5H7.8789V2.625H13.1289V3.5H17.5039V5.25H16.6289V16.625C16.6289 17.1062 16.4575 17.5182 16.1148 17.8609C15.7721 18.2036 15.3602 18.375 14.8789 18.375H6.12891ZM14.8789 5.25H6.12891V16.625H14.8789V5.25ZM7.8789 14.875H9.6289V7H7.8789V14.875ZM11.3789 14.875H13.1289V7H11.3789V14.875Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </div>
            ))}
        </ul>
      </div>
    </>
  );
};

export default TreeView;
