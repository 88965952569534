// @ts-nocheck
import { useEffect } from "react";
import { useState } from "react";

/**
 * Custom hook for fetching and updating the list of preset points
 * @param { Viewer } viewer Cesium viewer
 * @return { { presetList: Array, setPresetList: Function } } List of preset points and setter function to update preset points list
 */
const usePresetPoints = (viewer) => {
  const [presetList, setPresetList] = useState([]);
  const [entitiesUpdated, setEntitiesUpdated] = useState(false);

  useEffect(() => {
    var _points = [];
    viewer.entities.values.forEach((entity) => {
      entity.id.toString().startsWith("PSPoint") && _points.push(entity);
    });
    setPresetList(_points);
  }, [entitiesUpdated]);

  return { presetList, setPresetList, setEntitiesUpdated };
};

export default usePresetPoints;
