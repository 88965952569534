// @ts-nocheck
// ... React Imports ...
import React from 'react'

// ... NPM Packages ...
import { useTranslation } from "react-i18next";

// ... Components ...
import './topBar.css'

/**
 * @description `Component`: This component represents the top bar of the admin dashboard page, which includes a "Tenant List" heading, an "Add Tenant" button, a search bar, and sort criteria selection.
 * @param {Boolean} setShowAddTenantPopup A function that controls the visibility of the "Add Tenant" popup.
 * @param {Function} handleFilterCriteriaChange A function to handle changes in the filter criteria.
 * @param {String} searchQuery Used to filter and display tenants based on the user's search input.
 * @param {Function} handleSearchQueryChange A function to handle changes in the search query.
 * @category Admin Dashboard
 * @returns {JSX.Element} Renders Top Bar.
 */
const TopBar = ({
    setShowAddTenantPopup,
    handleFilterCriteriaChange,
    searchQuery,
    handleSearchQueryChange }) => {
    // .. Constants ..
    const { t } = useTranslation();

    /**
     * Sets the add tenant popup to be visible
     */
    const handleAddTenant = () => {
        setShowAddTenantPopup(true);
    }
    return (
        <div>
            <div className='topBar_main-container'>
                <div className='topBar_content-container'>
                    <div className='topBar_left-heading'>
                        <p>Tenant List</p>
                    </div>
                    <div className='topBar_right-content'>
                        <div className='topBar_add-tenant-btn-container'>
                            <div className='topBar_add-tenant-btn flex' onClick={handleAddTenant}>
                                <p>+ Add Tenant</p>
                            </div>
                        </div>
                        <div className="projectDashboard_search-bar">
                            <div className="search-input-container">
                                <input
                                    id='projectDashboard_search-bar-input'
                                    type="text"
                                    value={searchQuery}
                                    placeholder={t("main.search")}
                                    onChange={handleSearchQueryChange}
                                />
                            </div>
                        </div>
                        <div
                            className="topBar_filter"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <mask id="mask0_2605_1897" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
                                    <rect width="18" height="18" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_2605_1897)">
                                    <path d="M8.25177 15.0001C8.03927 15.0001 7.86114 14.9282 7.71739 14.7845C7.57364 14.6407 7.50177 14.4626 7.50177 14.2501V9.75012L3.15177 4.20012C2.96427 3.95012 2.93614 3.68762 3.06739 3.41262C3.19864 3.13762 3.42677 3.00012 3.75177 3.00012H14.2518C14.5768 3.00012 14.8049 3.13762 14.9361 3.41262C15.0674 3.68762 15.0393 3.95012 14.8518 4.20012L10.5018 9.75012V14.2501C10.5018 14.4626 10.4299 14.6407 10.2861 14.7845C10.1424 14.9282 9.96427 15.0001 9.75177 15.0001H8.25177ZM9.00177 9.22512L12.7143 4.50012H5.28927L9.00177 9.22512Z" fill="white" />
                                </g>
                            </svg>
                            <select
                                id="select"
                                onChange={handleFilterCriteriaChange}
                            >
                                <option>Last Login</option>
                                <option>ID</option>
                                <option>Name</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar