// @ts-nocheck
/**
 * Function: loadNew3DTile.js
 *
 * Author: Ankit Jangid
 */
// ... Cesium Imports ...
import {
  Cesium3DTileset,
  Cartesian3,
  Transforms,
  HeadingPitchRoll,
  Matrix4,
  sampleTerrainMostDetailed,
  Math,
  EllipsoidTerrainProvider,
} from "cesium";

/**
 * @description `Function`: Asynchronous function designed to load and visualize 3D tilesets within a Cesium viewer.
 * It fetches tileset data, processes georeferencing information (if available), and positions the tileset within
 * the viewer, also manages the uniqueness of tileset.
 * @param { Viewer } viewer Cesium Viewer
 * @param { Resource } resourceObj Cesium Resource Object
 * @param { Object } handleResourceInFocus Asset in focus
 * @param { Object } selectedResource Intially loaded resource
 */
const loadNew3DTile = async (
  viewer,
  resourceObj,
  handleResourceInFocus,
  selectedResource,
  insertToCurrLoc,
  setAssetAdded,
  setAssetFailed,
  setAssetDropDown
) => {
  let georeferenced;
  let position;
  let orientation;
  let scaling;

  /**
   * Fetches tileset.json
   * Determines if tileset is already georeferenced.
   * If yes then loads tileset to predefined position (extras)
   * If no then loads tileset to default location.
   */
  try {
    // .. Fetching the tileset via tileset url present in resourceObj also provider authentication data via headers ..
    const response = await fetch(resourceObj.url, {
      method: "GET",
      headers: resourceObj.headers,
    });
    if (!response.ok) {
      throw new Error("Error while fetching tileset.json");
    }

    // .. Destructure the response object and get its extras member ..
    const { extras } = await response.json();
    georeferenced = extras.georeferenced;

    console.log("insert to curr loc ", insertToCurrLoc);

    // Set current location clamped to terrain as geolocation if insert to current location is checked
    if (insertToCurrLoc) {
      if (viewer.terrainProvider instanceof EllipsoidTerrainProvider) {
        const cartographic = viewer.camera.positionCartographic;
        const latitude = Math.toDegrees(cartographic.latitude);
        const longitude = Math.toDegrees(cartographic.longitude);
        position = Cartesian3.fromDegrees(longitude, latitude, 0);
      } else {
        await sampleTerrainMostDetailed(viewer.terrainProvider, [
          viewer.camera.positionCartographic,
        ]).then((updatedPositions) => {
          const longitude = Math.toDegrees(updatedPositions[0].longitude);
          const latitude = Math.toDegrees(updatedPositions[0].latitude);
          position = Cartesian3.fromDegrees(
            longitude,
            latitude,
            updatedPositions[0].height
          );
        });
      }

      orientation = Transforms.headingPitchRollQuaternion(
        position,
        new HeadingPitchRoll.fromDegrees(0, 0, 0)
      );
      scaling = new Cartesian3(1, 1, 1);
    }

    // .. If tileset was georeferenced. Read for the georef properties ..
    else if (georeferenced) {
      // . Check if tileset is of type cityGML .
      if (georeferenced === "cgml") {
        console.log(" Loading CityGML");
      } else {
        // . extract position .
        position = Cartesian3.fromDegrees(
          extras.position.longitude,
          extras.position.latitude,
          extras.position.height
        );

        // . extract orientation .
        orientation = Transforms.headingPitchRollQuaternion(
          position,
          new HeadingPitchRoll.fromDegrees(
            extras.hpr.h,
            extras.hpr.p,
            extras.hpr.r
          )
        );

        // . extract scaling .
        scaling = new Cartesian3(
          extras.scale.x,
          extras.scale.y,
          extras.scale.z
        );
      }
    } else {
      // .. Else set default geolocation properties ..
      position = Cartesian3.fromDegrees(
        76.61758113143246,
        28.192182356673207,
        0
      );

      orientation = Transforms.headingPitchRollQuaternion(
        position,
        new HeadingPitchRoll.fromDegrees(0, 0, 0)
      );
      scaling = new Cartesian3(1, 1, 1);
    }

    // ..
    const newTileset = await Cesium3DTileset.fromUrl(resourceObj);
    viewer.scene.primitives.add(newTileset);

    // . Neutralize tileset's root transform .
    // .. NOTE : do not assign a custom transform matrix to tileset when loading a City GML ..
    if (georeferenced == "cgml") {
      console.log(" Tileset is a cityGML");
    } else {
      const modelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
        position,
        orientation,
        scaling
      );
      newTileset.root.transform = Matrix4.IDENTITY;
      newTileset.modelMatrix = modelMatrix;
      newTileset.pointCloudShading.attenuation = true;
      newTileset.pointCloudShading.eyeDomeLighting = true;
    }

    // .. Assign assetConclusion info ..

    if (extras.assetConclusion) {
      newTileset.extras.assetConclusion = extras.assetConclusion;
    }

    // TODO : Name to be fed into extras during tileset.json creation
    newTileset.extras.name = selectedResource.asset_name;
    handleResourceInFocus(newTileset);
    viewer.flyTo(newTileset);

    // . Check if TS with same URL exists and ID them accordingly .
    const numOfPrimitives = viewer.scene.primitives._primitives.length;
    let numOfTS = 1;
    for (let i = 0; i < numOfPrimitives; i++) {
      if (viewer.scene.primitives._primitives[i] instanceof Cesium3DTileset) {
        // console.log("3D TILESET : ", viewer.scene.primitives._primitives[i]);
        if (
          viewer.scene.primitives._primitives[i].extras.id ==
          "TS" + numOfTS
        ) {
          // console.log("TILE ID exists");
          numOfTS++;
        } else {
          viewer.scene.primitives._primitives[i].extras.id = "TS" + numOfTS;
          // console.log("TS LOADED WITH ID : ", "TS" + numOfTS);
          numOfTS++;
          setAssetAdded(true);
          setTimeout(() => {
            setAssetAdded(false);
            setAssetDropDown(false);
          }, 1000);
        }
      }
    }
    // newTileset.debugShowBoundingVolume = true;
  } catch (e) {
    console.log("Got an error while loading tileset : ", e);
    setAssetFailed(true);
    setTimeout(() => {
      setAssetAdded(false);
      setAssetDropDown(false);
    }, 1000);
  }
};

export default loadNew3DTile;
