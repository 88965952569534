// @ts-nocheck
// ... React Imports ...
import React from 'react'

/**
 * @description This component represents a Checkbox section in a React application. It includes functionalities for handling checkboxes, chart selection, and redirecting to the Thingspad dashboard.
 * @param {boolean} checked1 - Boolean indicating whether the first checkbox is checked.
 * @param {boolean} checked2 - Boolean indicating whether the second checkbox is checked.
 * @param {string} token - The authorization token.
 * @param {string} selectedChart - The currently selected chart type.
 * @param {boolean} secondBarChart - Boolean indicating whether to display a bar chart in the second panel.
 * @param {boolean} secondLineChart - Boolean indicating whether to display a line chart in the second panel.
 * @param {function} handleCheckbox1Change - Function to handle changes in the first checkbox.
 * @param {function} handleCheckbox2Change - Function to handle changes in the second checkbox.
 * @param {boolean} barChart - Boolean indicating whether to display a bar chart in the first panel.
 * @param {boolean} lineChart - Boolean indicating whether to display a line chart in the first panel.
 * @param {function} handleBarChart - Function to handle bar chart selection in the first panel.
 * @param {function} handleLineChart - Function to handle line chart selection in the first panel.
 * @param {function} handleSecondBarChart - Function to handle bar chart selection in the second panel.
 * @param {function} handleSecondLineChart - Function to handle line chart selection in the second panel.
 * @category Devices
 * @returns {JSX.Element} Rendered checkbox.
 */
const Checkbox = ({ checked1, checked2, token, selectedChart, secondBarChart, secondLineChart, handleCheckbox1Change, handleCheckbox2Change, barChart, lineChart, handleBarChart, handleLineChart, handleSecondBarChart, handleSecondLineChart }) => {

   /**
   * Redirects the user to the Thingspad dashboard page.
   */
    const handleClick = () => {
        const thingspadUrl = 'http://dev.thingspad.io/entities/devices?pageSize=30&token=' + token;
        window.location.href = thingspadUrl;
    };

    return (
        <div className={checked2 ? 'devices-open-in-thingspad-container-two' : (barChart || lineChart) ? 'devices-open-in-thingspad-container' : 'devices-open-in-thingspad-container-initial'}>
            {(barChart || lineChart) && <div className='devices-checkbox'>
                <label className="checkbox-container">
                    <input type="checkbox" className="checkbox-input" checked={checked1} onChange={handleCheckbox1Change} />
                    <div className={checked1 ? "custom-checkbox-active" : "custom-checkbox"}>
                        <div id='devices-checkmark' className='checkmark'>
                        </div>
                    </div>
                    <span className={checked1 ? 'span-text-active' : 'span-text'}>1</span>
                </label>
                <label className="checkbox-container">
                    <input type="checkbox" className="checkbox-input" checked={checked2} onChange={handleCheckbox2Change} />
                    <div className={checked2 ? "custom-checkbox-active" : "custom-checkbox"}>
                        <div id='devices-checkmark' className='checkmark'>
                        </div>
                    </div>
                    <span className={checked2 ? 'span-text-active' : 'span-text'}>2</span>
                </label>
                {(barChart || lineChart) && < div className={checked2 ? 'bar-line-active-container-two devices-flex-column' : 'bar-line-active-container devices-flex-column'}>
                    <div className='bar-active-container devices-flex' onClick={handleBarChart}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <mask id="mask0_3964_813" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                <rect width="24" height="24" fill="#FFF" />
                            </mask>
                            <g mask="url(#mask0_3964_813)">
                                <path d="M16 20V13H20V20H16ZM10 20V4H14V20H10ZM4 20V9H8V20H4Z" fill={selectedChart === 'bar' ? "#9EA940" : "#FFF"} />
                            </g>
                        </svg>
                    </div>
                    <div className='bar-active-container devices-flex' onClick={handleLineChart}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 6L15.7071 11.2929C15.3166 11.6834 14.6834 11.6834 14.2929 11.2929L12.7071 9.70711C12.3166 9.31658 11.6834 9.31658 11.2929 9.70711L7 14" stroke={selectedChart === 'line' ? "#9EA940" : "#FFF"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3 3V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21H21" stroke={selectedChart === 'line' ? "#9EA940" : "#FFF"} strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>}
                {checked2 && <div className='devices-bar-chart-active-second-devide-container devices-flex-column'>
                    <div className='bar-active-container devices-flex' onClick={handleSecondBarChart}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <mask id="mask0_3964_813" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                <rect width="24" height="24" fill="#FFF" />
                            </mask>
                            <g mask="url(#mask0_3964_813)">
                                <path d="M16 20V13H20V20H16ZM10 20V4H14V20H10ZM4 20V9H8V20H4Z" fill={secondBarChart ? "#9EA940" : "#FFF"} />
                            </g>
                        </svg>
                    </div>
                    <div className='bar-active-container devices-flex' onClick={handleSecondLineChart}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 6L15.7071 11.2929C15.3166 11.6834 14.6834 11.6834 14.2929 11.2929L12.7071 9.70711C12.3166 9.31658 11.6834 9.31658 11.2929 9.70711L7 14" stroke={secondLineChart ? "#9EA940" : "#FFF"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3 3V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21H21" stroke={secondLineChart ? "#9EA940" : "#FFF"} strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>}
            </div>}
            <div className='devices-thingspad-page-btn devices-flex' onClick={handleClick}>
                <p>Open in ThingsPad</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <mask id="mask0_3974_1101" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="13">
                        <rect x="1.43555" width="11" height="11" transform="rotate(7.5 1.43555 0)" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_3974_1101)">
                        <path d="M3.25299 8.5624L2.70057 7.84247L7.63724 4.05443L3.72929 3.53994L3.84894 2.63111L9.30189 3.34901L8.584 8.80195L7.67517 8.68231L8.18966 4.77436L3.25299 8.5624Z" fill="#979797" />
                    </g>
                </svg>
            </div>
        </div>
    )
}

export default Checkbox
