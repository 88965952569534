// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Viewer,
  Ion,
  CesiumTerrainProvider,
  Resource,
  JulianDate,
  BingMapsImageryProvider,
  BingMapsStyle,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
} from "cesium";
import { useNavigate } from "react-router-dom";
import "./containerFarm.css";
import loadJSONProject from "../../components/cesiumAPI/loadJSONProject";
import PresetLogic from "./components/presetLogic";

const ContainerFarm = () => {
  const navigation = useNavigate();
  const [viewer, setViewer] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API + "/auth/jwt/create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: "jangidankit@gmail.com",
        password: "tenant@1",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        Cesium.Ion.defaultAccessToken =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1ZDdmZWViMS0xZDVjLTRhYTUtYWI4Yy0yOGFkZmI4YjNmZGIiLCJpZCI6NTI3ODIsImlhdCI6MTYyMjU1NTY4M30.2Qry6HDCock7YrMi8GQaStykhvfbCfuJ-TDs7IRglmY";
        const viewer = new Viewer("cesium-Container", {
          terrain: Cesium.Terrain.fromWorldTerrain(),
          // terrainProvider: terrain,
          geocoder: false,
          baseLayerPicker: false,
        });

        // .. Make it day time ..
        const ISO8601 = "2023-10-01T00:00:00Z";
        viewer.clock.currentTime = JulianDate.fromIso8601(ISO8601);

        // ... Default Imagery and Terrain Provider ...
        const imageryTerrainLoader = async () => {
          // .. ArcGIS Terrain Provider ..
          const terrainProvider =
            await Cesium.ArcGISTiledElevationTerrainProvider.fromUrl(
              "https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer",
              {
                token:
                  "AAPK2099f81e64c6478c8922b18a20b89895bHIYvtbs39mvnK6gwF8TM7oYzkvYPu9O5sSFldOBkqBP17BKH9uT2DmujnIc1vkt",
              }
            );
          // viewer.terrainProvider = terrainProvider;

          const bing = await BingMapsImageryProvider.fromUrl(
            "https://dev.virtualearth.net",
            {
              key: "AmJAP16ARM2JD-U91lhBL3OxkLMrRpllxjMh1a--OYg9Yh2mgL-3qxjdpWKXg3M0",
              mapStyle: BingMapsStyle.ROAD,
            }
          );
          viewer.scene.imageryLayers.addImageryProvider(bing);
        };
        imageryTerrainLoader();

        const resourceObject = (resourceSpecificUrl) => {
          return new Resource({
            url: resourceSpecificUrl,
            headers: {
              Authorization: "JWT " + json.access,
            },
          });
        };

        loadJSONProject(
          viewer,
          resourceObject(
            "https://livolv.com:8000/api/project/get_url/?file_id=P291"
          )
        );
        setViewer(viewer);
        // const position = new Cesium.Cartesian3.fromDegrees(-75.1641667, 39.9522222, 5000); // Longitude, Latitude, Altitude
        const heading = Cesium.Math.toRadians(45); // Heading in radians
        const pitch = Cesium.Math.toRadians(-30); // Pitch in radians
        const roll = 0; // Roll in radians
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            135.77483583250114,
            34.99585741534009,
            400
          ),
          // orientation: {
          //   heading: heading,
          //   pitch: pitch,
          //   roll: roll,
          // },
        });
      })
      .catch((error) => {
        console.log("Network Error : ", error);
        navigation("/networkError");
      });

    return () => {
      try {
        viewer.entities.removeAll();
        viewer.scene.primitives.removeAll();
        viewer.destroy();
      } catch (error) {
        console.log("Error during destroying viewer : ", error);
      }
    };
  }, []);

  return (
    <>
      <div className="demo-window">
        <div id="cesium-Container" className="cesium-container"></div>
      </div>
      {viewer && <PresetLogic viewer={viewer} />}
    </>
  );
};

export default ContainerFarm;
