import React from 'react'
import { useTranslation } from "react-i18next";

const DeletePopup = ({ setUnassignGroup, unassignGroup, removeGroupFromProject, selectedResourceType, unassignAssetToGroup }) => {
    const { t } = useTranslation();

    const closeDeletePopup = () => {
        setUnassignGroup(false)
    }
    return (
        <div>
            {unassignGroup && (
                <div className="confirm-delete-popup">
                    <p>{t("main.are-you-sure-you-want-to-unassign-this-group?")}</p>
                    <div className="delete-btn-container">
                        <div id="delete-btn-cancel" onClick={closeDeletePopup}>
                            {t("main.cancel")}
                        </div>
                        <div id="confirm-delete-btn" onClick={selectedResourceType === "project" ? removeGroupFromProject : unassignAssetToGroup}>
                            {t("main.unassign")}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DeletePopup