// @ts-nocheck
// ... React Imports ...
import React, { useState, useRef, useEffect } from 'react'

// ... NPM Packages ...
import {
    Cartesian2,
    Cartesian3,
    Cartographic,
    JulianDate,
    Math,
    ScreenSpaceEventHandler,
    ScreenSpaceEventType,
    defined,
} from "cesium";
import { Bar, Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';

// ... Components
import FirstDevicePanel from './components/firstDevicePanel/firstDevicePanel';
import SecondDevicePanel from './components/secondDevicePanel/secondDevicePanel';
import Checkbox from './components/checkbox/checkbox';

// ... Misc ...
import './devices.css';

/**
 * @description A component for creating and managing real time annotations and displaying telemetry data from Thingspad.
 * @param {String} token The bearer token coming from the Thingspad login
 * @param {Viewer} viewer Cesium Viewer
 * @param {Object} packetElementKey Key names of all the saved annotations
 * @param {Object} sendMessage sendMessage hook of react-use-websocket
 * @param {Object} websocketData Data received from the web socket connection
 * @returns {JSX.Element} Thinspad devices component
 */
const Devices = ({ token, selectedLabel, labelValue, labelKey, viewer, packetElementKey, sendMessage, websocketData }) => {
    // ... State hook declarations ...

    // ... First Device Hooks
    const [entityType, setEntityType] = useState(null);
    const [entityId, setEntityId] = useState(null);
    const [deviceName, setDeviceName] = useState(null);
    const [valueName, setValueName] = useState([]);
    const [selectedKey, setSelectedKey] = useState("");
    const [value, setValue] = useState(null);
    const [selectedChart, setSelectedChart] = useState("bar");
    const [dataPoints, setDataPoints] = useState([]);
    const [selectedCriteria, setSelectedCriteria] = useState("Last 30 seconds");
    const [dataPointValues, setDataPointValues] = useState([]);
    const [widget, setWidget] = useState(false);
    const [firstKey, setFirstKey] = useState('');
    const [dataReceived, setDataReceived] = useState(false);
    const [addWidget, setAddWidget] = useState(true);

    // ... Second Device Hooks
    const [secondEntityType, setSecondEntityType] = useState(null);
    const [secondEntityId, setSecondEntityId] = useState(null);
    const [secondKeyValues, setSecondKeyValues] = useState([]);
    const [secondKey, setSecondKey] = useState([]);
    const [secondSelectedKey, setSecondSelectedKey] = useState("");
    const [secondValue, setSecondValue] = useState(null);
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [barChart, setBarChart] = useState(false);
    const [lineChart, setLineChart] = useState(false);
    const [secondBarChart, setSecondBarChart] = useState(false);
    const [secondLineChart, setSecondLineChart] = useState(false);
    const [secondDeviceDataPoints, setSecondDeviceDataPoints] = useState([]);
    const [secondDataPointValues, setSecondDataPointValues] = useState([]);
    const [annotations, setAnnotations] = useState([])
    const [select, setSelect] = useState(false);
    const [entity, setEntity] = useState('');

    // .. Other Hooks
    const keyRef = useRef([]);
    const labelContainerRef = useRef(null);
    const device = [];
    const secondDevice = [];

    useEffect(() => {
        // Check if websocketData and at least one of the selected keys are present
        if (token) {
            if ((websocketData && selectedKey) || (websocketData && secondSelectedKey) || labelKey) {
                //  If the selectedKey in present in the web socket message then update the value
                if (selectedKey || labelKey) {
                    if ((selectedKey in websocketData && firstKey === "First device sensor") || labelKey in websocketData) {
                        // Update the value based on websocket data
                        if (labelKey) {
                            setValue(websocketData[labelKey][0][1]);
                        } else {
                            setValue(websocketData[selectedKey][0][1]);
                        }
                        // Update annotations dynamically
                        if (annotations.length > 0) {
                            const uniqueAnnotationIds = new Set();

                            for (let i = 0; i < annotations.length; i++) {
                                if (!uniqueAnnotationIds.has(annotations[i].id)) {
                                    uniqueAnnotationIds.add(annotations[i].id);

                                    // Find and update heading element for each annotation
                                    const headingElement = document.getElementById(annotations[i].id);

                                    if (headingElement) {
                                        const heading = headingElement.querySelector(`.annotation-heading`);

                                        if (heading) {
                                            // Check if the label exists in the websocketData before updating
                                            const labelToUpdate = annotations[i].label;
                                            if (websocketData[labelToUpdate] && websocketData[labelToUpdate][0]) {
                                                heading.innerHTML = labelToUpdate + ':' + websocketData[labelToUpdate][0][1];
                                            } else {
                                                console.error(`Label "${labelToUpdate}" not found in websocketData`);
                                            }
                                        } else {
                                            console.error(`Heading element not found for annotation with ID: ${annotations[i].id}`);
                                        }
                                    } else {
                                        console.error(`HTML element not found for annotation with ID: ${annotations[i].id}`);
                                    }
                                }
                            }
                        }

                        // If bar or line graphs are selected then updates the data points dynamically
                        if (barChart || lineChart) {
                            if (dataPoints && dataPointValues) {
                                dataPoints.shift();
                                const currentDateandTime = Date.now();
                                const date = new Date(currentDateandTime);
                                const formattedDate = date.toLocaleString("en-GB", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                });
                                dataPoints.push(formattedDate)
                                setDataPoints(dataPoints)
                                dataPointValues.shift()
                                if (labelKey) {
                                    dataPointValues.push(websocketData[labelKey][0][1])
                                } else {
                                    dataPointValues.push(websocketData[selectedKey][0][1])
                                }
                                setDataPointValues(dataPointValues)
                            }
                        }
                    }
                }

                // If both device panels are selected then update the values for both the selected sensors.
                if (selectedKey && secondSelectedKey in websocketData) {
                    // Update values for the first selected key
                    if (websocketData[selectedKey] && websocketData[selectedKey][0]) {
                        setValue(websocketData[selectedKey][0][1]);

                        // Update data points dynamically
                        if (dataPoints && dataPointValues) {
                            dataPoints.shift();
                            const currentDateandTime = Date.now();
                            const date = new Date(currentDateandTime);
                            const formattedDate = date.toLocaleString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                            });
                            dataPoints.push(formattedDate)
                            setDataPoints(dataPoints)
                            dataPointValues.shift()
                            dataPointValues.push(websocketData[selectedKey][0][1])
                            setDataPointValues(dataPointValues)
                        }
                    }

                    // Combine data from websocket and received data
                    const combinedData = {
                        ...websocketData,
                        ...dataReceived
                    };
                    if (websocketData[secondSelectedKey] && websocketData[secondSelectedKey][0]) {
                        // Update value for the second selected key
                        setSecondValue(websocketData[secondSelectedKey][0][1]);

                        // Update data points for the second device dynamically
                        if (dataPoints && dataPointValues && secondDeviceDataPoints && secondDataPointValues) {
                            secondDeviceDataPoints.shift();
                            const currentDateandTime = Date.now();
                            const date = new Date(currentDateandTime);
                            const formattedDate = date.toLocaleString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                            });
                            secondDeviceDataPoints.push(formattedDate)
                            setSecondDeviceDataPoints(secondDeviceDataPoints)

                            secondDataPointValues.shift()
                            secondDataPointValues.push(websocketData[secondSelectedKey][0][1])
                            setSecondDataPointValues(secondDataPointValues)

                            // If the first selected key is present, update its value and data points
                            if (selectedKey in combinedData) {
                                setValue(combinedData[selectedKey][0][1]);

                                dataPoints.shift();
                                dataPoints.push(formattedDate)
                                setDataPoints(dataPoints)
                                dataPointValues.shift()
                                dataPointValues.push(combinedData[selectedKey][0][1])
                                setDataPointValues(dataPointValues)
                            }
                        }
                    }
                    if (dataReceived) {
                        setDataReceived(combinedData)
                    } else {
                        setDataReceived(websocketData)
                    }
                }
            }
        }
    }, [websocketData])


    /**
     * Sets the value for the selected key and sends a web socket request to get latest value for the key
     * @param {Object} event Event object
     */
    const handlSelectedKey = (event) => {
        let selectedKeyName;
        if (labelKey) {
            selectedKeyName = labelKey
        } else {
            selectedKeyName = event.target.value;
        }
        const selectedKey = valueName.find((key) => key === selectedKeyName);

        if (selectedKey) {
            const deviceKey = selectedKey;
            setSelectedKey(deviceKey);
            setFirstKey("First device sensor")
            handleKeyValues(token);
        }
        handleTelemetry(token);
    }

    /**
     * Sets the value for the selected key and sends a web socket request to get latest value for the key
     * @param {Object} event Event object
     */
    const handleSecondSelectedKey = (event) => {
        const keyName = event.target.value;
        setSecondSelectedKey(keyName);
        setFirstKey("Second device sensor")

        handleSecondTelemetry(token);
        handleSecondKeyValues(token);
        setSecondDataPointValues("")
        setChecked1(false)
        handleSecondValues(secondSelectedKey, secondEntityId, secondEntityType)
        if (secondSelectedKey && secondEntityId && secondEntityType) {
            handleSecondChart(event);
        }

        // Sends request to get websocket data for the selected entityType and entityId
        const request = {
            tsSubCmds: [
                {
                    entityType: secondEntityType,
                    entityId: secondEntityId,
                    scope: "LATEST_TELEMETRY",
                    cmdId: 10,
                },
            ],
            historyCmds: [],
            attrSubCmds: [],
        };
        const requestData = JSON.stringify(request);
        sendMessage(requestData);
    }

    /** 
     * Gets all the telemetry keys for the first selected device
     * @param {String} token Thingspad token
     */
    const handleTelemetry = (token) => {
        fetch(`https://dev.thingspad.io:443/api/plugins/telemetry/${entityType}/${entityId}/keys/timeseries`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                keyRef.current = json;
                setValueName(keyRef.current);
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    /** 
    * Gets all the telemetry keys for the second selected device
    * @param {String} token Thingspad token
    */
    const handleSecondTelemetry = (token) => {
        if (secondEntityType && secondEntityId) {
            fetch(`https://dev.thingspad.io:443/api/plugins/telemetry/${secondEntityType}/${secondEntityId}/keys/timeseries`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    keyRef.current = json;
                    setSecondKey(keyRef.current);
                    setChecked1(false);
                })
                .catch((error) => {
                    console.error("Error: ", error);
                });
        }
    };

    /** 
     * Gets all the telemetry values for the selected key of first selected device
     * @param {String} token Thingspad token
     */
    const handleKeyValues = (token) => {
        console.log("Entity type is", entityType)
        console.log("Entity type is", entityId)
        fetch(`https://dev.thingspad.io:443/api/plugins/telemetry/${entityType}/${entityId}/values/timeseries?keys=${valueName}&useStrictDataTypes=false`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                const keys = Object.keys(json);
                setValueName(keys);
                console.log(keys)
                if (selectedKey) {
                    setValue(json[selectedKey][0].value)
                }
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    /** 
     * Gets all the telemetry values for the selected key of second selected device
     * @param {String} token Thingspad token
     */
    const handleSecondKeyValues = (token) => {
        fetch(`https://dev.thingspad.io:443/api/plugins/telemetry/${secondEntityType}/${secondEntityId}/values/timeseries?keys=${secondKey}&useStrictDataTypes=false`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                setSecondKeyValues(json);
                const keys = Object.keys(json);
                setSecondKey(keys);
                if (secondSelectedKey) {
                    setSecondValue(json[secondSelectedKey][0].value)
                }
                setChecked1(false)
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    /* Sets initial telemetry value for the selected key of second device until a web socket message is received */
    const handleSecondValues = () => {
        if (secondSelectedKey && keyRef.current !== null && secondKeyValues[secondSelectedKey] && secondKeyValues[secondSelectedKey].length > 0) {
            setSecondValue(secondKeyValues[secondSelectedKey][0].value);
        }
    }

    useEffect(() => {
        if (entityType && entityId) {
            handleTelemetry(token);
        }
        if (entityType && entityId && selectedKey && labelKey) {
            handleKeyValues(token);
        }
        if (secondEntityType && secondEntityId) {
            handleSecondTelemetry(token);
        }
        if (secondEntityType && secondEntityId && secondSelectedKey) {
            handleSecondKeyValues(token);
        }
        if (secondSelectedKey) {
            handleSecondSelectedKey(event)
        }
    }, [entityType, entityId, selectedKey, secondEntityType, secondEntityId, secondSelectedKey]);

    /* Handles the display of graphs on click of first checkbox */
    const handleCheckbox1Change = () => {
        setChecked1(true);
        setChecked2(false);
        setWidget(false)

        if (selectedChart === "bar") {
            setBarChart(true);
            setLineChart(false);
        } else {
            setLineChart(true);
            setBarChart(false)
        }
    };

    /* Handles the display of graphs on click of second checkbox */
    const handleCheckbox2Change = () => {
        setChecked2(true);
        setChecked1(false);

        if (selectedChart === 'bar') {
            setSecondBarChart(true);
            setSecondLineChart(false)
        } else {
            setSecondBarChart(false)
            setSecondLineChart(true)
        }
    };

    /* Displays the bar or line graph options */
    const handleWidget = () => {
        setWidget(true);
        if (widget) {
            setChecked1(true)
        }
        if (checked2) {
            setChecked1(false)
        }
    }

    /* Sets the data points for the first bar graph */
    Chart.register(CategoryScale);
    const labels = dataPoints;

    const barOptions = {
        plugins: {
            legend: {
                display: false,
            }
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            },
            y: {
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            }
        }
    };

    const data = {
        labels: labels,
        datasets: [
            {
                label: '',
                data: dataPointValues,
                backgroundColor: '#F57600',
            },
        ],
    };

    /* Sets the data points for the first line graph */
    const lineOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            },
            y: {
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            }
        }
    };

    const lineData = {
        labels: labels,
        datasets: [{
            label: '',
            data: dataPointValues,
            fill: false,
            borderColor: '#FF5E03',
            tension: 0.1
        }]
    };

    /* Handles the display of first bar graph */
    const handleBarChart = () => {
        setBarChart(true);
        setLineChart(false)
        setSelectedChart('bar')
        setAddWidget(false)
        handleChart(event);
        setChecked1(true)
    }

    /* Handles the display of second bar graph */
    const handleSecondBarChart = () => {
        setSecondBarChart(true);
        setSecondLineChart(false)
        setWidget(widget);
        handleSecondChart(event);
    }

    /* Handles the display of first line graph */
    const handleLineChart = () => {
        setLineChart(true);
        setBarChart(false)
        setWidget(false);
        setSelectedChart('line')
        setAddWidget(false)
        handleChart(event);
    }

    /* Handles the display of second line graph */
    const handleSecondLineChart = () => {
        setSecondLineChart(true);
        setSecondBarChart(false)
        setWidget(false);
        setWidget(!widget);
    }

    /**
     * Function to calculate startTs and endTs for the selected option from the filter dropdown
     */
    const timeRangeMap = {
        "Last 1 second": { startTime: Date.now() - 1000, endTime: Date.now() },
        "Last 10 seconds": { startTime: Date.now() - 10000, endTime: Date.now() },
        "Last 30 seconds": { startTime: Date.now() - 30000, endTime: Date.now() },
        "Last 1 minute": { startTime: Date.now() - 60000, endTime: Date.now() },
        "Last 30 minutes": { startTime: Date.now() - 1800000, endTime: Date.now() },
        "Last 1 hour": { startTime: Date.now() - 3600000, endTime: Date.now() },
        "Last 5 hours": { startTime: Date.now() - 18000000, endTime: Date.now() },
        "Last 12 hours": { startTime: Date.now() - 43200000, endTime: Date.now() },
        "Last 1 day": { startTime: Date.now() - 86400000, endTime: Date.now() },
        "Last 2 days": { startTime: Date.now() - 172800000, endTime: Date.now() },
        "Last 3 days": { startTime: Date.now() - 259200000, endTime: Date.now() },
    };

    /**
    * Handles the display of telemetry data on the bar and line graph of first device
    * @param {Object} Event event object
    */
    const handleChart = (event) => {
        // Extract the selected filter criteria from the event.
        let selectedFilterCriteria = event.target.value;

        // If no criteria is selected or a device name is present, set the criteria to "Last 30 seconds."
        // Otherwise, use the selected value.
        !selectedFilterCriteria || deviceName ? selectedFilterCriteria = "Last 30 seconds" : event.target.value;

        // Get the time range based on the selected filter criteria.
        const timeRange = timeRangeMap[selectedFilterCriteria];
        setSelectedCriteria(selectedCriteria);
        console.log(selectedKey)
        fetch(`https://dev.thingspad.io:443/api/plugins/telemetry/${entityType}/${entityId}/values/timeseries?keys=${labelKey ? labelKey : selectedKey}&startTs=${timeRange.startTime}&endTs=${timeRange.endTime}&limit=100000&agg=NONE&orderBy=ASC&useStrictDataTypes=false`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                console.log(json)
                // Check if the response contains data.
                if (Object.keys(json).length > 0) {
                    // Extract timestamps and values from the response.
                    const timeStamps = Object.values(json);
                    const data = timeStamps[0];
                    const values = [];
                    const tsValues = [];

                    // Format timestamps and values for display.
                    for (const item of data) {
                        const date = new Date(item.ts);
                        const formattedDate = date.toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        });
                        tsValues.push(formattedDate);
                        values.push(item.value)
                    }
                    setDataPointValues(values)
                    console.log(values)

                    // Format timestamps for better display.
                    let formattedValues = [];
                    for (let i = 0; i < tsValues.length; i++) {
                        const currentDateTime = tsValues[i];
                        const currentDate = currentDateTime.split(', ')[0];
                        const currentTime = currentDateTime.split(', ')[1];

                        if (i === 0 || currentDate !== tsValues[i - 1].split(', ')[0]) {
                            formattedValues.push(`${currentDate}, ${currentTime}`);
                        } else {
                            formattedValues.push(currentTime);
                        }
                    }
                    console.log(formattedValues)

                    // If there are more than 100 data points, group them into sets of 3 for better display.
                    if (formattedValues.length > 100) {
                        const groupedFormattedValues = [];
                        for (let i = 0; i < formattedValues.length; i += 3) {
                            const group = formattedValues.slice(i, i + 3);
                            groupedFormattedValues.push(group.join(', '));
                        }
                        setDataPoints(groupedFormattedValues);
                    }
                    else {
                        setDataPoints(formattedValues)

                    }

                    // Reset the first checkbox state and set the second checkbox state.
                    if (checked2) {
                        setChecked1(false)
                    } else {
                        setChecked2(false)
                    }
                } else {
                    // If no data is received, reset the component state.
                    setDataPoints("")
                    setDataPointValues("")
                }
            })
            .catch((error) => {
                console.error("Error : ", error);
                setDataPoints("")
            });
    }

    /**
    * Handles the display of telemetry data on the bar and line graph of second device
    * @param {Object} Event event object
    */
    const handleSecondChart = (event) => {
        // Extract the selected filter criteria from the event.
        let selectedFilterCriteria = event.target.value;
        event.target.value === secondSelectedKey ? (selectedFilterCriteria = "Last 30 seconds") : (selectedFilterCriteria = event.target.value);

        // Get the time range based on the selected filter criteria
        const timeRange = timeRangeMap[selectedFilterCriteria];
        setSelectedCriteria(selectedCriteria);
        fetch(`https://dev.thingspad.io:443/api/plugins/telemetry/${secondEntityType}/${secondEntityId}/values/timeseries?keys=${secondSelectedKey}&startTs=${timeRange.startTime}&endTs=${timeRange.endTime}&limit=100000&agg=NONE&orderBy=ASC&useStrictDataTypes=false`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                // Extract timestamps and values from the response.
                if (Object.keys(json).length > 0) {
                    const timeStamps = Object.values(json);
                    const data = timeStamps[0];
                    const values = [];
                    const tsValues = [];

                    // Format timestamps and values for display.
                    for (const item of data) {
                        const date = new Date(item.ts);
                        const formattedDate = date.toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        });
                        tsValues.push(formattedDate);
                        values.push(item.value)
                    }
                    setSecondDataPointValues(values)

                    // Format timestamps for better display.
                    let formattedValues = [];
                    for (let i = 0; i < tsValues.length; i++) {
                        const currentDateTime = tsValues[i];
                        const currentDate = currentDateTime.split(', ')[0];
                        const currentTime = currentDateTime.split(', ')[1];

                        if (i === 0 || currentDate !== tsValues[i - 1].split(', ')[0]) {
                            formattedValues.push(`${currentDate}, ${currentTime}`);
                        } else {
                            formattedValues.push(currentTime);
                        }
                    }
                    setSecondDeviceDataPoints(formattedValues);

                    // Reset the first checkbox state and set the second checkbox state.
                    if (checked2) {
                        setChecked1(false)
                    } else {
                        setChecked1(false)
                    }
                } else {
                    // If no data is received, reset the component state.
                    setSecondDeviceDataPoints("")
                    setSecondDataPointValues("")
                    setChecked1(false)
                }
            })
            .catch((error) => {
                console.error("Error : ", error);
                setSecondDeviceDataPoints("")
                setChecked1(false)
                setSecondSelectedKey("")
            });
    }

    let annotationCounter = 0;

    /* Adds the real time annotation at the selected position */
    const handleAddLabelToModel = () => {
        setSelect(true);

        // Creates a flag to track whether a click has been handled
        let isClickHandled = false;

        setTimeout(() => {
            setSelect(false);

            // Creates a new ScreenSpaceEventHandler instance to handle mouse events
            const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);

            // Callback function to handle left clicks
            const handleClick = (click) => {
                if (isClickHandled) {
                    return;
                }

                // Picks the object at the clicked position in the Cesium scene
                const pickedObject = viewer.scene.pick(click.position);

                // If an object was picked, proceeds with creating an annotation
                if (pickedObject) {
                    const cartesian = viewer.scene.pickPosition(click.position);
                    const cartographic = Cartographic.fromCartesian(cartesian);
                    const longitude = Math.toDegrees(cartographic.longitude);
                    const latitude = Math.toDegrees(cartographic.latitude);
                    const altitude = cartographic.height;

                    // Function to create the HTML overlay for the annotation
                    const createHtmlOverlay = (annotationEntity) => {
                        // Creates a new HTML div element for the annotation overlay
                        const overlay = document.createElement("div");
                        overlay.classList.add("devices-htmlOverlay");
                        labelContainerRef.current = overlay;

                        const circlePoint = document.createElement("div");
                        circlePoint.classList.add("annotation-point");
                        circlePoint.style.backgroundColor = "#FF5E03";
                        overlay.appendChild(circlePoint);

                        const line = document.createElement("div");
                        line.classList.add("annotation-line");
                        line.style.backgroundColor = "#FF5E03";
                        overlay.appendChild(line);

                        const heading = document.createElement("div");
                        heading.classList.add("annotation-heading");
                        heading.innerHTML = annotationEntity.originalLabel + ':' + annotationEntity.value;
                        heading.style.backgroundColor = "#191919";
                        heading.style.borderLeft = "4px solid #FF5E03";
                        heading.style.color = "#FFF";
                        heading.style.fontFamily = "Inter";
                        overlay.appendChild(heading);

                        const id = document.createElement("div");
                        id.classList.add("annotation-id");
                        id.innerHTML = annotationEntity.id;
                        id.style.display = "none"
                        overlay.appendChild(id);

                        document.body.appendChild(overlay);
                        return overlay;
                    };

                    // Function to create the annotation
                    const createAnnotation = () => {
                        // Determines the annotation counter based on existing annotations or packet element keys
                        if (annotations.length > 0) {
                            annotationCounter = annotations.length;
                        }
                        else if (packetElementKey.length > 0) {
                            annotationCounter = packetElementKey.length;
                        }
                        else {
                            annotationCounter = 0;
                        }
                        try {
                            const annotationId = `RTA_${deviceName} ${selectedKey}_${++annotationCounter}`;
                            const annotationEntity = viewer.entities.add({
                                position: Cartesian3.fromDegrees(longitude, latitude, altitude),
                                name: deviceName,
                                id: annotationId,
                                originalLabel: selectedKey,
                                value: value,
                                selectedKey: selectedKey,
                                entityType: entityType,
                                entityId: entityId
                            });

                            const scratch = new Cartesian2();
                            const canvasPosition = viewer.scene.cartesianToCanvasCoordinates(
                                annotationEntity.position.getValue(JulianDate.now()),
                                scratch
                            );

                            if (defined(canvasPosition)) {
                                const overlay = createHtmlOverlay(annotationEntity);
                                overlay.setAttribute("id", annotationId);
                                overlay.setAttribute("name", deviceName)
                                overlay.style.top = `${canvasPosition.y}px`;
                                overlay.style.left = `${canvasPosition.x}px`;
                                overlay.style.scale = 2;
                                document.body.appendChild(overlay);
                            }
                            return annotationEntity;
                        } catch (err) {
                            console.log(err);
                            return undefined;
                        }
                    };

                    createAnnotation();
                    isClickHandled = true;

                    // Extracts annotation data from the created HTML overlay
                    const annotationData = {
                        label: labelContainerRef.current.querySelector('.annotation-heading').textContent.split(':')[0],
                        value: labelContainerRef.current.querySelector('.annotation-heading').textContent.split(':')[1],
                        id: labelContainerRef.current.querySelector('.annotation-id').textContent.split(':')[0]
                    };
                    // Stores the newly created annotations in an array
                    setAnnotations((prevAnnotations) =>
                        Array.isArray(prevAnnotations)
                            ? [...prevAnnotations, annotationData]
                            : [annotationData]
                    );

                    // Removes the left-click event listener to prevent additional annotations
                    handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
                } else {
                    // If no object is picked, sets an "entity" flag and clears it after 2 seconds
                    setEntity(true);
                    setTimeout(() => {
                        setEntity(false);
                    }, 2000);
                }
            };
            handler.setInputAction(handleClick, ScreenSpaceEventType.LEFT_CLICK);
        }, 1000);
    };

    return (
        <div>
            <div className='devices-main-container'>
                {token ? <div className={checked2 ? "devices-panel-two" : "devices-panel"}>
                    <p id='devices-heading'>Thingspad</p>

                    <FirstDevicePanel
                        setEntityId={setEntityId}
                        setEntityType={setEntityType}
                        setDeviceName={setDeviceName}
                        handlSelectedKey={handlSelectedKey}
                        handleChart={handleChart}
                        valueName={valueName}
                        value={value}
                        selectedKey={selectedKey}
                        barChart={barChart}
                        lineChart={lineChart}
                        handleAddLabelToModel={handleAddLabelToModel}
                        keyRef={keyRef}
                        token={token}
                        dataPointValues={dataPointValues}
                        setDataPoints={setDataPoints}
                        setDataPointValues={setDataPointValues}
                        setSelectedCriteria={setSelectedCriteria}
                        selectedCriteria={selectedCriteria}
                        device={device}
                        setValue={setValue}
                        setValueName={setValueName}
                        setSelectedKey={setSelectedKey}
                        entityId={entityId}
                        entityType={entityType}
                        sendMessage={sendMessage}
                        selectedLabel={selectedLabel}
                        labelKey={labelKey}
                        labelValue={labelValue}
                        handleBarChart={handleBarChart}
                        handleKeyValues={handleKeyValues}
                    />

                    <div className={checked2 ? 'devices-add-widgets-container-three' : (barChart || lineChart) ? 'devices-add-widgets-container-two' : 'devices-add-widgets-container'} onClick={handleWidget}>
                        {!widget && !lineChart && !secondLineChart ? (
                            <>
                                {addWidget && <div className='devices-flex-column'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <mask id="mask0_3977_1130" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                            <rect width="24" height="24" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_3977_1130)">
                                            <path d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z" fill="#979797" />
                                        </g>
                                    </svg>
                                    <p>ADD WIDGETS TO VIEW</p>
                                </div>}
                            </>
                        ) : (
                            <>
                                {(barChart || lineChart) ? ("") : (<div className='bar-graph-container' id='bar-graph-container'>
                                    <p onClick={handleBarChart}>Bar Graph</p>
                                    <p onClick={handleLineChart}>Line Graph</p>
                                </div>)}
                            </>
                        )}
                        <div className={checked2 ? 'graph-container-two' : 'graph-container'} onClick={() => setChecked1(false)}>
                            {barChart && <Bar options={barOptions} data={data} />}
                            {lineChart && <Line options={lineOptions} data={lineData} />}

                            <SecondDevicePanel
                                checked2={checked2}
                                barChart={barChart}
                                lineChart={lineChart}
                                handleSecondTelemetry={handleSecondTelemetry}
                                handleSecondSelectedKey={handleSecondSelectedKey}
                                secondSelectedKey={secondSelectedKey}
                                secondBarChart={secondBarChart}
                                secondLineChart={secondLineChart}
                                token={token}
                                setChecked1={setChecked1}
                                setSecondEntityType={setSecondEntityType}
                                setSecondEntityId={setSecondEntityId}
                                secondEntityType={secondEntityType}
                                setSelectedCriteria={setSelectedCriteria}
                                selectedCriteria={selectedCriteria}
                                secondEntityId={secondEntityId}
                                keyRef={keyRef}
                                setSecondKey={setSecondKey}
                                timeRangeMap={timeRangeMap}
                                secondKey={secondKey}
                                secondValue={secondValue}
                                checked1={checked1}
                                secondDeviceDataPoints={secondDeviceDataPoints}
                                setSecondDeviceDataPoints={setSecondDeviceDataPoints}
                                secondDataPointValues={secondDataPointValues}
                                setSecondDataPointValues={setSecondDataPointValues}
                                secondDevice={secondDevice}
                                setSecondValue={setSecondValue}
                                setSecondKey={setSecondKey}
                                setSecondSelectedKey={setSecondSelectedKey}
                                handleSecondChart={handleSecondChart}
                            />
                        </div>


                    </div>
                    <Checkbox
                        checked1={checked1}
                        checked2={checked2}
                        barChart={barChart}
                        lineChart={lineChart}
                        handleCheckbox1Change={handleCheckbox1Change}
                        handleCheckbox2Change={handleCheckbox2Change}
                        handleBarChart={handleBarChart}
                        handleLineChart={handleLineChart}
                        handleSecondBarChart={handleSecondBarChart}
                        handleSecondLineChart={handleSecondLineChart}
                        token={token}
                        secondBarChart={secondBarChart}
                        secondLineChart={secondLineChart}
                        selectedChart={selectedChart}
                    />
                </div> : <div className="devices-login-msg devices-flex-column">
                    <p>Login to Thingspad to access the devices</p>
                </div>}
                {select && <div className='select-notification-container'>
                    <div className='select-notification devices-flex'>
                        <p>Please select a model</p>
                    </div>
                </div>}
                {entity && <div className='select-notification-container'>
                    <div className='error-notification devices-flex'>
                        <p>Selected point is not model</p>
                    </div>
                </div>}

            </div >
        </div>
    )
}

export default Devices



