import React, { useState, useEffect } from "react";
import "./userList.css";
import { useTranslation } from "react-i18next";
import plus from "../../../../assets/plus.png";
import secureLocalStorage from "react-secure-storage";
import UnassignPopup from "./popup/unassignPopup";

/**
 * @description `Component`: Renders a list of Users part of a project or a asset.
* @param {Array} getData - An array of user data.
 * @param {Function} setData - A function to set the user data.
 * @param {Function} fetchList - A function to fetch the user list.
 * @param {Function} fetchAssetList - A function to fetch the asset list.
 * @param {number} usersLength - The number of users.
 * @param {number} selectedResourceId - The ID of the selected resource.
 * @param {Array} users - An array of user data.
 * @param {Array} groups - An array of group data.
 * @param {string} selectedResourceType - The type of the selected resource (e.g., "project" or "asset").
 * @param {Function} setSelectedItemID - A function to set the selected item's ID.
 * @param {Function} handleAssignUserToAsset - A function to handle assigning a user to an asset.
 * @param {Function} handleAssignGroupToAsset - A function to handle assigning a group to an asset.
 * @param {Function} handleAssignGroupToProject - A function to handle assigning a group to a project.
 * @param {Function} handleAssignUserToProject - A function to handle assigning a user to a project.
 * @param {boolean} assign - A boolean indicating if an assignment action is in progress.
 * @param {Function} setAssign - A function to set the assignment status.
 * @param {Function} setFailureMessage - A function to set the failure message.
 * @param {Function} setFailure - A function to set the failure status.
 * @param {Function} setSuccessMsg - A function to set the success message.
 * @param {Function} setSuccess - A function to set the success status.
 * @returns {JSX.Element} Rendered User List.
 */
const UserList = ({
  getData,
  setData,
  fetchList,
  fetchAssetList,
  usersLength,
  selectedResourceId,
  resourceUsers,
  resourceGroups,
  selectedResourceType,
  setSelectedItemID,
  handleAssignUserToAsset,
  handleAssignGroupToAsset,
  handleAssignGroupToProject,
  handleAssignUserToProject,
  assign,
  setAssign,
  setFailureMessage,
  setFailure,
  setSuccessMsg,
  setSuccess,
  assignedUsers,
  assignedGroups
}) => {
  const [isViewerPopupOpen, setIsViewerPopupOpen] = useState(false);
  const [selectedItemType, setSelectedItemType] = useState(null);
  const [unassignUser, setUnassignUser] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const jwtToken = secureLocalStorage.getItem("token");
  const { t } = useTranslation();

  /**
   * Function to close the user details popup
   */
  const closePopup = () => {
    setIsViewerPopupOpen(false);
  };


  useEffect(() => {
    /**
  * Function to handle clicks outside of the user details popup
  * @param {Event} event - The click event
  */
    const handleOutsideClick = (event) => {
      if (
        isViewerPopupOpen &&
        !event.target.closest(".projectManager_viewer-popup-content")
      ) {
        closePopup();
      }
    };

    if (isViewerPopupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isViewerPopupOpen]);

  /**
  * Function to open the user assignment popup
  */
  const handleAddPopup = () => {
    setAssign(true);
  };

  /**
   * Function to close the user assignment popup
   */
  const closeAssignPopup = () => {
    setAssign(false);
    setUnassignUser(false);
  };

  /**
  * Function to handle the selection of a user for assignment
  * @param {Object} user - The selected user object
  */
  const handleSelectedUser = (user) => {
    console.log(user.id);
    setSelectedItemID(user.id);
    setSelectedItemType("user");
    const rows = document.querySelectorAll(".detailsPreview_assign-popup-list");
    rows.forEach((row) => {
      row.classList.remove("selected-row-popup");
    });

    if (user) {
      const selectedRow = document.querySelector(`#user-${user.id}`);
      if (selectedRow) {
        selectedRow.classList.add("selected-row-popup");
        console.log("hi");
      }
    }
  };

  /**
 * Function to handle the selection of a group for assignment
 * @param {Object} group - The selected group object
 */
  const handleSelectedGroup = (group) => {
    console.log(group.id);
    setSelectedItemID(group.id);
    setSelectedItemType("group");
    const rows = document.querySelectorAll(".detailsPreview_assign-popup-list");
    rows.forEach((row) => {
      row.classList.remove("selected-row-popup");
    });

    if (group) {
      const selectedRow = document.querySelector(`#group-${group.id}`);
      if (selectedRow) {
        selectedRow.classList.add("selected-row-popup");
      }
    }
  };

  /**
 * Function to handle the assignment button click
 */
  const handleAssignButtonClick = () => {
    if (selectedResourceType === "project") {
      if (selectedItemType === "user") {
        handleAssignUserToProject();
      } else if (selectedItemType === "group") {
        handleAssignGroupToProject();
      }
    } else if (selectedResourceType === "asset") {
      if (selectedItemType === "user") {
        handleAssignUserToAsset();
      } else if (selectedItemType === "group") {
        handleAssignGroupToAsset();
      }
    }
  };

  /**
 * Function to open the user removal popup
 */
  const removeUserPopup = () => {
    setUnassignUser(true);
  };

  /**
 * Function to remove a user from a project
 */
  const removeUserFromProject = () => {
    console.log(selectedResourceId);
    console.log("Removing user with ID:", selectedUserId);
    fetch(
      process.env.REACT_APP_API +
      "/project/assign_project_user/" +
      selectedResourceId +
      "/" +
      selectedUserId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setUnassignUser(false);
        fetchList();
        if (json.status == "Successfully unassigned") {
          setSuccess(true);
          setSuccessMsg("Successfully unassigned");
          setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
          }, 3000);
        } else {
          setFailure(true);
          setFailureMessage("The user could not be removed");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Sends a DELETE request to the server API to remove the specified group, and upon success, updates the local state to reflect the deletion.
   */
  const unassignAssetToUser = () => {
    console.log(selectedResourceId);
    console.log("Removing user with ID:", selectedUserId);
    fetch(
      process.env.REACT_APP_API +
      "/project/assign_asset_user/" +
      selectedResourceId +
      "/" +
      selectedUserId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setUnassignUser(false);
        fetchAssetList();
        if (json.status == "Successfully unassigned") {
          setSuccess(true);
          setSuccessMsg("Successfully unassigned");
          setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
          }, 3000);
        } else {
          setFailure(true);
          setFailureMessage("The user could not be removed");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
 * Function to handle a user click and set the selected user's ID
 * @param {Object} user - The clicked user object
 */
  const handleClick = (user) => {
    setSelectedUserId(user.id);
    console.log(user.id);
  };

  /**
   * Handles update of search query coming as user input
   * @param {Object} e - Event object (Search query string input from DOM)
   */
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // ... Fetch asset list upon component mounting ...
  useEffect(() => {
    const filteredAssetList = getData.filter((user) => {
      return user.username.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setData(filteredAssetList);

    if (searchQuery === "") {
      fetchAssetList();
      fetchList();
    }
  }, [searchQuery]);

  return (
    <div>
      <div className="projectManager_entire-viewer-container">
        <div className="projectManager_viewer-header">
          <p id="projectManager_viewer-list">{t("main.user-list")}</p>
          <p id="projectManager_viewer-list-right">
            ({usersLength}/{usersLength})
          </p>
          <div className="projectManager_search-bar">
            <div className="projectManager_search-bar-content">
              <div className="projectManager_search-bar-left">
                <input
                  id="projectManager_search-bar-input"
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  placeholder={t("main.search")}
                />
              </div>
            </div>
          </div>
          <img src={plus} id="projectManager_plus" onClick={handleAddPopup} />
        </div>

        <table className="userList_table">
          <thead>
            <tr className="userList_user-list-heading">
              <th>{t("main.id")}</th>
              <th>{t("main.name")}</th>
              <th>{t("main.user-level")}</th>
              <th>{t("main.last-used")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="userList_user-list-table-body">
            {Array.isArray(getData) && getData.length > 0 ? (
              getData.map((user) => (
                <tr
                  key={user.id}
                  className={`userList_user-list ${user.id === selectedUserId ? "selected-row" : ""
                    }`}
                  onClick={() => handleClick(user)}
                >
                  <td>{user.id}</td>
                  <td>{user.username}</td>
                  <td>{user.user_level}</td>
                  <td>
                    {user.last_login == null
                      ? "Inactive"
                      : new Date(user.last_login).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                  </td>
                  <td>
                    <svg
                      onClick={removeUserPopup}
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <mask
                        id="mask0_2605_1154"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="21"
                        height="21"
                      >
                        <rect width="21" height="21" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_2605_1154)">
                        <path
                          d="M6.12891 18.375C5.64766 18.375 5.23568 18.2036 4.89297 17.8609C4.55026 17.5182 4.37891 17.1062 4.37891 16.625V5.25H3.50391V3.5H7.8789V2.625H13.1289V3.5H17.5039V5.25H16.6289V16.625C16.6289 17.1062 16.4575 17.5182 16.1148 17.8609C15.7721 18.2036 15.3602 18.375 14.8789 18.375H6.12891ZM14.8789 5.25H6.12891V16.625H14.8789V5.25ZM7.8789 14.875H9.6289V7H7.8789V14.875ZM11.3789 14.875H13.1289V7H11.3789V14.875Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colspan="5" id="projectDashboard_no-projects">
                  No users to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {assign && (
        <div className="detailsPreview_assign-popup-container">
          <div className="detailsPreview_assign-popup-full">
            <div className="detailsPreview_assign-popup">
              <div className="detailsPreview_popup">
                <div className="detailsPreview_assign-popup-heading">
                  <p>{t("main.select-a-user-to-assign")}</p>
                </div>
                <div className="detailsPreview_assign-heading-user">
                  <p>{t("main.id")}</p>
                  <p>{t("main.user")}</p>
                </div>
                <div className="detailsPreview_scrollable">
                  {resourceUsers.length > 0 ? (
                    resourceUsers
                      .filter((user) => !assignedUsers.some((au) => au.id === user.id))
                      .map((user) => (
                        <div
                          className="detailsPreview_assign-popup-list"
                          id={`user-${user.id}`}
                          key={`user-${user.id}`}
                          onClick={() => handleSelectedUser(user)}
                        >
                          <p>{user.id}</p>
                          <p>{user.username}</p>
                        </div>
                      ))
                  ) : (
                    <p id="projectDashboard_no-projects">No users to display</p>
                  )}
                </div>
              </div>
              <div className="detailsPreview_popup">
                <div className="detailsPreview_assign-popup-heading">
                  <p>{t("main.select-a-user-group-to-assign")}</p>
                </div>

                <div className="detailsPreview_assign-heading">
                  <p>{t("main.id")}</p>
                  <p>{t("main.group")}</p>
                </div>
                <div className="detailsPreview_scrollable">
                  {resourceGroups.length > 0 ? (
                    resourceGroups
                      .filter((group) => !assignedGroups.some((ag) => ag.id === group.id))
                      .map((group) => (
                        <div
                          className="detailsPreview_assign-popup-list"
                          id={`group-${group.id}`}
                          key={
                            group.id
                              ? `group-${group.id}`
                              : `group-${group.title}`
                          }
                          onClick={() => handleSelectedGroup(group)}
                        >
                          <p>{group.id}</p>
                          <p>{group.title}</p>
                        </div>
                      ))
                  ) : (
                    <p id="projectDashboard_no-projects">
                      No groups to display
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="detailsPreview_assign-btn-container">
              <div
                className="detailsPreview_assign-btn"
                onClick={handleAssignButtonClick}
              >
                {t("main.assign")}
              </div>
            </div>
          </div>
        </div>
      )}
      <UnassignPopup
        unassignUser={unassignUser}
        setUnassignUser={setUnassignUser}
        removeUserFromProject={removeUserFromProject}
        unassignAssetToUser={unassignAssetToUser}
        selectedResourceType={selectedResourceType}
      />
      {(assign || unassignUser) && (
        <div
          className="projectGroups_background-overlay"
          onClick={closeAssignPopup}
        ></div>
      )}
    </div>
  );
};

export default UserList;
