// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./header.css";
import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";
import logo from "../../assets/3D_logo.png";
import mail from "../../assets/mark_email_unread.png";
import { useTranslation } from "react-i18next";

const Header = ({ headerUserDetails }) => {
  const [isUserDetailsOpen, setUserDetailsOpen] = useState(false);
  const [totalSpaceConsumed, setTotalSpaceConsumed] = useState(0);
  const [totalSpaceAlloted, setTotalSpaceAlloted] = useState(0);
  const [confirmLogout, setConfirmLogout] = useState(false);

  const jwtToken = secureLocalStorage.getItem("token");
  const location = useLocation();
  const userLevel = location.state.userLevel;
  const showStorageDetails = userLevel === ("Tenant" || "VisioAdmin");
  const navigation = useNavigate();

  const fetchStorageDetails = (userid) => {
    fetch(
      process.env.REACT_APP_API + "/tenant/allotted_storage_details/" + userid,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        setTotalSpaceConsumed(json.data.total_space_consumed);
        setTotalSpaceAlloted(json.data.total_space_alloted)
      });
  };

  useEffect(() => {
    fetchStorageDetails(jwt_decode(jwtToken).user_id);
    const fetchProjectUserID = () => {
      const userId = jwt_decode(secureLocalStorage.getItem("token")).user_id;
    };

    fetchProjectUserID();
  }, []);

  const progress = (totalSpaceConsumed / 5120) * 100;

  const { i18n } = useTranslation();

  const openUserDetails = () => {
    setUserDetailsOpen(!isUserDetailsOpen);
  };

  const handleClosePopup = (event) => {
    const popup = document.querySelector(".user-details-popup");
    if (isUserDetailsOpen && popup && !popup.contains(event.target)) {
      setUserDetailsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClosePopup);
    return () => {
      document.removeEventListener("mousedown", openUserDetails);
    };
  }, [isUserDetailsOpen]);

  const handleLogout = () => {
    setConfirmLogout(true);
    setUserDetailsOpen(false);
  };

  const handleCancel = () => {
    setConfirmLogout(false);
  };

  const closePopup = () => {
    setConfirmLogout(false);
  };

  return (
    <div>
      <div className="header">
        <div className="logo-container">

          <img id="header_logo" src={logo} />

        </div>
        <div className="header-right">
          <select
            name="language"
            id="language"
            onChange={(event) => i18n.changeLanguage(event.target.value)}
          >
            <option value="en">ENG</option>
            <option value="ja">JPN</option>
          </select>

          <div className="mark-email-unread">
            <img src={mail} />
          </div>
          <div className="user-details" onClick={openUserDetails}>
            <div className="user-details-left">
              <p>
                {headerUserDetails.username
                  ? headerUserDetails.username.charAt(0)
                  : ""}{" "}
                {headerUserDetails.username
                  ? headerUserDetails.username.split(" ")[1]
                    ? headerUserDetails.username.split(" ")[1].charAt(0)
                    : ""
                  : ""}
              </p>
            </div>
            <div className="user-details-right">
              <p>{headerUserDetails.username}</p>
              <p id="emailId">{headerUserDetails.email}</p>
            </div>
          </div>
        </div>
      </div>

      {isUserDetailsOpen && (
        <div className="user-details-popup">
          <div className="user-details-popup-content">
            <div className="user-logo">
              <p>
                {headerUserDetails.username
                  ? headerUserDetails.username.charAt(0)
                  : ""}{" "}
                {headerUserDetails.username
                  ? headerUserDetails.username.split(" ")[1]
                    ? headerUserDetails.username.split(" ")[1].charAt(0)
                    : ""
                  : ""}
              </p>
            </div>
            <div className="user-name">
              <h3>{headerUserDetails.username}</h3>
              <p>{headerUserDetails.email}</p>
            </div>
            {showStorageDetails && (
              <div className="user-name">
                <progress id="file" value={totalSpaceConsumed} max={totalSpaceAlloted}>
                  {progress.toFixed(2)}%
                </progress>
                <p>{`${totalSpaceConsumed.toFixed(2)}MB used out of ${totalSpaceAlloted}MB`}</p>
              </div>
            )}
            <div className="settings">
              <p>View Details</p>
              <p>Privacy Policy</p>
              <p>Settings </p>
            </div>
            <div className="form-button-container">
              <div className="form-button" onClick={handleLogout}>
                <a>Log Out</a>
              </div>
            </div>
          </div>
        </div>
      )}
      {confirmLogout && (
        <div className="confirm-delete-popup">
          <p>Are you sure you want to logout?</p>
          <div className="delete-btn-container">
            <div id="delete-btn-cancel" onClick={handleCancel}>
              Cancel
            </div>
            <div
              id="confirm-delete-btn"
              onClick={() => {
                navigation(-1);
              }}
            >
              Logout
            </div>
          </div>
        </div>
      )}
      {confirmLogout && (
        <div
          className="projectGroups_background-overlay"
          onClick={closePopup}
        ></div>
      )}
    </div>
  );
};

export default Header;
