// @ts-nocheck

/**
 * Functional component for viewing the list of annotations
 * @param { Array } annotationsList List of annotations to display
 * @param { Boolean } isViewer Indicates whether list is displayed in viewer or editor mode
 * @param { Function } displayAnnotationDescription Function to display annotation description
 * @param { Viewer } viewer Cesium Viewer
 * @param { String } placeholderText Text to display when there are no annotations
 * @returns { JSX.Element } Annotation list component
 */
const AnnotationsList = ({ annotationsList, isViewer, displayAnnotationDescription, viewer, placeholderText }) => {

  return (
    <div className={`panel-container scrollable-container ${isViewer ? 'fixed-height-annotation-viewer' : 'fixed-height-annotation-editor'}`}>
      <table className="annotations-list">
        <tbody className="annotations-list-body">
          <tr>
            <th className="panel-subhead">Annotation Name</th>
            <th className="panel-subhead">Point</th>
          </tr>
          <hr />
          { annotationsList.length > 0 ? (
            annotationsList.map((annotation, index) => {
              return (
                <tr
                  key={index}
                  className="annotations-row"
                  onClick={() => {
                    viewer.flyTo(annotation);
                    displayAnnotationDescription(annotation);
                  }}
                >
                  <td className={`${isViewer ? 'panel-list-name-viewer' : 'panel-subhead'} panel-text`}>
                    {annotation.name}
                  </td>
                  <td>
                    <svg width="17px" height="17px">
                      <circle
                        cx="8.5"
                        cy="8.5"
                        r="8.5"
                        fill={annotation.annColor}
                        style={{ stroke: "black", strokeWidth: 2 }}
                      />
                    </svg>
                  </td>
                </tr>
              );
            })
          ) : (
            <div className="empty-annotations-list placeholder-text">
              {placeholderText}
            </div>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default AnnotationsList;