import React, { useEffect } from "react";
import "./zoomPanel.css";

const ZoomPanel = ({ viewer, handleGeoAlt, geoAlt }) => {
  useEffect(() => {
    viewer.scene.globe.depthTestAgainstTerrain = true;
    viewer.camera.defaultZoomAmount = 100;
  }, []);

  /**
   * Function to zoom into viewer. Zooming in is possible until the camera height is greater than 20 meters.
   * Zoom in amount also depends on the camera height, as it needs to be an exponential value (for a practical user experience)
   */
  const zoomIn = () => {
    // const camHeight = viewer.camera.positionCartographic.height.toFixed(2);
    if (geoAlt >= 20) {
      viewer.camera.zoomIn(geoAlt / 2);
      const camHeight = viewer.camera.positionCartographic.height.toFixed(2);
      handleGeoAlt(camHeight);
    }
  };

  const zoomOut = () => {
    // const camHeight = viewer.camera.positionCartographic.height.toFixed(2);
    if (geoAlt < 60000000) {
      viewer.camera.zoomOut(geoAlt / 1.18);
      const camHeight = viewer.camera.positionCartographic.height.toFixed(2);
      handleGeoAlt(camHeight);
    }
  };

  return (
    <div>
      <div className="zoomPanel-main-container">
        <div className="zoomPanel-button-container">
          <div
            className="zoomPanel-zoom-in-btn"
            id="zoom-in-button"
            onClick={zoomIn}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_1875_681"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1875_681)">
                <path
                  d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z"
                  fill="white"
                />
              </g>
            </svg>
          </div>
          <div
            className="zoomPanel-zoom-out-btn"
            id="zoom-out-button"
            onClick={zoomOut}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_1875_684"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1875_684)">
                <path d="M5 13V11H19V13H5Z" fill="white" />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZoomPanel;
