import { useEffect } from "react";
import { useState } from "react"

/**
 * Custom hook for fetching and updating the list of annotations
 * @param { Viewer } viewer Cesium viewer
 * @return { { annotationsList: Array, setAnnotationsList: Function } } List of annotations and setter function to update annotations list
 */
const useAnnotations = (viewer) => {

    const [ annotationsList, setAnnotationsList ] = useState([]);

    useEffect(() => {
        var _annotations = [];
        viewer.entities.values.forEach((entity) => {
          entity.id.toString().startsWith("ANNT") && _annotations.push(entity);
        });
        setAnnotationsList(_annotations);
    }, []);

    return { annotationsList, setAnnotationsList };
}

export default useAnnotations;