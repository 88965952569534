import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import '../forgotPassword/forgotPassword.css'
import logo from '../../../../assets/3D_logo.png'
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const ConfirmPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [passwordStrength, setPasswordStrength] = useState("");
    const [isReEnterFocused, setIsReEnterFocused] = useState(false);

    const jwtToken = secureLocalStorage.getItem("token");
    const { token, uid } = useParams();
    const navigation = useNavigate();

    const handleConfirmPassword = () => {
        const details = JSON.stringify({
            uid: uid,
            token: token,
            new_password: newPassword,
            re_new_password: confirmPassword
        });

        fetch(process.env.REACT_APP_API + "/auth/users/reset_password_confirm/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + jwtToken,
            },
            body: details,
        })
            .then((json) => {
                console.log(json);
                if (json.status === 204) {
                    console.log("Password reset was successful");
                    setSuccessMsg("Password successfully reset. Redirecting you to the login page..")
                    setTimeout(() => {
                        setSuccessMsg("");
                        navigation("/");
                    }, 4000);
                } else if (json.status === 400) {
                    console.log("There was an error");
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleError = () => {
        setErrorMsg('Password does not match')
        setIsReEnterFocused(true)
        setTimeout(() => {
            setErrorMsg('')
        }, 3000);
    }

    if (newPassword) {
        var password = document.getElementById("confirmPassword_current-password-input");
        password.addEventListener('keyup', function () {

            var pwd = password.value

            if (pwd.length === 0 && pwd !== "") {
                if (newPassword) {
                    document.getElementById("progresslabel").innerHTML = "";
                    document.getElementById("progress").value = "0";
                    return;
                }
            }

            var prog = [/[$@$!%*#?&]/, /[A-Z]/, /[0-9]/, /[a-z]/]
                .reduce((memo, test) => memo + test.test(pwd), 0);

            // Length must be at least 8 chars
            if (prog > 2 && pwd.length > 7) {
                prog++;
            }

            var progress = "";
            var strength = "";
            switch (prog) {
                case 1:
                    strength = "Weak";
                    setPasswordStrength(strength)
                    progress = "25";
                    break;
                case 2:
                    strength = "Medium";
                    setPasswordStrength(strength)
                    progress = "50";
                    break;
                case 3:
                    strength = "Medium";
                    setPasswordStrength(strength)
                    progress = "75";
                    break;
                case 4:
                    strength = "Strong";
                    setPasswordStrength(strength)
                    progress = "100";
                    break;
            }
            if (pwd !== "") {
                document.getElementById("progresslabel").innerHTML = strength;
                document.getElementById("progress").value = progress;
            }
        });
    }

    return (
        <div className='confirmPassword_main-container'>
            <div className='forgotPassword_logo'>
                <h1>3</h1>
                <img id="header_logo" src={logo} />
                <h1>Twin</h1>
            </div>
            <div className='forgotPassword_content-container'>
                <h1>Confirm</h1>
                <p id='confirmPassword_content-text'>Please enter a mix of letters, symbols and numbers in your password.</p>
                <div className='confirmPassword_current-password'>
                    <input
                        id='confirmPassword_current-password-input'
                        type="text"
                        placeholder='New Password:'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <div className='confirmPassword_current-password'>
                    <input
                        id='confirmPassword_current-password-input'
                        type="text"
                        placeholder='Re-Enter Password:'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onFocus={() => setIsReEnterFocused(true)}
                        onBlur={() => setIsReEnterFocused(false)}
                    />
                </div>
                <div className={errorMsg ? 'confirmPassword_message-container' : 'confirmPassword_message-container-one'}>
                    {errorMsg && <div className='confirmPassword_msg-container'>
                        <p>{errorMsg}</p>
                    </div>}
                    {newPassword && !isReEnterFocused && <div className={!errorMsg ? "password-prgress-bar" : ""}>
                        <div className='password-strength-msg'>
                            <p id="progresslabel" style={{
                                color: passwordStrength === "Weak" ? "#FF3F3F" :
                                    passwordStrength === "Medium" ? "#FFBD13" : "#6EA940",
                            }}>
                            </p>
                            <div>
                                {passwordStrength === "Weak" ? <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <mask id="mask0_2828_845" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                                        <rect width="21" height="21" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_2828_845)">
                                        <path d="M0.884766 18.3762L10.5098 1.75122L20.1348 18.3762H0.884766ZM3.90352 16.6262H17.116L10.5098 5.25122L3.90352 16.6262ZM10.5098 15.7512C10.7577 15.7512 10.9655 15.6674 11.1332 15.4997C11.3009 15.332 11.3848 15.1241 11.3848 14.8762C11.3848 14.6283 11.3009 14.4205 11.1332 14.2528C10.9655 14.0851 10.7577 14.0012 10.5098 14.0012C10.2618 14.0012 10.054 14.0851 9.88633 14.2528C9.71862 14.4205 9.63476 14.6283 9.63476 14.8762C9.63476 15.1241 9.71862 15.332 9.88633 15.4997C10.054 15.6674 10.2618 15.7512 10.5098 15.7512ZM9.63476 13.1262H11.3848V8.75122H9.63476V13.1262Z" fill="#FF3F3F" />
                                    </g>
                                </svg> :
                                    passwordStrength === "Medium" ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                            <mask id="mask0_2828_854" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                                                <rect width="21" height="21" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_2828_854)">
                                                <path d="M0.884766 18.3762L10.5098 1.75122L20.1348 18.3762H0.884766ZM3.90352 16.6262H17.116L10.5098 5.25122L3.90352 16.6262ZM10.5098 15.7512C10.7577 15.7512 10.9655 15.6674 11.1332 15.4997C11.3009 15.332 11.3848 15.1241 11.3848 14.8762C11.3848 14.6283 11.3009 14.4205 11.1332 14.2528C10.9655 14.0851 10.7577 14.0012 10.5098 14.0012C10.2618 14.0012 10.054 14.0851 9.88633 14.2528C9.71862 14.4205 9.63476 14.6283 9.63476 14.8762C9.63476 15.1241 9.71862 15.332 9.88633 15.4997C10.054 15.6674 10.2618 15.7512 10.5098 15.7512ZM9.63476 13.1262H11.3848V8.75122H9.63476V13.1262Z" fill="#FFBD13" />
                                            </g>
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                            <mask id="mask0_2828_861" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                                                <rect width="21" height="21" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_2828_861)">
                                                <path d="M0.884766 18.3762L10.5098 1.75122L20.1348 18.3762H0.884766ZM3.90352 16.6262H17.116L10.5098 5.25122L3.90352 16.6262ZM10.5098 15.7512C10.7577 15.7512 10.9655 15.6674 11.1332 15.4997C11.3009 15.332 11.3848 15.1241 11.3848 14.8762C11.3848 14.6283 11.3009 14.4205 11.1332 14.2528C10.9655 14.0851 10.7577 14.0012 10.5098 14.0012C10.2618 14.0012 10.054 14.0851 9.88633 14.2528C9.71862 14.4205 9.63476 14.6283 9.63476 14.8762C9.63476 15.1241 9.71862 15.332 9.88633 15.4997C10.054 15.6674 10.2618 15.7512 10.5098 15.7512ZM9.63476 13.1262H11.3848V8.75122H9.63476V13.1262Z" fill="#6EA940" />
                                            </g>
                                        </svg>}
                            </div>
                        </div>
                        <progress id="progress" value="0" max="100" style={{
                            border: passwordStrength === "Weak" ? "1px solid #FF3F3F" :
                                passwordStrength === "Medium" ? "1px solid #FFBD13" : "1px solid #6EA940"
                        }}></progress>
                    </div>}
                </div>
                <div className='confirmPassword_confirm-button-container' onClick={newPassword !== confirmPassword ? handleError : handleConfirmPassword}>
                    <div className='confirmPassword_confirm-button'>
                        Confirm
                    </div>
                </div>
                <div className='confirmPassword_sucsessMsg-container'>
                    <p>{successMsg}</p>
                </div>
            </div>
        </div >
    )
}

export default ConfirmPassword