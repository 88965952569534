// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect, useRef } from "react";

// ... NPM Packages ...
import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import axios from "axios";

// ... Components ...
import Header from "../../components/header/header";
import PreviewWindow from "../../components/previewWindow/previewWindow";
import DetailsPreview from "../../components/detailsPreview/detailsPreview";
import ProjectList from '../../components/projectList/projectList'
import AssetList from '../../components/assetList/assetList'
import "./viewerDashboard.css";

// ... Misc ...
import upload from "../../assets/upload.png";
import filter_alt_two from "../../assets/filter_alt_two.png";

/**
 * @description `Page` : Component that represents a user dashboard for managing projects and assets. It allows users to create, delete, and interact with their projects and assets, and provides features like uploading assets, filtering resources, and displaying project and asset details.
 * @category Viewer Dashboard
 * @returns {JSX.Element} Rendered viewer dashboard page.
 */
const ViewerDashboard = () => {
    // ... State and hook declarations ...

    // .. Project hooks ..
    const [projectList, setProjectList] = useState(true);
    const [projects, setProjects] = useState([]);
    const [newProjectName, setNewProjectName] = useState("");
    const [newProjectLocation, setNewProjectLocation] = useState("");
    const [projectValid, setProjectValid] = useState(false);
    const [projectListPopup, setProjectListPopup] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

    // .. Asset hooks ..
    const [georeferenceable, setGeoreferenceable] = useState(false);
    const [georeferenceChecked, setgeoreferenceChecked] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [asset, setAsset] = useState([]);
    const [assetList, setAssetList] = useState(false);
    const [fileValid, setFileValid] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadAsset, setUploadAsset] = useState("");
    const [assetListPopup, setAssetListPopup] = useState(false);

    // .. Other hooks ..
    const [selectedResource, setSelectedResource] = useState(null);
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filterList, setFilterList] = useState(false);
    const [selectedFilterCriteria, setSelectedFilterCriteria] = useState("name");
    const [headerUserDetails, setHeaderUserDetails] = useState({
        username: "",
        email: "",
    });

    useEffect(() => {
        // Function to close filter dropdown when clicking outside
        const handleDocumentClick = (event) => {
            if (
                filterDropdownRef.current &&
                !filterDropdownRef.current.contains(event.target)
            ) {
                setFilterList(false);
            }
        };

        // Add event listener to listen for clicks on the document
        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    /**
    *  Updates the selected filter criteria based on user input.
    * @param {Object} event - Event object 
    */
    const handleFilterCriteriaChange = (event) => {
        setSelectedFilterCriteria(event.target.value);
    };

    // .. Constants ..
    const jwtToken = secureLocalStorage.getItem("token");
    const userId = jwt_decode(jwtToken).user_id;
    const { t } = useTranslation();
    const filterDropdownRef = useRef(null);

    // ... Local Functions ...

    /**
  * Handles project creation.
  * @param {Object} e - Event object (Project name)
  * @param {number} userId - User ID.
  */
    const handleProjectCreation = (e, userId) => {
        e.preventDefault();

        const projectDetails = JSON.stringify({
            file_name: newProjectName,
            location: newProjectLocation,
        });

        fetch(process.env.REACT_APP_API + "/project/project_details/" + userId, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + jwtToken,
            },
            body: projectDetails,
        })
            .then((res) => res.json())
            .then((json) => {
                console.log(json.data);
                setProjects((prevProjects) => [...prevProjects, json.data]);
                setNewProjectLocation("");
                setNewProjectName("");
                setProjectListPopup(false);
                setAssetListPopup(false);
                setUserListPopup(false);
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    /**
     * Sends a DELETE request to the server API with the user ID and project ID to remove the specified project, and upon success, updates the local state to reflect the deletion.
     * @param {number} userid 
     * @param {number} projectID 
     */
    const handleDeleteProject = (userid, projectID) => {
        console.log("Deleting project with ID:", projectID);
        console.log(projectID);
        fetch(
            process.env.REACT_APP_API +
            "/project/project_details/" +
            userid +
            "/" +
            projectID,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            }
        )
            .then((res) => res.json())
            .then((json) => {
                console.log(json);

                setData((prevData) =>
                    prevData.filter((project) => project.id !== projectID)
                );
                fetchProjectList(userid);
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };


    // ... Asset handling functions ...

    /**
       * Fetches assets available to User ID.
       * @param {number} userId - The user ID.
       */
    const fetchAssetList = (userid) => {
        console.log(userid);
        fetch(process.env.REACT_APP_API + "/project/asset_details/" + userid, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + jwtToken,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status == "No Assets found") {
                    console.log("User has no assets");
                } else {
                }
            });
    };

    /**
   * Handles Asset uploading for user.
   * @param {number} userId - User ID.
   */
    const handleAssetUpload = (userId) => {
        let fileName;
        console.log("sending files to user Id : ", userId);
        const formData = new FormData();
        let assetUploadPath;
        georeferenceChecked
            ? (assetUploadPath = "/project/geo_upload_asset/")
            : (assetUploadPath = "/project/asset_details/");
        for (const element of asset) {
            console.log("Uploading  : ", asset);
            fileName = asset[0].name;
            console.log(asset[0].name);
            formData.append("files", element);
        }
        // Add resource type, for IFC - IFCGLTF
        formData.append("upload_type", "3DT");

        axios
            .post(
                process.env.REACT_APP_API + assetUploadPath + userId,
                formData,

                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "JWT " + jwtToken,
                    },
                    onUploadProgress: onUploadProgress,
                }
            )

            .then((res) => {
                console.log(fileName);
                console.log(Object.entries(res.data));
                const variableValue = Object.entries(res.data);
                console.log(variableValue[0][1]);
                setUploadAsset(variableValue[0][1]);
                console.log(res);
                fetchAssetList(userId);
                setAsset([]);
                setSelectedFileName("");
                setFileValid(false);
                setGeoreferenceable(false)
                setAssetListPopup(false)
                setProjectListPopup(false)
                setUserListPopup(false)
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    /**
     * Handles changes to the asset upload input.
     * @param {Object} e - Event object (Asset files)
     */
    const handleAssetChange = (e) => {
        console.log(e);
        let files = e.target.files;
        setAsset(Array.from(files));
        if (files.length > 0) {
            setSelectedFileName(files[0].name);
        } else {
            setSelectedFileName("");
        }
        console.log("Asset is : ", files[0].name);
        for (const element of files) {
            const fileName = element.name;
            if (!/^[a-zA-Z0-9][a-zA-Z0-9._-]{1,61}[a-zA-Z0-9]$/.test(fileName)) {
                console.log("Invalid file name");
                setFileValid(false);
                break;
            } else {
                console.log("valid file name");
                setFileValid(true);
                if (files.length == 1) {
                    const extension = files[0].name.split(".").pop();
                    console.log("Asset extension : ", extension);
                    if (extension === "gltf" || extension === "glb") {
                        setGeoreferenceable(true);
                        console.log("GEO YES");
                    } else {
                        setGeoreferenceable(false);
                        console.log("GEO No");
                    }
                }
            }
        }
        console.log("Number of assets : ", files.length);
    };

    /**
     * Sends a DELETE request to the server API with the user ID and asset ID to remove the specified asset, and upon success, updates the local state to reflect the deletion.
     * @param {number} userid 
     * @param {number} assetID 
     */
    const handleDeleteAsset = (userid, assetID) => {
        console.log("Deleting asset with ID:", assetID);
        fetch(
            process.env.REACT_APP_API +
            "/project/asset_details/" +
            userid +
            "/" +
            assetID,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            }
        )
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setAsset((prevAsset) =>
                    prevAsset.filter((asset) => asset.id !== assetID)
                );
                fetchAssetList(userid);
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    /**
   * Marks a gltf as georeferenceable or not.
   */
    const handleGeorefCheck = () => {
        setgeoreferenceChecked(!georeferenceChecked);
    };

    /**
     * Handles progress during asset upload.
     * @param {Object} progressEvent - The progress event object.
     */
    const onUploadProgress = (progressEvent) => {
        setProgress(Math.round(progressEvent.progress * 100));
        console.log("Uploading file...");
        console.log(Math.round(progressEvent.progress * 100));
    };

    /**
     * Makes GET request to the server to retrieve account details.
     * @param {number} userId 
     */
    const fetchAccountDetails = (userId) => {
        fetch(
            process.env.REACT_APP_API + "/accounts/master_account_details/" + userId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            }
        )
            .then((res) => res.json())
            .then((json) => {
                if (json.code == 100) {
                    const userDetails = {
                        username: json.data[0].username,
                        email: json.data[0].email,
                    };
                    setHeaderUserDetails(userDetails);
                    console.log(userDetails.username);
                }
                if (json.detail) {
                    console.log("CODE51");
                    const userDetails = {
                        username: "undefined",
                        email: "undefined",
                    };
                    setHeaderUserDetails(userDetails);
                }
            });
    };

    // Misc Handlers

    /**
     * Displays a success popup when a project or asset is successfully deleted.
     */
    const handleDeleteSuccess = () => {
        setShowDeleteSuccess(true);
        setTimeout(() => {
            setShowDeleteSuccess(false);
        }, 3000);
    };

    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };

    /**
    * Displays a filter dropdown
    */
    const handleFilter = () => {
        setFilterList(true);
    };

    /**
    * Handles update of selected resource (Project / Asset)
    * @param {Object} selectedItem - Item in focus.
    */
    const handleResourceUpdate = (selectedItem) => {
        setSelectedResource(selectedItem);
        setSelectedResourceId(selectedItem ? selectedItem.id : null);
    };

    /**
    * Sets the active tab based on user selection.
    * @param {string} id - The ID of the selected tab (Projects / Assets / Users).
    */
    const handleSetActive = (id) => {
        setSelectedResource(null);
        if (id == "asset") {
            setProjectList(false);
            setAssetList(true);
        } else {
            setAssetList(false);
            setProjectList(true);
        }
    };

    /**
     * Displays a pop-up 
     */
    const handleResourcePopup = () => {
        setProjectListPopup(true);
        setAssetListPopup(true);
    };

    /**
     * Closes the popup.
     */
    const closePopup = () => {
        setProjectListPopup(false);
        setAssetListPopup(false);
        setGeoreferenceable(false);
    };

    /**
    * viewerDashboard useEffect hook.
    */
    useEffect(() => {
        fetchAccountDetails(userId);
    }, []);

    return (
        <div>
            <Header headerUserDetails={headerUserDetails} />

            <div className="viewerDashboard_lower-heading-container">
                <div className="viewerDashboard_lower-heading-right">
                    <p
                        id="project"
                        onClick={(e) => handleSetActive(e.target.id)}
                        className={projectList ? "active" : "inactive"}
                    >
                        {t("main.project-list")}
                    </p>
                    <p
                        id="asset"
                        onClick={(e) => handleSetActive(e.target.id)}
                        className={assetList ? "active" : "inactive"}
                    >
                        {t("main.asset-list")}
                    </p>
                </div>
                <div className="viewerDashboard_search-bar-container">
                    <div className="viewerDashboard_upload-btn-container">
                        <div className="viewerDashboard_upload-btn" onClick={handleResourcePopup}>
                            <img src={upload} />
                            {assetList
                                ? "Upload Asset"
                                : projectList
                                    ? "Add Project"
                                    : "New User"}
                        </div>
                    </div>
                    <div className="viewerDashboard_search-bar">
                        <div className="search-input-container">
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchQueryChange}
                                placeholder={t("main.search")}
                            />
                        </div>
                        <div
                            className="viewerDashboard_search-bar-right"
                            onClick={handleFilter}
                            ref={filterDropdownRef}
                        >
                            <img src={filter_alt_two} />
                            {filterList && (
                                <select
                                    name="language"
                                    id="filter"
                                    onChange={handleFilterCriteriaChange}
                                >
                                    <option>Name</option>
                                    <option>ID</option>
                                    <option>Last Modified</option>
                                </select>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="projectDashboard_entire-container">
                <div className="projectDashboard_files">
                    <AssetList isActive={assetList}
                        handleResourceUpdate={handleResourceUpdate}
                        searchQuery={searchQuery}
                        filterCriteria={selectedFilterCriteria}
                        onDeleteAsset={() => handleDeleteAsset(userId, selectedResourceId)} />

                    <ProjectList
                        isActive={projectList}
                        newProjects={projects}
                        searchQuery={searchQuery}
                        filterCriteria={selectedFilterCriteria}
                        handleResourceUpdate={handleResourceUpdate}
                        setSelectedResourceId={setSelectedResourceId}
                        onDeleteProject={() =>
                            handleDeleteProject(userId, selectedResourceId)
                        } />
                </div>

                <div className="projectDashboard_preview-window">
                    <PreviewWindow selectedResource={selectedResource}
                        assetList={assetList} />
                    <div className="projectDashboard_open-in-viewer-btn-container">
                        <div
                            className={selectedResource == null ? "projectDashboard_open-in-viewer-btn disabled" : "projectDashboard_open-in-viewer-btn"}
                            onClick={() => {
                                navigation("/visioWindow", {
                                    replace: false,
                                    state: {
                                        selectedResource: selectedResource,
                                        userLevel: "editor",
                                    },
                                });
                            }}
                        >
                            {t("main.open-in-viewer")}
                        </div>
                    </div>
                    <DetailsPreview
                        selectedResource={selectedResource}
                        projectList={projectList}
                        onDeleteProject={() =>
                            handleDeleteProject(userId, selectedResourceId)
                        }
                        onDeleteAsset={() => handleDeleteAsset(userId, selectedResourceId)}
                        onDeleteSuccess={handleDeleteSuccess}
                        setSelectedResource={setSelectedResource} />
                </div>
            </div>
            {projectList && projectListPopup && (
                <div className="projectDashboard_popup-container">
                    <div className="projectDashboard_popup">
                        <p id="projectDashboard_popup-heading">Add new Project</p>
                        <form id="projectDashboard_form">
                            <div className="projectDashboard_form-input">
                                <label>Add Project Name:</label>
                                <input
                                    id="popup-input"
                                    type="text"
                                    required
                                    type="text"
                                    name="name"
                                    value={newProjectName}
                                    onChange={(e) => setNewProjectName(e.target.value)}
                                />
                            </div>
                            <div className="projectDashboard_form-input">
                                <label>Country:</label>
                                <input
                                    id="popup-input"
                                    type="text"
                                    required
                                    type="text"
                                    name="location"
                                    value={newProjectLocation}
                                    onChange={(e) => setNewProjectLocation(e.target.value)}
                                />
                            </div>
                            <div className="projectDashboard_form-button-container">
                                <div
                                    className="projectDashboard_form-button"
                                    disabled={!projectValid}
                                    type="submit"
                                    value="Submit"
                                    onClick={(e) => {
                                        handleProjectCreation(e, userId);
                                    }}
                                >
                                    <a>Create</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {assetList && assetListPopup && (
                <div className="projectDashboard_popup-container">
                    <div className="projectDashboard_popup">
                        <p id="projectDashboard_popup-heading">Add new Asset</p>
                        <form id="projectDashboard_form">
                            {georeferenceable && (
                                <div className="projectDashboard_Georeference">
                                    <input
                                        type="checkbox"
                                        id="georef"
                                        checked={georeferenceChecked}
                                        onChange={handleGeorefCheck}
                                    />
                                    <label htmlFor="georef">Georeference GLTF</label>
                                    <br />
                                </div>
                            )}
                            <p id="projectDashboard_file-name">{selectedFileName}</p>
                            <div className="projectDashboard_upload-asset-conatiner">
                                <label className="projectDashboard_choose-assets">
                                    <input
                                        type="file"
                                        onChange={handleAssetChange}
                                        multiple
                                        id="file-upload"
                                    />
                                    Choose Assets
                                </label>
                                <div className="projectDashboard_form-button-container">
                                    <div
                                        className="projectDashboard_form-button"
                                        disabled={!fileValid}
                                        onClick={() => handleAssetUpload(userId)}
                                    >
                                        <a>Upload</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {showDeleteSuccess && (
                <div className="notification success">
                    {projectList
                        ? "Project successfully deleted."
                        : "Asset successfully deleted."}
                </div>
            )}
            {projectListPopup && (
                <div
                    className="projectGroups_background-overlay"
                    onClick={closePopup}
                ></div>
            )}
        </div>
    );
};

export default ViewerDashboard;
