// @ts-nocheck

/**
 * NOte : While saving, set clock to the last milestone time (so that all assets are present in the Scene for SaveProject.jsx to capture)
 */
import { Cesium3DTileset, Iso8601, JulianDate, Model, TimeInterval, TimeIntervalCollection } from "cesium";
import React, { useEffect, useState } from "react";
import MilestonesList from "../../../features/MilestonesList";
import useMilestones from "../../../features/useMilestones";
import "./projectSchedule.css";
import DateTimePanel from "../../../features/DateTimePanel";

const ProjectSchedule = ({ viewer, resourceInFocus, handleUpdateTimeline }) => {
  const { milestoneList, setMilestoneList, setMilestoneUpdated } =
    useMilestones(viewer);
  const [assetSelected, setAssetSelected] = useState(undefined);
  const [milestoneName, setMilestoneName] = useState(undefined);
  const [showDateTimePanel, setShowDateTimePanel] = useState(false);
  const [startDateTime, setStartDateTime] = useState({
    startDate: new Date(),
    startTimeHrs: "00",
    startTimeMinutes: "00",
    startTimePeriod: 'am'
  })
  const [endDateTime, setEndDateTime] = useState({
    endDate: new Date(),
    endTimeHrs: "11",
    endTimeMinutes: "59",
    endTimePeriod: 'pm'
  })
  const [isDateAdded, setIsDateAdded] = useState(false);

  // Handles date time changes
  const handleDateTimeChange = (name, value) => {
    if (name.startsWith('start')) {
      setStartDateTime(prev => {
        return { ...prev, [name]: value }
      })
    } else {
      setEndDateTime(prev => {
        return { ...prev, [name]: value }
      })
    }

    if (value === 'clear') {
      setStartDateTime({
        startDate: new Date(),
        startTimeHrs: "00",
        startTimeMinutes: "00",
        startTimePeriod: 'am'
      });

      setEndDateTime({
        endDate: new Date(),
        endTimeHrs: "00",
        endTimeMinutes: "00",
        endTimePeriod: 'am'
      })
    }
  }

  // Toggles display of date panel
  const handleDatePanelShow = () => {
    setShowDateTimePanel(prev => !prev);
    setIsDateAdded(false);
  }

  // const getCesiumDate = () => {
  //   console.log("Date : ", Cesium.JulianDate.toDate(viewer.clock.currentTime));
  //   const cesiumDate = Cesium.JulianDate.toDate(viewer.clock.currentTime);
  //   const cesiumDateString =
  //     cesiumDate.getDate() +
  //     " / " +
  //     (cesiumDate.getMonth() + 1) +
  //     " / " +
  //     cesiumDate.getFullYear();
  //   return cesiumDateString;
  // };

  /**
   * Add milestone and time info into Asset member
   */
  const embedMilestoneInfo = () => {
    const startDate = new Date(startDateTime.startDate.getFullYear(), startDateTime.startDate.getMonth(), startDateTime.startDate.getDate(), startDateTime.startTimePeriod === 'am' ? parseInt(startDateTime.startTimeHrs) : parseInt(startDateTime.startTimeHrs) + 12, startDateTime.startTimeMinutes, 0);

    const endDate = new Date(endDateTime.endDate.getFullYear(), endDateTime.endDate.getMonth(), endDateTime.endDate.getDate(), endDateTime.endTimePeriod === 'am' ? parseInt(endDateTime.endTimeHrs) : parseInt(endDateTime.endTimeHrs) + 12, endDateTime.endTimeMinutes, 0);

    console.log('end date ', endDate);

    //ISO8601 timestamp
    // let day = startDate.getDate();
    // let month = startDate.getMonth() + 1;
    // startDate.getDate().toString().length == 1 ? (day = "0" + day) : {};
    // console.log(" DAY : ", day);

    // month.toString().length == 1 ? (month = "0" + month) : {};
    // console.log(" MONTH : ", month);

    // const ISO8601 = startDate.getFullYear() + "-" + month + "-" + day + "T00:00:00Z";
    // const displayDate = day + " / " + month + " / " + startDate.getFullYear();

    if (resourceInFocus instanceof Cesium3DTileset) {
      resourceInFocus.extras.assetAvailability = {
        assetAvailabilityName: milestoneName,
        startDateTime: startDate.toString(),
        endDateTime: endDate.toString()
      };
    }

    else if (resourceInFocus instanceof Model) {
      resourceInFocus._modelResources.assetAvailability = {
        assetAvailabilityName: milestoneName,
        startDateTime: startDate.toString(),
        endDateTime: endDate.toString()
      };
    }

    // Updating time availability property for annotations linked with the focued asset
    viewer.entities.values.forEach(entity => {
      if (entity.id.toString().startsWith('ANNT') && entity.isAssetBound && (entity?.assetId?.toString() === resourceInFocus?.extras?.id || entity?.assetId?.toString() === resourceInFocus.id)) {
        entity.availability = new TimeIntervalCollection([new TimeInterval({
          start: JulianDate.fromDate(new Date(resourceInFocus.extras.assetAvailability.startDateTime)),
          stop: JulianDate.fromDate(new Date(resourceInFocus.extras.assetAvailability.endDateTime))
        })]);
      }
    })

    // .. Reset milestone list and toggle state of setMilestonesUpdated
    console.log(" MS LIST: ", milestoneList);
    setMilestoneList([]);
    setMilestoneUpdated((prev) => !prev);
    handleUpdateTimeline();
  };

  const updateCesiumClock = (date) => {
    console.log("DATE : ", date);
    viewer.clock.currentTime = JulianDate.fromDate(new Date(date));
    console.log(date);
    console.log(date.substring(0, date.indexOf("T")));
  };

  useEffect(() => {
    if (resourceInFocus instanceof Cesium3DTileset) {
      setAssetSelected(resourceInFocus.extras.name);
    } else if (resourceInFocus instanceof Model) {
      setAssetSelected(resourceInFocus._credit._html);
    }
  }, [resourceInFocus]);

  return (
    <>
      <div className="panel-container milestone-log-panel">
        <h4 className="panel-subhead">Milestone Log</h4>
        <hr />
        <div className="panel-inputGroup">
          <label className="panel-inputLabel panel-text">Name</label>
          <div className="panel-input-container">
            <input
              type="text"
              onChange={(e) => setMilestoneName(e.target.value)}
              className="panel-inputField panel-text stretch-container"
            />
            <p className="panel-time-text" onClick={handleDatePanelShow}>Select date &gt;</p>
          </div>
          {showDateTimePanel && <DateTimePanel startDateTime={startDateTime} endDateTime={endDateTime} handleDateTimeChange={handleDateTimeChange} setShowDateTimePanel={setShowDateTimePanel} setIsDateAdded={setIsDateAdded} />}
        </div>

        {assetSelected ? (
          <>
            <div className="margin">
              <h3 className="panel-subhead">Asset : {assetSelected} </h3>
              <hr />
              <h4>{assetSelected.name}</h4>
            </div>
            {isDateAdded &&
              <h3 className="panel-subhead margin">
                Date:{" "}
                {startDateTime.startDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: '2-digit' }) + " " +
                  startDateTime.startTimeHrs + ":" + startDateTime.startTimeMinutes + " " +
                  startDateTime.startTimePeriod
                  + " - " + endDateTime.endDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: '2-digit' }) + " " +
                  endDateTime.endTimeHrs + ":" + endDateTime.endTimeMinutes + " " +
                  endDateTime.endTimePeriod
                }
              </h3>
            }
          </>
        ) : (
          <div className="panel-text panel-add-placeholder">
            <h3 className="placeholder-text">+</h3>
            <h3 className="placeholder-text">
              Select asset to add milestone for
            </h3>
          </div>
        )}

        <div className="presetPoint-buttons">
          <button className="panel-deleteBtn">Delete</button>
          <button
            className="panel-saveBtn panel-text"
            onClick={embedMilestoneInfo}
          >
            Save
          </button>
        </div>
      </div>

      <MilestonesList
        milestoneList={milestoneList}
        updateCesiumClock={updateCesiumClock}
      />

    </>
  );
};

export default ProjectSchedule;
