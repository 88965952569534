// @ts-nocheck
/**
 * Component: `visioWindow.jsx`
 *
 * Author: Ankit Jangid
 */
import {
  Math as CMath,
  Cesium3DTileStyle,
  Cesium3DTileset,
  Model,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  defined
} from "cesium";
import { useEffect, useState } from "react";
import { ProjectDateContext } from "../../visioWindow";
import { useContext } from "react";

/**
 * @description `Component`: Responsible for managing user interactions with 3D assets in
 * Cesium viewer. It detects mouse clicks on 3D assets, identifies the selected resource (either a 3D tileset or a model),
 * communicates it to the parent component, and provides visual feedback by changing the asset's style.
 * It also reverts the style changes when the user releases the mouse button. This component sets up and destroys
 * event handlers when mounting and unmounting to ensure proper functionality.
 * @param { Viewer } viewer Cesium Viewer
 * @param { Object } handleResourceInFocus In focus resource handler
 */
const ResourceFocus = ({
  viewer,
  handleResourceInFocus,
  geoAlt,
  handleGeoAlt,
  selectedResource,
  resourceInFocus,
}) => {
  const [geoLat, setGeoLat] = useState(0);
  const [geoLon, setGeoLon] = useState(0);
  // Using the project range context
  const projectRange = useContext(ProjectDateContext);

  useEffect(() => {
    // .. Mouse left down handler  ..
    const newHandler = new ScreenSpaceEventHandler(viewer.scene.canvas);
    newHandler.setInputAction(function (click) {
      const pickedObject = viewer.scene.pick(click.position);
      let selectedResource;
      if (
        defined(pickedObject) &&
        pickedObject.primitive instanceof Cesium3DTileset
      ) {
        selectedResource = pickedObject.primitive;

        // .. Set the selected resource to selectedResource state hook ..
        handleResourceInFocus(selectedResource);
        console.log(" TILE : ", selectedResource);

        // .. Apply color style to selectedResource ..
        selectedResource.style = new Cesium3DTileStyle({
          color: "color('#c2fc03')",
        });
      } else if (
        defined(pickedObject && pickedObject.primitive instanceof Model)
      ) {
        selectedResource = pickedObject.primitive;

        // .. Set the selected resource to selectedResource state hook ..
        handleResourceInFocus(selectedResource);
        console.log("Obj : ", selectedResource);

        // .. Apply color style to selectedResource ..
        if (selectedResource) {
          selectedResource.style = new Cesium3DTileStyle({
            color: "color('#c2fc03')",
          });
        }
      }
    }, ScreenSpaceEventType.LEFT_DOWN);

    // .. Mouse left release handler ..
    const newMouseUpHandler = new ScreenSpaceEventHandler(viewer.scene.canvas);
    newMouseUpHandler.setInputAction(function (click) {
      const pickedObject = viewer.scene.pick(click.position);
      let selectedResource;
      if (
        defined(pickedObject) &&
        pickedObject.primitive instanceof Cesium3DTileset
      ) {
        selectedResource = pickedObject.primitive;
        selectedResource.style = undefined;
      } else if (
        defined(pickedObject && pickedObject.primitive instanceof Model)
      ) {
        // .. Remove styles from selectedResource ..
        selectedResource = pickedObject.primitive;
        selectedResource.style = undefined;
      }
    }, ScreenSpaceEventType.LEFT_UP);

    // .. Geolocation label handler ..
    // const handleMouseMoveDebounced = _.debounce(handleMouseMove, 100);
    const geolocationInfoHandler = new ScreenSpaceEventHandler(
      viewer.scene.canvas
    );
    geolocationInfoHandler.setInputAction(function (movement) {
      const position = viewer.camera.pickEllipsoid(
        movement.endPosition,
        viewer.scene.globe.ellipsoid
      );
      if (position) {
        const ellipsoid = viewer.scene.globe.ellipsoid;
        const cartographic = ellipsoid.cartesianToCartographic(position);
        const latitude = CMath.toDegrees(cartographic.latitude).toFixed(5);
        const longitude = CMath.toDegrees(cartographic.longitude).toFixed(5);
        setGeoLat(latitude);
        setGeoLon(longitude);
      }
    }, ScreenSpaceEventType.MOUSE_MOVE);

    // ..  Cam height calculation ..
    const camHeightHandler = new ScreenSpaceEventHandler(viewer.scene.canvas);
    camHeightHandler.setInputAction(function () {
      const camHeight = viewer.camera.positionCartographic.height.toFixed(2);
      if (camHeight > 1) {
        handleGeoAlt(camHeight);
      }
    }, ScreenSpaceEventType.WHEEL);

    /**
     * Destroy event handlers upon exit
     */
    return () => {
      newHandler.destroy();
      newMouseUpHandler.destroy();
      geolocationInfoHandler.destroy();
      camHeightHandler.destroy();
    };
  }, []);

  return (
    <div className="visio-window-bottom-bar">
      <div className="project-details">
        <h4 className="project-title">Project: &nbsp;</h4>
        <h4 className="project-title-value">
          {selectedResource?.asset_name
            ? selectedResource?.asset_name
            : selectedResource?.project_name.split(".json")[0]}
        </h4>
        <h4 className="project-title">Project Range: &nbsp;</h4>
        <h4 className="project-title-value">{new Date(projectRange?.start_date).toLocaleDateString()} - {new Date(projectRange?.end_date).toLocaleDateString()}</h4>
        <h4 className="project-title">Asset: &nbsp;</h4>
        <h4 className="project-title-value">
          {resourceInFocus instanceof Cesium3DTileset &&
            resourceInFocus.extras.id + " " + resourceInFocus.extras.name}
          {resourceInFocus instanceof Model &&
            resourceInFocus._id + " " + resourceInFocus._credit._html}
        </h4>
      </div>

      <div className="geolocation_label">
        <p className="geolocation-element">Lat : {geoLat} °</p>
        <p className="geolocation-element">Lon : {Math.abs(geoLon)} °</p>
        <p className="geolocation-element">Cam : {geoAlt} m </p>
      </div>
    </div>
  );
};

export default ResourceFocus;
