// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect, useRef } from 'react'

// ... NPM Packages ...
import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";

// ... Components ...
import Header from "../../components/header/header";
import PreviewWindow from "../../components/previewWindow/previewWindow";
import DetailsPreview from "../../components/detailsPreview/detailsPreview";
import ProjectList from '../../components/projectList/projectList'
import AssetList from '../../components/assetList/assetList'
import './editorDashboard.css'

// ... Misc ...
import filter_alt_two from "../../assets/filter_alt_two.png";

/**
 * @description `Page` : Component that provides a user interface for managing projects and assets, including options for filtering, deletion, and previews.
 * @category Editor Dashboard
 * @returns {JSX.Element} Rendered editor dashboard page.
 */
const EditorDashboard = () => {
    // ... State and hook declarations ...

    // .. Project hooks ..
    const [projectList, setProjectList] = useState(true);
    const [projects, setProjects] = useState([]);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

    // .. Asset hooks ..
    const [assetList, setAssetList] = useState(false);

    // .. Other hooks ..
    const [selectedResource, setSelectedResource] = useState(null);
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filterList, setFilterList] = useState(false);
    const [selectedFilterCriteria, setSelectedFilterCriteria] = useState("name");
    const [headerUserDetails, setHeaderUserDetails] = useState({
        username: "",
        email: "",
    });

    useEffect(() => {
        // Function to close filter dropdown when clicking outside
        const handleDocumentClick = (event) => {
            if (
                filterDropdownRef.current &&
                !filterDropdownRef.current.contains(event.target)
            ) {
                setFilterList(false);
            }
        };
        // Add event listener to listen for clicks on the document
        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    /**
     *  Updates the selected filter criteria based on user input.
     * @param {Object} event - Event object 
     */
    const handleFilterCriteriaChange = (event) => {
        setSelectedFilterCriteria(event.target.value);
    };

    // .. Constants ..
    const jwtToken = secureLocalStorage.getItem("token");
    const userId = jwt_decode(jwtToken).user_id;
    const { t } = useTranslation();
    const filterDropdownRef = useRef(null);

    // ... Local Functions ...

    /**
   * Sends a DELETE request to the server API with the user ID and project ID to remove the specified project, and upon success, updates the local state to reflect the deletion.
   * @param {number} userid 
   * @param {number} projectID 
   */
    const handleDeleteProject = (userid, projectID) => {
        console.log("Deleting project with ID:", projectID);
        console.log(projectID);
        fetch(
            process.env.REACT_APP_API +
            "/project/project_details/" +
            userid +
            "/" +
            projectID,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            }
        )
            .then((res) => res.json())
            .then((json) => {
                console.log(json);

                setData((prevData) =>
                    prevData.filter((project) => project.id !== projectID)
                );
                fetchProjectList(userid);
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    /**
    * Sends a DELETE request to the server API with the user ID and asset ID to remove the specified asset, and upon success, updates the local state to reflect the deletion.
    * @param {number} userid 
    * @param {number} assetID 
    */
    const handleDeleteAsset = (userid, assetID) => {
        console.log("Deleting asset with ID:", assetID);
        fetch(
            process.env.REACT_APP_API +
            "/project/asset_details/" +
            userid +
            "/" +
            assetID,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            }
        )
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setAsset((prevAsset) =>
                    prevAsset.filter((asset) => asset.id !== assetID)
                );
                fetchAssetList(userid);
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    /**
    * Makes GET request to the server to retrieve account details.
    * @param {number} userId 
    */
    const fetchAccountDetails = (userId) => {
        fetch(
            process.env.REACT_APP_API + "/accounts/master_account_details/" + userId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            }
        )
            .then((res) => res.json())
            .then((json) => {
                if (json.code == 100) {
                    const userDetails = {
                        username: json.data[0].username,
                        email: json.data[0].email,
                    };
                    setHeaderUserDetails(userDetails);
                    console.log(userDetails.username);
                }
                if (json.detail) {
                    console.log("CODE51");
                    const userDetails = {
                        username: "undefined",
                        email: "undefined",
                    };
                    setHeaderUserDetails(userDetails);
                }
            });
    };

    // Misc Handlers

    /**
     * Displays a success popup when a project or asset is successfully deleted.
     */
    const handleDeleteSuccess = () => {
        setShowDeleteSuccess(true);
        setTimeout(() => {
            setShowDeleteSuccess(false);
        }, 3000);
    };

    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };

    /**
    * Displays a filter dropdown
    */
    const handleFilter = () => {
        setFilterList(true);
    };

    /**
    * Handles update of selected resource (Project / Asset)
    * @param {Object} selectedItem - Item in focus.
    */
    const handleResourceUpdate = (selectedItem) => {
        setSelectedResource(selectedItem);
        setSelectedResourceId(selectedItem ? selectedItem.id : null);
    };

    /**
   * Sets the active tab based on user selection.
   * @param {string} id - The ID of the selected tab (Projects / Assets / Users).
   */
    const handleSetActive = (id) => {
        setSelectedResource(null);
        if (id == "asset") {
            setProjectList(false);
            setAssetList(true);
        } else {
            setAssetList(false);
            setProjectList(true);
        }
    };

    /**
    * editorDashboard useEffect hook.
    */
    useEffect(() => {
        fetchAccountDetails(userId);
    }, []);


    return (
        <div>
            <Header headerUserDetails={headerUserDetails} />

            <div className="viewerDashboard_editor-heading-container">
                <div className="viewerDashboard_lower-heading-right">
                    <p
                        id="project"
                        onClick={(e) => handleSetActive(e.target.id)}
                        className={projectList ? "active" : "inactive"}
                    >
                        {t("main.project-list")}
                    </p>
                    <p
                        id="asset"
                        onClick={(e) => handleSetActive(e.target.id)}
                        className={assetList ? "active" : "inactive"}
                    >
                        {t("main.asset-list")}
                    </p>
                </div>
                <div className="viewerDashboard_search-bar-container">
                    <div className="viewerDashboard_search-bar">
                        <div className="search-input-container">
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchQueryChange}
                                placeholder={t("main.search")}
                            />
                        </div>
                        <div
                            className="viewerDashboard_search-bar-right"
                            onClick={handleFilter}
                            ref={filterDropdownRef}
                        >
                            <img src={filter_alt_two} />
                            {filterList && (
                                <select
                                    name="language"
                                    id="filter"
                                    onChange={handleFilterCriteriaChange}
                                >
                                    <option>Name</option>
                                    <option>ID</option>
                                    <option>Last Modified</option>
                                </select>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="projectDashboard_entire-container">
                <div className="projectDashboard_files">
                    <AssetList isActive={assetList}
                        handleResourceUpdate={handleResourceUpdate}
                        searchQuery={searchQuery}
                        filterCriteria={selectedFilterCriteria}
                        onDeleteAsset={() => handleDeleteAsset(userId, selectedResourceId)} />

                    <ProjectList
                        isActive={projectList}
                        newProjects={projects}
                        searchQuery={searchQuery}
                        filterCriteria={selectedFilterCriteria}
                        handleResourceUpdate={handleResourceUpdate}
                        setSelectedResourceId={setSelectedResourceId}
                        onDeleteProject={() =>
                            handleDeleteProject(userId, selectedResourceId)
                        } />
                </div>

                <div className="projectDashboard_preview-window">
                    <PreviewWindow selectedResource={selectedResource}
                        assetList={assetList} />
                    <div className="projectDashboard_open-in-viewer-btn-container">
                        <div
                            className={selectedResource == null ? "projectDashboard_open-in-viewer-btn disabled" : "projectDashboard_open-in-viewer-btn"}
                            onClick={() => {
                                navigation("/visioWindow", {
                                    replace: false,
                                    state: {
                                        selectedResource: selectedResource,
                                        userLevel: "editor",
                                    },
                                });
                            }}
                        >
                            {t("main.open-in-viewer")}
                        </div>
                    </div>
                    <DetailsPreview
                        selectedResource={selectedResource}
                        projectList={projectList}
                        onDeleteProject={() =>
                            handleDeleteProject(userId, selectedResourceId)
                        }
                        onDeleteAsset={() => handleDeleteAsset(userId, selectedResourceId)}
                        onDeleteSuccess={handleDeleteSuccess}
                        setSelectedResource={setSelectedResource} />
                </div>
            </div>
            {showDeleteSuccess && (
                <div className="notification success">
                    {projectList
                        ? "Project successfully deleted."
                        : "Asset successfully deleted."}
                </div>
            )}
        </div>
    )
}

export default EditorDashboard