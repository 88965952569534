// @ts-nocheck
import { useState } from "react";

const ViewerSideBar = ({
  handleViewerFeatureChange,
  viewerFeature,
  handleSplitViewEnabled,
  splitViewEnabled,
  resourceType,
  setTimelineOpen,
  timelineOpen,
  deviceList,
  handleDeviceList,
}) => {
  const [activeFeature, setActiveFeature] = useState(null);
  return (
    <>
      {resourceType === "project" ? (
        <>
          <div className="sidebar-icon-container">
            <div className="fill-remaining-width">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="sidebar-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={() => {
                  handleViewerFeatureChange(0);
                  // setActiveFeature("treeView");
                }}
              >
                <mask
                  id="mask0_2148_696"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2148_696)">
                  <path
                    d="M15 21V18H11V8H9V11H2V3H9V6H15V3H22V11H15V8H13V16H15V13H22V21H15ZM17 9H20V5H17V9ZM17 19H20V15H17V19ZM4 9H7V5H4V9Z"
                    fill="white"
                  />
                </g>
              </svg>
            </div>
            {viewerFeature === 0 && <div className="active-panel"></div>}
            <span className="tooltipText">Asset</span>
          </div>

          <div className="sidebar-icon-container">
            <div className="fill-remaining-width">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="sidebar-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={() => {
                  handleViewerFeatureChange(1);
                }}
              >
                <mask
                  id="mask0_2148_699"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect
                    width="24"
                    height="24"
                    transform="matrix(-1 0 0 1 24 0)"
                    fill="#D9D9D9"
                  />
                </mask>
                <g mask="url(#mask0_2148_699)">
                  <path
                    d="M2 22L6 18H20C20.55 18 21.0208 17.8042 21.4125 17.4125C21.8042 17.0208 22 16.55 22 16V4C22 3.45 21.8042 2.97917 21.4125 2.5875C21.0208 2.19583 20.55 2 20 2H4C3.45 2 2.97917 2.19583 2.5875 2.5875C2.19583 2.97917 2 3.45 2 4V22ZM5.15 16L4 17.125V4H20V16H5.15Z"
                    fill="white"
                  />
                </g>
              </svg>
            </div>
            {viewerFeature === 1 && <div className="active-panel"></div>}
            <span className="tooltipText">Annotation</span>
          </div>

          <div className="sidebar-icon-container">
            <div className="fill-remaining-width">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="sidebar-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={() => {
                  handleViewerFeatureChange(2);
                }}
              >
                <mask
                  id="mask0_2148_702"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2148_702)">
                  <path
                    d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
                    fill="white"
                  />
                </g>
              </svg>
            </div>
            {viewerFeature === 2 && <div className="active-panel"></div>}
            <span className="tooltipText">Milestone</span>
          </div>

          <div className="sidebar-icon-container">
            <div className="fill-remaining-width">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="sidebar-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={() => {
                  handleViewerFeatureChange(3);
                }}
              >
                <mask
                  id="mask0_2148_705"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2148_705)">
                  <path
                    d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H16C16.55 4 17.0208 4.19583 17.4125 4.5875C17.8042 4.97917 18 5.45 18 6V10.5L22 6.5V17.5L18 13.5V18C18 18.55 17.8042 19.0208 17.4125 19.4125C17.0208 19.8042 16.55 20 16 20H4ZM4 18H16V6H4V18Z"
                    fill="white"
                  />
                </g>
              </svg>
            </div>
            {viewerFeature === 3 && <div className="active-panel"></div>}
            <span className="tooltipText">Camera</span>
          </div>

          <div className="sidebar-icon-container">
            <div className="fill-remaining-width">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="sidebar-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={() => {
                  handleViewerFeatureChange(4);
                }}
              >
                <mask
                  id="mask0_2148_708"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2148_708)">
                  <path
                    d="M2 22V2H4V22H2ZM13 17V7H16V17H13ZM7 17V7H10V17H7Z"
                    fill="white"
                  />
                </g>
              </svg>
            </div>
            {viewerFeature === 4 && <div className="active-panel"></div>}
            <span className="tooltipText">Section</span>
          </div>

          {!splitViewEnabled && (
            <div className="sidebar-icon-container">
              <div className="fill-remaining-width">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="sidebar-icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={() => {
                    handleViewerFeatureChange(5);
                    handleSplitViewEnabled(true);
                  }}
                >
                  <mask
                    id="mask0_2148_711"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2148_711)">
                    <path
                      d="M10 23V21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H10V1H12V23H10ZM5 18H10V12L5 18ZM14 21V12L19 18V5H14V3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H14Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </div>
              {viewerFeature === 5 && <div className="active-panel"></div>}
              <span className="tooltipText">Split</span>
            </div>
          )}

          <div className="sidebar-icon-container">
            <div className="fill-remaining-width">
              <svg xmlns="http://www.w3.org/2000/svg" onClick={handleDeviceList} width="24" height="24" viewBox="0 0 24 24" fill="none" className="sidebar-icon">
                <mask id="mask0_3969_962" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_3969_962)">
                  <path d="M9.6 15.6L11 14.15L8.85 12L11 9.85L9.6 8.4L6 12L9.6 15.6ZM14.4 15.6L18 12L14.4 8.4L13 9.85L15.15 12L13 14.15L14.4 15.6ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9.2C9.41667 2.4 9.77917 1.91667 10.2875 1.55C10.7958 1.18333 11.3667 1 12 1C12.6333 1 13.2042 1.18333 13.7125 1.55C14.2208 1.91667 14.5833 2.4 14.8 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19ZM12 4.25C12.2167 4.25 12.3958 4.17917 12.5375 4.0375C12.6792 3.89583 12.75 3.71667 12.75 3.5C12.75 3.28333 12.6792 3.10417 12.5375 2.9625C12.3958 2.82083 12.2167 2.75 12 2.75C11.7833 2.75 11.6042 2.82083 11.4625 2.9625C11.3208 3.10417 11.25 3.28333 11.25 3.5C11.25 3.71667 11.3208 3.89583 11.4625 4.0375C11.6042 4.17917 11.7833 4.25 12 4.25Z" fill="white" />
                </g>
              </svg>
            </div>
            {deviceList && <div className="active-panel"></div>}
            <span className="tooltipText">Thingspad</span>
          </div>

          <div className="sidebar-icon-container timeline-icon-sidebar-container">
            <div className="fill-remaining-width">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="43" viewBox="0 0 42 43" fill="none" className="sidebar-icon timeline-view-icon" onClick={() => {
                setTimelineOpen(prev => !prev);
              }}>
                <mask id="mask0_3225_1277" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="9" y="10" width="24" height="24">
                  <rect x="9" y="10" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_3225_1277)">
                  <path d="M15 27H21V25H15V27ZM18 23H24V21H18V23ZM21 19H27V17H21V19ZM14 31C13.45 31 12.9792 30.8042 12.5875 30.4125C12.1958 30.0208 12 29.55 12 29V15C12 14.45 12.1958 13.9792 12.5875 13.5875C12.9792 13.1958 13.45 13 14 13H28C28.55 13 29.0208 13.1958 29.4125 13.5875C29.8042 13.9792 30 14.45 30 15V29C30 29.55 29.8042 30.0208 29.4125 30.4125C29.0208 30.8042 28.55 31 28 31H14ZM14 29H28V15H14V29Z" fill="white" />
                </g>
                <rect x="0.5" y="0.5" width="41" height="42" rx="3.5" stroke="white" />
              </svg>
            </div>
            {timelineOpen && <div className="active-panel"></div>}
            <span className="tooltipText">Timeline</span>
          </div>
        </>
      ) : (
        <div className="sidebar-icon-container">
          <div className="fill-remaining-width">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="sidebar-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => {
                handleViewerFeatureChange(0);
                // setActiveFeature("treeView");
              }}
            >
              <mask
                id="mask0_2148_696"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2148_696)">
                <path
                  d="M15 21V18H11V8H9V11H2V3H9V6H15V3H22V11H15V8H13V16H15V13H22V21H15ZM17 9H20V5H17V9ZM17 19H20V15H17V19ZM4 9H7V5H4V9Z"
                  fill="white"
                />
              </g>
            </svg>
          </div>
          {viewerFeature === 0 && <div className="active-panel"></div>}
          <span className="tooltipText">Asset</span>
        </div>
      )}
    </>
  );
};

export default ViewerSideBar;
