// @ts-nocheck

/**
 * Functional component for viewing the list of milestones
 * @param { Array } milestoneList List of milestones to display
 * @param { Boolean } isViewer Indicates whether list is displayed in viewer or editor mode
 * @param { Function } updateCesiumClock Function to update the cesium clock
 * @returns { JSX.Element } Milestone list component
 */
const MilestonesList = ({ milestoneList, isViewer, updateCesiumClock }) => {
  return (
    <div className={`panel-container scrollable-container ${isViewer ? 'fixed-height-list-viewer' : 'fixed-height-list-editor'}`}>
      <table className="milestones-list">
        <tbody className="milestones-list-body">
          <tr>
            <th className="panel-subhead">Milestone Name</th>
            <th className="panel-subhead">Date</th>
          </tr>
          <hr />
          {milestoneList.length > 0 ? (
            milestoneList.map((milestone) => {
              console.log(milestone);
              return (
                <tr
                  className="milestone-row"
                  onClick={() => updateCesiumClock(milestone.startDate)}
                >
                  <td className={`${isViewer ? 'panel-list-name-viewer' : 'panel-subhead'} panel-text`}>{milestone.name}</td>
                  <td className="milestone-date panel-text">
                    {new Date(milestone.startDate).toLocaleDateString()}
                  </td>
                </tr>
              );
            })
          ) : (
            <div className="empty-milestones-list placeholder-text">
              Add milestone to preview
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MilestonesList;
