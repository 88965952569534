// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Viewer,
  Ion,
  CesiumTerrainProvider,
  Resource,
  JulianDate,
  BingMapsImageryProvider,
  BingMapsStyle,
} from "cesium";
import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import "./demoWindow.css";
import loadJSONProject from "../../components/cesiumAPI/loadJSONProject";
import PresetLogic from "../containerFarm/components/presetLogic";

const DemoWindow = () => {
  const [viewer, setViewer] = useState(null);
  const navigation = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_API + "/auth/jwt/create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: "jangidankit@gmail.com",
        password: "tenant@1",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        Cesium.Ion.defaultAccessToken =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1ZDdmZWViMS0xZDVjLTRhYTUtYWI4Yy0yOGFkZmI4YjNmZGIiLCJpZCI6NTI3ODIsImlhdCI6MTYyMjU1NTY4M30.2Qry6HDCock7YrMi8GQaStykhvfbCfuJ-TDs7IRglmY";
        const viewer = new Viewer("cesium-Container", {
          terrain: Cesium.Terrain.fromWorldTerrain(),
          // terrainProvider: terrain,
          geocoder: false,
          baseLayerPicker: false,
        });

        // .. Make it day time ..
        const ISO8601 = "2023-10-01T00:00:00Z";
        viewer.clock.currentTime = JulianDate.fromIso8601(ISO8601);

        // ... Default Imagery and Terrain Provider ...
        const imageryTerrainLoader = async () => {
          // .. ArcGIS Terrain Provider ..
          const terrainProvider =
            await Cesium.ArcGISTiledElevationTerrainProvider.fromUrl(
              "https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer",
              {
                token:
                  "AAPK2099f81e64c6478c8922b18a20b89895bHIYvtbs39mvnK6gwF8TM7oYzkvYPu9O5sSFldOBkqBP17BKH9uT2DmujnIc1vkt",
              }
            );
          // viewer.terrainProvider = terrainProvider;

          const bing = await BingMapsImageryProvider.fromUrl(
            "https://dev.virtualearth.net",
            {
              key: "AmJAP16ARM2JD-U91lhBL3OxkLMrRpllxjMh1a--OYg9Yh2mgL-3qxjdpWKXg3M0",
              mapStyle: BingMapsStyle.ROAD,
            }
          );
          viewer.scene.imageryLayers.addImageryProvider(bing);
        };
        imageryTerrainLoader();

        setViewer(viewer);

        const resourceObject = (resourceSpecificUrl) => {
          return new Resource({
            url: resourceSpecificUrl,
            headers: {
              Authorization: "JWT " + json.access,
            },
          });
        };

        loadJSONProject(
          viewer,
          resourceObject(
            "https://livolv.com:8000/api/project/get_url/?file_id=P247"
          )
        );
        viewer.camera.setView({
          destination: new Cesium.Cartesian3(
            -3748632.3154630135,
            3648150.7991271825,
            3637682.006620833
          ),
          orientation: {
            direction: new Cesium.Cartesian3(0.6674974764685974, 0.645480625669681, -0.37121675715561575),
            up: new Cesium.Cartesian3(-0.4420307760843689, 0.7446873000013294, 0.5000496157492582),
          }
        });
      })
      .catch((error) => {
        console.log("Network Error : ", error);
        navigation("/networkError");
      });

    // Set the viewer state in the component's state
    return () => {
      try {
        viewer.entities.removeAll();
        viewer.scene.primitives.removeAll();
        viewer.destroy();
      } catch (error) {
        console.log("Error during destroying viewer : ", error);
      }
    };
  }, []);

  return (
    <>
      <div className="demo-window">
        <div id="cesium-Container" className="cesium-container"></div>
      </div>
      {viewer && <PresetLogic viewer={viewer} />}
    </>
  );
};

export default DemoWindow;
