import React from "react";
import { useLocation } from "react-router-dom";

export default function NoAuth() {
  const location = useLocation();
  console.log("errr: ", location.state.detail);
  return (
    <>
      <h1>Un-authorized request</h1>
      <h3>{location.state.detail}</h3>
    </>
  );
}
