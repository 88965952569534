// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect } from "react";

// ... NPM Packages ...
import jwt_decode from "jwt-decode";
import secureLocalStorage from "react-secure-storage";

// ... Components ...
import Header from '../../components/header/header'
import TopBar from "./components/topBar/topBar";
import TenantList from "./components/tenantList/tenantList";
import Popups from "./components/popups/popups";
import './adminDashboard.css'

/**
 * @description `AdminDashboard`: A React component representing the admin dashboard page. It provides functionality for managing tenants, user accounts, and displays tenant information.
 * @category Admin Dashboard
 * @returns {JSX.Element} Rendered admin dashboard page.
 */
const AdminDashboard = () => {
    // ... State and hook declarations ...

    const [getData, setData] = useState([]);
    const [showAddTenantPopup, setShowAddTenantPopup] = useState(false);
    const [showDeleteTenantPopup, setShowDeleteTenantPopup] = useState(false);
    const [users, setUsers] = useState([]);
    const [newTenantName, setNewTenantName] = useState("");
    const [newTenantEmail, setNewTenantEmail] = useState("");
    const [failureMessage, setFailureMessage] = useState("");
    const [failure, setFailure] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedFilterCriteria, setSelectedFilterCriteria] = useState("name");
    const [searchQuery, setSearchQuery] = useState("");
    const [headerUserDetails, setHeaderUserDetails] = useState({
        username: "",
        email: "",
    });

    // .. Constants ..
    const jwtToken = secureLocalStorage.getItem("token");
    const userId = jwt_decode(jwtToken).user_id;

    /**
     * AdminDashboard useEffect hook.
     */
    useEffect(() => {
        fetchAccountDetails(userId);
    }, []);

    /**
     * Makes GET request to the server to retrieve account details.
     * @param {number} userId 
     */
    const fetchAccountDetails = (userId) => {
        fetch(
            process.env.REACT_APP_API + "/accounts/master_account_details/" + userId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            }
        )
            .then((res) => res.json())
            .then((json) => {
                if (json.code == 100) {
                    const userDetails = {
                        username: json.data[0].username,
                        email: json.data[0].email,
                    };
                    setHeaderUserDetails(userDetails);
                }
                if (json.detail) {
                    console.log("CODE51");
                    const userDetails = {
                        username: "undefined",
                        email: "undefined",
                    };
                    setHeaderUserDetails(userDetails);
                }
            });
    };

    /**
    *  Makes GET request to fetch Tenant list 
    */
    const fetchTenantList = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API + "/visioAdmin/get_tenant_details", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            });
            if (response.ok) {
                const json = await response.json();
                if (json.status === "Success" && Array.isArray(json.data)) {
                    setData(json.data);
                }
            } else {
                console.error("Failed to fetch user data");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
        return "";
    };


    /**
    * Handles Tenant creation.
    * @param {Object} e - Event object
    */
    const handleTenantCreation = (e) => {
        e.preventDefault();

        const username = newTenantName;
        const email = newTenantEmail;

        const TenantDetails = {
            email: email,
            user_level: "Tenant",
            username: username,
            created_by: userId,
            password: "tenant@1",
            re_password: "tenant@1",
        };

        fetch(process.env.REACT_APP_API + "/auth/users/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + jwtToken,
            },
            body: JSON.stringify(TenantDetails),
        })
            .then((res) => res.json())
            .then((json) => {
                setUsers((prevUsers) =>
                    Array.isArray(prevUsers) ? [...prevUsers, json] : [json]
                );
                setShowAddTenantPopup(false);
                setNewTenantName("");
                setNewTenantEmail("");
                const createdUser = Object.keys(json)[5];
                if (createdUser == "id") {
                    handleUploadSuccess("Tenant created Successfully");
                    handleTenantActivation()
                } else {
                    setFailure(true);
                    setFailureMessage(json.email || json.password);
                    setTimeout(() => {
                        setFailure(false);
                        setFailureMessage("");
                    }, 3000);
                }
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    /**
  * Sends email to the user to activate their account.
  * @param {Object} e - Event object (Group name)
  */
    const handleTenantActivation = () => {
        const email = newTenantEmail;
        const tenantDetails = JSON.stringify({
            email: email,
            is_activation: true,
        });

        fetch(process.env.REACT_APP_API + "/auth/users/reset_password/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + jwtToken,
            },
            body: tenantDetails,
        })
            .then((json) => {
                if (json.status === 204) {
                    setSuccess(true);
                    setSuccessMsg("An activation link has been successfully sent to your email");
                    setTimeout(() => {
                        setSuccess(false);
                        setSuccessMsg("");
                    }, 3000);
                } else if (json.status === 400) {
                    console.log("Invalid mail ID");
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    /**
    * Sends a DELETE request to the server API with the user ID to remove the specified tenant, and upon success, updates the local state to reflect the deletion.
    * @param {number} userId
    */
    const handleDeleteTenant = (userid) => {
        console.log("Deleting Tenant with ID:", userid);
        fetch(process.env.REACT_APP_API + "/accounts/delete_account/" + userid, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + jwtToken,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                fetchTenantList(userId);
                setShowDeleteTenantPopup(false);
                if (json.status == "Successfully deleted") {
                    setSuccess(true);
                    setSuccessMsg("Tenant deleted successfully");
                    setTimeout(() => {
                        setSuccess(false);
                        setSuccessMsg("");
                    }, 3000);
                } else {
                    setFailure(true);
                    setFailureMessage("The selected tenant couldn't be deleted");
                    setTimeout(() => {
                        setFailure(false);
                        setFailureMessage("");
                    }, 3000);
                }
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };


    /**
    * Handles changes to the tenant name input.
    * @param {Object} e - Event object (Project name input from DOM)
    */
    const handleNameChange = (e) => {
        const newValue = e.target.value.replace(/[^a-zA-Z\s\-_()]/g, "");
        setNewTenantName(newValue);
    };

    /**
     * Handles changes to the tenant email input.
     * @param {Object} e - Event object (Location input from DOM)
     */
    const handleEmailChange = (e) => {
        const newValue = e.target.value.replace(/[^a-zA-Z0-9@._-]/g, "");
        setNewTenantEmail(newValue);
    };

    const handleSelectedTenant = (selectedTenant) => {
        setSelectedUser(selectedTenant);
        setSelectedUserId(selectedTenant.id);
    };

    /**
    * Displays a popup on creation or upload of projects, assets, users and groups
    */
    const handleUploadSuccess = (msg) => {
        setSuccess(true);
        setSuccessMsg(msg);
        setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
        }, 3000);
    };

    /**
     * Handles update of search query coming as user input
     * @param {Object} e - Event object (Search query string input from DOM)
     */
    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };

    /**
     * Sets the selected filter criteria
     */
    const handleFilterCriteriaChange = (event) => {
        setSelectedFilterCriteria(event.target.value);
    };

    /**
     * Closes the popup.
     */
    const closePopup = () => {
        setShowAddTenantPopup(false);
        setShowDeleteTenantPopup(false)
        setNewTenantName("");
        setNewTenantEmail("");
    };


    return (
        <div>
            <Header headerUserDetails={headerUserDetails} />

            <TopBar
                setShowAddTenantPopup={setShowAddTenantPopup}
                handleFilterCriteriaChange={handleFilterCriteriaChange}
                searchQuery={searchQuery}
                handleSearchQueryChange={handleSearchQueryChange}
            />

            <TenantList
                users={users}
                setShowDeleteTenantPopup={setShowDeleteTenantPopup}
                handleSelectedTenant={handleSelectedTenant}
                deletedTenants={getData}
                searchQuery={searchQuery}
                filterCriteria={selectedFilterCriteria}
            />

            <Popups
                showAddTenantPopup={showAddTenantPopup}
                showDeleteTenantPopup={showDeleteTenantPopup}
                setShowDeleteTenantPopup={setShowDeleteTenantPopup}
                newTenantName={newTenantName}
                newTenantEmail={newTenantEmail}
                handleNameChange={handleNameChange}
                handleEmailChange={handleEmailChange}
                handleTenantCreation={handleTenantCreation}
                success={success}
                successMsg={successMsg}
                failure={failure}
                failureMessage={failureMessage}
                handleDeleteTenant={() => handleDeleteTenant(selectedUserId)}
            />

            {(showAddTenantPopup || showDeleteTenantPopup) && (
                <div
                    className="adminDashboard_background-overlay"
                    onClick={closePopup}
                ></div>
            )}
        </div>
    )
}

export default AdminDashboard