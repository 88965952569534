// @ts-nocheck

// ... Cesium Imports ...
import {
  Cartesian3,
  Cesium3DTileset,
  ClippingPlane,
  ClippingPlaneCollection,
  HeadingPitchRoll,
  JulianDate,
  Matrix4,
  Model,
  NearFarScalar,
  PropertyBag,
  Resource,
  TimeIntervalCollection,
  Transforms,
} from "cesium";

/**
 * JSON Parser that loads project resources (packets) into Scene
 * @param { Viewer } viewer Cesium Viewer
 * @param { Resource } projectResource  Cesium Resource
 * @category Parser
 */

const loadJSONProject = async (viewer, projectResource) => {
  // console.log(projectResource);
  // .. Flush all Primitives ..
  // const { updatePacketElementIds } = usePacketContext();
  const packetElement_keys = [];
  viewer.scene.primitives._primitives =
    viewer.scene.primitives._primitives.filter((primitive) => {
      if (primitive instanceof Cesium3DTileset || primitive instanceof Model) {
        return false; // Exclude this primitive from the filtered array
      }
      return true; // Keep this primitive in the filtered array
    });

  viewer.entities.removeAll();
  // ...  Get the project JSON file ...
  try {
    const response = await fetch(projectResource.url, {
      method: "GET",
      headers: projectResource.headers,
    });
    if (!response.ok) {
      throw new Error("Error while fetching Tileset");
    }
    const projectData = await response.json();

    let position;
    let orientation;
    let scaling;
    let clippingPlanes;

    // .. Read through for packet-wise Primitive addition to scene ..
    for (let i = 0; i < projectData.length; i++) {
      // . Cesium3DTileset primitive parsing .

      const packetElement = projectData[i];
      if (packetElement.id.toString().startsWith("TS")) {
        const resourceObjTile = new Resource({
          url:
            projectResource._url +
            packetElement.tileset.uri +
            "?file_id=" +
            projectResource._queryParameters.file_id,
          headers: projectResource.headers,
        });

        if (packetElement.georeferenced === "cgml") {
          // console.log(" TS IS A CGML ");
        } else {
          position = Cartesian3.fromDegrees(
            packetElement.position.cartographicDegrees[0],
            packetElement.position.cartographicDegrees[1],
            packetElement.position.cartographicDegrees[2]
          );
          orientation = Transforms.headingPitchRollQuaternion(
            position,
            new HeadingPitchRoll.fromDegrees(
              packetElement.hpr.h,
              packetElement.hpr.p,
              packetElement.hpr.r
            )
          );
          scaling = new Cartesian3(
            packetElement.scale.x,
            packetElement.scale.y,
            packetElement.scale.z
          );

          // .. Setting clippingPlanes ..

          let planeDirectionObj = {};
          let planeModelMatrix;
          if (packetElement.clippingPlanes) {
            if (packetElement.clippingPlanes.planeDirection == "XY") {
              planeDirectionObj.x = 0.0;
              planeDirectionObj.y = 1.0;
              planeDirectionObj.z = 0.0;
              planeModelMatrix = Matrix4.fromTranslation(new Cartesian3(0.0, packetElement.clippingPlanes.clipDistance, 0.0));
            } else if (packetElement.clippingPlanes.planeDirection === "YZ") {
              planeDirectionObj.x = 1.0;
              planeDirectionObj.y = 0.0;
              planeDirectionObj.z = 0.0;
              planeModelMatrix = Matrix4.fromTranslation(new Cartesian3(packetElement.clippingPlanes.clipDistance, 0.0, 0.0));
            }
            // . Depth plane .
            else if (packetElement.clippingPlanes.planeDirection === "XZ") {
              planeDirectionObj.x = 0.0;
              planeDirectionObj.y = 0.0;
              planeDirectionObj.z = -1.0;
              planeModelMatrix = Matrix4.fromTranslation(new Cartesian3(0.0, 0.0, packetElement.clippingPlanes.clipDistance));
            }

            // console.log(" PD : ", planeDirectionObj, packetElement.clippingPlanes.clipDistance)
            clippingPlanes = new ClippingPlaneCollection({
              planes: [
                new ClippingPlane(
                  new Cartesian3(
                    planeDirectionObj.x,
                    planeDirectionObj.y,
                    planeDirectionObj.z
                  ),
                  20.0
                ),
              ],
              edgeWidth: 2.0,
            });

            clippingPlanes.modelMatrix = planeModelMatrix
          }
        }


        const newTileset = await Cesium3DTileset.fromUrl(resourceObjTile);

        // .. Assign assetConclusion info ..

        // .. If Tileset is not a city GML then set the modelMatrix, and extras ..
        if (packetElement.georeferenced !== "cgml") {
          const modelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
            position,
            orientation,
            scaling
          );

          // .. Assign assetConclusion info ..
          newTileset.extras.assetAvailability = packetElement.assetAvailability;

          // .. Assign clippingPlane ..
          if (packetElement.clippingPlanes) {
            newTileset.clippingPlanes = clippingPlanes;
            newTileset.extras.clippingPlanes = packetElement.clippingPlanes
          }

          // . Neutralize tileset's root transform .
          newTileset.root.transform = Matrix4.IDENTITY;

          // . Assign the created modelMatrix .
          newTileset.modelMatrix = modelMatrix;

          // . Assign orientation .
          newTileset.extras.hpr = {
            h: packetElement.hpr.h,
            p: packetElement.hpr.p,
            r: packetElement.hpr.r,
          };

          // . Assign scale .
          newTileset.extras.scale = {
            x: packetElement.scale.x,
            y: packetElement.scale.y,
            z: packetElement.scale.z,
          };

          // . Assign position .
          newTileset.extras.position = {
            x: packetElement.position.longitude,
            y: packetElement.position.latitude,
            z: packetElement.position.height,
          };
        }

        // . Add tileset to scene primitives.
        viewer.scene.primitives.add(newTileset);

        // . Set extras for the primitive .
        newTileset.extras.id = packetElement.id;

        // viewer.flyTo(newTileset);

        newTileset.pointCloudShading.attenuation = true;
        newTileset.pointCloudShading.eyeDomeLighting = true;
      }

      // .. Model primitive parsing ..
      else if (packetElement.id.toString().startsWith("GL")) {
        const position = Cartesian3.fromDegrees(
          packetElement.position.cartographicDegrees[0],
          packetElement.position.cartographicDegrees[1],
          packetElement.position.cartographicDegrees[2]
        );
        const orientation = Transforms.headingPitchRollQuaternion(
          position,
          new HeadingPitchRoll.fromDegrees(
            packetElement.hpr.h,
            packetElement.hpr.p,
            packetElement.hpr.r
          )
        );
        const scaling = new Cartesian3(
          packetElement.model.scale.x,
          packetElement.model.scale.y,
          packetElement.model.scale.z
        );

        const resourceObjTile = new Resource({
          url:
            projectResource._url +
            packetElement.model.uri +
            "?file_id=" +
            projectResource._queryParameters.file_id,
          headers: projectResource.headers,
        });

        const modelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
          position,
          orientation,
          scaling
        );

        const newModel = await Model.fromGltfAsync({
          id: packetElement.id,
          credit: packetElement.name,
          url: resourceObjTile,
          modelMatrix: modelMatrix,
          minimumPixelSize: packetElement.model.minimumPixelSize,
        });
        //console.log(" DATA : ", resourceObj);
        viewer.scene.primitives.add(newModel);

        newModel._modelResources = [
          {
            hpr: {
              h: packetElement.hpr.h,
              p: packetElement.hpr.p,
              r: packetElement.hpr.r,
            },
          },
        ];

        newModel._modelResources.assetAvailability =
          packetElement.assetAvailability;
      } else if (packetElement.id.toString().startsWith("PSPoint")) {
        /* Loading preset points */
        const presetPointProperties = new PropertyBag(packetElement.orientation)
        presetPointProperties.addProperty("presetStartTime", packetElement.presetStartTime);
        // console.log("Build pp : ", presetPointProperties)
        viewer.entities.add({
          ...packetElement,
          position: Cartesian3.fromArray(packetElement.position.cartesian),
          billboard: {
            image: packetElement.billboard.image,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            scaleByDistance: new NearFarScalar(0.2e2, 1.0, 0.1e6, 0.2),
          },
          properties: presetPointProperties
        });
      } else if (packetElement.id.toString().startsWith("ANNT")) {
        /* Loading annotations */
        // Fetching the image from file storage and making a blob object for the same
        let imageData;
        packetElement.imageUrl &&
          (await fetch(
            process.env.REACT_APP_ASSET_API +
            packetElement.imageUrl +
            "/?file_id=" +
            projectResource._queryParameters.file_id,
            {
              method: "GET",
              headers: projectResource.headers,
            }
          )
            .then((res) => res.blob())
            .then((data) => (imageData = data)));

        // Creating the annotation entity and appending the blob object for the image if there is any
        const annotationEntity = viewer.entities.add({
          ...packetElement,
          position: Cartesian3.fromArray(packetElement.position.cartesian),
          billboard: {
            image:
              "data:image/svg+xml,<svg version=1.1 xmlns=http://www.w3.org/2000/svg xmlns:xlink=http://www.w3.org/1999/xlink width=120 height=40 xml:space=preserve><rect width=120 height=40 rx=10 ry=10 style=fill:rgba(0,0,0,0) /></svg>",
          },
          imageBlob: imageData,
        });

        // Attaching availability properties to the annotation if any
        if (packetElement.availability) {
          annotationEntity.availability = TimeIntervalCollection.fromIso8601({
            iso8601: packetElement.availability
          })
        }

        // Fetching the HTML overlay for displaying the annotation icon and adding it to scene
        const element = new DOMParser()
          .parseFromString(packetElement.annotationIcon, "text/html")
          .getElementsByClassName("annotation-htmlOverlay")[0];
        element.lastChild.style.visibility = "hidden";
        document
          .getElementsByClassName("visioWindow_visioContainer")[0]
          .appendChild(element);
        document.getElementById(annotationEntity.id).style.visibility = 'visible'
        // Attaching the source of the image to the image element
        if (imageData)
          element.lastChild.lastChild.src = URL.createObjectURL(imageData);
        // Attaching on click event handler on the icon to display annotation description
        element.addEventListener("click", function () {
          const descriptionElement = document.getElementById(
            packetElement.id
          ).lastChild;
          descriptionElement.style.visibility =
            descriptionElement.style.visibility === "hidden"
              ? "visible"
              : "hidden";
        });
      }

      // else if (packetElement.id.toString().startsWith("RTA")) {
      //   packetElement_keys.push(packetElement.selectedKey);
      //   console.log(packetElement_keys)
      //   // updatePacketElementIds(packetElement_ids);
      //   const annotationEntity = viewer.entities.add({
      //     ...packetElement,
      //     position: Cartesian3.fromArray(packetElement.position.cartesian),
      //   });
      //   console.log(packetElement)

      //   const label = document.getElementsByClassName("devices-htmlOverlay");
      //   console.log(label)

      //   const element = new DOMParser()
      //     .parseFromString(packetElement.annotationIcon, "text/html").getElementsByClassName("devices-htmlOverlay")[0];
      //   document
      //     .getElementsByClassName("visioWindow_visioContainer")[0]
      //     .appendChild(element);
      //   const heading = element.querySelector(`.annotation-heading`);
      //   console.log(element)
      //   // console.log(heading)
      //   // if (packetElement.selectedKey in packetElement.webSocketData) {
      //   //   console.log("I am here")
      //   // }
      //   // console.log(element)
      // }
    }

    // const tileset2 = await Cesium.createGooglePhotorealistic3DTileset();
    // // tileset2.extras.id = "TS18";
    // // tileset2.extras.name = "Photo Tiles";
    // viewer.scene.primitives.add(tileset2);
  } catch (error) {
    console.log(error);
  }
};

export default loadJSONProject;
