// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// ... NPM Packages ...
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";
import axios from "axios";

// ... Components ...
import Header from "../../components/header/header";
import ProjectList from "../../components/projectList/projectList";
import AssetList from "../../components/assetList/assetList";
import UserList from "./components/userList/userList";
import PreviewWindow from "../../components/previewWindow/previewWindow";
import DetailsPreview from "../../components/detailsPreview/detailsPreview";
import GroupList from "./components/groupList/groupList";
import ProjectPopup from "./components/popups/projectPopup/projectPopup";
import AssetPopup from "./components/popups/assetPopup/assetPopup";
import UserPopup from "./components/popups/userPopup/userPopup";
import GroupPopup from "./components/popups/groupPopup/groupPopup";
import SuccessFailurePopup from "./components/popups/successFailurePopup/successFailurePopup";
import Lists from "./components/lists/lists";
import SearchBar from "./components/searchBar/searchBar";
import DeviceList from "./components/deviceList/deviceList";
import "./projectDashboard.css";

/**
 * @description `Page` : A comprehensive user interface for managing projects, assets, and users. It enables users to create and view projects, upload assets, and interact with project-related data. Users can navigate between different views to manage their projects effectively. Integrated with routing and localization, the component enhances the project management experience.
 * @category Project Dashboard
 * @class
 * @returns {JSX.Element} Rendered project dashboard page.
 */
const ProjectDashboard = () => {
  // ... State and hook declarations ...

  // .. Project hooks ..
  const [projectList, setProjectList] = useState(true);
  const [projectValid, setProjectValid] = useState(false);
  const [projectListPopup, setProjectListPopup] = useState(false);
  const [projects, setProjects] = useState([]);
  const [getData, setData] = useState([]);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  // .. Asset hooks ..
  const [georeferenceable, setGeoreferenceable] = useState(false);
  const [georeferenceChecked, setgeoreferenceChecked] = useState(false);
  const [fileValid, setFileValid] = useState(false);
  const [asset, setAsset] = useState([]);
  const [uploadAsset, setUploadAsset] = useState("");
  const [progress, setProgress] = useState(0);
  const [assetList, setAssetList] = useState(false);
  const [assetListPopup, setAssetListPopup] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [success, setSuccess] = useState(false);

  // .. Other hooks ..
  const filterDropdownRef = useRef(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const [userList, setUserList] = useState(false);
  const [userListPopup, setUserListPopup] = useState(false);
  const [groupListPopup, setGroupListPopup] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [userToGroup, setUserToGroup] = useState(false);
  const [userToProject, setUserToProject] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryAsset, setSearchQueryAsset] = useState("");
  const [searchQueryUser, setSearchQueryUser] = useState("");
  const [searchQueryGroup, setSearchQueryGroup] = useState("");
  const [searchQueryDevice, setSearchQueryDevice] = useState("");
  const [filterList, setFilterList] = useState(false);
  const [selectedFilterCriteria, setSelectedFilterCriteria] = useState("name");
  const [users, setUsers] = useState([]);
  const [resourceUsers, setResourceUsers] = useState([]);
  const [groupList, setGroupList] = useState(false);
  const [groups, setGroups] = useState([]);
  const [resourceGroups, setResourceGroups] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [showDeleteUserButton, setShowDeleteUserButton] = useState(false);
  const [showDeleteGroupButton, setShowDeleteGroupButton] = useState(false);
  const [failureMessage, setFailureMessage] = useState("");
  const [failure, setFailure] = useState(false);
  const [showDeleteGroupSuccess, setShowDeleteGroupSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [newProjectName, setNewProjectName] = useState("");
  const [newProjectLocation, setNewProjectLocation] = useState("");
  const [projectRange, setProjectRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [selectedResourceName, setSelectedResourceName] = useState(null);
  const [selectedResourceType, setSelectedResourceType] = useState(null);
  const [groupUsers, setGroupUsers] = useState([]);
  const [newUsername, setNewUsername] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [commonGroups, setCommonGroups] = useState([]);
  const [unassignmentSuccess, setUnassignmentSuccess] = useState(false);
  const [deviceList, setDeviceList] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [headerUserDetails, setHeaderUserDetails] = useState({
    username: "",
    email: "",
  });
  const [uploadInProcess, setUploadInProcess] = useState(false);

  // .. Constants ..
  const jwtToken = secureLocalStorage.getItem("token");
  const userId = jwt_decode(jwtToken).user_id;
  const { t } = useTranslation();
  const buttonStyle = {
    backgroundColor: fileValid ? "#525252" : "#cccccc",
  };
  const navigation = useNavigate();
  const location = useLocation();
  const userLevel = location.state ? location.state.userLevel : null;
  const showUserList = userLevel === "Tenant";
  const showUploadButton = userLevel === "Tenant" || userLevel === "Editor";
  const userLevelDropdown = useRef("Viewer");
  const newGroupName = useRef();
  const newGroupDescription = useRef();

  // ... Local Functions ...

  /**
   * Makes GET request to fetch Project list as per user ID
   * @param {number} userId  User ID
   */
  const fetchProjectList = (userId) => {
    fetch(process.env.REACT_APP_API + "/project/project_details/" + userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setData(json.data);
        if (json.detail == "Authentication credentials were not provided.") {
          console.log("User has no projects, data : ", json.data);
        } else {
        }
      });
  };

  /**
   * Sends a DELETE request to the server API with the user ID and project ID to remove the specified project, and upon success, updates the local state to reflect the deletion.
   * @param {number} userId
   * @param {number} projectId
   */
  const handleDeleteProject = (userId, projectId) => {
    fetch(
      process.env.REACT_APP_API +
      "/project/project_details/" +
      userId +
      "/" +
      projectId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        // setData((prevData) =>
        //   prevData.filter((project) => project.id !== projectId)
        // );
        fetchProjectList(userId);
        setShowDeleteSuccess(false);
        if (json.status == "Successfully deleted") {
          setSuccess(true);
          setSuccessMsg("Project deleted successfully");
          setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
          }, 3000);
        } else {
          setFailure(true);
          setFailureMessage("The selected project couldn't be deleted");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Handles changes to the project name input.
   * @param {Object} e - Event object (Project name input from DOM)
   */
  const handleNameChange = (e) => {
    const newValue = e.target.value.replace(/[^a-zA-Z0-9\s\-_()]/g, "");
    setNewProjectName(newValue);
  };

  /**
   * Handles changes to the project name input.
   * @param {Object} e - Event object (Location input from DOM)
   */
  const handleLocationChange = (e) => {
    const newValue = e.target.value.replace(/[^a-zA-Z\s-]/g, "");
    setNewProjectLocation(newValue);
  };

  /**
   * Handles changes to the project range input.
   * @param {Array} value - Array of start and end dates
   */
  const handleProjectRangeChange = (value) => {
    setProjectRange({ startDate: value[0], endDate: value[1] });
  };

  /**
   * Handles project creation.
   * @param {Object} e - Event object (Project name)
   * @param {number} userId - User ID.
   */
  const handleProjectCreation = (e, userId) => {
    e.preventDefault();

    const newName = newProjectName;
    const newLocation = newProjectLocation;

    const projectDetails = JSON.stringify({
      file_name: newName,
      location: newLocation,
      start_date: projectRange.startDate.toString(),
      end_date: projectRange.endDate.toString(),
    });

    fetch(process.env.REACT_APP_API + "/project/project_details/" + userId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: projectDetails,
    })
      .then((res) => res.json())
      .then((json) => {
        fetchProjectList(userId)
        setProjectListPopup(false);
        setAssetListPopup(false);
        setUserListPopup(false);
        setGroupListPopup(false);
        setNewProjectLocation("");
        setNewProjectName("");
        fetchProjectList(userId);
        const uploadedFileName = Object.keys(json.data)[0];
        const uploadStatus = json.data[uploadedFileName];
        if (uploadStatus === "Uploaded") {
          handleUploadSuccess("Project created successfully");
        } else {
          setFailure(true);
          setFailureMessage("The project couldn't be created");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  // ... Asset handling functions ...

  /**
   * Fetches assets available to User ID.
   * @param {number} userId - The user ID.
   */
  const fetchAssetList = (userId) => {
    fetch(process.env.REACT_APP_API + "/project/asset_details/" + userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == "No Assets found") {
          console.log("User has no assets");
        } else {
        }
      });
  };

  /**
   * Sends a DELETE request to the server API with the user ID and asset ID to remove the specified asset, and upon success, updates the local state to reflect the deletion.
   * @param {number} userId
   * @param {number} assetID
   */
  const handleDeleteAsset = (userId, assetID) => {
    fetch(
      process.env.REACT_APP_API +
      "/project/asset_details/" +
      userId +
      "/" +
      assetID,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        setAsset((prevAsset) =>
          prevAsset.filter((asset) => asset.id !== selectedResourceId)
        );
        fetchAssetList(userId);
        setShowDeleteSuccess(false);
        if (json.status == "Successfully deleted") {
          setSuccess(true);
          setSuccessMsg("Asset deleted successfully");
          setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
          }, 3000);
        } else {
          setFailure(true);
          setFailureMessage("The selected asset couldn't be deleted");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
        setFailure(true);
        setFailureMessage("The selected asset couldn't be deleted");
        setTimeout(() => {
          setFailure(false);
          setFailureMessage("");
        }, 3000);
      });
  };

  /**
   * Handles Asset uploading for user.
   * @param {number} userId - User ID.
   */
  const handleAssetUpload = (userId) => {
    setUploadInProcess(true);
    let fileName;
    const formData = new FormData();
    let assetUploadPath;
    georeferenceChecked
      ? (assetUploadPath = "/project/geo_upload_asset/")
      : (assetUploadPath = "/project/asset_details/");
    for (const element of asset) {
      console.log("Uploading  : ", asset);
      fileName = asset[0].name;
      console.log(asset[0].name);
      formData.append("files", element);
    }
    // Add resource type, for IFC - IFCGLTF
    formData.append("upload_type", "3DT");

    axios
      .post(
        process.env.REACT_APP_API + assetUploadPath + userId,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "JWT " + jwtToken,
          },
          onUploadProgress: onUploadProgress,
        }
      )
      .then((res) => {
        setUploadInProcess(false);
        setProgress(0);
        const variableValue = Object.entries(res.data);
        setUploadAsset(variableValue[0][1]);
        setAsset([]);
        setSelectedFileName("");
        setFileValid(false);
        setGeoreferenceable(false);
        setAssetListPopup(false);
        setProjectListPopup(false);
        setUserListPopup(false);
        setGroupListPopup(false);
        const uploadedFileName = Object.keys(res.data.data)[0];
        const uploadStatus = res.data.data[uploadedFileName];
        fetchAssetList(userId);
        if (uploadStatus == "Uploaded") {
          handleUploadSuccess("Uploaded successfully");
        } else {
          setFailure(true);
          const extension = fileName.split(".").pop();
          if (extension === "zip") {
            setFailureMessage("Archive does not contain a tileset.json");
          } else {
            setFailureMessage("Asset could not be uploaded");
          }
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Handles changes to the asset upload input.
   * @param {Object} e - Event object (Asset files)
   */
  const handleAssetChange = (e) => {
    let files = e.target.files;
    setAsset(Array.from(files));
    if (files.length > 0) {
      const fileNames = Array.from(files).map((file) => file.name);
      const allFileNames = fileNames.join(", ");
      setSelectedFileName(allFileNames);
    } else {
      setSelectedFileName("");
    }
    for (const element of files) {
      const fileName = element.name;
      if (!/^[a-zA-Z0-9][a-zA-Z0-9_.-]{1,61}[a-zA-Z0-9]$/.test(fileName)) {
        handleFileType();
        setSelectedFileName("");
        setFileValid(false);
        break;
      } else {
        setFileValid(true);
        if (files.length == 1) {
          const extension = files[0].name.split(".").pop();
          if (extension === "gltf" || extension === "glb") {
            setGeoreferenceable(true);
          } else {
            setGeoreferenceable(false);
          }
        }
      }
    }
  };

  /**
   * Marks a gltf as georeferenceable or not.
   */
  const handleGeorefCheck = () => {
    setgeoreferenceChecked(!georeferenceChecked);
  };

  /**
   * Handles progress during asset upload.
   * @param {Object} progressEvent - The progress event object.
   */
  const onUploadProgress = (progressEvent) => {
    setProgress(Math.round(progressEvent.progress * 100));
    console.log("Uploading file...");
    console.log(Math.round(progressEvent.progress * 100));
  };

  // ... User handling functions ...
  /**
   * Fetches the list of users for the tenant.
   * @param {number} userId  - The user ID.
   */
  const fetchUserList = (userId) => {
    fetch(process.env.REACT_APP_API + "/accounts/get_users_details/" + userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.detail === "Item not found") {
          console.log("Item not found");
        } else {
          setUsers(json.data);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Sends a DELETE request to the server API with the user ID to remove the specified user, and upon success, updates the local state to reflect the deletion.
   * @param {number} userId
   */
  const handleDeleteUser = (userId) => {
    console.log(userId);
    console.log("Deleting user with ID:", userId);
    fetch(process.env.REACT_APP_API + "/accounts/delete_account/" + userId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json.data);
        fetchUserList(userId);
        setShowDeleteSuccess(false);
        if (json.status == "Successfully deleted") {
          setSuccess(true);
          setSuccessMsg("User deleted successfully");
          setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
          }, 3000);
        } else {
          setFailure(true);
          setFailureMessage("The selected user couldn't be deleted");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Handles user creation.
   * @param {number} userId - User ID.
   */
  const handleUserCreation = (e, userId) => {
    e.preventDefault();

    const username = newUsername;
    const email = newEmail;
    const userLevel = userLevelDropdown.current.value;

    const userDetails = {
      username: username,
      email: email,
      user_level: userLevel,
      created_by: userId,
      password: "tenant@1",
      re_password: "tenant@1",
    };

    fetch(process.env.REACT_APP_API + "/auth/users/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: JSON.stringify(userDetails),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        fetchUserList(userId)
        setProjectListPopup(false);
        setAssetListPopup(false);
        setUserListPopup(false);
        setGroupListPopup(false);
        setNewUsername("");
        setNewEmail("");
        const createdUser = Object.keys(json)[5];
        if (createdUser == "id") {
          handleUploadSuccess("User created Successfully");
          handleUserActivation();
        } else {
          setFailure(true);
          setFailureMessage(json.email);
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Sends email to the user to activate their account.
   * @param {Object} e - Event object (Group name)
   */
  const handleUserActivation = () => {
    console.log(newEmail);
    console.log(jwtToken)
    const userDetails = JSON.stringify({
      email: newEmail,
      is_activation: true,
    });

    fetch(process.env.REACT_APP_API + "/auth/users/reset_password/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: userDetails,
    })
      .then((json) => {
        console.log(json);
        if (json.status === 204) {
          console.log("Activation link sent");
          setSuccessMsg(
            "An activation link has been successfully sent to your account"
          );
        } else if (json.status === 400) {
          console.log("Invalid mail ID");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // ... Group handling functions ...
  /**
   * Makes GET request to fetch Group list as per user ID
   * @param {number} userId  User ID
   */
  const fetchGroupList = (userId) => {
    fetch(process.env.REACT_APP_API + "/customer/customer_group/" + userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.detail === "Item not found") {
          console.log("Item not found");
        } else {
          setGroups(json.data);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Handles Group creation.
   * @param {Object} e - Event object (Group name)
   * @param {number} userId - User ID.
   */
  const handleGroupCreation = (e, userId) => {
    e.preventDefault();

    const name = newGroupName.current.value;
    const description = newGroupDescription.current.value;

    const groupDetails = JSON.stringify({
      title: name,
      created_by: userId,
      description: description,
    });

    fetch(process.env.REACT_APP_API + "/customer/customer_group/" + userId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: groupDetails,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        // setGroups((prevGroups) =>
        //   Array.isArray(prevGroups) ? [...prevGroups, json.data] : [json.data]
        // );
        fetchGroupList(userId)
        setProjectListPopup(false);
        setAssetListPopup(false);
        setUserListPopup(false);
        setGroupListPopup(false);
        fetchProjectList(userId);
        const uploadedFileName = Object.keys(json)[1];
        console.log(uploadedFileName);
        const uploadStatus = json[uploadedFileName];
        if (uploadStatus === "Successfully created") {
          handleUploadSuccess("Group created successfully");
        } else {
          setFailure(true);
          setFailureMessage(json.data.title);
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Sends a DELETE request to the server API to remove the specified group, and upon success, updates the local state to reflect the deletion.
   */
  const handleDeleteGroup = () => {
    console.log(userId);
    console.log("Deleting user with group ID:", selectedGroupId);
    fetch(
      process.env.REACT_APP_API +
      "/customer/customer_group/" +
      userId +
      "/" +
      selectedGroupId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        fetchGroupList(userId);
        setShowDeleteGroupSuccess(false);
        if (json.status == "Successfully deleted") {
          setSuccess(true);
          setSuccessMsg("Group deleted successfully");
          setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
          }, 3000);
        } else {
          setFailure(true);
          setFailureMessage("The selected group couldn't be deleted");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Makes GET request to the server to retrieve account details.
   * @param {number} userId
   */
  const fetchAccountDetails = (userId) => {
    fetch(
      process.env.REACT_APP_API + "/accounts/master_account_details/" + userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (json.code == 100) {
          const userDetails = {
            username: json.data[0].username,
            email: json.data[0].email,
          };
          setHeaderUserDetails(userDetails);
        }
        if (json.detail) {
          const userDetails = {
            username: "undefined",
            email: "undefined",
          };
          setHeaderUserDetails(userDetails);
        }
      });
  };

  /**
   *  Makes GET request to fetch group list the selected user is a part of
   * @param {number} userid User ID
   */
  const fetchList = (userid) => {
    fetch(process.env.REACT_APP_API + "/customer/user_groups/" + userid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json.data);
        setCommonGroups(json.data);
        if (json.detail == "Authentication credentials were not provided.") {
          console.log("User has no projects, data : ", json.data);
        } else {
        }
      });
  };

  // Misc Handlers

  /**
   * Handles update of selected resource (Project / Asset)
   * @param {Object} selectedItem - Item in focus.
   */
  const handleResourceUpdate = (selectedItem) => {
    setSelectedResource(selectedItem);
    if (users.length > 0) {
      const newResourceUsers = users.filter(
        (user) =>
          !selectedItem.users.some((selectedUser) => selectedUser.id === user.id)
      );
      setResourceUsers(newResourceUsers);
    }

    if (groups.length > 0) {
      const newResourceGroups = groups.filter(
        (group) =>
          !selectedItem.groups.some(
            (selectedGroup) => selectedGroup.id === group.id
          )
      );
      setResourceGroups(newResourceGroups);
    }

    setSelectedResourceName(
      (selectedItem.project_name || selectedItem.asset_name).replace(
        ".json",
        ""
      )
    );
    setSelectedResourceId(selectedItem ? selectedItem.id : null);

    if (projectList) {
      setSelectedResourceType("project");
    } else {
      setSelectedResourceType("asset");
    }
  };

  const handleSelectedUser = (selectedUser) => {
    console.log(selectedUser.id);
    setSelectedUser(selectedUser);
    setSelectedUserId(selectedUser.id);
    setShowDeleteUserButton(true);
    setShowDeleteGroupButton(false);
    fetchList(selectedUser.id);
  };

  const handleSelectedGroup = (selectedGroup) => {
    console.log(selectedGroup.id);
    console.log(selectedGroup.users);
    setSelectedUser(selectedGroup);
    setSelectedGroupId(selectedGroup.id);
    setShowDeleteUserButton(false);
    setShowDeleteGroupButton(true);
    fetchGroupList(selectedGroup.id);
  };

  /**
   * Sets the active tab based on user selection.
   * @param {string} id - The ID of the selected tab (Projects / Assets / Users).
   */
  const handleSetActive = (id) => {
    setSelectedResource(null);
    if (id == "asset") {
      setProjectList(false);
      setUserList(false);
      setAssetList(true);
      setGroupList(false);
      fetchAssetList(userId);
      setShowDeleteSuccess(false);
      setGroupUsers([]);
      setDeviceList(false);
    } else if (id == "user") {
      setProjectList(false);
      setAssetList(false);
      setUserList(true);
      setGroupList(false);
      fetchUserList(userId);
      setShowDeleteSuccess(false);
      setShowDeleteGroupButton(false);
      setGroupUsers([]);
      setDeviceList(false);
    } else if (id == "project") {
      setAssetList(false);
      setUserList(false);
      setProjectList(true);
      setGroupList(false);
      fetchProjectList(userId);
      setShowDeleteSuccess(false);
      setSelectedResource(null);
      setGroupUsers([]);
      setDeviceList(false);
    } else if (id == "device") {
      setAssetList(false);
      setUserList(false);
      setProjectList(false);
      setGroupList(false);
      fetchProjectList(userId);
      setShowDeleteSuccess(false);
      setSelectedResource(null);
      setGroupUsers([]);
      setDeviceList(true);
    } else {
      setAssetList(false);
      setUserList(false);
      setProjectList(false);
      setGroupList(true);
      fetchGroupList(userId);
      setShowDeleteSuccess(false);
      setShowDeleteUserButton(false);
      setDeviceList(false);
    }
  };

  /**
   * Displays a filter dropdown
   */
  const handleFilter = () => {
    setFilterList(true);
  };

  /**
   * Handles update of search query coming as user input
   * @param {Object} e - Event object (Search query string input from DOM)
   */
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  /**
   * Handles update of search query coming as user input
   * @param {Object} e - Event object (Search query string input from DOM)
   */
  const handleSearchQueryChangeAsset = (event) => {
    setSearchQueryAsset(event.target.value);
  };

  /**
   * Handles update of search query coming as user input
   * @param {Object} e - Event object (Search query string input from DOM)
   */
  const handleSearchQueryChangeUser = (event) => {
    setSearchQueryUser(event.target.value);
  };

  /**
   * Handles update of search query coming as user input
   * @param {Object} e - Event object (Search query string input from DOM)
   */
  const handleSearchQueryChangeGroup = (event) => {
    setSearchQueryGroup(event.target.value);
  };

  /**
   * Handles update of search query coming as user input
   * @param {Object} e - Event object (Search query string input from DOM)
   */
  const handleSearchQueryChangeDevice = (event) => {
    setSearchQueryDevice(event.target.value);
  };

  /**
   * Displays a pop-up.
   */
  const handleResourcePopup = () => {
    setProjectListPopup(true);
    setAssetListPopup(true);
    setUserListPopup(true);
    setGroupListPopup(true);
  };

  /**
   * Closes the popup.
   */
  const closePopup = () => {
    setProjectListPopup(false);
    setAssetListPopup(false);
    setUserListPopup(false);
    setGeoreferenceable(false);
    setGroupListPopup(false);
    setSelectedCheckbox(false);
    setUserToGroup(false);
    setUserToProject(false);
    setSelectedFileName("");
    setNewProjectLocation("");
    setNewProjectName("");
    setNewUsername("");
    setNewEmail("");
    setFileValid(false);
    setgeoreferenceChecked(false);
  };

  /**
   * Displays a success popup when a project or asset is successfully deleted.
   */
  const handleDeleteSuccess = () => {
    setShowDeleteSuccess(true);
  };

  const handleDeleteGroupSuccess = () => {
    setShowDeleteGroupSuccess(true);
  };

  /**
   * Displays a popup if the file type is not valid.
   */
  const handleFileType = () => {
    setFailure(true);
    setFailureMessage("Invalid file name");
    setTimeout(() => {
      setFailure(false);
      setFailureMessage("");
    }, 2000);
  };

  /**
   * Displays a popup on creation or upload of projects, assets, users and groups
   */
  const handleUploadSuccess = (msg) => {
    setSuccess(true);
    setSuccessMsg(msg);
    setTimeout(() => {
      setSuccess(false);
      setSuccessMsg("");
    }, 5000);
  };

  /**
   * Sets the selected filter criteria
   */
  const handleFilterCriteriaChange = (event) => {
    setSelectedFilterCriteria(event.target.value);
  };

  /**
   * useEffect hook to close the filter dropdown
   */
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterList(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleToken = (token) => {
    setToken(token);
  };

  /**
   * ProjectDashboard useEffect hook.
   */
  useEffect(() => {
    fetchAccountDetails(userId);
    fetchUserList(userId);
    fetchGroupList(userId);
  }, []);

  return (
    <div className="main-container">
      <Header headerUserDetails={headerUserDetails} />

      <div className="projectDashboard_lower-heading-container">
        <Lists
          projectList={projectList}
          assetList={assetList}
          userList={userList}
          groupList={groupList}
          showUserList={showUserList}
          deviceList={deviceList}
          handleSetActive={handleSetActive}
        />

        <SearchBar
          projectList={projectList}
          userList={userList}
          assetList={assetList}
          groupList={groupList}
          selectedResource={selectedResource}
          showUploadButton={showUploadButton}
          searchQuery={searchQuery}
          searchQueryAsset={searchQueryAsset}
          searchQueryUser={searchQueryUser}
          searchQueryGroup={searchQueryGroup}
          searchQueryDevice={searchQueryDevice}
          handleSearchQueryChange={handleSearchQueryChange}
          handleSearchQueryChangeAsset={handleSearchQueryChangeAsset}
          handleSearchQueryChangeUser={handleSearchQueryChangeUser}
          handleSearchQueryChangeGroup={handleSearchQueryChangeGroup}
          handleSearchQueryChangeDevice={handleSearchQueryChangeDevice}
          handleFilter={handleFilter}
          filterDropdownRef={filterDropdownRef}
          filterList={filterList}
          handleResourcePopup={handleResourcePopup}
          handleFilterCriteriaChange={handleFilterCriteriaChange}
          handleDeleteGroup={handleDeleteGroup}
          selectedUser={selectedUser}
          showDeleteUserButton={showDeleteUserButton}
          showDeleteGroupButton={showDeleteGroupButton}
          groupList={groupList}
          deviceList={deviceList}
          authenticated={authenticated}
        />
      </div>

      <div className="projectDashboard_entire-container">
        <div
          className={
            UserList ? "projectDashboard_files-full" : "projectDashboard_files"
          }
        >
          <AssetList
            isActive={assetList}
            handleResourceUpdate={handleResourceUpdate}
            searchQuery={searchQuery}
            filterCriteria={selectedFilterCriteria}
            deletedAssets={asset}
            searchQueryAsset={searchQueryAsset}
          />

          <ProjectList
            isActive={projectList}
            handleResourceUpdate={handleResourceUpdate}
            searchQuery={searchQuery}
            filterCriteria={selectedFilterCriteria}
            newProjects={projects}
            deletedProjects={getData}
          />

          {showUserList && (
            <UserList
              isActive={userList}
              searchQuery={searchQuery}
              handleSelectedUser={handleSelectedUser}
              newUsers={users}
              filterCriteria={selectedFilterCriteria}
              deletedUsers={users}
              selectedUser={selectedUser}
              onDeleteUser={() => handleDeleteUser(selectedUserId)}
              showDeleteSuccess={showDeleteSuccess}
              onDeleteSuccess={handleDeleteSuccess}
              setShowDeleteSuccess={setShowDeleteSuccess}
              onDeleteGroup={() => handleDeleteGroup(selectedUserId)}
              commonGroups={commonGroups}
              setSuccess={setSuccess}
              setSuccessMsg={setSuccessMsg}
              setFailure={setFailure}
              setFailureMessage={setFailureMessage}
              searchQueryUser={searchQueryUser}
            />
          )}

          {showUserList && (
            <GroupList
              isActive={groupList}
              newGroups={groups}
              searchQuery={searchQuery}
              filterCriteria={selectedFilterCriteria}
              handleSelectedGroup={handleSelectedGroup}
              deletedGroups={groups}
              selectedUser={selectedUser}
              onDeleteGroup={() => handleDeleteGroup(selectedUserId)}
              showDeleteGroupSuccess={showDeleteGroupSuccess}
              onDeleteSuccess={handleDeleteGroupSuccess}
              setShowDeleteGroupSuccess={setShowDeleteGroupSuccess}
              selectedGroupId={selectedGroupId}
              setFailureMessage={setFailureMessage}
              setFailure={setFailure}
              setSuccess={setSuccess}
              setSuccessMsg={setSuccessMsg}
              setUnassignmentSuccess={setUnassignmentSuccess}
              unassignmentSuccess={unassignmentSuccess}
              searchQueryGroup={searchQueryGroup}
            />
          )}

          <DeviceList
            isActive={deviceList}
            searchQuery={searchQuery}
            filterCriteria={selectedFilterCriteria}
            searchQueryUser={searchQueryUser}
            authenticated={authenticated}
            setAuthenticated={setAuthenticated}
            searchQueryDevice={searchQueryDevice}
            filterCriteria={selectedFilterCriteria}
            handleToken={handleToken}
          />
        </div>

        <div
          className={
            userList || groupList || deviceList
              ? "projectDashboard_hide-preview-window"
              : "projectDashboard_preview-window"
          }
        >
          <PreviewWindow
            selectedResource={selectedResource}
            assetList={assetList}
          />
          <div className="projectDashboard_open-in-viewer-btn-container">
            <div
              className={
                selectedResource == null
                  ? "projectDashboard_open-in-viewer-btn disabled"
                  : "projectDashboard_open-in-viewer-btn"
              }
              onClick={() => {
                navigation("/visioWindow", {
                  replace: false,
                  state: {
                    selectedResource: selectedResource,
                    selectedItemType: selectedResourceType,
                    jwtToken: token,
                    userLevel: "editor",
                  },
                });
              }}
            >
              {t("main.open-in-viewer")}
            </div>
          </div>
          <DetailsPreview
            selectedResource={selectedResource}
            projectList={projectList}
            onDeleteProject={() =>
              handleDeleteProject(userId, selectedResourceId)
            }
            onDeleteAsset={() => handleDeleteAsset(userId, selectedResourceId)}
            setSelectedResource={setSelectedResource}
            onDeleteSuccess={handleDeleteSuccess}
            showDeleteSuccess={showDeleteSuccess}
            setShowDeleteSuccess={setShowDeleteSuccess}
            selectedResourceName={selectedResourceName}
            selectedResourceId={selectedResourceId}
            userLevel={userLevel}
            assetList={assetList}
            selectedResourceType={selectedResourceType}
            showUserList={showUserList}
            setFailureMessage={setFailureMessage}
            setFailure={setFailure}
            setSuccess={setSuccess}
            setSuccessMsg={setSuccessMsg}
            resourceUsers={resourceUsers}
            resourceGroups={resourceGroups}
            handleResourceUpdate={handleResourceUpdate}
          />
        </div>
      </div>

      <ProjectPopup
        newProjectName={newProjectName}
        newProjectLocation={newProjectLocation}
        projectRange={projectRange}
        handleProjectRangeChange={handleProjectRangeChange}
        setNewProjectName={setNewProjectName}
        setNewProjectLocation={setNewProjectLocation}
        projectList={projectList}
        projectListPopup={projectListPopup}
        projectValid={projectValid}
        handleProjectCreation={handleProjectCreation}
        userId={userId}
        isDisabled={isDisabled}
        handleNameChange={handleNameChange}
        handleLocationChange={handleLocationChange}
      />

      <AssetPopup
        progress={progress}
        uploadInProcess={uploadInProcess}
        assetList={assetList}
        assetListPopup={assetListPopup}
        georeferenceable={georeferenceable}
        handleAssetUpload={handleAssetUpload}
        selectedFileName={selectedFileName}
        handleAssetChange={handleAssetChange}
        fileValid={fileValid}
        georeferenceChecked={georeferenceChecked}
        handleGeorefCheck={handleGeorefCheck}
        userId={userId}
        setSelectedFileName={setSelectedFileName}
        setGeoreferenceable={setGeoreferenceable}
        setFileValid={setFileValid}
      />

      <UserPopup
        userList={userList}
        userListPopup={userListPopup}
        userToGroup={userToGroup}
        userToProject={userToProject}
        newUsername={newUsername}
        newEmail={newEmail}
        userLevelDropdown={userLevelDropdown}
        handleUserCreation={handleUserCreation}
        userId={userId}
        setNewUsername={setNewUsername}
        setNewEmail={setNewEmail}
      />

      <GroupPopup
        groupList={groupList}
        groupListPopup={groupListPopup}
        newGroupName={newGroupName}
        newGroupDescription={newGroupDescription}
        handleGroupCreation={handleGroupCreation}
        userId={userId}
      />

      <SuccessFailurePopup
        success={success}
        successMsg={successMsg}
        failure={failure}
        failureMessage={failureMessage}
      />

      {(projectListPopup ||
        assetListPopup ||
        userListPopup ||
        groupListPopup ||
        showDeleteSuccess ||
        showDeleteGroupSuccess ||
        unassignmentSuccess) && (
          <div
            className="projectGroups_background-overlay"
            onClick={closePopup}
          ></div>
        )}
    </div>
  );
};

export default ProjectDashboard;
