// @ts-nocheck
// ... React Imports ...
import React, { useState, useRef, useEffect } from 'react'

// ... NPM Packages ...
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import useWebSocket from "react-use-websocket";

// ... Components ...
import './deviceList.css'

/**
 * @description Component that integrates IoT device management into the Thingspad platform, offering real-time telemetry updates, user authentication, and an intuitive interface for efficient device monitoring and interaction.
 * @param {Boolean} isActive - Flag to determine whether to display devices if active.
 * @param {Boolean} authenticated - Flag to determine user authentication status.
 * @param {Function} setAuthenticated - Function to update user authentication status.
 * @param {Function} handleToken - Function to handle token-related actions.
 * @param {String} searchQueryDevice - Search query for filtering devices.
 * @param {String} filterCriteria - Criteria for sorting and filtering devices.
 * @returns Rendered Thingpad devices list.
 */
const DeviceList = ({ isActive, authenticated, setAuthenticated, handleToken, searchQueryDevice, filterCriteria }) => {
    const [entityType, setEntityType] = useState(null);
    const [entityId, setEntityId] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [keyValues, setKeyValues] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [directToken, setDirectToken] = useState("");
    const [deviceData, setDeviceData] = useState([]);
    const [token, setToken] = useState(null);
    const [confirmLogout, setConfirmLogout] = useState(false);
    const [webSocketKey, setWebSocketKey] = useState([]);
    const keyRef = useRef([]);

    // Translation hook
    const { t } = useTranslation();

    // WebSocket URL
    const wsUrl = "wss://dev.thingspad.io/api/ws/plugins/telemetry";
    const wsWithToken = `${wsUrl}?token=${token}`;

    // WebSocket setup using react-use-websocket
    const {
        sendMessage, readyState, getWebSocket,
    } = useWebSocket(wsWithToken, {
        onOpen: () => {
            console.log("WebSocket opened");
        },
        onMessage: (event) => {
            // The web socket message is received here
            var received_msg = event.data;
            console.log("Message is received: " + received_msg);
            const parsedData = JSON.parse(received_msg)
            const data = parsedData.data;

            if (data) {
                const updatedValues = {};
                for (const key in data) {
                    // Updates the telemetry key values if the keys are present in the web socket data received.
                    updatedValues[key] = { value: data[key][0][1] };
                }
                setWebSocketKey(updatedValues);
            }
        },
        onClose: (event) => {
            console.log("Connection is closed!");
        },
        shouldReconnect: (closeEvent) => true,
    });

    // Function to establish WebSocket connection
    const establishWebSocketConnection = () => {
        if (readyState !== WebSocket.OPEN && readyState !== WebSocket.CONNECTING) {
            getWebSocket().connect();
        }
    };

    // Function to format a timestamp
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    }

    // Function to handle user login
    const loginUser = () => {
        const credentials = {
            username: email,
            password: password,
        };

        fetch("https://dev.thingspad.io/api/auth/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    var y = document.getElementById("deviceList_loginErrorMessage-container");
                    y.style.visibility = "visible";
                    setTimeout(() => {
                        y.style.visibility = "hidden";
                    }, 2000);
                    throw new Error("Authentication failed");
                } else {
                    throw new Error("API request failed");
                }
            })
            .then((json) => {
                secureLocalStorage.setItem("thingspad-token", json.token);
                console.log("thingspad-token:", json.token);
                setToken(json.token);
                setEntityId(null);
                setKeyValues(null);
                handleDevices(json.token);
                handleToken(json.token, sendMessage)
                console.log("Login successful. Token stored!");
                setAuthenticated(true);
                establishWebSocketConnection();
                setConfirmLogout(false);
                setEmail("")
                setPassword("")
            })
            .catch((error) => {
                console.error("Error : ", error.message);
            });
    }

    // Function to handle token-based login
    const handleTokenLogin = () => {
        console.log("The entered token is", directToken);
        fetch("https://dev.devthingspad.io/api/tenant/deviceInfos?pageSize=30&page=0", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + directToken,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    var y = document.getElementById("deviceList_token-loginErrorMessage-container");
                    y.style.visibility = "visible";
                    setTimeout(() => {
                        y.style.visibility = "hidden";
                    }, 2000);
                    throw new Error("Authentication failed");
                } else {
                    throw new Error("API request failed");
                }
            })
            .then((json) => {
                secureLocalStorage.setItem("thingspad-token", directToken);
                console.log("The devices are", json.data);
                setToken(directToken);
                handleToken(directToken)
                setEntityId(null);
                setKeyValues(null);
                console.log("Login successful. Token stored!");
                setAuthenticated(true);
                setDeviceData(json.data);
                establishWebSocketConnection();
                setConfirmLogout(false)
                setDirectToken("")
            })
            .catch((error) => {
                console.error("Error : ", error.message);
            });
    }

    // Function to handle fetching device data
    const handleDevices = (token) => {
        fetch("https://dev.thingspad.io/api/tenant/deviceInfos?pageSize=30&page=0", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (Array.isArray(json.data)) {
                    const sortedData = json.data.sort((a, b) => {
                        return a.name.localeCompare(b.name, undefined, {
                            sensitivity: "base"
                        })
                    });

                    setDeviceData(sortedData);
                } else if (json.status === 401) {
                    setAuthenticated(false)
                    secureLocalStorage.removeItem('thingspad-token');
                }
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    }

    // Function to handle the clicked device data
    const handleDeviceData = (device) => {
        setSelectedDevice(device)
        setEntityType(device.id.entityType)
        setEntityId(device.id.id)

        const request = {
            tsSubCmds: [
                {
                    entityType: entityType,
                    entityId: entityId,
                    scope: "LATEST_TELEMETRY",
                    cmdId: 10,
                },
            ],
            historyCmds: [],
            attrSubCmds: [],
        };

        const requestData = JSON.stringify(request);
        sendMessage(requestData);
    };

    // Function to fetch the telemetry data of a selected device
    const handleKeyValues = (token) => {
        fetch(`https://dev.thingspad.io:443/api/plugins/telemetry/${entityType}/${entityId}/values/timeseries?keys=${keyRef.current}&useStrictDataTypes=false`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                setKeyValues(json);
                const keys = Object.keys(json);
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    //Closes the web socket connection
    const closeWebSocketConnection = () => {
        if (readyState === WebSocket.OPEN || readyState === WebSocket.CONNECTING) {
            getWebSocket().close();
        }
    };

    // Function to clear local storage
    const logout = () => {
        secureLocalStorage.removeItem('thingspad-token');
        setAuthenticated(false);
        closeWebSocketConnection();
        setConfirmLogout(false)
        setEmail("")
        setPassword("")
        setDirectToken("")
    }


    // Displays the confirm logout popup
    const handleLogout = () => {
        setConfirmLogout(true)
    }


    // Closes the confirm logout popup
    const closePopup = () => {
        setConfirmLogout(false)
    }

    // Filters and sorts the list of devices based on the search query and selected filter criteria.
    const filteredAndSortedProjects =
        deviceData.length > 0
            ? deviceData
                .filter((device) =>
                    device.name && device.name.toLowerCase()
                        .includes(searchQueryDevice.toLowerCase())
                )
                .sort((a, b) => {
                    switch (filterCriteria) {
                        case "Created Time":
                            return a.createdTime - b.createdTime;
                        case "Activity Status":
                            const isActiveA = a.active === "true";
                            const isActiveB = b.active === "true";
                            console.log(isActiveA)
                            console.log(isActiveB)
                            if (isActiveA && !isActiveB) {
                                return -1;
                            } else if (!isActiveA && isActiveB) {
                                return 1;
                            } else {
                                return isActiveB - isActiveA;
                            }
                        case "Name":
                            console.log("name")
                            return a.name.localeCompare(b.name, undefined, {
                                sensitivity: "base",
                            });
                        default:
                            return 0;
                    }
                })
            : [];

    // Calls loginUser function on the click of enter key
    const handleKeyPress = (event) => {
        if (
            event.key === "Enter" &&
            email.trim() !== "" &&
            password.trim() !== ""
        ) {
            event.preventDefault();
            loginUser();
        }
    };

    // Device list useEffect hook
    useEffect(() => {
        if (entityType && entityId) {
            handleDeviceData(selectedDevice);
        }

        if (entityType && entityId && keyRef.current) {
            handleKeyValues(token);
        }

        // If a token already exists in the local storage then show the user all the devices from thingspad without asking to login.
        const storedToken = secureLocalStorage.getItem("thingspad-token");
        console.log("The stored token is", storedToken)
        if (storedToken) {
            setToken(storedToken);
            handleDevices(storedToken);
            setAuthenticated(true);
            handleToken(storedToken, sendMessage);
        }
    }, [entityType, entityId, keyRef]);

    return (
        <div>
            {isActive && authenticated && (
                <div className='deviceList_main-container'>
                    <div className='deviceList_devices-container'>
                        <table className="projectDashboard_table">
                            <thead className="projectDashboard_heading">
                                <tr className="projectDashboard_user-table-heading">
                                    <th>{t("main.device-type")}</th>
                                    <th id="align-left">{t("main.name")}</th>
                                    <th>{t("main.created-time")}</th>
                                    <th>{t("main.active")}</th>
                                </tr>
                            </thead>
                            <tbody className="projectDashboard_table-user-list">
                                {deviceData && filteredAndSortedProjects.length > 0 ? (
                                    filteredAndSortedProjects.map((device) => (
                                        <tr
                                            key={device.id.id}
                                            onClick={() => handleDeviceData(device)}
                                            className={device.id.id === entityId ? "selected-row" : ""}
                                        >
                                            <td>{device.type}</td>
                                            <td id="align-left">{device.name.length > 22
                                                ? `${device.name.slice(0, 18)}...`
                                                : device.name}</td>
                                            <td>{formatTimestamp(device.createdTime)}</td>
                                            <td className={device.active ? "activity-status-active" : "activity-status-inactive"}>
                                                {device.active ? "Active" : "Inactive"}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" id="projectDashboard_no-projects">
                                            No devices to display
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='deviceList_telemetry-values-container'>
                        <div className="deviceList_telemetry-heading">
                            <p>{t("main.latest-telemetry")}</p>
                        </div>
                        <table className="deviceList_telemetry-values-list">
                            <thead>
                                <tr className="deviceList_telemetry-values-list-heading">
                                    <th id='align-left'>{t("main.last-update-time")}</th>
                                    <th id='align-left'>{t("main.key")}</th>
                                    <th>{t("main.value")}</th>
                                </tr>
                            </thead>
                            <tbody className='deviceList_telemetry-key-values'>
                                {keyValues && Object.keys(keyValues).length > 0 ? (
                                    Object.keys(keyValues).map((key) => (
                                        keyValues[key].map((item, index) => (
                                            <tr key={index} className="deviceList_telemetry-key-value">
                                                <td>{formatTimestamp(item.ts)}</td>
                                                <td>{key}</td>
                                                <td id='align-center'>
                                                    {key in webSocketKey ? webSocketKey[key].value : item.value}
                                                </td>
                                            </tr>
                                        ))
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" id='deviceList-no-devices'>No data to display</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className='deviceList_logout-button-container'>
                            <div className='deviceList_logout-button' onClick={handleLogout}>
                                <p>Thingspad Logout</p>
                            </div>
                        </div>
                    </div>
                    {confirmLogout && authenticated && <div
                        className="projectGroups_background-overlay"
                        onClick={closePopup}
                    ></div>}
                </div>
            )}
            {((isActive && !authenticated) || (isActive && token === null)) && <div className='deviceList_login-form'>
                <div className='deviceList_login-form-heading'>
                    <p>Login with credentials</p>
                </div>
                <div className="loginPage_email">
                    <input
                        id="loginPage_email-input"
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Email:"
                        required
                        maxLength={320}
                    />
                </div>
                <div>
                    <div className="loginPage_password">
                        <input
                            type='password'
                            id="loginPage_input"
                            placeholder="Password:"
                            required
                            maxLength={30}
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className='deviceList_loginErrorMessage-container' id='deviceList_loginErrorMessage-container'>
                        <p id='deviceList_loginErrorMessage'>No active account found with the given credentials</p>
                    </div>
                </div>
                <div className="deviceList_login-btn-container">
                    <div
                        className="loginPage_login-btn"
                        onClick={loginUser}
                    >
                        <a>Log In</a>
                    </div>
                </div>
                <p className='deviceList_login-form-or'>or</p>
                <div className='deviceList_login-form-heading'>
                    <p>Login with token</p>
                </div>
                <div className='deviceList-token-input'>
                    <div>
                        <div className="loginPage_email">
                            <input
                                id="loginPage_token-input"
                                placeholder="Enter the JWT token:"
                                required
                                value={directToken}
                                type='email'
                                onChange={(event) => setDirectToken(event.target.value)}
                            />e
                        </div>
                        <div className='deviceList_token-loginErrorMessage-container' id='deviceList_token-loginErrorMessage-container'>
                            <p id='deviceList_loginErrorMessage'>Please enter a valid token</p>
                        </div>
                    </div>
                    <div className="deviceList_login-btn-container">
                        <div
                            className="loginPage_login-btn"
                            onClick={handleTokenLogin}
                        >
                            <a>Log In</a>
                        </div>
                    </div>
                </div>
            </div>}
            {confirmLogout && authenticated && <div className="confirm-delete-popup">
                <p>Are you sure you want to logout of Thingspad?</p>
                <div className="delete-btn-container">
                    <div id="delete-btn-cancel" onClick={closePopup}>
                        {t("main.cancel")}
                    </div>
                    <div id="confirm-delete-btn" onClick={logout}>
                        Logout
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default DeviceList