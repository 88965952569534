// @ts-nocheck
// ... React Imports ...
import React from "react";

/**
 * @description `Component`: The UploadSuccessPopup React component is a notification element that displays a success message when a file, project, user, or group is successfully uploaded or created, based on the provided props. Additionally, it displays an error message when an uploaded file is of an unsupported type.
 * @param {Boolean} uploadSuccess Displays popup if the state is true.
 * @param {Boolean} projectList Used to determine whether the success message should pertain to the creation of a project.
 * @param {Boolean} assetList Used to determine whether the success message should pertain to the upload of assets.
 * @param {Boolean} userList Used to determine whether the success message should pertain to the creation of a user.
 * @param {Boolean} @param {Boolean} uploadedFile Conditionally displays an error message when a file of an unsupported type is uploaded
 * @category Project Dashboard
 * @returns Renders Success Popup.
 */
const SuccessFailurePopup = ({
  success,
  successMsg,
  failure,
  failureMessage,
}) => {
  return (
    <div>
      {success && <div className="notification success">{successMsg}</div>}

      {failure && <div className="notification-fail">{failureMessage}</div>}
    </div>
  );
};

export default SuccessFailurePopup;
