// @ts-nocheck
// ... React Imports ...
import React, { useState, CSSProperties } from "react";
// import "./assetPopup.css";
import { useTranslation } from "react-i18next";

/**
 *
 * @description `Component`: The AssetPopup component is a React component that renders a popup for adding a new asset, including choose file and a "Upload" button.
 * @param {Boolean} assetList Displays popup if asset list is active.
 * @param {Function} handleAssetUpload Uploads a new asset and adds it to the list.
 * @param {Boolean} assetListPopup Displays popup if the state is true.
 * @param {Number} userId User ID
 * @category Project Dashboard
 * @returns Renders Asset Popup.
 */
const AssetPopup = ({
  progress,
  uploadInProcess,
  assetList,
  assetListPopup,
  handleAssetUpload,
  georeferenceable,
  selectedFileName,
  handleAssetChange,
  fileValid,
  georeferenceChecked,
  handleGeorefCheck,
  userId,
  setSelectedFileName,
  setGeoreferenceable,
  setFileValid,
}) => {
  const { t } = useTranslation();

  const handleClearFileName = () => {
    setSelectedFileName("");
    setGeoreferenceable(false);
    setFileValid(false);
  };

  return (
    <div>
      {assetList && assetListPopup && (
        <div className="projectDashboard_popup-container">
          <div className="projectDashboard_popup">
            <p id="projectDashboard_popup-heading">{t("main.add-new-asset")}</p>
            <div className="projectDashboard_upload-asset-container">
              <div className="projectDashboard_asset-upload-btn">
                <div className="projectDashboard_file-name-container">
                  <p id="projectDashboard_file-name">{selectedFileName}</p>
                  {selectedFileName ? (
                    <svg
                      onClick={handleClearFileName}
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                    >
                      <mask
                        id="mask0_3332_615"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="26"
                        height="26"
                      >
                        <rect width="26" height="26" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_3332_615)">
                        <path
                          d="M6.91706 20.5838L5.40039 19.0672L11.4671 13.0005L5.40039 6.93384L6.91706 5.41718L12.9837 11.4838L19.0504 5.41718L20.5671 6.93384L14.5004 13.0005L20.5671 19.0672L19.0504 20.5838L12.9837 14.5172L6.91706 20.5838Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
                <label
                  className={
                    selectedFileName
                      ? "projectDashboard_choose-assets disabled disabled-font"
                      : "projectDashboard_choose-assets"
                  }
                >
                  <>
                    <p id="projectDashboard_file-name">
                      {t("main.choose-file")}
                    </p>
                    <input
                      type="file"
                      onChange={handleAssetChange}
                      multiple
                      id="file-upload"
                    />
                  </>
                </label>
              </div>
            </div>
            {georeferenceable ? (
              <div className="projectDashboard_Georeference">
                <div className="asset-type-checkbox">
                  <input
                    type="checkbox"
                    id="georef"
                    checked={georeferenceChecked}
                    onChange={handleGeorefCheck}
                  />
                  <label htmlFor="georef">
                    {t("main.load-to-in-built-location")}
                  </label>
                </div>
                <div className="asset-type-checkbox">
                  <input
                    type="checkbox"
                    id="georef"
                    checked={!georeferenceChecked}
                    onChange={handleGeorefCheck}
                  />
                  <label htmlFor="georef">
                    {t("main.load-to-pre-defined-location")}
                  </label>
                </div>
                <br />
              </div>
            ) : (
              ""
            )}

            <div
              className={
                georeferenceable
                  ? "projectDashboard_add-asset-container-georeference"
                  : "projectDashboard_add-asset-container"
              }
            >
              {uploadInProcess && (
                <>
                  <div className="loading-spinner"></div>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
                </>
              )}
              <div
                className={
                  fileValid
                    ? "projectDashboard_form-button"
                    : "projectDashboard_form-button disabled"
                }
                disabled={!fileValid}
                onClick={() => handleAssetUpload(userId)}
              >
                <a>{t("main.add-asset")}</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetPopup;
