// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect, useRef } from "react";

// ... NPM Packages ...
import secureLocalStorage from "react-secure-storage";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";

// ... Components ...
import "./assetList.css";

// ... Misc ...
// import status_error from "../../assets/status_error.png";
// import status_success from "../../assets/status_success.png";
// import conversion from "../../assets/retweet.png";

/**
 * @description `Component`: Renders a list of user assets, allowing users to view and interact with asset details such as asset ID, name, file type, file size, and last modified date.
 * @param {Boolean} isActive Display assets if active.
 * @param {Function} handleResourceUpdate Sets selected asset.
 * @param {String} filterCriteria Displays list of assets sorted based on the selected filter criterion.
 * @param {Array} deletedAssets Array containing deleted assets.
 * @param {String} searchQueryAsset Used to filter and display projects based on the user's search input
 * @category Project Dashboard
 * @returns {JSX.Element} Rendered Asset List.
 */
const AssetList = ({
  isActive,
  handleResourceUpdate,
  filterCriteria,
  deletedAssets,
  searchQueryAsset
}) => {
  // ... React hook declarations ...
  const [getData, setData] = useState([]);
  const [selectedAssetId, setSelectedAssetId] = useState(null);

  // .. Constants ..
  const { t } = useTranslation();
  const jwtToken = secureLocalStorage.getItem("token");
  const filterCriteriaDefault = filterCriteria || "Last Modified";

  /**
   * Asset List useEffect hook.
   */
  useEffect(() => {
    fetchAssetUserID();
  }, [deletedAssets]);

  // ... Local Functions ...

  const fetchAssetUserID = () => {
    fetchAssetList(jwt_decode(jwtToken).user_id);
  };

  /**
   * Filters and sorts the list of assets based on the search query and selected filter criteria.
   */
  const filteredAndSortedProjects =
    getData.length > 0
      ? getData
        .filter(
          (asset) =>
            asset.asset_name &&
            asset.asset_name.toLowerCase().includes(searchQueryAsset.toLowerCase())
        )
        .sort((a, b) => {
          switch (filterCriteria && filterCriteriaDefault) {
            case "Last Modified":
              const dateA = new Date(
                a.last_modified.replace(
                  /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/,
                  "$3-$2-$1T$4:$5"
                )
              );
              const dateB = new Date(
                b.last_modified.replace(
                  /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/,
                  "$3-$2-$1T$4:$5"
                )
              );
              return dateB - dateA;
            case "ID":
              return a.id - b.id;
            case "Name":
              return a.asset_name.localeCompare(b.asset_name, undefined, {
                sensitivity: "base",
              });
            default:
              return 0;
          }
        })
      : [];

  /**
   *  Makes GET request to fetch Asset list as per user ID
   * @param {number} userid User ID
   */
  const fetchAssetList = (userid) => {
    fetch(process.env.REACT_APP_API + "/project/asset_details/" + userid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == "No Assets found") {
          console.log("User has no assets");
        } else if (Array.isArray(json.data)) {
          const sortedData = json.data.sort((a, b) => {
            const dateA = new Date(
              a.last_modified.replace(
                /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/,
                "$3-$2-$1T$4:$5"
              )
            );
            const dateB = new Date(
              b.last_modified.replace(
                /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/,
                "$3-$2-$1T$4:$5"
              )
            );
            return dateB - dateA;
          });
          setData(sortedData);
        } else {
          console.error("Invalid projects data:", json.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };

  /**
   * Updates state for the clicked asset
   * @param {Object} asset Selected asset
   */
  const handleClick = (asset) => {
    handleResourceUpdate(asset);
    setSelectedAssetId(asset.id);
  };

  return (
    <div>
      {isActive && (
        <table className="projectDashboard_table">
          <thead className="projectDashboard_heading">
            <tr className="projectDashboard_table-heading-one">
              <th>{t("main.id")}</th>
              <th id="align-left">{t("main.name")}</th>
              <th className="center-align">{t("main.file-type")}</th>
              <th>{t("main.file-size")}</th>
              <th>{t("main.last-modified")}</th>
            </tr>
          </thead>
          <tbody className="projectDashboard_table-list">
            {filteredAndSortedProjects.length > 0 ? (
              filteredAndSortedProjects.map((asset) => (
                <tr
                  onClick={() => handleClick(asset)}
                  className={`projectDashboard_row ${asset.id === selectedAssetId ? "selected-row" : ""
                    }`}
                  id={`asset-${asset.id}`}
                  key={`asset-${asset.id}`}
                >
                  <td>A{asset.id}</td>
                  <td id="align-left">
                    {asset.asset_name.length > 22 ? `${asset.asset_name.slice(0, 20)}...` : asset.asset_name}
                  </td>
                  <td className="center-align">
                    {asset.input_file_type === "glb" ||
                      asset.input_file_type === "gltf"
                      ? "glTF"
                      : asset.input_file_type === "fbx"
                        ? "FBX"
                        : asset.input_file_type === "las" ||
                          asset.input_file_type === "laz"
                          ? "LAS"
                          : "3D Tile"}
                  </td>
                  <td>{asset.file_size}</td>
                  <td>
                    {asset.last_modified == null
                      ? "Inactive"
                      : new Date(asset.last_modified).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" id="projectDashboard_no-projects">
                  No assets to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default React.memo(AssetList);
