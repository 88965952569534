// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect } from 'react'

/**
 * @description FirstDevicePanel component displays information and controls for a selected device. It retrieves device data from Thingspad, displays live sensor data, and allows for adding data to a model.
 * @param {string} valueName - The name of the value.
 * @param {string} entityType - The type of the entity.
 * @param {function} sendMessage - Function to send messages.
 * @param {string} entityId - The ID of the entity.
 * @param {Array} device - Array of selected devices.
 * @param {function} setSelectedKey - Function to set the selected key.
 * @param {function} setValueName - Function to set the value name.
 * @param {function} setValue - Function to set the value.
 * @param {string} value - The current value.
 * @param {function} setDataPoints - Function to set data points.
 * @param {function} setSelectedCriteria - Function to set selected criteria.
 * @param {string} selectedCriteria - The selected criteria.
 * @param {string} selectedKey - The selected key.
 * @param {boolean} barChart - Boolean indicating whether to display a bar chart.
 * @param {boolean} lineChart - Boolean indicating whether to display a line chart.
 * @param {function} handlSelectedKey - Function to handle the selected key.
 * @param {function} handleAddLabelToModel - Function to add a label to the model.
 * @param {Object} keyRef - Reference to the key.
 * @param {function} handleChart - Function to handle chart selection.
 * @param {function} setEntityType - Function to set the entity type.
 * @param {function} setEntityId - Function to set the entity ID.
 * @param {function} setDeviceName - Function to set the device name.
 * @param {string} token - The authorization token.
 * @category Devices
 * @returns {JSX.Element} Rendered first device panel.
 */
const FirstDevicePanel = ({ valueName, entityType, sendMessage, handleKeyValues, entityId, device, setSelectedKey, setValueName, setValue, value, setDataPoints, setSelectedCriteria, selectedCriteria, selectedKey, barChart, lineChart, handlSelectedKey, handleAddLabelToModel, keyRef, handleChart, setEntityType, setEntityId, setDeviceName, token, selectedLabel, labelKey, handleBarChart }) => {
    // State to store device data and active status.
    const [deviceData, setDeviceData] = useState([]);
    const [active, setActive] = useState(false);

    /**
    * Fetches and handles the display of devices from Thingspad.
    */
    const handleDevices = () => {
        fetch("https://dev.thingspad.io/api/tenant/deviceInfos?pageSize=30&page=0", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                console.log(json)
                if (Array.isArray(json.data)) {
                    const sortedData = json.data.sort((a, b) => {
                        return a.name.localeCompare(b.name, undefined, {
                            sensitivity: "base"
                        })
                    });
                    setDeviceData(sortedData);
                    console.log(sortedData)
                }
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    }

    /**
    * Handles the change of the selected device.
    * @param {Object} event - Event object.
    */
    const handleDeviceChange = (event) => {
        handleKeyValues(token)
        console.log(handleDevices)
        let selectedDeviceName;
        console.log("The label is", selectedLabel)
        if (selectedLabel) {
            selectedDeviceName = selectedLabel;
        } else {
            selectedDeviceName = event.target.value;
        }
        device.push(selectedDeviceName)

        console.log(deviceData)
        const selectedDevice = deviceData.find((device) => device.name === selectedDeviceName);
        console.log(selectedDevice)

        if (selectedDevice) {
            setDeviceName(selectedDevice.name)
            setEntityType(selectedDevice.id.entityType);
            setEntityId(selectedDevice.id.id);
            setActive(true);
            console.log(selectedDevice.id.entityType)
            handleBarChart();
        }

        setDataPoints("")
        setSelectedCriteria(selectedCriteria)

        if (device.length > 0) {
            setValue("")
            setValueName("")
            setSelectedKey("")
            setSelectedCriteria("")
        }

        // Sends request to get websocket data for the selected entityType and entityId
        if (entityType, entityId) {
            const request = {
                tsSubCmds: [
                    {
                        entityType: entityType,
                        entityId: entityId,
                        scope: "LATEST_TELEMETRY",
                        cmdId: 10,
                    },
                ],
                historyCmds: [],
                attrSubCmds: [],
            };
            const requestData = JSON.stringify(request);
            sendMessage(requestData);
        }
    };

    // Effect hook to handle device change on entityType and entityId change.
    useEffect(() => {
        if (selectedLabel) {
            handleDevices(event)
        }
        if ((entityType && entityId) || (selectedLabel)) {
            handleDeviceChange(event);
        }
    }, [entityType, entityId, selectedLabel])

    return (
        <div>
            <div className='devices-flex'>
                <div className='devices-sensor-name' onClick={handleDevices} onChange={handleDeviceChange}>
                    <select className='devices-sensor-name-dropdown' id='sensor-dropdown' autoFocus>
                        {deviceData && deviceData.length > 0 ? (
                            deviceData.map((device) => (
                                <option key={device.id.id} selected={selectedLabel === device.name}>{device.name}</option>
                            ))
                        ) : (<option>Device Name</option>)}
                    </select>
                </div>
                <div className='devices-availability-container'>
                    {active ? (<div className='devices-availability-active devices-flex'>
                        <p>Active</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                            <circle cx="2" cy="2" r="2" fill="#00D916" />
                        </svg>
                    </div>) : (<div className='devices-availability devices-flex'>
                        <p>NA</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                            <circle cx="2" cy="2" r="2" fill="#9D9D9D" />
                        </svg>
                    </div>)}
                </div>
            </div>
            <div className='devices-flex'>
                <div className='devices-sensor-data-dropdown' onChange={handlSelectedKey}>
                    <select className='devices-sensor-name-dropdown' id='sensor-dropdown'>
                        {valueName && valueName.length > 0 ? (
                            valueName.map((device) => (
                                <option key={device} selected={labelKey === device}>{device}</option>
                            ))
                        ) : (<option>Value Name</option>)
                        }
                    </select>
                </div>
                <div className='devices-sensor-live-data devices-flex'>
                    {value && value !== null && keyRef !== null ? (
                        <p>{value}</p>
                    ) : (
                        <p>LIVE data pending</p>
                    )}
                </div>
            </div>
            {selectedKey && <div className='add-to-model-container devices-flex'>
                <div className='add-to-model-btn devices-flex' onClick={handleAddLabelToModel}>
                    <p>Add to Model</p>
                </div>
            </div>}
            {(barChart || lineChart) && < div className='devices-history-container devices-flex'>
                <p>{selectedKey && selectedKey.charAt(0).toUpperCase() + selectedKey.slice(1)} History</p>
                <div className='telemetry-history-dropdown'>
                    <select className='history-dropdown' onChange={handleChart}>
                        <option>Last 1 second</option>
                        <option>Last 10 seconds</option>
                        <option selected="selected">Last 30 seconds</option>
                        <option>Last 1 minute</option>
                        <option>Last 30 minutes</option>
                        <option>Last 1 hour</option>
                        <option>Last 5 hours</option>
                        <option>Last 12 hours</option>
                        <option>Last 1 day</option>
                        <option>Last 2 days</option>
                        <option>Last 3 days</option>
                    </select>
                </div>
            </div>}
        </div>
    )
}

export default FirstDevicePanel