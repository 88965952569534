import React from 'react'

const SuccessFailurePopup = ({
    success,
    successMsg,
    failure,
    failureMessage,
}) => {
    return (
        <div>
            {success && <div className="notification success">{successMsg}</div>}

            {failure && <div className="notification-fail">{failureMessage}</div>}
        </div>
    )
}

export default SuccessFailurePopup