import React, { useState, useEffect } from 'react'
import './userGroupList.css'
import { useTranslation } from "react-i18next";
import plus from '../../../../assets/plus.png'
import secureLocalStorage from "react-secure-storage";
import DeletePopup from './popup/deletePopup'

const UserGroupList = ({ getGroups, setGroups, groupsLength, setAssign, selectedResourceId, fetchList, fetchAssetList, selectedResourceType, setSuccess, setSuccessMsg, setFailure, setFailureMessage }) => {
    const [unassignGroup, setUnassignGroup] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    const jwtToken = secureLocalStorage.getItem("token");
    const { t } = useTranslation();

    const handleAddPopup = () => {
        setAssign(true);
    }

    const closeAssignPopup = () => {
        setUnassignGroup(false)
    }


    const removeGroup = () => {
        setUnassignGroup(true)
    }

    /**
  * Sends a DELETE request to the server API to remove the specified group, and upon success, updates the local state to reflect the deletion.
  */
    const removeGroupFromProject = () => {
        console.log(selectedResourceId);
        console.log("Removing user with ID:", selectedGroupId);
        fetch(
            process.env.REACT_APP_API +
            "/project/assign_project_group/" +
            selectedResourceId +
            "/" +
            selectedGroupId,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            }
        )
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setUnassignGroup(false)
                fetchList();
                if (json.status == "Successfully unassigned") {
                    setSuccess(true);
                    setSuccessMsg("Successfully unassigned");
                    setTimeout(() => {
                        setSuccess(false);
                        setSuccessMsg("");
                    }, 3000);
                } else {
                    setFailure(true);
                    setFailureMessage("The user could not be removed");
                    setTimeout(() => {
                        setFailure(false);
                        setFailureMessage("");
                    }, 3000);
                }
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    /**
  * Sends a DELETE request to the server API to remove the specified group, and upon success, updates the local state to reflect the deletion.
  */
    const unassignAssetToGroup = () => {
        console.log(selectedResourceId);
        console.log("Removing user with ID:", selectedGroupId);
        fetch(
            process.env.REACT_APP_API +
            "/project/assign_asset_group/" +
            selectedResourceId +
            "/" +
            selectedGroupId,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            }
        )
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setUnassignGroup(false)
                fetchAssetList();
                if (json.status == "Successfully unassigned") {
                    setSuccess(true);
                    setSuccessMsg("Successfully unassigned");
                    setTimeout(() => {
                        setSuccess(false);
                        setSuccessMsg("");
                    }, 3000);
                } else {
                    setFailure(true);
                    setFailureMessage("The user could not be removed");
                    setTimeout(() => {
                        setFailure(false);
                        setFailureMessage("");
                    }, 3000);
                }
            })
            .catch((error) => {
                console.error("Error : ", error);
            });
    };

    const handleClick = (group) => {
        setSelectedGroupId(group.id)
        console.log(group.id)
    }

    /**
  * Handles update of search query coming as user input
  * @param {Object} e - Event object (Search query string input from DOM)
  */
    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // ... Fetch asset list upon component mounting ...
    useEffect(() => {
        const filteredAssetList = getGroups.filter((group) => {
            return group.title.toLowerCase().includes(searchQuery.toLowerCase());
        });
        setGroups(filteredAssetList);

        if (searchQuery === "") {
            fetchAssetList();
            fetchList();
        }
    }, [searchQuery]);

    return (
        <div>
            <div className='projectManager_entire-editor-container'>
                <div className='projectManager_editor-header'>
                    <p id='projectManager_editor-list'>{t("main.user-group-list")}</p>
                    <p id='projectManager_editor-list-right'>({groupsLength}/{groupsLength})</p>
                    <div className='projectManager_search-bar'>
                        <div className='projectManager_search-bar-content'>
                            <div className='projectManager_search-bar-left'>
                                <input
                                    id='projectManager_search-bar-input'
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearchQueryChange}
                                    placeholder={t("main.search")}
                                />
                            </div>
                        </div>
                    </div>
                    <img src={plus} id='projectManager_plus' onClick={handleAddPopup} />
                </div>

                <table>
                    <thead className='userGroupList-table'>
                        <tr className='userGroupList_group-list-heading'>
                            <th>{t("main.id")}</th>
                            <th>{t("main.group-name")}</th>
                            <th>{t("main.viewers")}</th>
                            <th>{t("main.editors")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className='userGroupList_group-list-table-body'>
                        {Array.isArray(getGroups) && getGroups.length > 0 ? (
                            getGroups.map((group) => (
                                <tr key={group.id}
                                    className={`userGroupList_group-list ${group.id === selectedGroupId ? 'selected-row' : ''}`}
                                    onClick={() => handleClick(group)}>
                                    <td>{group.id}</td>
                                    <td>{group.title}</td>
                                    <td>7</td>
                                    <td>1</td>
                                    <td>
                                        <svg onClick={removeGroup} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                            <mask id="mask0_2605_1154" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                                                <rect width="21" height="21" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_2605_1154)">
                                                <path d="M6.12891 18.375C5.64766 18.375 5.23568 18.2036 4.89297 17.8609C4.55026 17.5182 4.37891 17.1062 4.37891 16.625V5.25H3.50391V3.5H7.8789V2.625H13.1289V3.5H17.5039V5.25H16.6289V16.625C16.6289 17.1062 16.4575 17.5182 16.1148 17.8609C15.7721 18.2036 15.3602 18.375 14.8789 18.375H6.12891ZM14.8789 5.25H6.12891V16.625H14.8789V5.25ZM7.8789 14.875H9.6289V7H7.8789V14.875ZM11.3789 14.875H13.1289V7H11.3789V14.875Z" fill="white" />
                                            </g>
                                        </svg>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colspan="5" id="projectDashboard_no-projects">No groups to display</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <DeletePopup
                unassignGroup={unassignGroup}
                setUnassignGroup={setUnassignGroup}
                selectedResourceType={selectedResourceType}
                removeGroupFromProject={removeGroupFromProject}
                unassignAssetToGroup={unassignAssetToGroup}
            />
            {unassignGroup &&
                <div
                    className="projectGroups_background-overlay"
                    onClick={closeAssignPopup}
                ></div>
            }
        </div>
    )
}

export default UserGroupList