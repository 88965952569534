// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// ... NPM Packages ...
import secureLocalStorage from "react-secure-storage";

// ... Components ...
import './tenantList.css'

/**
 * @description `TenantList`: This component renders a list of tenants in admin dashboard. It displays tenant information such as ID, name, email, storage, last login, and provides options to interact with tenants.
 * @param {Object[]} users An array of user data.
 * @param {Function} setShowDeleteTenantPopup  A function that controls the visibility of the "Delete Tenant" popup.
 * @param {Function} handleSelectedTenant A function to handle the selection of a tenant.
 * @param {Array} deletedTenants An array of deleted tenant data.
 * @param {string} searchQuery Used to filter and display tenants based on the user's search input.
 * @param {string} filterCriteria The selected filter criteria for sorting the tenant list.
 * @category Admin Dashboard
 * @returns {JSX.Element} Renders Tenant List.
 */
const TenantList = ({ users, setShowDeleteTenantPopup, handleSelectedTenant, deletedTenants, searchQuery, filterCriteria }) => {

    // ... React hook declarations ...
    const [getData, setData] = useState([]);
    const { t } = useTranslation();
    const jwtToken = secureLocalStorage.getItem("token");

    /**
    * TenantList useEffect hook.
    */
    useEffect(() => {
        fetchTenantList();
    }, [users, deletedTenants]);

    /**
    *  Makes GET request to fetch Tenant list 
    */
    const fetchTenantList = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API + "/visioAdmin/get_tenant_details", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + jwtToken,
                },
            });
            if (response.ok) {
                const json = await response.json();
                if (json.status === "Success" && Array.isArray(json.data)) {
                    const sortedData = json.data.sort((a, b) => {
                        const dateA = parseLastLoginDate(a.last_login);
                        const dateB = parseLastLoginDate(b.last_login);
                        return dateB - dateA;
                    });
                    setData(sortedData);
                }
            } else {
                console.error("Failed to fetch tenant data");
            }
        } catch (error) {
            console.error("Error fetching tenant data:", error);
        }
        return "";
    };

    /**
     * Displays the delete tenant popup
     */
    const handleDelete = () => {
        setShowDeleteTenantPopup(true)
    }

    /**
     * Updates the state for the clicked user
     * @param {Object} tenant Selected tenant
     */
    const handleClick = async (tenant) => {
        if (tenant) {
            handleSelectedTenant(tenant);
        }
        const rows = document.querySelectorAll(".tenantList_row");
        rows.forEach((row) => {
            row.classList.remove("selected-row");
        });

        if (tenant) {
            const selectedRow = document.querySelector(`#tenant-${tenant.id}`);
            if (selectedRow) {
                selectedRow.classList.add("selected-row");
            }
        }
    };

    /**
     * Filters and sorts the list of projects based on the search query and selected filter criteria.
     */
    const filteredAndSortedProjects =
        getData.length > 0
            ? getData
                .filter((tenant) =>
                    tenant.username && tenant.username.toLowerCase()
                        .includes(searchQuery.toLowerCase())
                )
                .sort((a, b) => {
                    switch (filterCriteria) {
                        case "Last Login":
                            const dateA = parseLastLoginDate(a.last_login);
                            const dateB = parseLastLoginDate(b.last_login);
                            return dateB - dateA;
                        case "ID":
                            return a.id - b.id;
                        case "Name":
                            return a.username.localeCompare(b.username, undefined, {
                                sensitivity: "base",
                            });
                        default:
                            return 0;
                    }
                })
            : [];


    /**
    * Parses a last login date string into a JavaScript Date object.
    * 
    * @param {string} lastLogin - A string representing the last login date in the format "MM/DD/YYYY, HH:MM".
    * 
    * @returns {Date} A JavaScript Date object representing the parsed last login date. If `lastLogin` is falsy, it returns a default date of "1900-01-01T00:00:00".
    */
    function parseLastLoginDate(lastLogin) {
        // If the last login date is false (e.g., null or empty string), return a default date.
        if (!lastLogin) {
            return new Date("1900-01-01T00:00:00");
        }

        // Parse the last login date string into a JavaScript Date object.
        return new Date(lastLogin.replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/, '$3-$2-$1T$4:$5'));
    }

    return (
        <div className='tenantList_main-container'>
            <table className="tenantList_table">
                <thead className="tenantList_heading">
                    <tr className="tenantList_user-table-heading">
                        <th>{t("main.id")}</th>
                        <th id="align-left">{t("main.name")}</th>
                        <th>{t("main.email")}</th>
                        <th>{t("main.storage")}</th>
                        <th>{t("main.last-login")}</th>
                        <th>{t("main.more-actions")}:</th>
                    </tr>
                </thead>
                <tbody className="tenantList_table-user-list">
                    {filteredAndSortedProjects.length > 0 ? (
                        filteredAndSortedProjects.map((tenant) => (
                            <tr
                                onClick={() => handleClick(tenant)}
                                className="tenantList_row"
                                id={`tenant-${tenant.id}`}
                                key={`tenant-${tenant.id}`}
                            >
                                <td>{tenant.id}</td>
                                <td id="align-left">{tenant.username}</td>
                                <td>{tenant.email}</td>
                                <td id="tenantList_storage"><progress id="storage-progress-bar" value={tenant.storage[0].total_space_consumed} max="5120">
                                </progress>
                                </td>
                                <td>{tenant?.last_login == null ? "Inactive" : (
                                    new Date(tenant?.last_login).toLocaleString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })
                                )}</td>
                                <td className="user-options">
                                    <svg onClick={handleDelete} id="tenant-delete-btn" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                        <mask id="mask0_2605_1154" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                                            <rect width="21" height="21" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_2605_1154)">
                                            <path d="M6.12891 18.375C5.64766 18.375 5.23568 18.2036 4.89297 17.8609C4.55026 17.5182 4.37891 17.1062 4.37891 16.625V5.25H3.50391V3.5H7.8789V2.625H13.1289V3.5H17.5039V5.25H16.6289V16.625C16.6289 17.1062 16.4575 17.5182 16.1148 17.8609C15.7721 18.2036 15.3602 18.375 14.8789 18.375H6.12891ZM14.8789 5.25H6.12891V16.625H14.8789V5.25ZM7.8789 14.875H9.6289V7H7.8789V14.875ZM11.3789 14.875H13.1289V7H11.3789V14.875Z" fill="white" />
                                        </g>
                                    </svg>

                                    <svg xmlns="http://www.w3.org/2000/svg" id="user-details-btn" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                        <mask id="mask0_2802_618" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                                            <rect width="21" height="21" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_2802_618)">
                                            <path d="M9.625 14.875H11.375V9.625H9.625V14.875ZM10.5 7.875C10.7479 7.875 10.9557 7.79115 11.1234 7.62344C11.2911 7.45573 11.375 7.24792 11.375 7C11.375 6.75208 11.2911 6.54427 11.1234 6.37656C10.9557 6.20885 10.7479 6.125 10.5 6.125C10.2521 6.125 10.0443 6.20885 9.87656 6.37656C9.70885 6.54427 9.625 6.75208 9.625 7C9.625 7.24792 9.70885 7.45573 9.87656 7.62344C10.0443 7.79115 10.2521 7.875 10.5 7.875ZM10.5 19.25C9.28958 19.25 8.15208 19.0203 7.0875 18.5609C6.02292 18.1016 5.09687 17.4781 4.30938 16.6906C3.52187 15.9031 2.89844 14.9771 2.43906 13.9125C1.97969 12.8479 1.75 11.7104 1.75 10.5C1.75 9.28958 1.97969 8.15208 2.43906 7.0875C2.89844 6.02292 3.52187 5.09687 4.30938 4.30938C5.09687 3.52187 6.02292 2.89844 7.0875 2.43906C8.15208 1.97969 9.28958 1.75 10.5 1.75C11.7104 1.75 12.8479 1.97969 13.9125 2.43906C14.9771 2.89844 15.9031 3.52187 16.6906 4.30938C17.4781 5.09687 18.1016 6.02292 18.5609 7.0875C19.0203 8.15208 19.25 9.28958 19.25 10.5C19.25 11.7104 19.0203 12.8479 18.5609 13.9125C18.1016 14.9771 17.4781 15.9031 16.6906 16.6906C15.9031 17.4781 14.9771 18.1016 13.9125 18.5609C12.8479 19.0203 11.7104 19.25 10.5 19.25ZM10.5 17.5C12.4542 17.5 14.1094 16.8219 15.4656 15.4656C16.8219 14.1094 17.5 12.4542 17.5 10.5C17.5 8.54583 16.8219 6.89063 15.4656 5.53438C14.1094 4.17813 12.4542 3.5 10.5 3.5C8.54583 3.5 6.89063 4.17813 5.53438 5.53438C4.17813 6.89063 3.5 8.54583 3.5 10.5C3.5 12.4542 4.17813 14.1094 5.53438 15.4656C6.89063 16.8219 8.54583 17.5 10.5 17.5Z" fill="white" />
                                        </g>
                                    </svg>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" id="projectDashboard_no-projects">
                                No users to display
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default TenantList