// @ts-nocheck
/**
 * Functional Component: LoadNewAsset.jsx
 *
 * Author: Ankit Jangid + Thwisha Kotian
 */

import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import LoadNewAsset from "../editor/editorFunctions/loadNewAsset/loadNewAsset";

/**
 * @description  `Component`:  user interface for selecting and managing assets within a Cesium.js 3D viewer.
 * It provides a list of assets that users can choose from and allows for the insertion of selected assets into the viewer.
 * The component also handles feedback for successful or failed asset additions.
 * Users can insert assets into the viewer.
 * @param { Viewer } viewer Cesium Viewer
 * @param { Object } resourceInFocus Asset in focus (via user click or recent load)
 * @param { Function } handleResourceInFocus Sets the newly loaded resource into focus
 * @param { Boolean } handleRefreshData To be reviewed
 * @param { Boolean } editorMode Boolean state for editor mode
 * @param { Boolean } setAssetDropDown Boolean state for enabling / disabling the drop down menu
 * @returns {JSX.Element} Asset drop down menu.
 */
const AssetDropDown = ({
  viewer,
  resourceInFocus,
  handleResourceInFocus,
  handleRefreshData,
  editorMode,
  setAssetDropDown,
}) => {
  // ... State hook declarations ...
  const [assetList, setAssetList] = useState([]);
  const [resourceToLoad, setResourceToLoad] = useState(null);
  const [insertToCurrLoc, setInsertToCurrLoc] = useState(false);
  const [addAsset, setAddAsset] = useState(false);
  const [reloadResource, setReloadResource] = useState(null);
  const [assetToLoad, setAssetToLoad] = useState(null);
  const [assetAdded, setAssetAdded] = useState(false);
  const [assetFailed, setAssetFailed] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // ... Constants ...
  const jwtToken = secureLocalStorage.getItem("token");
  const userId = jwt_decode(jwtToken).user_id;
  const { t } = useTranslation();

  // ...  Get the list of available assets ...
  const fetchAssetList = () => {
    fetch(process.env.REACT_APP_API + "/project/asset_details/" + userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == "No Assets found") {
          console.log("User has no assets");
        } else if (Array.isArray(json.data)) {
          const sortedData = json.data.sort((a, b) => {
            const dateA = new Date(
              a.last_modified.replace(
                /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/,
                "$3-$2-$1T$4:$5"
              )
            );
            const dateB = new Date(
              b.last_modified.replace(
                /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/,
                "$3-$2-$1T$4:$5"
              )
            );
            return dateB - dateA;
          });
          setAssetList(sortedData);
        } else {
          console.error("Invalid projects data:", json.data);
        }
      });
  };

  // ... TODO : add commment -Thwisha
  const handleReloadResource = () => {
    setReloadResource(!reloadResource);
  };

  // ... TODO : add commment -Thwisha
  const handleClick = (asset) => {
    setAssetToLoad(asset);
    console.log(asset);
    const rows = document.querySelectorAll(".asset-list");
    rows.forEach((row) => {
      row.classList.remove("selected-row");
    });

    if (asset) {
      const selectedRow = document.querySelector(`#asset-${asset.id}`);
      if (selectedRow) {
        selectedRow.classList.add("selected-row");
      }
    }
  };

  // ... TODO : add commment -Thwisha
  const handleInsertAsset = () => {
    handleRefreshData();
    handleReloadResource();
    setResourceToLoad(assetToLoad);
    setAddAsset(true);
  };

  /**
   * Handles update of search query coming as user input
   * @param {Object} e - Event object (Search query string input from DOM)
   */
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // ... Fetch asset list upon component mounting ...
  useEffect(() => {
    const filteredAssetList = assetList.filter((asset) => {
      return asset.asset_name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setAssetList(filteredAssetList);

    if (searchQuery === "") {
      fetchAssetList();
    }
  }, [searchQuery]);

  return (
    <>
      {assetList && editorMode && (
        <div
          className="asset-list-container"
          onClick={(e) => e.stopPropagation()}
        >
          {assetAdded || assetFailed ? (
            <div className="asset-successful">
              {assetFailed ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                    fill="#FF3F3F"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <mask
                    id="mask0_2588_584"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2588_584)">
                    <path
                      d="M9.54961 18.0001L3.84961 12.3001L5.27461 10.8751L9.54961 15.1501L18.7246 5.9751L20.1496 7.4001L9.54961 18.0001Z"
                      fill="#9EA93F"
                    />
                  </g>
                </svg>
              )}
              {assetFailed ? (
                <p>Asset addition failed</p>
              ) : (
                <p>Asset added successfully</p>
              )}
            </div>
          ) : (
            <>
              <div className="asset-heading">
                <p>Choose Asset</p>
                <div className="projectDashboard_search-bar">
                  <div className="assetList_search-input-container">
                    <input
                      id="assetListDropdown_search-input"
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchQueryChange}
                      placeholder={t("main.search")}
                    />
                  </div>
                </div>
              </div>
              <div className="scrollable-assets">
                {assetList.length > 0 ? (
                  assetList.map((asset) => (
                    <div
                      onClick={() => handleClick(asset)}
                      id={`asset-${asset.id}`}
                      key={`asset-${asset.id}`}
                      className="asset-list"
                    >
                      {asset.asset_name}{" "}
                    </div>
                  ))
                ) : (
                  <p id="asset-display-msg">No assets to display</p>
                )}
              </div>
              <div className="asset-divider"></div>
              <div className="insert-curr-location-container">
                <input
                  type="checkbox"
                  name="insertToCurrLoc"
                  checked={insertToCurrLoc}
                  onChange={(e) => setInsertToCurrLoc(e.target.checked)}
                />
                <label htmlFor="insertToCurrLoc">
                  Insert to current location
                </label>
              </div>
              <div className="insert-asset-btn-container">
                <div
                  className="insert-asset-btn asset-flex"
                  onClick={() => handleInsertAsset()}
                >
                  Insert
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {addAsset && (
        <LoadNewAsset
          handleReloadResource={handleReloadResource}
          handleResourceInFocus={handleResourceInFocus}
          reloadResource={reloadResource}
          resourceInFocus={resourceInFocus}
          resourceToLoad={resourceToLoad}
          viewer={viewer}
          insertToCurrLoc={insertToCurrLoc}
          setAssetAdded={setAssetAdded}
          setAssetFailed={setAssetFailed}
          setAssetDropDown={setAssetDropDown}
        />
      )}
    </>
  );
};

export default AssetDropDown;
