// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  Cartesian3,
  Matrix4,
  Transforms,
  HeadingPitchRoll,
  Cartographic,
  Math,
  Cesium3DTileset,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  defined,
  Model,
  Cartesian2,
} from "cesium";

import "./localize.css";

const Localize = ({ viewer, resourceInFocus }) => {
  const [modelDrag, setModelDrag] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [height, setHeight] = useState(0);
  const [dragHandler, setDragHandler] = useState(null);
  const [clickPosHandler, setClickPosHandler] = useState(null);
  const [pickPosition, setPickPosition] = useState(false);
  const [clampToGround, setClampToGround] = useState(false);

  // .. Set dragging of asset flag to true ..
  const handleReposition = () => {
    setModelDrag(!modelDrag);
  };

  // .. Set click to position of asset flag to true ..
  const triggerClickToPosition = () => {
    setPickPosition(true);
  };

  // .. Update UI form values and extras values of tileset ..
  const updatePositionInForm = (updatedPosition) => {
    const cartographicPosition = Cartographic.fromCartesian(updatedPosition);
    // . Rounding off number shall decide the accuracy of movement .
    setLatitude(Math.toDegrees(cartographicPosition.latitude).toFixed(50));

    setLongitude(Math.toDegrees(cartographicPosition.longitude).toFixed(50));

    setHeight(cartographicPosition.height.toFixed(50));

    // .. Update the position in extras.position  ..
    if (resourceInFocus instanceof Cesium3DTileset) {
      resourceInFocus.extras.position.longitude = Math.toDegrees(
        cartographicPosition.longitude
      );
      resourceInFocus.extras.position.latitude = Math.toDegrees(
        cartographicPosition.latitude
      );
      resourceInFocus.extras.position.height = cartographicPosition.height;
    }
  };

  const updateLocation = (latitude, longitude, height) => {
    const position = Cartesian3.fromDegrees(
      parseFloat(longitude),
      parseFloat(latitude),
      parseFloat(height)
    );

    if (resourceInFocus instanceof Model) {
      //resourceInFocus._modelResources
      var orientation = Transforms.headingPitchRollQuaternion(
        position,
        new HeadingPitchRoll.fromDegrees(
          resourceInFocus._modelResources[0].hpr.h,
          resourceInFocus._modelResources[0].hpr.p,
          resourceInFocus._modelResources[0].hpr.r
        )
      );

      // Get the current scaling
      const scaling2 = new Cartesian3();
      Matrix4.getScale(resourceInFocus.modelMatrix, scaling2);

      const newModelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
        position,
        orientation,
        scaling2
      );

      resourceInFocus.modelMatrix = newModelMatrix;
    } else {
      // .. Update the position in extras.position (NON DRAG) ..
      resourceInFocus.extras.position.longitude = parseFloat(longitude);
      resourceInFocus.extras.position.latitude = parseFloat(latitude);
      resourceInFocus.extras.position.height = parseFloat(height);
      console.log("PACK : ", resourceInFocus.extras.position.height);
      var orientation = Transforms.headingPitchRollQuaternion(
        position,
        new HeadingPitchRoll.fromDegrees(
          resourceInFocus.extras.hpr.h,
          resourceInFocus.extras.hpr.p,
          resourceInFocus.extras.hpr.r
        )
      );

      // Get the current scaling
      const scaling2 = new Cartesian3();
      Matrix4.getScale(resourceInFocus.modelMatrix, scaling2);

      const newModelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
        position,
        orientation,
        scaling2
      );
      resourceInFocus.modelMatrix = newModelMatrix;
    }
  };

  const handleLatitudeChange = (event) => {
    const newLatitude = parseFloat(event.target.value);
    setLatitude(newLatitude);
    updateLocation(newLatitude, longitude, height);
  };

  const handleLongitudeChange = (event) => {
    const newLongitude = parseFloat(event.target.value);
    setLongitude(newLongitude);
    updateLocation(latitude, newLongitude, height);
  };

  const handleHeightChange = (event) => {
    const newHeight = parseFloat(event.target.value);
    setHeight(newHeight);
    updateLocation(latitude, longitude, newHeight);
  };

  useEffect(() => {
    if (resourceInFocus instanceof Model) {
      const modelMatrix = resourceInFocus.modelMatrix;
      var position = new Cartesian3();
      Matrix4.getTranslation(modelMatrix, position);
      const cartographicPosition = Cartographic.fromCartesian(position);
      setLatitude(Math.toDegrees(cartographicPosition.latitude).toFixed(20));
      setLongitude(Math.toDegrees(cartographicPosition.longitude).toFixed(20));
      setHeight(cartographicPosition.height.toFixed(20));
    } else if (resourceInFocus instanceof Cesium3DTileset) {
      // .. Retrieving position data from modelMatrix ..
      // .. extras.position is applicable too ..
      const modelMatrix = resourceInFocus.modelMatrix;
      var position = new Cartesian3();
      Matrix4.getTranslation(modelMatrix, position);
      const cartographicPosition = Cartographic.fromCartesian(position);
      // // ..  Before click to position feature ..
      // setLatitude(Math.toDegrees(cartographicPosition.latitude).toFixed(20));
      // setLongitude(Math.toDegrees(cartographicPosition.longitude).toFixed(20));
      setLatitude(Math.toDegrees(cartographicPosition.latitude));
      setLongitude(Math.toDegrees(cartographicPosition.longitude));
      setHeight(cartographicPosition.height.toFixed(20));
    }
  }, [resourceInFocus]);

  useEffect(() => {
    if (clampToGround) {
      const modelMatrix = resourceInFocus.modelMatrix;
      var position = new Cartesian3();
      Matrix4.getTranslation(modelMatrix, position);
      const cartographicPosition = Cartographic.fromCartesian(position);
      var longitudeString = Cesium.Math.toDegrees(
        cartographicPosition.longitude
      );
      var latitudeString = Cesium.Math.toDegrees(cartographicPosition.latitude);
      Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, [
        cartographicPosition,
      ]).then(function (updatedPositions) {
        updateLocation(
          latitudeString,
          longitudeString,
          updatedPositions[0].height
        );
        const clampedPosition = Cartesian3.fromDegrees(
          longitudeString,
          latitudeString,
          updatedPositions[0].height
        );
        console.log(" CLAMP INFO : ", clampedPosition);
        updatePositionInForm(clampedPosition);
      });
    }
    setClampToGround(false);
  }, [clampToGround]);

  useEffect(() => {
    if (modelDrag) {
      const newHandler = new ScreenSpaceEventHandler(viewer.scene.canvas);
      let pickedObject;
      let entityToMove;
      let startPosition;
      let draggingModel = false;
      let draggingTileset = false;

      // Cesium handler that keeps listening to events inside Viewer

      // Call back function that identifies what entity is to be moved based on mouse left down event.
      newHandler.setInputAction(function (click) {
        pickedObject = viewer.scene.pick(click.position);
        //Is the selected object a valid entity, if yes then set dragging to true.

        if (
          defined(pickedObject) &&
          pickedObject.primitive instanceof Cesium3DTileset
        ) {
          draggingTileset = true;
          startPosition = viewer.camera.pickEllipsoid(
            click.position,
            viewer.scene.globe.ellipsoid
          );
          entityToMove = resourceInFocus;
        } else if (defined(pickedObject)) {
          startPosition = viewer.camera.pickEllipsoid(
            click.position,
            viewer.scene.globe.ellipsoid
          );
          draggingModel = true;
          entityToMove = pickedObject.primitive;
        }
      }, ScreenSpaceEventType.LEFT_DOWN);

      // Call back function that determines new position of the dragged entity based on mouse drag.
      newHandler.setInputAction(function (movement) {
        if (draggingModel) {
          //disable camera rotation on dragging
          viewer.scene.screenSpaceCameraController.enableRotate = false;
          //set mouse's current position as end position (dynamic as mouse id dragged)
          const endPosition = viewer.camera.pickEllipsoid(
            movement.endPosition,
            viewer.scene.globe.ellipsoid
          );
          const delta = new Cartesian3();
          //calculate the difference between the entity's inital position and final position, subsequently store it in variable delta
          Cartesian3.subtract(endPosition, startPosition, delta);
          startPosition = endPosition;
          if (defined(startPosition) && defined(endPosition)) {
            const position = Cartesian3.fromDegrees(
              parseFloat(longitude),
              parseFloat(latitude),
              parseFloat(height)
            );
            var orientation = Transforms.headingPitchRollQuaternion(
              position,
              new HeadingPitchRoll.fromDegrees(
                resourceInFocus._modelResources[0].hpr.h,
                resourceInFocus._modelResources[0].hpr.p,
                resourceInFocus._modelResources[0].hpr.r
              )
            );

            const scaling = new Cartesian3();
            Matrix4.getScale(resourceInFocus.modelMatrix, scaling);
            const currentPostion = Matrix4.getTranslation(
              resourceInFocus.modelMatrix,
              new Cartesian3()
            );
            const updatedPosition = Cartesian3.add(
              currentPostion,
              delta,
              new Cartesian3()
            );
            const newModelMatrix =
              Matrix4.fromTranslationQuaternionRotationScale(
                updatedPosition,
                orientation,
                scaling
              );
            updatePositionInForm(updatedPosition);
            resourceInFocus.modelMatrix = newModelMatrix;
          }
        } else if (draggingTileset) {
          if (entityToMove instanceof Cesium3DTileset) {
            viewer.scene.screenSpaceCameraController.enableRotate = false;
            // Update modelMatrix of the 3D tileset
            const endPosition = viewer.camera.pickEllipsoid(
              movement.endPosition,
              viewer.scene.globe.ellipsoid
            );

            const delta = new Cartesian3();
            Cartesian3.subtract(endPosition, startPosition, delta);
            startPosition = endPosition;

            if (defined(startPosition) && defined(endPosition)) {
              const position = Cartesian3.fromDegrees(
                parseFloat(longitude),
                parseFloat(latitude),
                parseFloat(height)
              );
              const orientation = Transforms.headingPitchRollQuaternion(
                position,
                new HeadingPitchRoll.fromDegrees(
                  pickedObject.primitive.extras.hpr.h,
                  pickedObject.primitive.extras.hpr.p,
                  pickedObject.primitive.extras.hpr.r
                )
              );
              const scaling = new Cartesian3();
              Matrix4.getScale(pickedObject.primitive.modelMatrix, scaling);
              const currentPostion = Matrix4.getTranslation(
                pickedObject.primitive.modelMatrix,
                new Cartesian3()
              );
              const updatedPosition = Cartesian3.add(
                currentPostion,
                delta,
                new Cartesian3()
              );
              const newModelMatrix =
                Matrix4.fromTranslationQuaternionRotationScale(
                  updatedPosition,
                  orientation,
                  scaling
                );
              updatePositionInForm(updatedPosition);
              pickedObject.primitive.modelMatrix = newModelMatrix;
            }
          }
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);
      // setDragHandler(newHandler);

      newHandler.setInputAction(function () {
        //enable camera rotation on mouse left release and disable dragging
        viewer.scene.screenSpaceCameraController.enableRotate = true;
        draggingModel = false;
        draggingTileset = false;
        setModelDrag(false);
      }, ScreenSpaceEventType.LEFT_UP);
      setDragHandler(newHandler);
    } else if (pickPosition) {
      const clickToPosHandler = new ScreenSpaceEventHandler(
        viewer.scene.canvas
      );
      resourceInFocus._root;
      clickToPosHandler.setInputAction(function (click) {
        // Get the click coordinates in window (screen) space
        const clickPosition = new Cartesian2(
          click.position.x,
          click.position.y
        );

        // Use the scene's camera to convert window coordinates to world coordinates
        const pickRay = viewer.camera.getPickRay(clickPosition);

        // Specify the ellipsoid to use (Earth's ellipsoid by default)
        const ellipsoid = viewer.scene.globe.ellipsoid;

        // Use pickEllipsoid to find the intersection point with the ellipsoid
        const intersection = viewer.scene.globe.pick(pickRay, viewer.scene);
        // var altitude = cartographic.height;

        const cartographic = ellipsoid.cartesianToCartographic(intersection);

        // Extract the longitude and latitude in degrees
        const longitude = Math.toDegrees(cartographic.longitude);
        const latitude = Math.toDegrees(cartographic.latitude);
        const clickedPosition = Cartesian3.fromDegrees(
          longitude,
          latitude,
          Number(height)
        );
        // .. Update asset location ..
        updateLocation(latitude, longitude, Number(height));
        setPickPosition(false);
        // .. Update form values ..
        updatePositionInForm(clickedPosition);
        // setClickPosHandler(null);

        if (pickPosition) {
          clickPosHandler.destroy();
          setClickPosHandler(null);
        }
      }, ScreenSpaceEventType.LEFT_CLICK);
      console.log(" Setting lickpos handler");
      setClickPosHandler(clickToPosHandler);
    }

    if (!modelDrag && dragHandler) {
      console.log("destroying clickpos handler");
      dragHandler.destroy();
      setDragHandler(null);
    }

    // Destroy the click position handler when it's no longer needed
    if (!pickPosition && clickPosHandler) {
      console.log("destroying clickpos handler");
      clickPosHandler.destroy();
      setClickPosHandler(null);
    }
  }, [modelDrag, pickPosition]);

  return (
    <>
      <div>
        <div className="panel-inputGroup">
          <label htmlFor="latitude" className="panel-inputLabel panel-text">
            Latitude:
          </label>
          <input
            type="number"
            step="0.001"
            name="latitude"
            value={latitude}
            className="panel-inputField panel-text"
            onChange={handleLatitudeChange}
          />
        </div>
        <div className="panel-inputGroup">
          <label htmlFor="longitude" className="panel-inputLabel panel-text">
            Longitude:
          </label>
          <input
            type="number"
            name="longitude"
            step="0.001"
            value={longitude}
            className="panel-inputField panel-text"
            onChange={handleLongitudeChange}
          />
        </div>
        <div className="panel-inputGroup">
          <label htmlFor="height" className="panel-inputLabel panel-text">
            Height:
          </label>
          <input
            type="number"
            name="height"
            min="-300"
            step="5"
            value={height}
            className="panel-inputField panel-text"
            onChange={handleHeightChange}
          />
        </div>
        <br />
        <div className="panel-inputGroup">
          <button
            className="panel-saveBtn "
            disabled={pickPosition}
            onClick={triggerClickToPosition}
          >
            Click to Position
          </button>
        </div>
      </div>
      <div className="geolocation_3inputGroup">
        <div className="geolocation_checkboxInputGroup">
          <button
            className="panel-saveBtn2"
            onClick={() => {
              setClampToGround(true);
            }}
          >
            Clamp to Terrain
          </button>
        </div>
        <div className="geolocation_checkboxInputGroup">
          <input
            type="checkbox"
            id="repos"
            checked={modelDrag}
            onChange={handleReposition}
          />
          <label htmlFor="georef" className="panel-inputLabel panel-text">
            Drag to reposition
          </label>
        </div>
      </div>
    </>
  );
};

export default Localize;
