// @ts-nocheck
/**
 * Component: `visioWindow.jsx`
 *
 * Author: Ankit Jangid
 */
// ... React Imports ...
import React, { useEffect } from "react";

// ... Components ...
import loadNew3DTile from "../editor/editorFunctions/loadNewAsset/components/loadNew3DTile";
import loadJSONProject from "../../../../components/cesiumAPI/loadJSONProject";
import loadCZMLGLTF from "../../../../components/cesiumAPI/loadCZMLGLTF";

/**
 * @description `Component`: Function to load resource selected from Preview Window to Viewer
 * @param { Object } resourceType Type of selected resource
 * @param { Viewer } viewer Cesium Viewer
 * @param { Resource } resourceObj Cesium Resource object for selected resource loading
 * @param { String } gltfURL Absolute path to GLTF
 * @param { Object } handleResourceInFocus In focus resource handler
 * @param { Object } selectedResource Resource selected coming from Dashboard .
 */

const LoadInitialResource = ({
  resourceType,
  viewer,
  resourceObj,
  gltfURL,
  handleResourceInFocus,
  selectedResource,
  setLoading,
}) => {
  // .. Load selected resource coming from Dashboard to Viewer ..
  useEffect(() => {
    async function getData() {
      if (resourceType === "project") {
        setLoading(true);
        console.log('HELLO')
        await loadJSONProject(viewer, resourceObj);
        console.log(resourceObj)
        setLoading(false);
      } else if (resourceType === "3DTile") {
        loadNew3DTile(
          viewer,
          resourceObj,
          handleResourceInFocus,
          selectedResource
        );
      } else if (resourceType === "georefGLTF") {
        loadCZMLGLTF(
          viewer,
          resourceObj,
          gltfURL,
          selectedResource,
          handleResourceInFocus
        );
      } else {
        console.log(" Loading bare gltfs disabled ");
      }
    }
    getData();
  }, []);

  return null;
};

export default LoadInitialResource;
