// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// ... NPM Packages ...
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";

// ... Components ...
import "./detailsPreview.css";
import status_success from '../../assets/status_success.png'
import status_error from '../../assets/status_error.png'
/**
 * @description `Component`: The DetailsPreview Component offers a concise display of selected resource information within Project Dashboard, adapting to show project or asset details based on context.
 * @param {Object} selectedResource Sets selected resource
 * @param {Boolean} projectList Displays projects if active
 * @param {Function} onDeleteProject Function to handle project deletion
 * @param {Function} onDeleteSuccess Function to handle successful deletion
 * @param {Function} setSelectedResource Function to set the selected resource
 * @param {Function} onDeleteAsset Function to handle asset deletion
 * @param {Boolean} showDeleteSuccess Flag to control the display of delete confirmation popup
 * @param {Function} setShowDeleteSuccess Function to toggle the display of delete confirmation popup
 * @param {String} selectedResourceName Name of the selected resource
 * @param {Number} selectedResourceId ID of the selected resource
 * @param {Number} userLevel User level or access level
 * @param {Boolean} assetList Displays asset details if active
 * @param {String} selectedResourceType Type of the selected resource
 * @param {Boolean} showUserList Flag to determine if the user list should be displayed
 * @param {Function} setSuccessMsg Function to set success message
 * @param {Function} setSuccess Function to set success flag
 * @param {Function} setFailure Function to set failure flag
 * @param {Function} setFailureMessage Function to set failure message
 * @param {Array} resourceUsers List of resource users
 * @param {Array} resourceGroups List of resource groups
 * @category Project Dashboard
 * @returns {JSX.Element} Renders Details Preview
 */
const DetailsPreview = ({
  selectedResource,
  projectList,
  onDeleteProject,
  onDeleteSuccess,
  setSelectedResource,
  onDeleteAsset,
  showDeleteSuccess,
  setShowDeleteSuccess,
  selectedResourceName,
  selectedResourceId,
  userLevel,
  assetList,
  selectedResourceType,
  showUserList,
  setSuccessMsg,
  setSuccess,
  setFailure,
  setFailureMessage,
  resourceUsers,
  resourceGroups,
}) => {
  // ... React hook declarations ..
  const [assign, setAssign] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [selectedItemType, setSelectedItemType] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [assignedGroups, setAssignedGroups] = useState([]);
  const { t } = useTranslation();
  const navigation = useNavigate();
  const jwtToken = secureLocalStorage.getItem("token");
  const assetPending = 61;
  const assetStarted = 62;
  const assetPending3DT = 63;
  const assetCompleted = 64;
  const assetGeoreferenced = 65;

  useEffect(() => {
    if (resourceUsers) {
      const updatedAssignedUsers = resourceUsers.filter(
        (user) => assignedUsers.includes(user.id)
      );
      setAssignedUsers(updatedAssignedUsers);
    }

    if (resourceGroups) {
      const updatedAssignedGroups = resourceGroups.filter(
        (group) => assignedGroups.includes(group.id)
      );
      setAssignedUsers(updatedAssignedGroups);
    }
  }, [resourceUsers, resourceGroups]);

  /**
  * Handles the deletion of a selected resource (project or asset).
  */
  const handleDelete = () => {
    if (selectedResource) {
      if (projectList) {
        onDeleteProject(selectedResource.id);
      } else {
        onDeleteAsset(selectedResource.id);
      }
      setSelectedResource(null);
    }
  };

  /**
  * Handles the confirmation of the resource deletion.
  */
  const handleConfirmDelete = () => {
    onDeleteSuccess();
  }

  /**
   * Closes the assign popup
   */
  const closePopup = () => {
    setAssign(false)
  }

  /**
  * Closes the delete confirmation popup.
  */
  const closeDeletePopup = () => {
    setShowDeleteSuccess(false)
  }

  /**
  * Handles the assignment action and opens the assign popup.
  */
  const handleAssign = () => {
    setAssign(true)
  }

  /**
  * Handles the selection of a user for assignment.
  * @param {Object} user - The selected user to assign.
  */
  const handleSelectedUser = (user) => {
    console.log(user.id)
    setSelectedItemID(user.id)
    setSelectedItemType("user");
    const rows = document.querySelectorAll(".detailsPreview_assign-popup-list");
    rows.forEach((row) => {
      row.classList.remove("selected-row-popup");
    });

    if (user) {
      const selectedRow = document.querySelector(`#user-${user.id}`);
      if (selectedRow) {
        selectedRow.classList.add("selected-row-popup");
      }
    }
  }

  /**
  * Handles the selection of a group for assignment.
  * @param {Object} group - The selected group to assign.
  */
  const handleSelectedGroup = (group) => {
    console.log(group.id)
    setSelectedItemID(group.id)
    setSelectedItemType("group");
    const rows = document.querySelectorAll(".detailsPreview_assign-popup-list");
    rows.forEach((row) => {
      row.classList.remove("selected-row-popup");
    });

    if (group) {
      const selectedRow = document.querySelector(`#group-${group.id}`);
      if (selectedRow) {
        selectedRow.classList.add("selected-row-popup");
      }
    }
  }

  /**
  * Handles the assignment of a user to a project and makes an API call.
  */
  const handleAssignUserToProject = () => {
    console.log(selectedResourceId)
    console.log(selectedItemID)
    const userDetails = JSON.stringify({
      project_id: selectedResourceId,
      user_id: selectedItemID,
    });

    fetch(process.env.REACT_APP_API + "/project/assign_project_user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: userDetails,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setAssign(false)
        if (json.status == "Successfully assigned") {
          setSuccess(true);
          setSuccessMsg("Project successfully assigned");
          setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
          }, 3000);
          setAssignedUsers([...assignedUsers, { id: selectedItemID }]);
        } else {
          setFailure(true);
          setFailureMessage("Project assignment failed");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
 * Handles the assignment of a group to a project and makes an API call.
 */
  const handleAssignGroupToProject = () => {
    console.log(selectedResourceId)
    console.log(selectedItemID)
    const groupDetails = JSON.stringify({
      project_id: selectedResourceId,
      group_id: selectedItemID,
    });

    fetch(process.env.REACT_APP_API + "/project/assign_project_group", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: groupDetails,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setAssign(false)
        if (json.status == "Successfully assigned") {
          setSuccess(true);
          setSuccessMsg("Project successfully assigned");
          setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
          }, 3000);
          setAssignedGroups([...assignedGroups, { id: selectedItemID }]);
        } else {
          setFailure(true);
          setFailureMessage("Project assignment failed");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
 * Handles the assignment of a group to an asset and makes an API call.
 */
  const handleAssignGroupToAsset = () => {
    console.log(selectedResourceId)
    console.log(selectedItemID)
    const groupDetails = JSON.stringify({
      asset_id: selectedResourceId,
      group_id: selectedItemID,
    });

    fetch(process.env.REACT_APP_API + "/project/assign_asset_group", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: groupDetails,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setAssign(false)
        if (json.status == "Successfully assigned") {
          setSuccess(true);
          setSuccessMsg("Asset successfully assigned");
          setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
          }, 3000);
          setAssignedGroups([...assignedGroups, { id: selectedItemID }]);
        } else {
          setFailure(true);
          setFailureMessage("Asset assignment failed");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
  * Handles the assignment of a user to an asset and makes an API call.
  */
  const handleAssignUserToAsset = () => {
    console.log(selectedResourceId)
    console.log(selectedItemID)
    const groupDetails = JSON.stringify({
      asset_id: selectedResourceId,
      user_id: selectedItemID,
    });

    fetch(process.env.REACT_APP_API + "/project/assign_asset_user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: groupDetails,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setAssign(false)
        if (json.status == "Successfully assigned") {
          setSuccess(true);
          setSuccessMsg("Asset successfully assigned");
          setTimeout(() => {
            setSuccess(false);
            setSuccessMsg("");
          }, 3000);
          setAssignedUsers([...assignedUsers, { id: selectedItemID }]);
        } else {
          setFailure(true);
          setFailureMessage("Asset assignment failed");
          setTimeout(() => {
            setFailure(false);
            setFailureMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  return (
    <div>
      <div className="projectDashboard_right-viewer-container">
        <div className="projectDashboard_upper-right-project-details">
          <div className="projectDashboard_right-btn-container">

            {showUserList && <div
              className={
                selectedResource == null
                  ? "projectDashboard_view-user-list disabled"
                  : "projectDashboard_view-user-list"
              }
              onClick={() => {
                navigation("/projectManager", {
                  replace: false,
                  state: {
                    selectedResource: selectedResourceName,
                    selectedItemId: selectedResourceId,
                    user: userLevel,
                    resourceType: selectedResourceType,
                    users: resourceUsers,
                    groups: resourceGroups,
                  },
                });
              }}
            >
              <a>{t("main.view-user-list")}</a>
            </div>}
            {showUserList && <div
              onClick={handleAssign}
              className={
                selectedResource == null
                  ? "projectDashboard_delete-btn disabled"
                  : "projectDashboard_delete-btn"
              }
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                <path d="M17 10V7H14V5H17V2H19V5H22V7H19V10H17ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM0 16V13.2C0 12.6333 0.145833 12.1125 0.4375 11.6375C0.729167 11.1625 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64583 4.75 9.3875C5.81667 9.12917 6.9 9 8 9C9.1 9 10.1833 9.12917 11.25 9.3875C12.3167 9.64583 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2708 11.1625 15.5625 11.6375C15.8542 12.1125 16 12.6333 16 13.2V16H0ZM2 14H14V13.2C14 13.0167 13.9542 12.85 13.8625 12.7C13.7708 12.55 13.65 12.4333 13.5 12.35C12.6 11.9 11.6917 11.5625 10.775 11.3375C9.85833 11.1125 8.93333 11 8 11C7.06667 11 6.14167 11.1125 5.225 11.3375C4.30833 11.5625 3.4 11.9 2.5 12.35C2.35 12.4333 2.22917 12.55 2.1375 12.7C2.04583 12.85 2 13.0167 2 13.2V14ZM8 6C8.55 6 9.02083 5.80417 9.4125 5.4125C9.80417 5.02083 10 4.55 10 4C10 3.45 9.80417 2.97917 9.4125 2.5875C9.02083 2.19583 8.55 2 8 2C7.45 2 6.97917 2.19583 6.5875 2.5875C6.19583 2.97917 6 3.45 6 4C6 4.55 6.19583 5.02083 6.5875 5.4125C6.97917 5.80417 7.45 6 8 6Z" fill="#9EA93F" />
              </svg>
            </div>}
          </div>
          <div className="projectDashboard_display-project-details">
            <div className="projectDashboard_display">
              <div className="projectDashboard_project-details-heading">
                {projectList ? (t("main.project-details")) : (t("main.asset-details"))}
              </div>
              <div className="detailsPreview-delete-btn-container">
                <div className={selectedResource == null ? "disabled-delete-btn" : "detailsPreview-delete-btn"} onClick={handleConfirmDelete}>{t("main.delete")}</div>
              </div>
            </div>
            <div className="detailsPreview_status-container">
              {assetList ? (<p id="projectDashboard_label">{t("main.status")}</p>) : ("")}
              {(assetList && selectedResource) ? (
                selectedResource.status === assetCompleted ||
                  selectedResource.status === assetGeoreferenced ||
                  selectedResource.status === assetPending3DT ? (
                  <img id="status-img" src={status_success} title="Completed" />
                ) : selectedResource.status === assetStarted ||
                  selectedResource.status === assetPending ? (
                  <svg
                    id="conversion"
                    xmlns="http://www.w3.org/2000/svg"
                    height="17"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path
                      d="M204-318q-22-38-33-78t-11-82q0-134 93-228t227-94h7l-64-64 56-56 160 160-160 160-56-56 64-64h-7q-100 0-170 70.5T240-478q0 26 6 51t18 49l-60 60ZM481-40 
                          321-200l160-160 56 56-64 64h7q100 0 170-70.5T720-482q0-26-6-51t-18-49l60-60q22 38 33 78t11 82q0 134-93 228t-227 94h-7l64 64-56 56Z"
                    />
                    <title>Under Conversion</title>
                  </svg>
                ) : (
                  <img id="status-img" src={status_error} title="Failed" />
                )
              ) : (
                <div></div>
              )}
            </div>

            <div className="projectDashboard_label-name">
              <p id="projectDashboard_label">
                {projectList ? t("main.project-name") : t("main.asset-name")}:
              </p>
              <p>{selectedResource
                ? selectedResource.project_name?.split(".json")[0] ||
                selectedResource.asset_name
                : " "}</p>
            </div>
            <div className="projectDashboard_id-email">
              <div className="projectDashboard_label-name">
                <p id="projectDashboard_label">{t("main.id")}:</p>
                <p>{selectedResource ? selectedResource.id : ""}</p>
              </div>
              <div className="projectDashboard_label-name">
                <p id="projectDashboard_label">{t("main.file-size")}:</p>
                <p>{selectedResource ? selectedResource.file_size : ""}</p>
              </div>
            </div>
            <div className="projectDashboard_label-name">
              <p id="projectDashboard_label">
                {projectList ? t("main.location") : t("main.file-type")}:
              </p>
              <p>{selectedResource ? (selectedResource.location || selectedResource.input_file_type) : ""}</p>
            </div>
            <div className="projectDashboard_id-email">
              <div className="projectDashboard_label-name">
                <p id="projectDashboard_label">{t("main.created-on")}:</p>
                <p>{selectedResource
                  ? selectedResource.created_on == null
                    ? "Inactive"
                    : new Date(
                      selectedResource.last_modified
                    ).toLocaleString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  : ""}</p>
              </div>
              <div className="projectDashboard_label-name">
                <p id="projectDashboard_label">{t("main.last-modified")}:</p>
                <p>{selectedResource
                  ? selectedResource.last_modified == null
                    ? "Inactive"
                    : new Date(
                      selectedResource.last_modified
                    ).toLocaleString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteSuccess && <div className='confirm-delete-popup'>
        <p>{t("main.are-you-sure-you-want-to-delete-the-selected-item?")}</p>
        <div className="delete-btn-container">
          <div id="delete-btn-cancel" onClick={closeDeletePopup}>{t("main.cancel")}</div>
          <div id="confirm-delete-btn" onClick={handleDelete}>{t("main.delete")}</div>
        </div>
      </div>}

      {assign && projectList && <div className="detailsPreview_assign-popup-container">
        <div className="detailsPreview_assign-popup-full">
          <div className="detailsPreview_assign-popup">
            <div className="detailsPreview_popup">
              <div className="detailsPreview_assign-popup-heading">
                <p>{t("main.select-a-user-to-assign")}</p>
              </div>
              <div className="detailsPreview_assign-heading-user">
                <p>{t("main.id")}</p>
                <p>{t("main.user")}</p>
              </div>
              <div className="detailsPreview_scrollable">
                {resourceUsers.length > 0 ? (
                  resourceUsers
                    .filter((user) => !assignedUsers.map((au) => au.id).includes(user.id))
                    .map((user) => (
                      <div
                        className="detailsPreview_assign-popup-list"
                        id={`user-${user.id}`}
                        key={`user-${user.id}`}
                        onClick={() => handleSelectedUser(user)}
                      >
                        <p>{user.id}</p>
                        <p>{user.username}</p>
                      </div>
                    ))
                ) : (
                  <p id="projectDashboard_no-projects">No users to display</p>
                )}
              </div>
            </div>
            <div className="detailsPreview_popup">
              <div className="detailsPreview_assign-popup-heading">
                <p>{t("main.select-a-user-group-to-assign")}</p>
              </div>

              <div className="detailsPreview_assign-heading">
                <p>{t("main.id")}</p>
                <p>{t("main.group")}</p>
              </div>
              <div className="detailsPreview_scrollable">
                {resourceGroups.length > 0 ? (
                  resourceGroups
                    .filter((group) => !assignedGroups.map((au) => au.id).includes(group.id))
                    .map((group) => (
                      <div className="detailsPreview_assign-popup-list" id={`group-${group.id}`} key={`group-${group.id}`} onClick={() => handleSelectedGroup(group)}>
                        <p>{group.id}</p>
                        <p>{group.title.length > 25
                          ? `${group.title.slice(0, 20)}...`
                          : group.title}</p>
                      </div>
                    ))
                ) : (
                  <p id="projectDashboard_no-projects">No groups to display</p>
                )}
              </div>
            </div>
          </div>
          <div className="detailsPreview_assign-btn-container">
            <div className="detailsPreview_assign-btn" onClick={() => {
              if (selectedItemType === "user") {
                handleAssignUserToProject();
              } else if (selectedItemType === "group") {
                handleAssignGroupToProject();
              }
            }}>
              {t("main.assign")}
            </div>
          </div>
        </div>
      </div>}

      {assign && assetList && <div className="detailsPreview_assign-popup-container">
        <div className="detailsPreview_assign-popup-full">
          <div className="detailsPreview_assign-popup">
            <div className="detailsPreview_popup">
              <div className="detailsPreview_assign-popup-heading">
                <p>{t("main.select-a-user-to-assign")}</p>
              </div>
              <div className="detailsPreview_assign-heading-user">
                <p>{t("main.id")}</p>
                <p>{t("main.user")}</p>
              </div>
              <div className="detailsPreview_scrollable">
                {resourceUsers.length > 0 ? (
                  resourceUsers
                    .filter((user) => !assignedUsers.map((au) => au.id).includes(user.id))
                    .map((user) => (
                      <div className="detailsPreview_assign-popup-list" id={`user-${user.id}`}
                        key={`user-${user.id}`} onClick={() => handleSelectedUser(user)}>
                        <p>{user.id}</p>
                        <p>{user.username}</p>
                      </div>
                    ))
                ) : (
                  <p id="projectDashboard_no-projects">No users to display</p>
                )}
              </div>
            </div>
            <div className="detailsPreview_popup">
              <div className="detailsPreview_assign-popup-heading">
                <p>{t("main.select-a-user-group-to-assign")}</p>
              </div>

              <div className="detailsPreview_assign-heading">
                <p>{t("main.id")}</p>
                <p>{t("main.group")}</p>
              </div>
              <div className="detailsPreview_scrollable">
                {resourceGroups.length > 0 ? (
                  resourceGroups
                    .filter((group) => !assignedGroups.map((au) => au.id).includes(group.id))
                    .map((group) => (
                      <div className="detailsPreview_assign-popup-list" id={`group-${group.id}`}
                        key={`group-${group.id}`} onClick={() => handleSelectedGroup(group)}>
                        <p>{group.id}</p>
                        <p>{group.title.length > 25
                          ? `${group.title.slice(0, 20)}...`
                          : group.title}</p>
                      </div>
                    ))
                ) : (
                  <p id="projectDashboard_no-projects">No groups to display</p>
                )}
              </div>
            </div>
          </div>
          <div className="detailsPreview_assign-btn-container">
            <div className="detailsPreview_assign-btn" onClick={() => {
              if (selectedItemType === "user") {
                handleAssignUserToAsset();
              } else if (selectedItemType === "group") {
                handleAssignGroupToAsset();
              }
            }}>
              {t("main.assign")}
            </div>
          </div>
        </div>
      </div>}

      {assign && (
        <div
          className="projectGroups_background-overlay"
          onClick={closePopup}
        ></div>
      )}

    </div>
  );
};

export default DetailsPreview;
