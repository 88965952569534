// @ts-nocheck
import {
  Cartesian3,
  EllipsoidTerrainProvider,
  HeadingPitchRoll,
  Math,
  Matrix4,
  Model,
  Transforms,
  sampleTerrainMostDetailed,
} from "cesium";

/**
 * An async function for loading and displaying 3D models in a Cesium viewer using CZML data. It fetches CZML content, manages model IDs, and loads models with specified properties like position and orientation
 * @param {*} viewer Cesium Viewer
 * @param {*} resourceObj Cesium Resource object for resource loading
 * @param {*} gltfURL Absolute path to GLTF
 * @param {*} handleResourceInFocus In focus resource handler
 * @param {*} resourceToLoad GLTF selected from asset list.
 */
const loadNewCZML = async (
  viewer,
  resourceObj,
  gltfURL,
  insertToCurrLoc,
  resourceToLoad,
  handleResourceInFocus,
  setAssetAdded,
  setAssetFailed,
  setAssetDropDown
) => {
  console.log(" RS TO LOAD : ", resourceToLoad);
  try {
    // .. Get the gltf.czml file ..
    const response = await fetch(resourceObj.url, {
      method: "GET",
      headers: resourceObj.headers,
    });
    if (!response.ok) {
      throw new Error("Error while fetching Tileset");
    }
    const data = await response.json();
    
    const gltfObj = data[1];

    // .. Check if Model with same URL exists and ID them accordingly ..
    const numOfPrimitives = viewer.scene.primitives._primitives.length;
    let gltfId = 1;
    for (let i = 0; i < numOfPrimitives; i++) {
      if (viewer.scene.primitives._primitives[i] instanceof Model) {
        if (viewer.scene.primitives._primitives[i]._id == "GL_" + gltfId) {
          gltfId++;
        } else {
          viewer.scene.primitives._primitives[i]._id = "GL_" + gltfId;
          gltfId++;
        }
      }
    }

    // .. As cesium cannot apply flyTo method to Model primitive, camera position for the Model has to be declared explicitly ..
    // .. TODO : Calculate and apply appropriate orientation for camer too ..

    // TODO : Retrieve GLTF attributes from czml instead
    let position, camPosition;
    if (insertToCurrLoc) {
      // Insert to current location clamped to terrain if the respective checkbox is selected
      if (viewer.terrainProvider instanceof EllipsoidTerrainProvider) {
        const cartographic = viewer.camera.positionCartographic;
        const latitude = Math.toDegrees(cartographic.latitude);
        const longitude = Math.toDegrees(cartographic.longitude);
        position = Cartesian3.fromDegrees(longitude, latitude, 0);
        camPosition = Cartesian3.fromDegrees(longitude, latitude, 100000);
      } else {
        await sampleTerrainMostDetailed(viewer.terrainProvider, [
          viewer.camera.positionCartographic,
        ]).then((updatedPositions) => {
          const longitude = Math.toDegrees(updatedPositions[0].longitude);
          const latitude = Math.toDegrees(updatedPositions[0].latitude);
          position = Cartesian3.fromDegrees(
            longitude,
            latitude,
            updatedPositions[0].height
          );
          camPosition = Cartesian3.fromDegrees(longitude, latitude, 100000);
        });
      }
    } else {
      // Insert to default location
      position = Cartesian3.fromDegrees(
        gltfObj.position.cartographicDegrees[0],
        gltfObj.position.cartographicDegrees[1],
        gltfObj.position.cartographicDegrees[2]
      );
      camPosition = Cartesian3.fromDegrees(
        gltfObj.position.cartographicDegrees[0],
        gltfObj.position.cartographicDegrees[1],
        100000
      );
    }

    // .. Use the fetched gltf obj to set properties for the gltf to be loaded ..
    // .. Load the GLTF with 0 degree HPR values ..
    const heading = Math.toRadians(0.0);
    const pitch = Math.toRadians(0.0);
    const roll = Math.toRadians(0.0);

    const orientation = Transforms.headingPitchRollQuaternion(
      position,
      new HeadingPitchRoll(heading, pitch, roll)
    );

    const scaling = new Cartesian3(gltfObj.scale.x, gltfObj.scale.y, gltfObj.scale.z);

    // .. Load Model ..
    try {
      resourceObj.url = gltfURL;
      const modelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
        position,
        orientation,
        scaling
      );

      const newModel = await Model.fromGltfAsync({
        id: "GL_" + gltfId,
        credit: resourceToLoad.asset_name,
        url: resourceObj,
        modelMatrix: modelMatrix,
        // scale: gltfObj.scale
        // minimumPixelSize: 128,
      });
      viewer.scene.primitives.add(newModel);

      // .. Add orientation into modelResources member of Model ..
      newModel._modelResources[0] = gltfObj.orientation
    
      // TODO CHECK BELOW : ?
      // .. Add assetConlusion into modelResources member of Model ..
      // newModel.assetConclusion = gltfObj.assetConclusion;

      viewer.camera.flyTo({
        destination: camPosition,
        duration: 2,
      });
      // .. Set the loaded model to focus ..
      if (handleResourceInFocus) {
        handleResourceInFocus(newModel);
        setAssetAdded(true);
        setTimeout(() => {
          setAssetAdded(false);
          setAssetDropDown(false);
        }, 1000);
      }
    } catch (error) {
      console.log(`Failed to load model. ${error}`);
      setAssetFailed(true);
      setTimeout(() => {
        setAssetAdded(false);
        setAssetDropDown(false);
      }, 1000);
    }
  } catch (error) {
    console.log(" ERROR : ", error);
    setAssetFailed(true);
    setTimeout(() => {
      setAssetAdded(false);
      setAssetDropDown(false);
    }, 1000);
  }
};

export default loadNewCZML;
