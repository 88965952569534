import React from 'react'

const UploadSuccessFailurePopup = ({ failureMessage, failure, successMsg, success }) => {
    return (
        <div>
            {success && <div className="notification success">{successMsg}</div>}

            {failure && <div className="notification-fail">{failureMessage}</div>}
        </div>
    )
}

export default UploadSuccessFailurePopup