// @ts-nocheck
// ... React Imports ...
import React, { useState, useEffect, useRef } from "react";

// ... NPM Packages ...
import secureLocalStorage from "react-secure-storage";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";

// ... Components ...
import "./projectList.css";

/**
 * @description `Component`:  This component displays a list of user projects, allowing users to view and interact with project details such as project ID, name, location, file size, and last modified date.
 * @param {Boolean} isActive Display projects if active.
 * @param {Function} handleResourceUpdate Sets the selected project.
 * @param {String} searchQuery Used to filter and display projects based on the user's search input.
 * @param {String} filterCriteria Displays the list of projects sorted based on the selected filter criterion.
 * @param {Array} newProjects Array containing newly added projects.
 * @param {Array} deletedProjects Array containing deleted projects.
 * @category Project Dashboard
 * @category Project Dashboard
 * @returns {JSX.Element} Renders Project List.
 */
const ProjectList = ({
  isActive,
  handleResourceUpdate,
  searchQuery = "",
  filterCriteria,
  newProjects,
  deletedProjects,
}) => {
  // ... React hook declarations ...
  const [getData, setData] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  // .. Constants ..
  const jwtToken = secureLocalStorage.getItem("token");
  const { t } = useTranslation();

  /**
   * Project List useEffect hook.
   */
  useEffect(() => {
    fetchProjectUserID();
  }, [deletedProjects, newProjects]);

  // ... Local Functions ...

  // Fetches the list of projects associated with a specific user ID.
  const fetchProjectUserID = () => {
    fetchProjectList(jwt_decode(jwtToken).user_id);
  };

  // Filters and sorts the list of projects based on the search query and selected filter criteria.
  const filteredAndSortedProjects =
    getData.length > 0
      ? getData
        .filter((project) =>
          project.project_name && project.project_name.toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          switch (filterCriteria) {
            case "Last Modified":
              const dateA = new Date(
                a.last_modified.replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/, '$3-$2-$1T$4:$5')
              );
              const dateB = new Date(
                b.last_modified.replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/, '$3-$2-$1T$4:$5')
              );
              return dateB - dateA;
            case "ID":
              return a.id - b.id;
            case "Name":
              return a.project_name.localeCompare(b.project_name, undefined, {
                sensitivity: "base",
              });
            default:
              return 0;
          }
        })
      : [];


  /**
   * Makes GET request to fetch Project list as per user ID
   * @param {number} userid User ID
   */
  const fetchProjectList = (userid) => {
    fetch(process.env.REACT_APP_API + "/project/project_details/" + userid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
    })
      .then((res) => res.json())

      .then((json) => {
        if (json.status == "No projects found") {
          console.log("User has no projects");
        } else if (Array.isArray(json.data)) {
          const sortedData = json.data.sort((a, b) => {
            const dateA = new Date(
              a.last_modified.replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/, '$3-$2-$1T$4:$5')
            );
            const dateB = new Date(
              b.last_modified.replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/, '$3-$2-$1T$4:$5')
            );
            return dateB - dateA;
          });

          setData(sortedData);
        } else {
          console.log("Invalid projects data:", json.data);
        }
      })
      .catch((error) => {
        console.log("Error fetching projects:", error);
      });
  };

  /**
   * Updates state for the clicked project
   * @param {Object} project Selected project
   */
  const handleClick = (project) => {
    if (project) {
      handleResourceUpdate(project);
      setSelectedProjectId(project.id);
    }
  };

  return (
    <div>
      {isActive && (
        <table className="projectDashboard_table">
          <thead>
            <tr className="projectDashboard_table-heading">
              <th>{t("main.id")}</th>
              <th id="align-left">{t("main.name")}</th>
              <th>{t("main.location")}</th>
              <th>{t("main.file-size")}</th>
              <th>{t("main.last-modified")}</th>
            </tr>
          </thead>
          <tbody className="projectDashboard_project-table-list">
            {filteredAndSortedProjects.length > 0 ? (
              filteredAndSortedProjects.map((project) => (
                <tr
                  onClick={() => handleClick(project)}
                  className={`projectDashboard_row ${project.id === selectedProjectId ? 'selected-row' : ''}`}
                  id={`project-${project.id}`}
                  key={`project-${project.id}`}
                >
                  <td>P{project.id}</td>
                  <td id="align-left">
                    {project.project_name.length > 22
                      ? `${project.project_name.slice(0, 15).replace(".json", "")}...`
                      : project.project_name.replace(".json", "")}
                  </td>
                  <td>{project.location.length > 15
                    ? `${project.location.slice(0, 10)}...`
                    : project.location}</td>
                  <td>{project.file_size}</td>
                  <td>
                    {project.last_modified == null
                      ? "Inactive"
                      : new Date(project.last_modified).toLocaleString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" id="projectDashboard_no-projects">
                  No projects to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProjectList;
