// @ts-nocheck
// ... React Imports ...
import React from 'react'

/**
 * @description `AddTenantPopup`: Represents a popup component for adding a new tenant in the admin dashboard.
 * @param {boolean} showAddTenantPopup  Indicates whether the popup should be displayed.
 * @param {string} newTenantName  The input value for the new tenant's name.
 * @param {string} newTenantEmail The input value for the new tenant's email.
 * @param {Function} handleNameChange A function to handle changes in the tenant name input.
 * @param {Function} handleEmailChange A function to handle changes in the tenant email input.
 * @param {Function} handleTenantCreation A function to handle the creation of a new tenant.
 * @returns {JSX.Element} The rendered add tenant popup component.
 */
const AddTenantPopup = ({ showAddTenantPopup, newTenantName, newTenantEmail, handleNameChange, handleEmailChange, handleTenantCreation }) => {
    return (
        <div>
            {showAddTenantPopup && <div className="projectDashboard_popup-container">
                <div className="projectDashboard_popup-container">
                    <div className="projectDashboard_popup">
                        <p id="projectDashboard_popup-heading">Add new Tenant</p>
                        <form id="projectDashboard_form">
                            <div className="projectDashboard_form-input">
                                <label>Add Tenant Name:</label>
                                <input
                                    id="popup-input"
                                    type="text"
                                    maxLength="40"
                                    name="name"
                                    value={newTenantName}
                                    required
                                    placeholder='Add Username'
                                    onChange={handleNameChange}
                                />
                            </div>

                            <div className="projectDashboard_form-input">
                                <label>Email:</label>
                                <input
                                    id="popup-input"
                                    type="text"
                                    maxLength="50"
                                    name="location"
                                    value={newTenantEmail}
                                    placeholder='Add Email'
                                    required
                                    onChange={handleEmailChange}
                                />
                            </div>

                            <div className="projectDashboard_form-button-container">
                                <div
                                    className={(newTenantName === '' || newTenantEmail === '') ? "projectDashboard_form-button disabled" : "projectDashboard_form-button"}
                                    type="submit"
                                    value="Submit"
                                    onClick={handleTenantCreation}
                                >
                                    <a>Create Tenant</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default AddTenantPopup