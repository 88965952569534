// @ts-nocheck
import { Cesium3DTileset, Model } from "cesium";
import { useEffect, useState } from "react";

/**
 * Custom hook for fetching and updating the list of milestones
 * @param { Viewer } viewer Cesium viewer 
 * @returns { { milestonesList: Array, setMilestonesList: Function } } List of milestones and setter function to update milestones list
 */
const useMilestones = (viewer) => {
  const [milestoneList, setMilestoneList] = useState([]);
  const [milestoneUpdated, setMilestoneUpdated] = useState(false);
  
 // ... Asset visibility control ...

  useEffect(() => {
    for (var i = 0; i < viewer.scene.primitives._primitives.length; i++) {
      const asset = viewer.scene.primitives._primitives[i];
      let milestoneName;
      let milestoneStartDate, milestoneEndDate;

      // .. Check type of primitive and also check if it contains assetConclusion member ..
      if (asset instanceof Cesium3DTileset) {
        if (asset.extras.assetAvailability) {
          (milestoneName = asset.extras.assetAvailability.assetAvailabilityName),
            (milestoneStartDate = asset.extras.assetAvailability.startDateTime),
            (milestoneEndDate = asset.extras.assetAvailability.endDateTime);
        }
      } else if (asset instanceof Model) {
        console.log(" CHECKIING FOR MODEL ASSET CONC");
        if (asset._modelResources.assetAvailability) {
          (milestoneName =
            asset._modelResources.assetAvailability.assetAvailabilityName),
            (milestoneStartDate =
              asset._modelResources.assetAvailability.startDateTime),
              (milestoneEndDate = asset._modelResources.assetAvailability.endDateTime)
        }
        console.log("PASSED");
      }

      // .. if conclusionName was defined in the previous if else, add the data to milestoneList state variable
      if (milestoneName) {
        setMilestoneList((prevList) => [
          ...prevList,
          {
            name: milestoneName,
            startDate: milestoneStartDate,
            endDate: milestoneEndDate,
          },
        ]);
      }
    }
  }, [milestoneUpdated]);

  return { milestoneList, setMilestoneList, setMilestoneUpdated };
};

export default useMilestones;
