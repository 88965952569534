// @ts-nocheck
import {
  Cartesian2,
  Cartesian3,
  Cartographic,
  EllipsoidTerrainProvider,
  HorizontalOrigin,
  JulianDate,
  Math,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  TimeInterval,
  TimeIntervalCollection,
  defined,
  sampleTerrainMostDetailed,
} from "cesium";
import { useEffect, useRef, useState } from "react";
import ArrowDropdown from "../../../../../../assets/arrow_drop_down.png";
import UploadImage from "../../../../../../assets/upload_image.png";
import AnnotationsList from "../../../features/AnnotationsList";
import useAnnotations from "../../../features/useAnnotations";
import "./annotation.css";
import DateTimePanel from "../../../features/DateTimePanel";

/**
 * Functional component for creating and managing annotations.
 * @param { Viewer } viewer Cesium Viewer
 * @param { Object } resourceInFocus Asset in focus (via user click or recent load)
 * @returns { JSX.Element } Annotation editor component
 */
const Annotation = ({ viewer, resourceInFocus, handleUpdateTimeline }) => {
  /* Getting annotations list from the custom useAnnotations hook */
  const { annotationsList, setAnnotationsList } = useAnnotations(viewer);
  /* State declaration */
  const [selectedCoordinates, setSelectedCoordinates] = useState({
    latitude: undefined,
    longitude: undefined,
    altitude: undefined,
  });
  const nameRef = useRef(null);
  const descRef = useRef(null);
  const [annotation, setAnnotation] = useState({
    name: "",
    description: "",
    color: "#FF3F3F",
    image: "",
  });
  const [annotationSelected, setAnnotationSelected] = useState(undefined);
  const [showColorDropdown, setShowColorDropdown] = useState(false);
  const [showAnnotationTypeDropdown, setShowAnnotationTypeDropdown] =
    useState(false);
  const [annotationType, setAnnotationType] = useState(1);
  const [showDateTimePanel, setShowDateTimePanel] = useState(false);
  const [startDateTime, setStartDateTime] = useState({
    startDate: new Date(),
    startTimeHrs: "00",
    startTimeMinutes: "00",
    startTimePeriod: "am",
  });
  const [endDateTime, setEndDateTime] = useState({
    endDate: new Date(),
    endTimeHrs: "11",
    endTimeMinutes: "59",
    endTimePeriod: "pm",
  });
  const [isDateAdded, setIsDateAdded] = useState(false);

  useEffect(() => {
    /* Attaching click handler on the scene */
    const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);
    console.log(handler)
    handler.setInputAction(async function (click) {
      const pickedObject = viewer.scene.pick(click.position);
      console.log("The picked object is", pickedObject);
      /* Checking if the user has selected a point on the focused asset or if it is a global annotation */
      if (
        annotationType === 0 ||
        (defined(pickedObject) &&
          ((pickedObject.primitive &&
            pickedObject.primitive === resourceInFocus) ||
            (pickedObject.id && pickedObject.id === resourceInFocus)))
      ) {
        /* Setting and displaying the selected coordinates */
        var cartesian, cartographic;

        /* Clamping the global annotation point to the terrain if it is not selected on a resource and terrain is present */
        if (
          annotationType === 0 &&
          !defined(pickedObject) &&
          !(viewer.terrainProvider instanceof EllipsoidTerrainProvider)
        ) {
          cartesian = viewer.camera.pickEllipsoid(
            click.position,
            viewer.scene.globe.ellipsoid
          );
          cartographic =
            viewer.scene.globe.ellipsoid.cartesianToCartographic(cartesian);
          console.log("cartographic ", cartographic);
          await sampleTerrainMostDetailed(viewer.terrainProvider, [
            cartographic,
          ]).then((updatedPositions) => {
            console.log("Updated positions ", updatedPositions);
            altitude = updatedPositions[0].height;
          });
        } else {
          cartesian = viewer.scene.pickPosition(click.position);
          cartographic = Cartographic.fromCartesian(cartesian);
        }

        /* Converting cartographic points to degrees */
        var longitude = Math.toDegrees(cartographic.longitude);
        var latitude = Math.toDegrees(cartographic.latitude);
        var altitude = cartographic.height;

        /* Creating a point on the viewer to show the point selected */
        viewer.entities.removeById("PT_Selected");
        viewer.entities.add({
          name: "Selected Point",
          id: "PT_Selected",
          position: Cartesian3.fromDegrees(longitude, latitude, altitude),
          billboard: {
            image:
              'data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><circle cx="5" cy="5" r="5" fill="%23FF3F3F"/></svg>',
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            horizontalOrigin: HorizontalOrigin.LEFT,
          },
        });

        /* Setting the state for the selected point */
        setSelectedCoordinates({
          latitude: latitude,
          longitude: longitude,
          altitude: altitude,
        });

        setAnnotationSelected(undefined);
        nameRef.current.focus();
      }
    }, ScreenSpaceEventType.LEFT_CLICK);

    /* Destorying event handlers */
    return () => {
      handler.destroy();
      viewer.entities.removeById("PT_Selected");
    };
  }, [resourceInFocus, annotationType]);

  /* Handles user input change and updates state */
  const handleChange = (e) => {
    const { name, value } = e.target;

    setAnnotation((prevValue) => {
      return {
        ...prevValue,
        [name]: name === "image" ? e.target.files[0]?.name : value,
        ...(name === "image" && { imagePath: e.target.files[0] }),
      };
    });
  };

  /* Handles date time change */
  const handleDateTimeChange = (name, value) => {
    if (name.startsWith("start")) {
      setStartDateTime((prev) => {
        return { ...prev, [name]: value };
      });
    } else {
      setEndDateTime((prev) => {
        return { ...prev, [name]: value };
      });
    }

    if (value === "clear") {
      setStartDateTime({
        startDate: new Date(),
        startTimeHrs: "00",
        startTimeMinutes: "00",
        startTimePeriod: "am",
      });

      setEndDateTime({
        endDate: new Date(),
        endTimeHrs: "00",
        endTimeMinutes: "00",
        endTimePeriod: "am",
      });
    }
  };

  /* Handles annotation color change */
  const handleColorChange = (color) => {
    setAnnotation((prevValue) => {
      return {
        ...prevValue,
        color: color,
      };
    });
    setShowColorDropdown(false);
  };

  /* Creates the HTML overlay element to display icon for the annotation and adds it to the scene */
  const createHtmlOverlay = (annotation) => {
    const overlay = document.createElement("div");
    overlay.classList.add("annotation-htmlOverlay");
    const circlePoint = document.createElement("div");
    circlePoint.classList.add("annotation-point");
    circlePoint.style.backgroundColor = annotation.annColor;
    overlay.appendChild(circlePoint);
    const line = document.createElement("div");
    line.classList.add("annotation-line");
    overlay.appendChild(line);
    const heading = document.createElement("div");
    heading.classList.add("annotation-heading");
    heading.innerHTML = annotation.name;
    heading.style.backgroundColor = annotation.annColor;
    const dropdownIcon = document.createElement("div");
    dropdownIcon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none" style=cursor:pointer><path d="M0.291687 0.666687L3.29169 6.66669L6.29169 0.666687" fill="black" fill-opacity="0.5"/></svg>`;
    dropdownIcon.addEventListener("click", function (e) {
      displayAnnotationDescription(annotation);
    });
    heading.appendChild(dropdownIcon);
    overlay.appendChild(heading);
    const descriptionOverlay = createDescriptionOverlay(annotation);
    overlay.appendChild(descriptionOverlay);
    document
      .getElementsByClassName("visioWindow_visioContainer")[0]
      .appendChild(overlay);
    return overlay;
  };

  /* Creates annotation entity */
  const createAnnotation = () => {
    try {
      /* Adding annotation entity to the entites */
      const annotationEntity = viewer.entities.add({
        position: Cartesian3.fromDegrees(
          selectedCoordinates.longitude,
          selectedCoordinates.latitude,
          selectedCoordinates.altitude
        ),
        name: annotation.name,
        id: `ANNT_${annotation.name}`,
        billboard: {
          image:
            "data:image/svg+xml,<svg version=1.1 xmlns=http://www.w3.org/2000/svg xmlns:xlink=http://www.w3.org/1999/xlink width=120 height=40 xml:space=preserve><rect width=120 height=40 rx=10 ry=10 style=fill:rgba(0,0,0,0) /></svg>",
        },
      });

      /* Picking the time availability properties, if any, from the underlying asset and assigning the same to the annotation entity */
      if (annotationType === 1) {
        if (resourceInFocus.extras?.assetAvailability) {
          const timeInterval = new TimeInterval({
            start: JulianDate.fromDate(
              new Date(resourceInFocus.extras.assetAvailability.startDateTime)
            ),
            stop: JulianDate.fromDate(
              new Date(resourceInFocus.extras.assetAvailability.endDateTime)
            ),
          });
          const timeIntervalCollection = new TimeIntervalCollection();
          timeIntervalCollection.addInterval(timeInterval);

          annotationEntity.availability = timeIntervalCollection;
        }
        annotationEntity.addProperty("isAssetBound");
        annotationEntity.isAssetBound = true;
      } else if (annotationType === 2) {
        /* If it is timed annotation setting the time interval selected by the user for the annotation */
        const timeInterval = new TimeInterval({
          start: JulianDate.fromDate(
            new Date(
              startDateTime.startDate.getFullYear(),
              startDateTime.startDate.getMonth(),
              startDateTime.startDate.getDate(),
              startDateTime.startTimePeriod === "am"
                ? parseInt(startDateTime.startTimeHrs)
                : parseInt(startDateTime.startTimeHrs) + 12,
              startDateTime.startTimeMinutes,
              0
            )
          ),
          stop: JulianDate.fromDate(
            new Date(
              endDateTime.endDate.getFullYear(),
              endDateTime.endDate.getMonth(),
              endDateTime.endDate.getDate(),
              endDateTime.endTimePeriod === "am"
                ? parseInt(endDateTime.endTimeHrs)
                : parseInt(endDateTime.endTimeHrs) + 12,
              endDateTime.endTimeMinutes,
              0
            )
          ),
        });
        const timeIntervalCollection = new TimeIntervalCollection();
        timeIntervalCollection.addInterval(timeInterval);

        annotationEntity.availability = timeIntervalCollection;
        annotationEntity.addProperty("isAssetBound");
        annotationEntity.isAssetBound = false;
      }

      /* Adding the additional properties to the newly created annotation entity object */
      annotationEntity.addProperty("annColor");
      annotationEntity.annColor = annotation.color;
      annotationEntity.addProperty("descImagePath");
      annotationEntity.descImagePath = annotation?.imagePath;
      annotationEntity.addProperty("annDescription");
      annotationEntity.annDescription = annotation.description;
      annotationEntity.addProperty("annCreatedDate");
      annotationEntity.annCreatedDate = new Date().toUTCString().slice(5, 16);
      if (annotationType === 1 || annotationType === 2) {
        annotationEntity.addProperty("assetId");
        annotationEntity.assetId = resourceInFocus._extras.id;
      }

      /* Creating and adding the annotation icon at the selected position on the scene */
      const scratch = new Cartesian2();
      const canvasPosition = viewer.scene.cartesianToCanvasCoordinates(
        annotationEntity.position.getValue(JulianDate.now()),
        scratch
      );
      if (defined(canvasPosition)) {
        const overlay = createHtmlOverlay(annotationEntity);
        /* Assigning the same id as the annotation entity id to the HTML overlay element */
        overlay.setAttribute("id", `ANNT_${annotation.name}`);
        overlay.style.top = `${canvasPosition.y}px`;
        overlay.style.left = `${canvasPosition.x}px`;
      }

      return annotationEntity;
    } catch (err) {
      console.log(err);
      return undefined;
    }
  };

  /* Save button click handler. Pushes the annotation to the annotations list */
  const onSaveBtnClick = async () => {
    if (annotation.name === "" || annotation.description === "") {
      annotation.name === ""
        ? nameRef.current.focus()
        : descRef.current.focus();
      return;
    }

    const newAnnotation = createAnnotation();
    viewer.entities.removeById("PT_Selected");

    /* Updating the annotations list for the newly created annotation if it was successfully created */
    if (newAnnotation) {
      setAnnotationsList((prevList) => {
        const newList = [...prevList];
        newList.push(newAnnotation);
        return newList;
      });

      setAnnotation({ name: "", description: "", color: "#FF3F3F", image: "" });
      setSelectedCoordinates({
        latitude: undefined,
        longitude: undefined,
        altitude: undefined,
      });
      setIsDateAdded(false);
      handleUpdateTimeline();
    } else {
      /* Asking user to re-enter name since it already exists */
      nameRef.current.focus();
    }
  };

  /* Creates the description overlay for the annotation */
  const createDescriptionOverlay = (annotation) => {
    const descriptionElement = document.createElement("div");
    descriptionElement.classList.add("description-overlay");
    descriptionElement.style.backgroundColor = `rgba(${parseInt(
      annotation.annColor.substring(1, 3),
      16
    )}, ${parseInt(annotation.annColor.substring(3, 5), 16)}, ${parseInt(
      annotation.annColor.substring(5, 7),
      16
    )}, 0.7)`;
    descriptionElement.style.visibility = "hidden";
    descriptionElement.innerHTML = `<p>${annotation.annDescription}</p>${annotation?.descImagePath
      ? `<img src=${URL.createObjectURL(
        annotation?.descImagePath
      )} alt='Annotation image' class='annotation-desc-image' />`
      : ""
      }`;
    return descriptionElement;
  };

  /* Displays the description for the selected annotation */
  const displayAnnotationDescription = (annotation) => {
    const descriptionElement = document.getElementById(annotation.id).lastChild;
    descriptionElement.style.visibility =
      descriptionElement.style.visibility === "hidden" ? "visible" : "hidden";
    setAnnotationSelected(annotation);
    setAnnotation({ name: "", description: "", color: "#FF3F3F", image: "" });
  };

  /* Deletes the annotation and updates state */
  const onDeleteBtnClick = () => {
    if (annotationSelected) {
      /* Removing the annotation from the list of entities */
      viewer.entities.removeById(annotationSelected.id);
      document.getElementById(annotationSelected.id).remove();
      /* Updating the annotations list for the deleted annotation */
      setAnnotationsList((prevList) => {
        var newList = [...prevList];
        newList = newList.filter(
          (annotation) => annotation.id !== annotationSelected.id
        );
        return newList;
      });
      setAnnotationSelected(undefined);
    }
  };

  /* Handles annotation type change */
  const handleAnnotationTypeChange = (type) => {
    setAnnotationType(type);
    setShowAnnotationTypeDropdown(false);
  };

  /* Toggles date panel display */
  const handleDatePanelShow = () => {
    setShowDateTimePanel((prev) => !prev);
    setIsDateAdded(false);
  };

  return (
    <>
      <div className="panel-container panel-main-section">
        <div className="annotation-type">
          <div
            className="selected-annotation-type"
            onClick={() => setShowAnnotationTypeDropdown((prev) => !prev)}
          >
            {showAnnotationTypeDropdown ? (
              <h4 className="panel-inputLabel placeholder-text">
                Select Annotation Type
              </h4>
            ) : (
              <h4 className="panel-inputLabel panel-text">
                {annotationType === 0
                  ? "Global Annotation"
                  : annotationType === 1
                    ? "Asset Bound Annotation"
                    : "Timed Annotation"}
              </h4>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
            >
              <mask
                id="mask0_3457_581"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="19"
                height="19"
              >
                <rect width="19" height="19" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_3457_581)">
                <path
                  d="M9.49544 11.8739L5.53711 7.91553H13.4538L9.49544 11.8739Z"
                  fill="white"
                />
              </g>
            </svg>
          </div>
          {showAnnotationTypeDropdown && (
            <>
              <hr />
              <div className="annotation-type-dropdown">
                <h4
                  className="panel-inputLabel panel-text"
                  onClick={() => handleAnnotationTypeChange(0)}
                >
                  Global Annotation
                </h4>
                <h4
                  className="panel-inputLabel panel-text"
                  onClick={() => handleAnnotationTypeChange(1)}
                >
                  Asset Bound Annotation
                </h4>
                <h4
                  className="panel-inputLabel panel-text"
                  onClick={() => handleAnnotationTypeChange(2)}
                >
                  Timed Annotation
                </h4>
              </div>
            </>
          )}
        </div>
        <hr />
        <div className="panel-2inputGroup">
          <div className="panel-inputGroup">
            <label className="panel-inputLabel">Name</label>
            <input
              name="name"
              value={annotation.name}
              type="text"
              ref={nameRef}
              className="panel-namefield panel-text"
              onChange={handleChange}
            />
          </div>
          <div className="panel-inputGroup">
            <label className="panel-inputLabel">Color</label>
            <div className="panel-colorfield">
              <div
                className="selected-color"
                onClick={() => setShowColorDropdown((prev) => !prev)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <circle cx="8.5" cy="8.5" r="8.5" fill={annotation.color} />
                </svg>
                <img src={ArrowDropdown} alt="Arrow dropdown" />
              </div>
              {showColorDropdown && (
                <div className="color-dropdown">
                  <hr />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    onClick={() => handleColorChange("#FF3F3F")}
                  >
                    <circle cx="8.5" cy="8.5" r="8.5" fill="#FF3F3F" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    onClick={() => handleColorChange("#9747FF")}
                  >
                    <circle cx="8.5" cy="8.5" r="8.5" fill="#9747FF" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    onClick={() => handleColorChange("#FFBD13")}
                  >
                    <circle cx="8.5" cy="8.5" r="8.5" fill="#FFBD13" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    onClick={() => handleColorChange("#3FFFFF")}
                  >
                    <circle cx="8.5" cy="8.5" r="8.5" fill="#3FFFFF" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    onClick={() => handleColorChange("#3FFF8C")}
                  >
                    <circle cx="8.5" cy="8.5" r="8.5" fill="#3FFF8C" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    onClick={() => handleColorChange("#FFF500")}
                  >
                    <circle cx="8.5" cy="8.5" r="8.5" fill="#FFF500" />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
        {annotationType === 2 && (
          <div className="panel-inputGroup margin">
            {isDateAdded ? (
              <p className="panel-subhead">
                Date:{" "}
                {startDateTime.startDate.toLocaleDateString(undefined, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                }) +
                  " " +
                  startDateTime.startTimeHrs +
                  ":" +
                  startDateTime.startTimeMinutes +
                  " " +
                  startDateTime.startTimePeriod +
                  " - " +
                  endDateTime.endDate.toLocaleDateString(undefined, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  }) +
                  " " +
                  endDateTime.endTimeHrs +
                  ":" +
                  endDateTime.endTimeMinutes +
                  " " +
                  endDateTime.endTimePeriod}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="panel-time-text" onClick={handleDatePanelShow}>
                  &gt;
                </span>
              </p>
            ) : (
              <p className="panel-time-text" onClick={handleDatePanelShow}>
                Select date &gt;
              </p>
            )}
            {showDateTimePanel && (
              <DateTimePanel
                startDateTime={startDateTime}
                endDateTime={endDateTime}
                handleDateTimeChange={handleDateTimeChange}
                setShowDateTimePanel={setShowDateTimePanel}
                setIsDateAdded={setIsDateAdded}
              />
            )}
          </div>
        )}
        <div className="panel-inputGroup">
          <label className="panel-inputLabel">Description</label>
          <textarea
            name="description"
            ref={descRef}
            value={annotation.description}
            rows="5"
            className="panel-inputField panel-text description-textarea"
            onChange={handleChange}
          />
        </div>
        <div className="panel-border-container">
          <label className="panel-upload">
            <input
              name="image"
              type="file"
              accept="image/*"
              onChange={handleChange}
              onClick={(e) => (e.target.value = "")}
            />
            {annotation.image ? (
              <span className="panel-subhead panel-text">
                {annotation.image}
              </span>
            ) : (
              <>
                <img src={UploadImage} alt="Upload image" />
                <p className="placeholder-text">upload image</p>
              </>
            )}
          </label>
        </div>

        <div className="annotation-buttons">
          <button
            className="panel-deleteBtn"
            disabled={!annotationSelected}
            onClick={onDeleteBtnClick}
          >
            Delete
          </button>
          <button
            className="panel-saveBtn panel-text"
            disabled={!selectedCoordinates?.latitude}
            onClick={onSaveBtnClick}
          >
            Save
          </button>
        </div>
      </div>

      <AnnotationsList
        displayAnnotationDescription={displayAnnotationDescription}
        annotationsList={annotationsList}
        placeholderText="Add Annotations from Above"
        viewer={viewer}
      />
    </>
  );
};

export default Annotation;
