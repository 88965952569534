// @ts-nocheck
import { Cesium3DTileset, Model } from "cesium";
import { useEffect } from "react";
import { useState } from "react";

/**
 * Custom hook for fetching and updating the list of assets
 * @param { Viewer } viewer Cesium viewer
 * @return { { assetList: Array, setAssetList: Function } } List of assets and setter function to update assets list
 */
const useAssetsList = (viewer) => {
  // State that keeps list of assets in Scene .
  const [assetList, setAssetList] = useState([]);

  // Iterates through primtives in scene and loads them into assetList state
  useEffect(() => {
    var _assetsList = [];
    try {
      for (const primitive of viewer.scene.primitives._primitives) {
        if (primitive instanceof Cesium3DTileset) {
          _assetsList.push({
            name: primitive.extras.name,
            id: primitive.extras.id,
            show: primitive.show,
          });
        } else if (primitive instanceof Model) {
          _assetsList.push({
            name: primitive.credit._html,
            id: primitive.id,
            show: primitive.show,
          });
        }
      }
      setAssetList(_assetsList);
    } catch (error) {
      console.log("Error on reading assets");
    }
  }, []);

  return { assetList, setAssetList };
};

export default useAssetsList;
