// @ts-nocheck
/**
 * Functional Component: LoadNewAsset.jsx
 *
 * Author: Ankit Jangid + Thwisha Kotian
 */

import { Resource } from "cesium";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import resourceLoader from "../../../../../../utils/resourceLoader";
import loadNew3DTile from "./components/loadNew3DTile";
import loadNewCZML from "./components/loadNewCZML";

/**
 * @description  `Component`: Loads new assets into a Cesium.js viewer.
 * It detects the asset type and URL using resourceLoader and handles the integration of 3D models or projects.
 * @param { Viewer } viewer Cesium Viewer
 * @param { Function } handleResourceInFocus Sets the newly loaded resource into focus
 * @param { Object } resourceToLoad Selected asset from the drop down list
 * @param { Object } reloadResource TODO : to be reviewed + add comments for remaining params -Thwisha
 */
const LoadNewAsset = ({
  viewer,
  handleResourceInFocus,
  resourceToLoad,
  reloadResource,
  setAssetAdded,
  setAssetFailed,
  setAssetDropDown,
  insertToCurrLoc,
}) => {
  // .. Loads asset selected from drop down to viewer ..
  useEffect(() => {
    const jwtToken = secureLocalStorage.getItem("token");
    if (resourceToLoad) {
      const { resourceUrl, resourceType, gltfPath } =
        resourceLoader(resourceToLoad);

      const resourceObj = (resourceSpecificUrl) => {
        return new Resource({
          url: resourceSpecificUrl,
          headers: {
            Authorization: "JWT " + jwtToken,
          },
        });
      };
      // .. condition won't be true, until subprojects are supported ..
      if (resourceType === "Project") {
        const projectUrl = resourceUrl + "?file_id=P" + resourceToLoad.id;
        loadNewCZML(
          viewer,
          resourceObj(projectUrl),
          gltfPath,
          handleResourceInFocus,
          resourceToLoad,
          setAssetFailed
        );
      } else if (resourceType === "3D Tile") {
        console.log("Resoiue Asse :", resourceUrl);
        const assetUrl = resourceUrl + "?file_id=A" + resourceToLoad.id;
        loadNew3DTile(
          viewer,
          resourceObj(assetUrl),
          handleResourceInFocus,
          resourceToLoad,
          insertToCurrLoc,
          setAssetAdded,
          setAssetFailed,
          setAssetDropDown
        );
        // .. For asset is a czml gltf ..
      } else {
        const gltfAbPath = gltfPath + "?file_id=A" + resourceToLoad.id;
        const assetUrl = resourceUrl + "?file_id=A" + resourceToLoad.id;
        loadNewCZML(
          viewer,
          resourceObj(assetUrl),
          gltfAbPath,
          insertToCurrLoc,
          resourceToLoad,
          handleResourceInFocus,
          setAssetAdded,
          setAssetFailed,
          setAssetDropDown
        );
      }
    }
  }, [resourceToLoad, reloadResource]);
};

export default LoadNewAsset;
