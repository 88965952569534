import React, { useState } from 'react'
import './forgotPassword.css'
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import logo from '../../../../assets/3D_logo.png'

const ForgotPassword = () => {
    const [email, setEmail] = useState(null)
    const [invalidEmail, setInvalidEmail] = useState(null)
    const [validEmail, setValidEmail] = useState(null)

    const jwtToken = secureLocalStorage.getItem("token");
    const navigation = useNavigate();

    /**
    * Sends email to the user to reset the password.
    * @param {Object} e - Event object (Group name)
    */
    const handleResetPassword = (e) => {
        e.preventDefault();
        console.log(email);
        const userDetails = JSON.stringify({
            email: email,
            is_activation: false,
        });

        fetch(process.env.REACT_APP_API + "/auth/users/reset_password/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + jwtToken,
            },
            body: userDetails,
        })
            .then((json) => {
                console.log(json);
                if (json.status === 204) {
                    console.log("reset link sent")
                    setValidEmail(true)
                    setTimeout(() => {
                        setValidEmail(false)
                        navigation("/");
                    }, 2000)
                } else if (json.status === 400) {
                    setInvalidEmail(true)
                    setTimeout(() => {
                        setInvalidEmail(false)
                    }, 5000);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <div>
            <div className='forgotPassword_main-container'>
                <div className='forgotPassword_logo'>
                    <h1>3</h1>
                    <img id="header_logo" src={logo} />
                    <h1>Twin</h1>
                </div>

                <div className='forgotPassword_content-container'>
                    <h1>Forgot Password</h1>
                    <p id='forgotPassword_content-text'>Enter the email with your account and we'll send an email to reset  your password</p>
                    <div className='confirmPassword_current-password'>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            id='confirmPassword_current-password-input'
                            type="email"
                            placeholder='Enter your email:'
                            maxLength={40} />
                    </div>
                    {invalidEmail ? (<p id='forgotPassword_invalid-email'>Enter a valid email address</p>) : ("")}
                    <div className='forgotPassword_verify-btn-container'>
                        <div
                            className={validEmail ? 'forgotPassword_verify-btn disabled' : 'forgotPassword_verify-btn'}
                            onClick={handleResetPassword}
                        >
                            <p>Send</p>
                        </div>
                    </div>
                    {validEmail ? <p id='forgotPassword_email-sent'>A password reset link has been successfully sent to your email.</p> : ""}
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword