// @ts-nocheck
/**
 * Component: `visioWindow.jsx`
Description:
    Designed for visualizing and interacting with 3D assets and geospatial data. Dynamically loads and previews different 
    types of resources, such as 3D models, CZML data, and 3D Tiles, based on the selected resource's file extension. 
    Includes a side-bar that can switch between "Editor" and "Viewer" modes, catering to different user roles (e.g., Tenant, Editor). 
    Provides quick access to essential features and tools. The top bar offers options to toggle the asset dropdown and switch between 
    editor and viewer modes. Resource Focus: Allows users to focus on specific resources within the 3D environment. Resource focus allows 
    users to focus on specific resources within the 3D environment. Users can interact with and edit 3D assets. This includes adding, 
    modifying, and deleting project entities, as well as making changes to the 3D model's properties. The component handles user authentication 
    and user-level access control, ensuring that certain features are available only to authorized users.


Author: Ankit Jangid

 */

// ... React Imports ...
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// ... NPM Packages ...
import {
  BingMapsImageryProvider,
  BingMapsStyle,
  Cartesian3,
  JulianDate,
  Resource,
  Terrain,
  Viewer,
  defined,
} from "cesium";
import secureLocalStorage from "react-secure-storage";
import useWebSocket from "react-use-websocket";

// ... Components ...
import AssetDropDown from "./components/assetDropDown/assetDropDown";
import Editor from "./components/editor/editor";
import LoadInitialResource from "./components/loadInitialResource/loadInitialResource";
import ResourceFocus from "./components/resourceFocus/resourceFocus";
import EditorSideBar from "./components/sidebar/editorSidebar";
import ViewerSideBar from "./components/sidebar/viewerSidebar";
import TopBar from "./components/topBar/topBar";
import ViewerInterface from "./components/viewer/viewerInterface";
import ZoomPanel from "./components/zoomPanel/zoomPanel";
import ViewerControl from "./components/viewerControl/viewerControl";

// ... Misc ...
import visioTwin_logo from "../../assets/3D_logo.png";
import resourceLoader from "../../utils/resourceLoader";
import TimelineViewer from "./components/features/TimelineViewer";
import SplitView from "./components/viewer/viewerFunctions/SplitView";
import "./visioWindow.css";
import { createContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import { PropagateLoader } from "react-spinners";

// Creating and exporting the project date context
export const ProjectDateContext = createContext();

/**
 * @description `Page`: A comprehensive page for visualizing and interacting with diverse 3D and geospatial resources.
 * It incorporates a Cesium-based 3D viewer, offers dynamic loading and previewing of various resource types,
 * supports user authentication and access control, features a flexible sidebar for "Editor" and "Viewer" modes,
 * provides asset management capabilities with an asset dropdown, and enables 3D asset editing in "Editor" mode.
 * @category VisioWindow
 * @class
 * @returns {JSX.Element} Rendered Visio Window page.
 */
const VisioWindow = () => {
  // ... State hook declarations ...
  const [resourceInFocus, setResourceInFocus] = useState();
  const [gltfURL, setGLTFURL] = useState(null);
  const [updateTimeline, setUpdateTimeline] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  // .. Viewer hooks ..
  const [viewer, setViewer] = useState(null);
  const [resourceObj, setResourceObj] = useState(null);
  const [resourceType, setResourceType] = useState(null);
  const [editorMode, setEditorMode] = useState(null);
  const [geoAlt, setGeoAlt] = useState(null);

  // .. TODO : state variable used for toggling between fullscreen, to be named appropriately ..
  const [isVisible, setIsVisible] = useState(true);
  const [splitViewEnabled, setSplitViewEnabled] = useState(false);

  // .. Editor hooks ..
  const [assetDropDown, setAssetDropDown] = useState(false);
  const [editorFeature, setEditorFeature] = useState(-1);
  const [viewerFeature, setViewerFeature] = useState(-1);
  const [deviceList, setDeviceList] = useState(false);
  const [project, setProject] = useState(null)
  const [requestSent, setRequestSent] = useState(false);
  const [websocketData, setWebsocketData] = useState(null)

  // .. Other hooks ..
  const location = useLocation();
  const [timelineOpen, setTimelineOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [packetElementKey, setPacketElementKey] = useState([]);
  const [packetElementId, setPacketElementId] = useState([]);
  const [packetElementEntityId, setPacketElementEntityId] = useState([])
  const [packetElementEntityType, setPacketElementEntityType] = useState([])
  const [selectedLabel, setSelectedLabel] = useState(null)
  const [labelKey, setLabelKey] = useState(null);
  const [labelValue, setLabelValue] = useState(null);

  // ... Constants ...
  const userLevel = secureLocalStorage.getItem("userLevel");
  const selectedResource = location.state.selectedResource;
  const selectedResourceType = location.state.selectedItemType;
  const token = location.state.jwtToken;
  const jwtToken = secureLocalStorage.getItem("token");
  const packetElement_ids = [];
  let receivedData = {};
  const wsUrl = "wss://dev.thingspad.io/api/ws/plugins/telemetry";
  const wsWithToken = `${wsUrl}?token=${token}`;

  // Establishes the web socket connection
  const {
    sendMessage
  } = useWebSocket(wsWithToken, {
    onOpen: () => {
      console.log("WebSocket opened");
    },
    onMessage: (event) => {
      var received_msg = event.data;
      // console.log("Message is received: " + received_msg);
      const parsedData = JSON.parse(received_msg);

      if (parsedData) {
        const data = parsedData.data;
        setWebsocketData(data)
        for (const key of Object.keys(data)) {
          receivedData[key] = data[key];
        }

        // If an asset is selected then update the values of the saved annotations on the selected asset.
        if (project) {
          for (let i = 0; i < packetElementKey.length > 0; i++) {
            if (packetElementKey[i] in data) {
              const headingElement = document.getElementById(packetElementId[i]);
              if (headingElement) {
                const heading = headingElement.querySelector(`.annotation-heading`);
                heading.innerHTML = packetElementKey[i] + ':' + receivedData[packetElementKey[i]][0][1];
                setLabelValue(receivedData[packetElementKey[i]][0][1])
              }
            }
          }
          receivedData = {};
        }
      }
    },
    onClose: (event) => {
      console.log("Connection is closed!");
    },
    shouldReconnect: (closeEvent) => true,
  });


  // ... Local Functions ...
  /**
   * Sets a selected resource in focus (state - setter) for property manipulation via editing functionalities.
   * @param {Entity, Cesium3DTileset} resource Cesium Entity or a Cesium3DTileset
   */
  const handleResourceInFocus = (resource) => {
    setResourceInFocus(resource);
  };

  // ... re-renderTimeline ..
  const handleUpdateTimeline = () => {
    setUpdateTimeline(!updateTimeline);
  };

  /**
   * Handles 'refreshData' state asset addition from  dropdown list. Notifies Viewer features to update state via render
   */
  const handleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  /**
   * Toggles Add Asset dropdown list
   */
  const handleAssetDropDown = () => {
    setAssetDropDown(!assetDropDown);
  };

  /**
   * Toggle Editor Mode
   * @param {Boolean} editorMode
   */
  const handleEditorModeChange = (editorMode) => {
    setEditorMode(editorMode);
    setDeviceList(false);
  };

  /**
   *
   * @param {*} value
   */
  const handleSplitViewEnabled = (value) => {
    if (value) {
      document.getElementById("visioContainer").style.width = "50%";
      document.getElementsByClassName("visioWindow_top-bar")[0].style.right =
        "50%";
    }
    // .. Disable empty side panels ..
    setViewerFeature(-1);
    setEditorFeature(-1);
    setSplitViewEnabled(value);
    setDeviceList(false);
  };

  /**
   * Updates currently under focus editor feature
   * @param {Number} selectedFeature in focus selected feature
   */
  const handleEditorFeatureChange = (selectedFeature) => {
    console.log("EDT  : ", selectedFeature);
    setEditorFeature(selectedFeature);
    setDeviceList(false);
  };

  /**
   * Opens the Thingspad device panel
   */
  const handleDeviceList = () => {
    setDeviceList(!deviceList);
    setViewerFeature(-1);
    setEditorFeature(-1);
  };

  /**
   * Updates currently under focus viewer feature
   * @param {Number} selectedFeature in focus selected feature
   */
  const handleViewerFeatureChange = (selectedFeature) => {
    setViewerFeature(selectedFeature);
    setDeviceList(false);
  };

  /**
   * Toggles sibebar state variable
   */
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleGeoAlt = (camHeight) => {
    setGeoAlt(camHeight);
  };

  /**
   * Callback function to handle the selection of a project from the section view
   * @param {Object} project - The selected project
   */
  const handleProject = (project) => {
    setProject(project);
  }


  // .. Initialize cesium viewer ..
  useEffect(() => {
    // .. Cesium access token (for using cesium Terrain)..
    Cesium.Ion.defaultAccessToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1ZDdmZWViMS0xZDVjLTRhYTUtYWI4Yy0yOGFkZmI4YjNmZGIiLCJpZCI6NTI3ODIsImlhdCI6MTYyMjU1NTY4M30.2Qry6HDCock7YrMi8GQaStykhvfbCfuJ-TDs7IRglmY";

    // ..  Viewer setup ..
    const viewer = new Viewer("visioContainer", {
      // . default terrain set to cesium's terrain .
      terrain: Terrain.fromWorldTerrain(),
      geocoder: false,
      baseLayerPicker: false,
      // globe: false,
    });

    // .. Enable FXAA for anti-aliasing ..
    viewer.scene.postProcessStages.fxaa.enabled = true;

    // ..  Enables tileset and point cloud editing (provided by default by CesiumJS)..
    // viewer.extend(Cesium.viewerCesium3DTilesInspectorMixin);

    // .. Defualt Imagery and Terrain Provider ..
    const imageryTerrainLoader = async () => {
      // .. ArcGIS Terrain Provider ..
      const terrainProvider =
        await Cesium.ArcGISTiledElevationTerrainProvider.fromUrl(
          "https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer",
          {
            token:
              "AAPK2099f81e64c6478c8922b18a20b89895bHIYvtbs39mvnK6gwF8TM7oYzkvYPu9O5sSFldOBkqBP17BKH9uT2DmujnIc1vkt",
          }
        );
      // .. To enable arc gis terrain ..
      // viewer.terrainProvider = terrainProvider;

      // .. Default imagery by BingMaps - ROAD .. (more options available)
      const bing = await BingMapsImageryProvider.fromUrl(
        "https://dev.virtualearth.net",
        {
          key: "AmJAP16ARM2JD-U91lhBL3OxkLMrRpllxjMh1a--OYg9Yh2mgL-3qxjdpWKXg3M0",
          mapStyle: BingMapsStyle.AERIAL,
        }
      );
      viewer.scene.imageryLayers.addImageryProvider(bing);

      // .. To enable OSM Buildings loading ..
      // const osmBuildingsTileset = await Cesium.createOsmBuildingsAsync();
      // viewer.scene.primitives.add(osmBuildingsTileset);
    };
    imageryTerrainLoader();

    // .. OSM Imagery Provider ..
    // const osmImageryProvider = new OpenStreetMapImageryProvider({
    //   url: "https://a.tile.openstreetmap.org/",
    // });
    // viewer.scene.imageryLayers.addImageryProvider(osmImageryProvider);

    // .. Camera settings ..
    const cameraController = viewer.scene.screenSpaceCameraController;
    cameraController.inertiaSpin = 0.6;
    cameraController.inertiaTranslate = 0.6;
    cameraController.inertiaZoom = 0.6;
    // cameraController.enableCollisionDetection = false;

    // .. Performance display..
    viewer.scene.debugShowFramesPerSecond = false;

    // .. Disable model info window ..
    viewer.infoBox.destroy();

    // .. Event listener to adjust the annotations position with camera movement ..
    viewer.scene.preRender.addEventListener(function () {
      viewer?.entities?.values.forEach((entity) => {
        if (
          entity.id.toString().startsWith("ANNT") ||
          entity.id.toString().startsWith("RTA")
        ) {
          // . Converting cartesian position to canvas position and assigning to the overlay element .
          const canvasPosition = viewer?.scene?.cartesianToCanvasCoordinates(
            entity.position.getValue(JulianDate.now())
          );
          const distance = Cartesian3.distance(
            viewer.scene.camera.position,
            entity.position.getValue(JulianDate.now())
          );
          if (defined(canvasPosition)) {
            const annotation = document.getElementById(entity.id);
            annotation.style.top = `${canvasPosition.y}px`;
            annotation.style.left = `${canvasPosition.x}px`;
            if (distance > 800) annotation.style.scale = 0;
            else annotation.style.scale = 1.0 - 0.1 * (distance / 100);
          }
        }
      });
    });

    // .. Set viewer state variable ..
    setViewer(viewer);

    // .. Destructuring resourceLoader to get returned array elements ..
    const { resourceUrl, resourceType, gltfPath } =
      resourceLoader(selectedResource);

    // .. Build a Resource object for resource loading ..
    const resourceObject = (resourceSpecificUrl) => {
      return new Resource({
        url: resourceSpecificUrl,
        headers: {
          Authorization: "JWT " + jwtToken,
        },
      });
    };

    // .. Resource type check and setting appropriate states accordingly ..
    if (resourceType === "Project") {
      const projectUrl = resourceUrl + "?file_id=P" + selectedResource.id;
      setResourceType("project");
      console.log(projectUrl);
      setResourceObj(resourceObject(projectUrl));
    } else if (resourceType === "3D Tile") {
      const assetUrl = resourceUrl + "?file_id=A" + selectedResource.id;
      setResourceType("3DTile");
      setResourceObj(resourceObject(assetUrl));
    } else {
      // .  In case of GLTF, absolute path for GLTF has to be constructed .
      const gltfAbPath = gltfPath + "?file_id=A" + selectedResource.id;
      const assetUrl = resourceUrl + "?file_id=A" + selectedResource.id;
      setResourceType("georefGLTF");
      setGLTFURL(gltfAbPath);
      setResourceObj(resourceObject(assetUrl));
    }

    // .. Destroy Viewer upon component dis-mounting ..
    return () => {
      try {
        viewer.entities.removeAll();
        viewer.scene.primitives.removeAll();
        viewer.destroy();
      } catch (error) {
        console.log("Error during destroying viewer : ", error);
      }
    };
  }, []);

  useEffect(() => {
    if (resourceObj) {
      loadJSONProject(resourceObj);
    }
    if (viewer) {
      const camHeight = viewer.camera.positionCartographic.height.toFixed(2);
      handleGeoAlt(camHeight);
    }
  }, [geoAlt, viewer, resourceObj]);

  // Function to load a project from a JSON file and add its elements to the scene
  const loadJSONProject = async (projectResource) => {

    let selectedElement = null;

    // Function to handle single click (show/hide deviceList)
    const handleSingleClick = (element) => {
      if (selectedElement === element) {
        // Clicked on the same element, hide deviceList
        setDeviceList(null);
        selectedElement = null;
      } else {
        // Clicked on a different element, show deviceList
        setDeviceList(element);
        setViewerFeature(-1);
        setEditorFeature(-1);
        selectedElement = element;
        console.log(selectedElement.name)
        setSelectedLabel(element.name)
        console.log(element.selectedKey)
        setLabelKey(element.selectedKey)
      }
    };

    // Function to handle double click (always hide deviceList)
    const handleDoubleClick = () => {
      setDeviceList(null);
      selectedElement = null;
    };

    // .. Flush all Primitives ..

    // ...  Get the project JSON file ...
    try {
      const response = await fetch(projectResource.url, {
        method: "GET",
        headers: projectResource.headers,
      });
      if (!response.ok) {
        throw new Error("Error while fetching Tileset");
      }
      const projectData = await response.json();
      const packetElement_keys = [];
      const packetElement_entityType = [];
      const packetElement_entityId = [];

      // .. Read through for packet-wise Primitive addition to scene ..
      for (let i = 0; i < projectData.length; i++) {
        // . Cesium3DTileset primitive parsing .
        const packetElement = projectData[i];
        if (packetElement.id.toString().startsWith("RTA")) {
          const annotationEntity = viewer.entities.add({
            ...packetElement,
            position: Cartesian3.fromArray(packetElement.position.cartesian),
          });

          const element = new DOMParser()
            .parseFromString(packetElement.annotationIcon, "text/html")
            // Get the first element with either class
            .querySelector(".devices-htmlOverlay, .annotation-htmlOverlay");

          element.name = packetElement.name;
          element.selectedKey = packetElement.selectedKey;

          if (element) {

            element.addEventListener('click', () => handleSingleClick(element));
            element.addEventListener('dblclick', handleDoubleClick);

            if (element.classList.contains("devices-htmlOverlay")) {
              // Remove the class devices-htmlOverlay
              element.classList.remove("devices-htmlOverlay");
              // Add the class annotation-htmlOverlay
              element.classList.add("annotation-htmlOverlay");
            }
            document.getElementsByClassName("visioWindow_visioContainer")[0].appendChild(element);
          } else {
            console.error("Element with class 'devices-htmlOverlay' or 'annotation-htmlOverlay' not found in the collection");
          }

          packetElement_keys.push(packetElement.selectedKey);
          setPacketElementKey(packetElement_keys)

          packetElement_ids.push(packetElement.id)
          setPacketElementId(packetElement_ids)

          packetElement_entityId.push(packetElement.entityId)
          setPacketElementEntityId(packetElement_entityId)

          packetElement_entityType.push(packetElement.entityType)
          setPacketElementEntityType(packetElement_entityType)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Sends web socket request for all the saved annotations and updates the values on the annotations.
  const requests = [];
  if (packetElementEntityId.length > 0 && packetElementEntityType.length > 0 && project) {
    if (!requestSent) {
      // Sends a batched request for all the saved annotations
      for (let i = 0; i < packetElementEntityId.length; i++) {
        requests.push({
          entityType: packetElementEntityType[i],
          entityId: packetElementEntityId[i],
          scope: "LATEST_TELEMETRY",
          cmdId: 10,
        });
      }
      const requestData = JSON.stringify({ tsSubCmds: requests, historyCmds: [], attrSubCmds: [] });
      sendMessage(requestData);
      setRequestSent(true)
    }
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner={<PropagateLoader color="#9EA940" />}
      text="Initializing project..."
    >
      <div
        className="visioWindow_visioViewer"
        onClick={() => assetDropDown && setAssetDropDown(false)}
      >
        {isVisible && (
          <div className="visioWindow_side-bar">
            <img src={visioTwin_logo} alt="VT" className="visioWindow_logo" />

            {/* Based on user level set accesibility for EditorSideBar */}
            {userLevel === "Tenant" || userLevel === "Editor" ? (
              editorMode ? (
                <EditorSideBar
                  resourceType={resourceType}
                  editorFeature={editorFeature}
                  handleEditorFeatureChange={handleEditorFeatureChange}
                  handleSplitViewEnabled={handleSplitViewEnabled}
                  setTimelineOpen={setTimelineOpen}
                  timelineOpen={timelineOpen}
                  handleDeviceList={handleDeviceList}
                  deviceList={deviceList}
                />
              ) : (
                <ViewerSideBar
                  resourceType={resourceType}
                  viewerFeature={viewerFeature}
                  handleSplitViewEnabled={handleSplitViewEnabled}
                  handleViewerFeatureChange={handleViewerFeatureChange}
                  setTimelineOpen={setTimelineOpen}
                  timelineOpen={timelineOpen}
                  handleDeviceList={handleDeviceList}
                  deviceList={deviceList}
                  setDeviceList={setDeviceList}
                />
              )
            ) : (
              <ViewerSideBar
                handleSplitViewEnabled={handleSplitViewEnabled}
                handleViewerFeatureChange={handleViewerFeatureChange}
                setTimelineOpen={setTimelineOpen}
                timelineOpen={timelineOpen}
              />
            )}
          </div>
        )}
        <div className="visioWindow_top-bar">
          <TopBar
            resourceType={resourceType}
            handleAssetDropDown={handleAssetDropDown}
            handleEditorModeChange={handleEditorModeChange}
            editorMode={editorMode}
            viewer={viewer}
            splitViewEnabled={splitViewEnabled}
            toggleVisibility={toggleVisibility}
            isVisible={isVisible}
            setAssetDropDown={setAssetDropDown}
            selectedResourceType={selectedResourceType}
            selectedResource={selectedResource}
            resourceObj={resourceObj}
            setDeviceList={setDeviceList}
          />
        </div>

        {/* Toggle Accesibility for uploading asset into Editor*/}
        {assetDropDown && (
          <div className="visioWindow_asset-dropDown">
            <AssetDropDown
              editorMode={editorMode}
              viewer={viewer}
              selectedResource={selectedResource}
              handleResourceInFocus={handleResourceInFocus}
              handleRefreshData={handleRefreshData}
              setAssetDropDown={setAssetDropDown}
            />
          </div>
        )}

        {/* Setting Title for loaded resource */}
        {/* {!splitViewEnabled && (
        <div className="visioWindow_resource-title">
          <ResourceTitle
            viewer={viewer}
            selectedResource={selectedResource}
            resourceInFocus={resourceInFocus}
            editorMode={editorMode}
            handleEditorFeatureChange={handleEditorFeatureChange}
            resourceObj={resourceObj}
            selectedResourceType={selectedResourceType}
          />
        </div>
      )} */}

        {/* {viewer && <ZoomPanel viewer={viewer} />}

      {viewer && <ViewerControl viewer={viewer} />} */}

        <div className="visioWindow_visioContainer">
          <div id="visioContainer"></div>
          {splitViewEnabled && (
            <SplitView
              viewer={viewer}
              refreshData={refreshData}
              handleSplitViewEnabled={handleSplitViewEnabled}
              isVisible={isVisible}
            />
          )}
        </div>

        {/* Once Cesium Viewer is available, allow components */}
        {viewer && (
          <ProjectDateContext.Provider value={selectedResource}>
            <ZoomPanel
              viewer={viewer}
              geoAlt={geoAlt}
              handleGeoAlt={handleGeoAlt}
            />
            {/* <ViewerControl viewer={viewer} /> */}

            <LoadInitialResource
              selectedResource={selectedResource}
              handleResourceInFocus={handleResourceInFocus}
              gltfURL={gltfURL}
              resourceType={resourceType}
              resourceObj={resourceObj}
              viewer={viewer}
              setLoading={setLoading}
            />
            <ResourceFocus
              viewer={viewer}
              selectedResource={selectedResource}
              handleResourceInFocus={handleResourceInFocus}
              resourceInFocus={resourceInFocus}
              selectedResource={selectedResource}
              geoAlt={geoAlt}
              handleGeoAlt={handleGeoAlt}
            />

            {(userLevel === "Tenant" || userLevel === "Editor") &&
              editorMode ? (
              <div className={(deviceList) ? "" : "feature-panel"}>
                <Editor
                  resourceObj={resourceObj}
                  selectedResource={selectedResource}
                  resourceInFocus={resourceInFocus}
                  resourceType={resourceType}
                  editorFeature={editorFeature}
                  setEditorFeature={setEditorFeature}
                  viewer={viewer}
                  isVisible={isVisible}
                  handleUpdateTimeline={handleUpdateTimeline}
                  deviceList={deviceList}
                  token={token}
                  packetElementKey={packetElementKey}
                  packetElementId={packetElementId}
                  handleProject={handleProject}
                  project={project}
                  sendMessage={sendMessage}
                  websocketData={websocketData}
                  selectedLabel={selectedLabel}
                  labelKey={labelKey}
                />
              </div>
            ) : (
              <div className={(deviceList) ? "" : "feature-panel"}>
                <ViewerInterface
                  refreshData={refreshData}
                  resourceInFocus={resourceInFocus}
                  resourceType={resourceType}
                  viewer={viewer}
                  viewerFeature={viewerFeature}
                  setViewerFeature={setViewerFeature}
                  handleViewerFeatureChange={handleViewerFeatureChange}
                  isVisible={isVisible}
                  deviceList={deviceList}
                  token={token}
                  packetElementKey={packetElementKey}
                  packetElementId={packetElementId}
                  handleProject={handleProject}
                  project={project}
                  sendMessage={sendMessage}
                  websocketData={websocketData}
                  selectedLabel={selectedLabel}
                  labelKey={labelKey}
                  labelValue={labelValue}
                />
              </div>
            )}
          </ProjectDateContext.Provider>
        )}
        {timelineOpen && (
          <ProjectDateContext.Provider value={selectedResource}>
            <TimelineViewer
              viewer={viewer}
              updateTimeline={updateTimeline}
              editorMode={editorMode}
              handleEditorFeatureChange={handleEditorFeatureChange}
            />
          </ProjectDateContext.Provider>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default VisioWindow;
