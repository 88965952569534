// @ts-nocheck
import React, { useState, useEffect } from "react";
import "./projectManager.css";
import Header from "../../components/header/header";
import UserList from "./components/userList/userList";
import ProjectDetails from "./components/projectDetails/projectDetails";
import UserGroupList from "./components/userGroupList/userGroupList";
import SuccessFailurePopup from "./components/successFailurePopup/successFailurePopup";
import jwt_decode from "jwt-decode";
import secureLocalStorage from "react-secure-storage";
import { useLocation } from "react-router-dom";

/**
 * @description `Page`: A component exclusively used for Project Management based on projects, user accounts and groups
 * @category Project Manager
 * @returns {JSX.Element} Rendered project manager page.
 */
const ProjectManager = () => {
  const [getData, setData] = useState([]);
  const [getGroups, setGroups] = useState([]);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [assign, setAssign] = useState(false);
  const [failureMessage, setFailureMessage] = useState("");
  const [failure, setFailure] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [usersLength, setUsersLength] = useState(null);
  const [groupsLength, setGroupsLength] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [assignedGroups, setAssignedGroups] = useState([]);

  const jwtToken = secureLocalStorage.getItem("token");
  const userId = jwt_decode(jwtToken).user_id;
  const [headerUserDetails, setHeaderUserDetails] = useState({
    username: "",
    email: "",
  });
  const location = useLocation();
  let selectedResourceName = location.state.selectedResource;
  let selectedResourceId = location.state.selectedItemId;
  let userLevel = location.state.user;
  let selectedResourceType = location.state.resourceType;
  let resourceUsers = location.state.users;
  let resourceGroups = location.state.groups;

  useEffect(() => {
    if (resourceUsers) {
      const updatedAssignedUsers = resourceUsers.filter(
        (user) => assignedUsers.includes(user.id)
      );
      setAssignedUsers(updatedAssignedUsers);
    }

    if (resourceGroups) {
      const updatedAssignedGroups = resourceGroups.filter(
        (group) => assignedGroups.includes(group.id)
      );
      setAssignedGroups(updatedAssignedGroups);
    }
  }, [resourceUsers, resourceGroups]);

  /**
   * ProjectManager useEffect hook.
   */
  useEffect(() => {
    fetchAccountDetails(userId);
    if (selectedResourceType === "project") {
      fetchList();
    } else if (selectedResourceType === "asset") {
      fetchAssetList();
    }
  }, [userId, selectedResourceType]);

  /**
   * Makes GET request to the server to retrieve account details.
   * @param {number} userId
   */
  const fetchAccountDetails = (userId) => {
    fetch(
      process.env.REACT_APP_API + "/accounts/master_account_details/" + userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (json.code == 100) {
          const userDetails = {
            username: json.data[0].username,
            email: json.data[0].email,
          };
          setHeaderUserDetails(userDetails);
          console.log(userDetails.username);
        }
        if (json.detail) {
          console.log("CODE51");
          const userDetails = {
            username: "undefined",
            email: "undefined",
          };
          setHeaderUserDetails(userDetails);
        }
      });
  };

  /**
   * Function to fetch a list of users and groups for a selected project.
   */
  const fetchList = () => {
    fetch(
      process.env.REACT_APP_API +
      "/project/project_details/" +
      userId +
      "/" +
      selectedResourceId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (Object.keys(json.data).length > 0) {
          setData(json.data[0].users);
          setGroups(json.data[0].groups);
          console.log(json.data[0].users);
          setUsersLength(json.data[0].users.length)
          setGroupsLength(json.data[0].groups.length)
        }
        if (json.detail == "Authentication credentials were not provided.") {
          console.log("User has no projects, data : ", json.data);
        } else {
        }
      });
  };

  /**
   * Function to fetch a list of users and groups for a selected asset.
   */
  const fetchAssetList = () => {
    fetch(
      process.env.REACT_APP_API +
      "/project/asset_details/" +
      userId +
      "/" +
      selectedResourceId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + jwtToken,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (Object.keys(json.data).length > 0) {
          setData(json.data[0].users);
          setGroups(json.data[0].groups);
          console.log(json.data[0].groups);
          setUsersLength(json.data[0].users.length)
          setGroupsLength(json.data[0].groups.length)
        }
        if (json.detail == "Authentication credentials were not provided.") {
          console.log("User has no projects, data : ", json.data);
        } else {
        }
      });
  };

  /**
   * Function to assign a user to a selected project.
   */
  const handleAssignUserToProject = () => {
    console.log(selectedResourceId);
    console.log(selectedItemID);
    const userDetails = JSON.stringify({
      project_id: selectedResourceId,
      user_id: selectedItemID,
    });

    fetch(process.env.REACT_APP_API + "/project/assign_project_user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: userDetails,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setAssign(false);
        fetchList();
        setAssignedUsers([...assignedUsers, { id: selectedItemID }]);
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Function to assign a group to a selected project.
   */
  const handleAssignGroupToProject = () => {
    console.log(selectedResourceId);
    console.log(selectedItemID);
    const groupDetails = JSON.stringify({
      project_id: selectedResourceId,
      group_id: selectedItemID,
    });

    fetch(process.env.REACT_APP_API + "/project/assign_project_group", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: groupDetails,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setAssign(false);
        fetchList();
        setAssignedGroups([...assignedGroups, { id: selectedItemID }]);
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Function to assign a group to a selected asset.
   */
  const handleAssignGroupToAsset = () => {
    console.log(selectedResourceId);
    console.log(selectedItemID);
    const groupDetails = JSON.stringify({
      asset_id: selectedResourceId,
      group_id: selectedItemID,
    });

    fetch(process.env.REACT_APP_API + "/project/assign_asset_group", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: groupDetails,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setAssign(false);
        fetchAssetList();
        setAssignedGroups([...assignedGroups, { id: selectedItemID }]);
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  /**
   * Function to assign a user to a selected asset.
   */
  const handleAssignUserToAsset = () => {
    console.log(selectedResourceId);
    console.log(selectedItemID);
    const groupDetails = JSON.stringify({
      asset_id: selectedResourceId,
      user_id: selectedItemID,
    });

    fetch(process.env.REACT_APP_API + "/project/assign_asset_user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + jwtToken,
      },
      body: groupDetails,
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setAssign(false);
        fetchAssetList();
        setAssignedUsers([...assignedUsers, { id: selectedItemID }]);
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  };

  return (
    <div>
      <Header headerUserDetails={headerUserDetails} />

      <ProjectDetails selectedResourceName={selectedResourceName} />

      <div className="projectManager_list-container">
        <UserList
          userId={userId}
          getData={getData}
          setData={setData}
          selectedResourceType={selectedResourceType}
          resourceUsers={resourceUsers}
          resourceGroups={resourceGroups}
          handleAssignUserToAsset={handleAssignUserToAsset}
          handleAssignGroupToAsset={handleAssignGroupToAsset}
          handleAssignGroupToProject={handleAssignGroupToProject}
          handleAssignUserToProject={handleAssignUserToProject}
          assign={assign}
          setSelectedItemID={setSelectedItemID}
          setAssign={setAssign}
          selectedResourceId={selectedResourceId}
          fetchList={fetchList}
          fetchAssetList={fetchAssetList}
          setSuccess={setSuccess}
          setSuccessMsg={setSuccessMsg}
          setFailure={setFailure}
          setFailureMessage={setFailureMessage}
          usersLength={usersLength}
          assignedGroups={assignedGroups}
          assignedUsers={assignedUsers}
        />

        <UserGroupList
          userId={userId}
          getGroups={getGroups}
          setGroups={setGroups}
          selectedResourceType={selectedResourceType}
          setAssign={setAssign}
          selectedResourceId={selectedResourceId}
          fetchList={fetchList}
          fetchAssetList={fetchAssetList}
          setSuccess={setSuccess}
          setSuccessMsg={setSuccessMsg}
          setFailure={setFailure}
          setFailureMessage={setFailureMessage}
          groupsLength={groupsLength}
        />
      </div>

      <SuccessFailurePopup
        success={success}
        successMsg={successMsg}
        failure={failure}
        failureMessage={failureMessage}
      />
    </div>
  );
};

export default ProjectManager;
