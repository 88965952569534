// @ts-nocheck
import DocumentWriter from "./components/czmlWriter";
import { Cesium3DTileset, Entity, Matrix4, Model, JulianDate } from "cesium";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { useState } from "react";
import SuccessFailurePopup from "../../../../../projectDashboard/components/popups/successFailurePopup/successFailurePopup";

const SaveProject = ({ viewer, selectedResource }) => {
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [failure, setFailure] = useState(false);
  const [failureMessage, setFailureMsg] = useState("");
  const currentCesiumTime = viewer.clock.currentTime;
  const initiateProjectSave = () => {
    //console.log("Save PRJ : ", viewer.scene.primitives._primitives);
    const czmlName = selectedResource.project_name.replace(/\.[^/.]+$/, "");

    let cesium3DTilesets = [];
    let otherEntities = [];
    let models = [];

    // .. Run scene scan to set show true and build array for the primitives ..
    for (let i = 0; i < viewer.scene.primitives._primitives.length; i++) {
      const asset = viewer.scene.primitives._primitives[i];
      if (asset instanceof Cesium3DTileset) {
        asset.show = true;
        cesium3DTilesets.push(asset);
        // console.log("TS : ", asset);
      } else if (asset instanceof Model) {
        asset.show = true;
        models.push(asset);
      }
    }

    viewer.entities.values.forEach((entity) => {
      otherEntities.push(entity);
    });

    const sceneResources = cesium3DTilesets
      .concat(models)
      .concat(otherEntities);
    writeCZML(sceneResources, czmlName);
  };

  async function writeCZML(sceneResources, czmlName) {
    const w = new DocumentWriter();
    w.czmlDetails(czmlName);
    let numOfEntities;

    /* Saving image files to file storage and getting the urls */
    let imageFiles = [];
    let entitiesWithImages = [];
    let imageUrls = [];
    let existingImageIds = [];

    viewer.entities.values.forEach((entity) => {
      // Checking if it is a newly created annotation and if there is any image attached to it
      if (
        entity.id.toString().startsWith("ANNT") &&
        entity.imageId === undefined &&
        entity.descImagePath
      ) {
        imageFiles.push(entity.descImagePath);
        entitiesWithImages.push(entity.id);
      }
      // If an existing annotation has an image, appending to list
      else if (entity.id.toString().startsWith("ANNT") && entity.imageId)
        existingImageIds.push(entity.imageId);
    });

    // Creating form data and appending image files and respective entity ids
    var imageUploadData = new FormData();
    imageUploadData.append("project_id", selectedResource.id);
    for (const image of imageFiles) {
      imageUploadData.append("files", image);
    }
    imageUploadData.append("entity_ids", entitiesWithImages);

    const jwtToken = secureLocalStorage.getItem("token");

    // API call to save images
    entitiesWithImages.length > 0 &&
      (await axios
        .post(
          process.env.REACT_APP_API + "/project/save_images",
          imageUploadData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "JWT " + jwtToken,
            },
          }
        )
        .then((res) => {
          if (Array.isArray(res.data.data)) {
            imageUrls = res.data.data.map((obj) => {
              // Appending newly saved images to existing image ids list
              existingImageIds.push(obj.id);
              // Creating the image urls array for mapping
              return {
                entityId: obj.entity_id,
                imageUrl: obj.image_url,
                imageId: obj.id,
                imageName: obj.image_name,
              };
            });
          } else {
            console.error("res.data.data is not an array");
          }
        })
        .catch((err) => {
          setFailure(true);
          setFailureMsg(err.message);
          setTimeout(() => {
            setFailure(false);
            setFailureMsg("");
          }, 2000);
        }));

    // Creating the JSON file
    sceneResources.forEach((e) => {
      if (e.show) {
        w.addResource(e, imageUrls);
        // console.log("Entities found : " + numOfEntities);
        numOfEntities++;
      }
    });

    //converting obj to JSON
    const czmlObj = w.toJSON();
    console.log("CZML obj", czmlObj);

    //Download CZML
    // const czml =
    //   "data:text/json;charset=utf-8," +
    //   encodeURIComponent(JSON.stringify(czmlObj));

    // let link = document.createElement("A");
    // link.href = czml;
    // link.download = filename;
    // link.click();

    const str = JSON.stringify(czmlObj);
    const blob = new Blob([str]);

    var updatedCZML = new FormData();
    updatedCZML.append("id", selectedResource.id);
    updatedCZML.append("file", blob, selectedResource.project_name);
    updatedCZML.append("existing_images", existingImageIds);

    // Upload the new czml
    axios
      .post(
        process.env.REACT_APP_API + "/project/update_project",
        updatedCZML,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "JWT " + jwtToken,
          },
        }
      )

      .then((res) => {
        setSuccess(true);
        setSuccessMsg("Project saved successfully");
        setTimeout(() => {
          setSuccess(false);
          setSuccessMsg("");
        }, 3000);
      })
      .catch((error) => {
        console.error("Error : ", error);
        setFailure(true);
        setFailureMsg(err.message);
        setTimeout(() => {
          setFailure(false);
          setFailureMsg("");
        }, 3000);
      });

  }

  return (
    <>
      <div
        onClick={() => {
          initiateProjectSave();
        }}
        className="save_button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <mask
            id="mask0_2588_611"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2588_611)">
            <path
              d="M21 7V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H17L21 7ZM19 7.85L16.15 5H5V19H19V7.85ZM12 18C12.8333 18 13.5417 17.7083 14.125 17.125C14.7083 16.5417 15 15.8333 15 15C15 14.1667 14.7083 13.4583 14.125 12.875C13.5417 12.2917 12.8333 12 12 12C11.1667 12 10.4583 12.2917 9.875 12.875C9.29167 13.4583 9 14.1667 9 15C9 15.8333 9.29167 16.5417 9.875 17.125C10.4583 17.7083 11.1667 18 12 18ZM6 10H15V6H6V10Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
      <SuccessFailurePopup
        success={success}
        successMsg={successMsg}
        failure={failure}
        failureMessage={failureMessage}
      />
    </>
  );
};

export default SaveProject;
