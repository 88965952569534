// @ts-nocheck
import {
  ArcGISTiledElevationTerrainProvider,
  BingMapsImageryProvider,
  BingMapsStyle,
  EllipsoidTerrainProvider,
  OpenStreetMapImageryProvider,
  Terrain,
  TileMapServiceImageryProvider,
  buildModuleUrl,
} from "cesium";
import { useEffect, useState } from "react";

import ArcGIS from "../../../../assets/ArcGIS.png";
import bingAerial from "../../../../assets/bingAerial.png";
import bingRoad from "../../../../assets/bingRoad.png";
import cesiumT from "../../../../assets/cesiumT.png";
import defaultImageryImage from "../../../../assets/defaultImageryImage.png";
import noTerrain from "../../../../assets/noTerrain.png";
import openStreet from "../../../../assets/openStreet.png";
import createJapanGSITerrainProvider from "../japanTerrain/japanTerrain";

/**
 * Functional component that enables changing the imagery and terrain of the viewer
 * @param { Viewer } viewer Cesium viewer
 * @param { Int } activeImagery Number indicating the active imagery of the viewer
 * @param { Function } setActiveImagery Setter function to set the active imagery
 * @param { Int }  activeTerrain Number indicating the active terrain of the viewer
 * @param { Function } setActiveTerrain Setter function to set the active terrain of the viewer
 * @returns { JSX.Element } Imagery Provider component
 */
const ImageryProvider = ({
  viewer,
  activeImagery,
  setActiveImagery,
  activeTerrain,
  setActiveTerrain,
}) => {
  const [isImageryClicked, setIsImageryClicked] = useState(false);
  const [osmImageryAdded, setOsmImageryAdded] = useState(false);

  // Preloading imagery/terrain images
  useEffect(() => {
    const imageSrcs = [
      ArcGIS,
      bingAerial,
      bingRoad,
      cesiumT,
      defaultImageryImage,
      noTerrain,
      openStreet,
    ];

    async function loadImages() {
      const imagePromiseList = [];

      imageSrcs.forEach((imageSrc) => {
        imagePromiseList.push(
          new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(imageSrc);
            img.src = imageSrc;
          })
        );
      });

      await Promise.all(imagePromiseList);
    }
    loadImages();
  }, []);

  const handleImagery = () => {
    setIsImageryClicked(!isImageryClicked);
  };
  /**
   * Adds Open street imagery provider
   */
  const openStreetImagery = () => {
    const osmImageryProvider = new OpenStreetMapImageryProvider({
      url: "https://a.tile.openstreetmap.org/", //https://stamen-tiles.a.ssl.fastly.net/toner/
    });

    if (viewer) {
      viewer.scene.imageryLayers.addImageryProvider(osmImageryProvider);
      setOsmImageryAdded(true);
      setIsImageryClicked(false);
      setActiveImagery(0);
    }
  };

  /**
   * Adds Bing Maps (Aerial)
   */
  const bingAerailImagery = async () => {
    try {
      // Configuration for the Bing Maps imagery provider
      const bingConfig = {
        url: "https://dev.virtualearth.net",
        key: "AmJAP16ARM2JD-U91lhBL3OxkLMrRpllxjMh1a--OYg9Yh2mgL-3qxjdpWKXg3M0",
        mapStyle: BingMapsStyle.AERIAL_WITH_LABELS,
      };

      // Create the Bing Maps imagery provider
      const bing = await BingMapsImageryProvider.fromUrl(bingConfig.url, {
        key: bingConfig.key,
        mapStyle: bingConfig.mapStyle,
      });

      if (viewer) {
        // Add the Bing Maps imagery provider to the Cesium viewer's imagery layers
        viewer.scene.imageryLayers.addImageryProvider(bing);
        setOsmImageryAdded(true);
        setIsImageryClicked(false);
        setActiveImagery(1);
      }
    } catch (error) {
      console.error("Error adding Bing Maps imagery:", error);
    }
  };

  const bingRoadImagery = async () => {
    try {
      // Configuration for the Bing Maps imagery provider
      const bingConfig = {
        url: "https://dev.virtualearth.net",
        key: "AmJAP16ARM2JD-U91lhBL3OxkLMrRpllxjMh1a--OYg9Yh2mgL-3qxjdpWKXg3M0",
        mapStyle: BingMapsStyle.ROAD,
      };

      // Create the Bing Maps imagery provider
      const bing = await BingMapsImageryProvider.fromUrl(bingConfig.url, {
        key: bingConfig.key,
        mapStyle: bingConfig.mapStyle,
      });

      if (viewer) {
        // Add the Bing Maps imagery provider to the Cesium viewer's imagery layers
        viewer.scene.imageryLayers.addImageryProvider(bing);
        setOsmImageryAdded(true);
        setIsImageryClicked(false);
        setActiveImagery(2);
      }
    } catch (error) {
      console.error("Error adding Bing Maps imagery:", error);
    }
  };

  const defaultImagery = async () => {
    try {
      // Create the Bing Maps imagery provider
      const defaultImagery = await TileMapServiceImageryProvider.fromUrl(
        buildModuleUrl("Assets/Textures/NaturalEarthII")
      );

      if (viewer) {
        // Add the Bing Maps imagery provider to the Cesium viewer's imagery layers
        viewer.scene.imageryLayers.addImageryProvider(defaultImagery);
        setOsmImageryAdded(true);
        setIsImageryClicked(false);
        setActiveImagery(3);
      }
    } catch (error) {
      console.error("Error adding Bing Maps imagery:", error);
    }
  };

  const loadArcGISTerrain = async () => {
    // .. ArcGIS Terrain Provider ..
    const terrainProvider = await ArcGISTiledElevationTerrainProvider.fromUrl(
      "https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer",
      {
        token:
          "AAPK2099f81e64c6478c8922b18a20b89895bHIYvtbs39mvnK6gwF8TM7oYzkvYPu9O5sSFldOBkqBP17BKH9uT2DmujnIc1vkt",
      }
    );
    viewer.terrainProvider = terrainProvider;
    setIsImageryClicked(false);
    setActiveTerrain(1);
  };

  const loadCesiumTerrain = async () => {
    // .. Cesium Terrain Provider ..
    viewer.scene.setTerrain(
      Terrain.fromWorldTerrain({
        requestVertexNormals: true,
      })
    );
    setIsImageryClicked(false);
    setActiveTerrain(0);
  };

  const loadJapanTerrain = () => {
    viewer.terrainProvider = createJapanGSITerrainProvider({
      heightPower: 1.4,
    });
    setIsImageryClicked(false);
    setActiveTerrain(2);
  };

  const removeTerrain = () => {
    viewer.terrainProvider = new EllipsoidTerrainProvider();
    setIsImageryClicked(false);
    setActiveTerrain(3);
  };

  return (
    <>
      <div className="visioWindow_btn-imagery" onClick={handleImagery}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <mask
            id="mask0_2279_74"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2279_74)">
            <path
              d="M15 21L9 18.9L4.35 20.7C4.01667 20.8333 3.70833 20.7958 3.425 20.5875C3.14167 20.3792 3 20.1 3 19.75V5.75C3 5.53333 3.0625 5.34167 3.1875 5.175C3.3125 5.00833 3.48333 4.88333 3.7 4.8L9 3L15 5.1L19.65 3.3C19.9833 3.16667 20.2917 3.20417 20.575 3.4125C20.8583 3.62083 21 3.9 21 4.25V18.25C21 18.4667 20.9375 18.6583 20.8125 18.825C20.6875 18.9917 20.5167 19.1167 20.3 19.2L15 21ZM14 18.55V6.85L10 5.45V17.15L14 18.55ZM16 18.55L19 17.55V5.7L16 6.85V18.55ZM5 18.3L8 17.15V5.45L5 6.45V18.3Z"
              fill="white"
            />
          </g>
        </svg>
      </div>

      {isImageryClicked && (
        <div className="visioWindow_imagery-layer-container">
          <div className="visioWindow_imagery-layer">
            <div className="visioWindow_imagery-layer-heading">
              Add Imagery Layer
            </div>
            <div className="visioWindow_imagery-layer-maps">
              <div
                className="visioWindow_imagery-layer-map"
                onClick={openStreetImagery}
              >
                <img
                  className={
                    activeImagery === 0
                      ? "active-imagery"
                      : "non-active-imagery"
                  }
                  height={105}
                  src={openStreet}
                />
                <p>Open Street</p>
              </div>
              <div
                className="visioWindow_imagery-layer-map"
                onClick={bingAerailImagery}
              >
                <img
                  className={
                    activeImagery === 1
                      ? "active-imagery"
                      : "non-active-imagery"
                  }
                  height={105}
                  src={bingAerial}
                />
                <p>Aerial</p>
              </div>
              <div
                className="visioWindow_imagery-layer-map"
                onClick={bingRoadImagery}
              >
                <img
                  className={
                    activeImagery === 2
                      ? "active-imagery"
                      : "non-active-imagery"
                  }
                  height={105}
                  src={bingRoad}
                />
                <p>Road</p>
              </div>
              <div
                className="visioWindow_imagery-layer-map"
                onClick={defaultImagery}
              >
                <img
                  className={
                    activeImagery === 3
                      ? "active-imagery"
                      : "non-active-imagery"
                  }
                  height={105}
                  src={defaultImageryImage}
                />
                <p>Default</p>
              </div>
            </div>
          </div>
          <div className="visioWindow_terrain">
            <div className="visioWindow_terrain-heading">Add Terrain</div>
            <div className="visioWindow_terrain-maps">
              <div
                className="visioWindow_imagery-layer-map"
                onClick={loadCesiumTerrain}
              >
                <img
                  className={
                    activeTerrain === 0
                      ? "active-imagery"
                      : "non-active-imagery"
                  }
                  height={105}
                  src={cesiumT}
                />
                <p>Cesium</p>
              </div>
              <div
                className="visioWindow_imagery-layer-map"
                onClick={loadArcGISTerrain}
              >
                <img
                  className={
                    activeTerrain === 1
                      ? "active-imagery"
                      : "non-active-imagery"
                  }
                  height={105}
                  src={ArcGIS}
                />
                <p>ArcGIS</p>
              </div>
              <div
                className="visioWindow_imagery-layer-map"
                onClick={loadJapanTerrain}
              >
                <img
                  className={
                    activeTerrain === 2
                      ? "active-imagery"
                      : "non-active-imagery"
                  }
                  height={105}
                  src={bingRoad}
                />
                <p>Japan</p>
              </div>
              <div
                className="visioWindow_imagery-layer-map"
                onClick={removeTerrain}
              >
                <img
                  className={
                    activeTerrain === 3
                      ? "active-imagery"
                      : "non-active-imagery"
                  }
                  height={105}
                  src={noTerrain}
                />
                <p>None</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageryProvider;
