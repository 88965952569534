// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Viewer,
  OpenStreetMapImageryProvider,
  ClockViewModel,
  JulianDate,
  Clock,
  ClockRange,
  ClockStep,
  Cesium3DTileset,
  Model,
  BingMapsStyle,
} from "cesium";
import "./previewWindow.css";
import { useTranslation } from "react-i18next";
import LoadAsset from "./components/loadAsset";

/**
 * Functional component that loads Cesium Viewer into DOM and is parent to all Viewer child components.
 *
 * @returns {JSX.Element} - Void. Loads the added model to Viewer.
 */
export default function PreviewWindow({ selectedResource, assetList }) {
  const [viewer, setViewer] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    Cesium.Ion.defaultAccessToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1ZDdmZWViMS0xZDVjLTRhYTUtYWI4Yy0yOGFkZmI4YjNmZGIiLCJpZCI6NTI3ODIsImlhdCI6MTYyMjU1NTY4M30.2Qry6HDCock7YrMi8GQaStykhvfbCfuJ-TDs7IRglmY";
    const clock = new Clock({
      startTime: JulianDate.fromIso8601("2023-12-25"),
      currentTime: JulianDate.fromIso8601("2023-12-25"),
      stopTime: JulianDate.fromIso8601("2023-12-26"),
      clockRange: ClockRange.LOOP_STOP,
      clockStep: ClockStep.SYSTEM_CLOCK_MULTIPLIER,
      multiplier: 1000,
      shouldAnimate: true,
    });

    const viewer = new Viewer("cesiumContainer", {
      // terrain: Cesium.Terrain.fromWorldTerrain(),
      baseLayerPicker: false,
      clockViewModel: new ClockViewModel(clock),
    });
    viewer.scene.globe.enableLighting = true;

    const bingConfig = {
      url: "https://dev.virtualearth.net",
      key: "AmJAP16ARM2JD-U91lhBL3OxkLMrRpllxjMh1a--OYg9Yh2mgL-3qxjdpWKXg3M0",
      mapStyle: BingMapsStyle.AERIAL_WITH_LABELS,
    };

    // Performance display
    viewer.scene.debugShowFramesPerSecond = false;
    // .. Enable FXAA for anti-aliasing ..
    viewer.scene.postProcessStages.fxaa.enabled = true;

    // @ts-ignore
    setViewer(viewer);

    /**
     * Destroy Viewer upon component dis-mounting
     */
    return () => {
      try {
        viewer.entities.removeAll();
        viewer.scene.primitives.removeAll();
        viewer.destroy();
      } catch (error) {
        console.log("Error during destroying viewer : ", error);
      }
    };
  }, []);

  const onHomeButtonClick = () => {
    if (viewer.scene.primitives._primitives.length > 1) {
      for (
        let i = viewer.scene.primitives._primitives.length - 1;
        i >= 0;
        i--
      ) {
        if (
          viewer.scene.primitives._primitives[i] instanceof Cesium3DTileset ||
          viewer.scene.primitives._primitives[i] instanceof Model
        ) {
          viewer.flyTo(viewer.scene.primitives._primitives[i]);
          break;
        }
      }
    } else viewer.scene.camera.flyHome();
  };

  return (
    <div>
      <div id="cesiumContainer">
        <div id="previewWindow_project-name">
          <p id="previewWindow_project-name-label">
            {assetList
              ? `${t("main.asset-name")}:`
              : `${t("main.project-name")}:`}
          </p>
          <p id="previewWindow_selected-project">
            {selectedResource
              ? (
                selectedResource.project_name || selectedResource.asset_name
              ).replace(".json", "")
              : " "}
          </p>
        </div>
        {/* <div id="previewWindow_camera" onClick={onHomeButtonClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="43"
            viewBox="0 0 52 43"
            fill="none"
          >
            <rect width="52" height="43" rx="4" fill="#191919" />
            <mask
              id="mask0_2605_2031"
              maskUnits="userSpaceOnUse"
              x="14"
              y="10"
              width="24"
              height="24"
            >
              <rect x="14" y="10" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2605_2031)">
              <path
                d="M21.5 25.5L22.9 24.1L21.8 23H26.2L25.1 24.1L26.5 25.5L30 22L26.5 18.5L25.1 19.9L26.2 21H21.8L22.9 19.9L21.5 18.5L18 22L21.5 25.5ZM18 30C17.45 30 16.9792 29.8042 16.5875 29.4125C16.1958 29.0208 16 28.55 16 28V16C16 15.45 16.1958 14.9792 16.5875 14.5875C16.9792 14.1958 17.45 14 18 14H30C30.55 14 31.0208 14.1958 31.4125 14.5875C31.8042 14.9792 32 15.45 32 16V20.5L36 16.5V27.5L32 23.5V28C32 28.55 31.8042 29.0208 31.4125 29.4125C31.0208 29.8042 30.55 30 30 30H18ZM18 28H30V16H18V28Z"
                fill="white"
              />
            </g>
          </svg>
        </div> */}
      </div>
      {viewer && (
        <LoadAsset selectedResource={selectedResource} viewer={viewer} />
      )}
    </div>
  );
}
