// @ts-nocheck
import { JulianDate } from "cesium";
import PresetPointsList from "../../features/PresetPointsList";
import usePresetPoints from "../../features/usePresetPoints";

/**
 * Functional component for handling preset points in viewer mode
 * @param { Viewer } viewer Cesium Viewer
 * @returns { JSX.Element } Preset points viewer component
 */
const PresetPointsViewer = ({ viewer }) => {
    /* Getting the list of preset points from usePresetPoints custom hook */
    const { presetList } = usePresetPoints(viewer);

    /* Flies to the preset point */
    const flyToPresetPoint = (presetPoint) => {
        viewer.scene.camera.flyTo({
            destination: presetPoint.position.getValue(JulianDate.now()),
            orientation: {
                direction: presetPoint.properties.getValue(JulianDate.now()).direction,
                up: presetPoint.properties.getValue(JulianDate.now()).up
            }
        });
    }

    return (
        <>
            <PresetPointsList presetList={presetList} flyToPresetPoint={flyToPresetPoint} placeholderText="No preset points added" isViewer={true} />
        </>
    )
}

export default PresetPointsViewer;