// @ts-nocheck
import { Resource } from "cesium";
import secureLocalStorage from "react-secure-storage";
import React, { useEffect } from "react";
import { loadGLTF } from "./cesiumAPI/loadGLTF";
import load3DTile from "./cesiumAPI/load3DTile";
import loadJSONProject from "../../cesiumAPI/loadJSONProject";
import loadCZMLGLTF from "../../cesiumAPI/loadCZMLGLTF";

import resourceLoader from "../../../utils/resourceLoader";

export default function LoadAsset({ viewer, selectedResource }) {
  useEffect(() => {
    if (selectedResource) {
      const { resourceUrl, resourceType, gltfPath } =
        resourceLoader(selectedResource);

      const jwtToken = secureLocalStorage.getItem("token");

      const resourceObj = (resourceSpecificUrl) => {
        return new Resource({
          url: resourceSpecificUrl,
          headers: {
            Authorization: "JWT " + jwtToken,
          },
        });
      };
      // console.log(" RS TYPE : ", resourceType);
      if (resourceType === "Project") {
        const projectUrl = resourceUrl + "?file_id=P" + selectedResource.id;
        loadJSONProject(viewer, resourceObj(projectUrl));
      } else if (resourceType === "3D Tile") {
        const assetUrl = resourceUrl + "?file_id=A" + selectedResource.id;
        load3DTile(viewer, resourceObj(assetUrl));
      } else if (resourceType === "georefGLTF") {
        const assetUrl = resourceUrl + "?file_id=A" + selectedResource.id;
        const gltfURL = gltfPath + "?file_id=A" + selectedResource.id;
        loadCZMLGLTF(viewer, resourceObj(assetUrl), gltfURL, selectedResource);
      } else {
        const assetUrl = resourceUrl + "?file_id=A" + selectedResource.id;
        // Resource OBJ for GLTF to be implemented
        console.log(" Resource Is Not Georeferenced");
        //loadGLTF(viewer, resourceObj(assetUrl));
      }
    }
  }, [selectedResource]);
}
