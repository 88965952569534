import React from 'react'
import { useTranslation } from "react-i18next";

const UnassignPopup = ({ unassignUser, setUnassignUser, removeUserFromProject, selectedResourceType, unassignAssetToUser }) => {
    const { t } = useTranslation();
    const closeDeletePopup = () => {
        setUnassignUser(false)
    };
    return (
        <div>
            {unassignUser && (
                <div className="confirm-delete-popup">
                    <p>{t("main.are-you-sure-you-want-to-unassign-this-user?")}</p>
                    <div className="delete-btn-container">
                        <div id="delete-btn-cancel" onClick={closeDeletePopup}>
                        {t("main.cancel")}
                        </div>
                        <div id="confirm-delete-btn" onClick={selectedResourceType === "project" ? removeUserFromProject : unassignAssetToUser}>
                        {t("main.unassign")}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default UnassignPopup