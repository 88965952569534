import {
  JulianDate,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  defined,
} from "cesium";
import { useEffect, useState } from "react";

const PresetLogic = ({ viewer }) => {
  const flyToPresetPoint = (presetPoint) => {
    viewer.scene.camera.flyTo({
      destination: presetPoint.position.getValue(JulianDate.now()),
      orientation: {
        direction: presetPoint.properties.getValue(JulianDate.now()).direction,
        up: presetPoint.properties.getValue(JulianDate.now()).up,
      },
    });
  };

  useEffect(() => {
    const clickHandler = new ScreenSpaceEventHandler(viewer.scene.canvas);
    clickHandler.setInputAction(function (click) {
      const pickedObject = viewer.scene.pick(click.position);
      if (
        defined(pickedObject) &&
        pickedObject?.id?.id?.toString().startsWith("PSPoint")
      )
        flyToPresetPoint(pickedObject.id);
    }, ScreenSpaceEventType.LEFT_CLICK);

    /* Destroying event handlers */
    return () => {
      clickHandler.destroy();
    };
  }, [viewer]); 
};

export default PresetLogic;
