// @ts-nocheck
import {
  JulianDate,
  NearFarScalar,
  PropertyBag,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  defined
} from "cesium";
import { useEffect, useRef, useState } from "react";
import PresetPointsList from "../../../features/PresetPointsList";
import usePresetPoints from "../../../features/usePresetPoints";
import DateTimePanel from "../../../features/DateTimePanel";
import "../../editor.css";
import "./presetPoint.css";

/**
 * Functional component to create and manage preset points in editor
 * @param {Viewer} viewer Cesium viewer 
 * @returns { JSX.Element } Preset Point editor component
 */
const PresetPoint = ({ viewer, handleUpdateTimeline }) => {
  /* Getting the list of preset points from usePresetPoints custom hook */
  const { presetList, setPresetList, setEntitiesUpdated } = usePresetPoints(viewer);
  /* State declaration */
  const nameRef = useRef(null);
  const [presetName, setPresetName] = useState('');
  const [pointSelected, setPointSelected] = useState(undefined);
  const [viewSelected, setViewSelected] = useState(false);
  const [showDateTimePanel, setShowDateTimePanel] = useState(false);
  const [startDateTime, setStartDateTime] = useState({
    startDate: new Date(),
    startTimeHrs: "00",
    startTimeMinutes: "00",
    startTimePeriod: 'am'
  })
  const [isDateAdded, setIsDateAdded] = useState(false);

  useEffect(() => {
    /* Attaching click handler to check if user clicked on preset point. If so fly to the point */
    const clickHandler = new ScreenSpaceEventHandler(viewer.scene.canvas);
    clickHandler.setInputAction(function (click) {

      const pickedObject = viewer.scene.pick(click.position);
      if (defined(pickedObject) && pickedObject?.id?.id?.toString().startsWith("PSPoint"))
        flyToPresetPoint(pickedObject.id);

    }, ScreenSpaceEventType.LEFT_CLICK)

    /* Destorying event handlers */
    return () => {
      clickHandler.destroy();
    };
  }, [])

  /* Creates preset point entity */
  const createPresetPointEntity = (id, position = undefined, name = "", orientation = undefined) => {
    const svgString = `<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' xml:space='preserve'><g><circle cx='50%' cy='50%' r='20' fill='%23FC82FF' /><text x='50%' y='50%' text-anchor='middle' alignment-baseline='middle' stroke='black' strokeWidth='2px'>${id}</text></g></svg>`;

    const presetPoint = viewer.entities.add({
      position: position ? position : viewer.scene.camera.position,
      name: name === "" ? presetName : name,
      id: `PSPoint_${id}`,
      billboard: {
        image: "data:image/svg+xml, " + svgString,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        scaleByDistance: new NearFarScalar(0.2e2, 1.0, 0.1e6, 0.2),
      }
    });


    /* Saving the current camera orientation */
    const properties = new PropertyBag(orientation ? orientation : {
      direction: viewer.scene.camera.direction,
      up: viewer.scene.camera.up
    })
    console.log(viewer.scene.camera.direction)
    console.log(viewer.scene.camera.up)
    console.log(viewer.scene.camera.position)

    // .. Saving timeInfo into presetPoint as a property ..
    const startDate = new Date(startDateTime.startDate.getFullYear(), startDateTime.startDate.getMonth(), startDateTime.startDate.getDate(), startDateTime.startTimePeriod === 'am' ? parseInt(startDateTime.startTimeHrs) : parseInt(startDateTime.startTimeHrs) + 12, startDateTime.startTimeMinutes, 0);

    properties.addProperty("presetStartTime", startDate.toString())
    presetPoint.properties = properties;
    console.log("PRESET SAVED : ", presetPoint.properties.presetStartTime.getValue(JulianDate.now()))

    return presetPoint;
  }

  /* Save button click handler */
  const onSaveBtnClick = () => {
    if (presetName === '') {
      nameRef.current.focus();
      return;
    }

    var newPresetPoint = createPresetPointEntity(presetList.length + 1);

    /* Add newly create preset point to the list */
    setPresetList((prevList) => {
      const newList = [...prevList];
      newList.push(newPresetPoint);
      return newList;
    });

    setPresetName("");
    setViewSelected(false);
    var ssImgElement = document.getElementById('screenshot-img');
    ssImgElement.src = undefined;
    ssImgElement.style.visibility = 'hidden';
    handleUpdateTimeline();
  };

  /* Function that enables flying to preset point on click of the same */
  const flyToPresetPoint = (presetPoint) => {
    setPointSelected(presetPoint);
    setPresetName('');
    setViewSelected(false);
    var ssImgElement = document.getElementById('screenshot-img');
    if (ssImgElement) {
      ssImgElement.src = undefined;
      ssImgElement.style.visibility = 'hidden';
    }
    viewer.scene.camera.flyTo({
      destination: presetPoint.position.getValue(JulianDate.now()),
      orientation: {
        direction: presetPoint.properties.getValue(JulianDate.now()).direction,
        up: presetPoint.properties.getValue(JulianDate.now()).up
      }
    });
  }


  /* Displays screenshot effect */
  const handleViewSelected = () => {
    document.getElementById('visioContainer').classList.add('screenshot-effect');
    setTimeout(() => {
      document.getElementById('visioContainer').classList.remove('screenshot-effect');
    }, 300);
    setViewSelected(true);
    viewer.scene.postRender.addEventListener(takeScreenshot);
    setTimeout(() => nameRef.current.focus(), 0);
  }

  /* Handles deletion of preset point and reshuffling of preset point numbers */
  const onDeleteBtnClick = () => {
    if (pointSelected) {
      /* Removes the preset point from the list of entities */
      viewer.entities.remove(pointSelected);

      /* Reshuffling the numbering of preset points from the index of the deleted preset point in the preset points list */
      const deletePointIdx = parseInt(pointSelected.id.split("_")[1]);

      for (let i = deletePointIdx; i < presetList.length; i++) {
        const { position, name, properties } = presetList[i];
        viewer.entities.remove(presetList[i]);
        /* Creating a new preset point with the same name but updated numbering */
        createPresetPointEntity(i, position.getValue(JulianDate.now()), name, properties.getValue(JulianDate.now()));
      }

      /* Changing entitiesUpdated state to trigger useEffect dependancy to fetch new list of preset points after deletion with updated numbering */
      setEntitiesUpdated((prev) => !prev);
      setPointSelected(undefined);
    }
  };

  /* Takes screenshot and displays the screenshot image */
  var takeScreenshot = function () {

    viewer.scene.postRender.removeEventListener(takeScreenshot);
    var canvas = viewer.scene.canvas;
    canvas.toBlob(function (blob) {
      var url = URL.createObjectURL(blob);
      var ssImgElement = document.getElementById('screenshot-img');
      ssImgElement.src = url;
      ssImgElement.style.visibility = 'visible';
    });
  }

  // Handles date time change
  const handleDateTimeChange = (name, value) => {
    if (name.startsWith('start')) {
      setStartDateTime(prev => {
        return { ...prev, [name]: value }
      })
    }

    if (value === 'clear') {
      setStartDateTime({
        startDate: new Date(),
        startTimeHrs: "00",
        startTimeMinutes: "00",
        startTimePeriod: 'am'
      });
    }
  }

  // Toggles display of date panel
  const handleDatePanelShow = () => {
    setShowDateTimePanel(prev => !prev);
    setIsDateAdded(false);
  }

  return (
    <>
      <div className="panel-container panel-main-section">
        <h4 className="panel-subhead">Define Preset</h4>
        <hr />

        {viewSelected ?
          <div className="panel-inputGroup">
            <label className="panel-inputLabel panel-text">Name</label>
            <input
              value={presetName}
              type="text"
              ref={nameRef}
              onChange={(e) => setPresetName(e.target.value)}
              className="panel-inputField panel-text"
            />
            {isDateAdded ?
              <div className="panel-title margin">
                <p className="panel-subhead">Date: {startDateTime.startDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: '2-digit' }) + " " +
                  startDateTime.startTimeHrs + ":" + startDateTime.startTimeMinutes + " " +
                  startDateTime.startTimePeriod}
                </p>
                <span className="panel-time-text" onClick={handleDatePanelShow}>Select date &gt;</span>
              </div>
              :
              <p className="panel-time-text margin" onClick={handleDatePanelShow}>Select date &gt;</p>
            }
            {showDateTimePanel && <DateTimePanel actionType={"preset"} startDateTime={startDateTime} handleDateTimeChange={handleDateTimeChange} setShowDateTimePanel={setShowDateTimePanel} setIsDateAdded={setIsDateAdded} />}
            <img id="screenshot-img" src={undefined} alt="screenshot img" className="margin" />
          </div>
          :
          <div className="presetPoint-clickToAdd placeholder-text margin" onClick={handleViewSelected}>
            <p>+</p>
            Click here to capture the current view
          </div>
        }

        <div className="presetPoint-buttons">
          <button
            className="panel-deleteBtn"
            disabled={!pointSelected}
            onClick={onDeleteBtnClick}
          >
            Delete
          </button>
          <button
            className="panel-saveBtn panel-text"
            disabled={presetName === ''}
            onClick={onSaveBtnClick}
          >
            Save
          </button>
        </div>

      </div>

      <PresetPointsList
        presetList={presetList}
        flyToPresetPoint={flyToPresetPoint}
        placeholderText="Add preset to preview"
      />
    </>
  );
};

export default PresetPoint;
