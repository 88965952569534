// @ts-nocheck
import {
  BingMapsImageryProvider,
  BingMapsStyle,
  Cartesian3,
  Cesium3DTileset,
  Matrix4,
  Model,
  Resource,
  Terrain,
  Viewer,
} from "cesium";
import { useEffect, useState } from "react";
import close from "../../../../../assets/close.png";
import ViewerSideBar from "../../sidebar/viewerSidebar";
import ViewerInterface from "../viewerInterface";
import secureLocalStorage from "react-secure-storage";
import { useRef } from "react";
import ResourceFocus from "../../resourceFocus/resourceFocus";
import ImageryProvider from "../../topBar/imageryProvider";

/**
 * Functional component that enables split view functionality
 * @param { Viewer } viewer Cesium viewer
 * @param { Boolean } refreshData Notifies viewer features to update state via render
 * @param { Function } handleSplitViewEnabled Function to toggle split view
 * @param { Boolean } isVisible Should display sidebar
 * @returns
 */

const SplitView = ({
  viewer,
  refreshData,
  handleSplitViewEnabled,
  isVisible,
}) => {
  // State variables declaration
  const [duplicateViewer, setDuplicateViewer] = useState(null);
  const [viewerFeature, setViewerFeature] = useState(0);
  const [syncMode, setSyncMode] = useState("no-sync");
  const [resourceInFocus, setResourceInFocus] = useState();
  const unsubscribe = useRef(null);

  // Handling viewer features change in the duplicate viewer
  const handleViewerFeatureChange = (selectedFeature) => {
    setViewerFeature(selectedFeature);
  };

  // Sets the resource in focus
  const handleResourceInFocus = (resource) => {
    setResourceInFocus(resource);
  };

  // Method to handle camera sync/async toggle
  const toggleSync = (value) => {
    setSyncMode(value);
    if (value === "sync") {
      // Attaching pre render event listener on duplicate viewer to listen for camera changes when in sync mode
      unsubscribe.current =
        duplicateViewer.scene.preRender.addEventListener(syncCamera);
      viewer.camera.percentageChanged = 0.01;
      duplicateViewer.scene.screenSpaceCameraController.enableInputs = false;
    } else {
      // Removing the pre render event listener
      unsubscribe.current();
      duplicateViewer.scene.screenSpaceCameraController.enableInputs = true;
    }
  };

  // Syncs the camera of primary viewer with that of the secondary viewer
  const syncCamera = () => {
    Cartesian3.clone(
      viewer.scene.camera.position,
      duplicateViewer.scene.camera.position
    );
    Cartesian3.clone(
      viewer.scene.camera.direction,
      duplicateViewer.scene.camera.direction
    );
    Cartesian3.clone(viewer.scene.camera.up, duplicateViewer.scene.camera.up);
    Cartesian3.clone(
      viewer.scene.camera.right,
      duplicateViewer.scene.camera.right
    );
    duplicateViewer.scene.camera.lookAtTransform(viewer.scene.camera.transform);
  };

  useEffect(() => {
    // Creating an instance of the duplicate viewer
    const _duplicateViewer = new Viewer("duplicateViewerContainer", {
      terrain: Terrain.fromWorldTerrain(),
      geocoder: false,
      baseLayerPicker: false,
    });

    // .. Disable model info window ..
    _duplicateViewer.infoBox.destroy();

    // .. Enable FXAA for anti-aliasing ..
    _duplicateViewer.scene.postProcessStages.fxaa.enabled = true;

    // ... Defualt Imagery and Terrain Provider on the duplicate viewer ...
    const imageryTerrainLoader = async () => {
      const bing = await BingMapsImageryProvider.fromUrl(
        "https://dev.virtualearth.net",
        {
          key: "AmJAP16ARM2JD-U91lhBL3OxkLMrRpllxjMh1a--OYg9Yh2mgL-3qxjdpWKXg3M0",
          mapStyle: BingMapsStyle.ROAD,
        }
      );
      _duplicateViewer.scene.imageryLayers.addImageryProvider(bing);
    };
    imageryTerrainLoader();

    // .. Enable FXAA for anti-aliasing ..
    viewer.scene.postProcessStages.fxaa.enabled = true;

    // Copying all assets/entities/milestones of original viewer to duplicate viewer
    const copyAssets = async () => {
      const jwtToken = secureLocalStorage.getItem("token");

      for (var primitive of viewer.scene.primitives._primitives) {
        // Copying 3D tilesets
        if (primitive instanceof Cesium3DTileset && primitive._url) {
          const resourceObj = new Resource({
            url: primitive._url,
            headers: {
              Authorization: "JWT " + jwtToken,
            },
          });
          const newTileset = await Cesium3DTileset.fromUrl(resourceObj);

          // Copying milestones information
          newTileset.extras.assetAvailability = primitive?.extras?.assetAvailability;
          newTileset.extras.id = primitive.extras.id;
          _duplicateViewer.scene.primitives.add(newTileset);
          _duplicateViewer.flyTo(newTileset);

          // .. Trasform present in the root of the tileset needs to be neutralized ..
          newTileset.root.transform = Matrix4.IDENTITY;
          newTileset.modelMatrix = primitive.modelMatrix;
        }
        // Copying gLTF models
        else if (primitive instanceof Model) {
          const resourceObj = new Resource({
            url: primitive._resource.request.url,
            headers: {
              Authorization: "JWT " + jwtToken,
            },
          });

          const newModel = await Model.fromGltfAsync({
            id: primitive.id,
            credit: primitive.credit._html,
            url: resourceObj,
            modelMatrix: primitive.modelMatrix,
            minimumPixelSize: primitive.minimumPixelSize,
          });

          newModel._modelResources.assetAvailability = primitive?._modelResources?.assetAvailability
          _duplicateViewer.scene.primitives.add(newModel);
        }
      }

      // Copying preset points and annotations
      viewer.entities.values.forEach((entity) => {
        _duplicateViewer.entities.add(entity);
      });

      setDuplicateViewer(_duplicateViewer);
    };
    copyAssets();

    // Setting imagery icon
    var imageryElement = document.getElementsByClassName(
      "visioWindow_btn-imagery"
    )[1];
    imageryElement.style.position = "absolute";
    imageryElement.style.top = "20px";
    imageryElement.style.right = "20px";

    return () => {
      // Resizing original viewer back to 100% width and repositioning the top bar
      document.getElementById("visioContainer").style.width = "100%";
      document.getElementsByClassName("visioWindow_top-bar")[0].style.right =
        "0px";

      // Destroying duplicate viewer instance
      duplicateViewer?.entities.removeAll();
      duplicateViewer?.scene.primitives.removeAll();
      duplicateViewer?.destroy();
    };
  }, []);

  return (
    <>
      <div className="visioWindow_side-bar">
        <img
          src={close}
          alt="VT"
          className="visioWindow_logo"
          onClick={() => handleSplitViewEnabled(false)}
        />
        <ViewerSideBar handleViewerFeatureChange={handleViewerFeatureChange} splitViewEnabled={true} viewerFeature={viewerFeature} resourceType="project" />
        <div className="sync-icons">
          <div
            className={`sync-icon ${
              syncMode === "no-sync" && "sync-icon-active"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => toggleSync("no-sync")}
            >
              <mask
                id="mask0_2148_968"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2148_968)">
                <path
                  d="M19.8004 22.5999L16.0754 18.8749C15.7587 19.0582 15.4296 19.2249 15.0879 19.3749C14.7462 19.5249 14.3837 19.6499 14.0004 19.7499V17.6499C14.1004 17.6166 14.2004 17.5791 14.3004 17.5374C14.4004 17.4957 14.5004 17.4499 14.6004 17.3999L6.60039 9.3999C6.41706 9.81657 6.27122 10.2457 6.16289 10.6874C6.05456 11.1291 6.00039 11.5832 6.00039 12.0499C6.00039 12.7999 6.14206 13.5291 6.42539 14.2374C6.70872 14.9457 7.15039 15.5999 7.75039 16.1999L8.00039 16.4499V13.9999H10.0004V19.9999H4.00039V17.9999H6.75039L6.35039 17.6499C5.53372 16.8332 4.93789 15.9457 4.56289 14.9874C4.18789 14.0291 4.00039 13.0499 4.00039 12.0499C4.00039 11.2999 4.09622 10.5791 4.28789 9.8874C4.47956 9.19574 4.75872 8.54157 5.12539 7.9249L1.40039 4.1999L2.82539 2.7749L21.2254 21.1749L19.8004 22.5999ZM18.9254 16.0249L17.4254 14.5249C17.6087 14.1249 17.7504 13.7082 17.8504 13.2749C17.9504 12.8416 18.0004 12.3999 18.0004 11.9499C18.0004 11.1999 17.8587 10.4707 17.5754 9.7624C17.2921 9.05407 16.8504 8.3999 16.2504 7.7999L16.0004 7.5499V9.9999H14.0004V3.9999H20.0004V5.9999H17.2504L17.6504 6.3499C18.4671 7.16657 19.0629 8.05407 19.4379 9.0124C19.8129 9.97074 20.0004 10.9499 20.0004 11.9499C20.0004 12.6999 19.9046 13.4124 19.7129 14.0874C19.5212 14.7624 19.2587 15.4082 18.9254 16.0249ZM9.47539 6.5749L7.97539 5.0749C8.29206 4.89157 8.61706 4.73324 8.95039 4.5999C9.28372 4.46657 9.63372 4.3499 10.0004 4.2499V6.3499C9.91706 6.38324 9.82956 6.41657 9.73789 6.4499C9.64622 6.48324 9.55872 6.5249 9.47539 6.5749Z"
                  fill={syncMode === "no-sync" ? "#9EA940" : "#FFFFFF"}
                />
              </g>
            </svg>
          </div>
          <div
            className={`sync-icon ${syncMode === "sync" && "sync-icon-active"}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => toggleSync("sync")}
            >
              <mask
                id="mask0_2148_959"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2148_959)">
                <path
                  d="M4 20V18H6.75L6.35 17.65C5.48333 16.8833 4.875 16.0083 4.525 15.025C4.175 14.0417 4 13.05 4 12.05C4 10.2 4.55417 8.55417 5.6625 7.1125C6.77083 5.67083 8.21667 4.71667 10 4.25V6.35C8.8 6.78333 7.83333 7.52083 7.1 8.5625C6.36667 9.60417 6 10.7667 6 12.05C6 12.8 6.14167 13.5292 6.425 14.2375C6.70833 14.9458 7.15 15.6 7.75 16.2L8 16.45V14H10V20H4ZM14 19.75V17.65C15.2 17.2167 16.1667 16.4792 16.9 15.4375C17.6333 14.3958 18 13.2333 18 11.95C18 11.2 17.8583 10.4708 17.575 9.7625C17.2917 9.05417 16.85 8.4 16.25 7.8L16 7.55V10H14V4H20V6H17.25L17.65 6.35C18.4667 7.16667 19.0625 8.05417 19.4375 9.0125C19.8125 9.97083 20 10.95 20 11.95C20 13.8 19.4458 15.4458 18.3375 16.8875C17.2292 18.3292 15.7833 19.2833 14 19.75Z"
                  fill={syncMode === "sync" ? "#9EA940" : "#FFFFFF"}
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div id="duplicateViewerContainer"></div>
      <div className="duplicate-viewer-features">
        {duplicateViewer && (
          <>
            <ViewerInterface
              refreshData={refreshData}
              viewer={duplicateViewer}
              viewerFeature={viewerFeature}
              setViewerFeature={setViewerFeature}
              resourceInFocus={resourceInFocus}
              isVisible={isVisible}
            />
            <ResourceFocus
              viewer={duplicateViewer}
              handleResourceInFocus={handleResourceInFocus}
            />
          </>
        )}
      </div>
      <ImageryProvider viewer={duplicateViewer} />
    </>
  );
};

export default SplitView;
