import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import upload from "../../../../assets/upload.png";
import sort from '../../../../assets/sort.png'

const SearchBar = ({ projectList, userList, assetList, groupList, deviceList, authenticated, searchQueryAsset, searchQueryUser, searchQueryGroup, searchQueryDevice, handleSearchQueryChangeAsset, handleSearchQueryChangeUser, handleSearchQueryChangeGroup, handleSearchQueryChangeDevice, showUploadButton, searchQuery, handleSearchQueryChange, handleFilter, filterDropdownRef, handleResourcePopup, handleFilterCriteriaChange }) => {
    const { t } = useTranslation();


    return (
        <div>
            <div className="projectDashboard_search-bar-container">
                <div className="projectDashboard_upload-btn-container">
                    {showUploadButton && !deviceList && <div
                        className="projectDashboard_upload-btn"
                        onClick={handleResourcePopup}
                    >
                        {assetList ? (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <mask id="mask0_3360_11" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
                                <rect width="18" height="18" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3360_11)">
                                <path d="M8.25195 11.9999V5.88744L6.30195 7.83744L5.25195 6.74994L9.00195 2.99994L12.752 6.74994L11.702 7.83744L9.75195 5.88744V11.9999H8.25195ZM4.50195 14.9999C4.08945 14.9999 3.73633 14.8531 3.44258 14.5593C3.14883 14.2656 3.00195 13.9124 3.00195 13.4999V11.2499H4.50195V13.4999H13.502V11.2499H15.002V13.4999C15.002 13.9124 14.8551 14.2656 14.5613 14.5593C14.2676 14.8531 13.9145 14.9999 13.502 14.9999H4.50195Z" fill="white" />
                            </g>
                        </svg>) : (<p id='searchBar_add-btn'>+</p>)}
                        {assetList
                            ? t("main.upload")
                            : projectList
                                ? t("main.add")
                                : userList
                                    ? t("main.add")
                                    : t("main.add")}
                    </div>}
                </div>
                {(projectList || assetList || userList || groupList || authenticated) && <div className="projectDashboard_search-bar">
                    <div className='projectManager_search-bar-left'>
                        <input
                            id='projectManager_search-bar-input'
                            type="text"
                            value={
                                projectList ? searchQuery :
                                    assetList ? searchQueryAsset :
                                        userList ? searchQueryUser :
                                            groupList ? searchQueryGroup :
                                                searchQueryDevice
                            }
                            onChange={
                                projectList ? handleSearchQueryChange :
                                    assetList ? handleSearchQueryChangeAsset :
                                        userList ? handleSearchQueryChangeUser :
                                            groupList ? handleSearchQueryChangeGroup :
                                                handleSearchQueryChangeDevice
                            }
                            placeholder={t("main.search")}
                        />
                    </div>
                </div>}
                {(projectList || assetList || userList || groupList || authenticated) && <div
                    className="projectDashboard_search-bar-right"
                    onClick={handleFilter}
                    ref={filterDropdownRef}
                >
                    <img src={sort} />
                    <select
                        id="select"
                        onChange={handleFilterCriteriaChange}
                    >
                        <option>
                            {userList
                                ? t("main.last-login")
                                : (projectList || assetList)
                                    ? t("main.last-modified")
                                    : groupList
                                        ? t("main.id")
                                        : t("main.name")}
                        </option>
                        {(groupList || deviceList) ? "" : <option>{t("main.id")}</option>}
                        {deviceList ? "" : <option>{t("main.name")}</option>}
                        {deviceList ? <option>{t("main.created-time")}</option> : ""}
                        {deviceList ? <option>{t("main.activity-status")}</option> : ""}
                    </select>
                </div>}
            </div>
        </div>
    )
}

export default SearchBar