// @ts-nocheck
import React, { useState, useEffect } from "react";
import AddTenants from "./components/addTenants/addTenants";
import ViewTenants from "./components/viewTenants/viewTenant";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./visioAdmin.css";

export default function VisioAdmin() {
  const navigation = useNavigate();
  const location = useLocation();
  console.log(
    "User level and user id is : ",
    location.state.userLevel,
    location.state.userId
  );

  const handleLogout = () => {
    const shouldLogout = window.confirm('Are you sure you want to log out?');

    if (shouldLogout) {
      // Perform logout action and navigate to the login page
      navigation('/', {
        replace: true,
      });
    }
  };

  function confirmBack() {
    if (window.confirm('Are you sure you want to go back?')) {
        window.removeEventListener('popstate', confirmBack);
        window.history.back()
    } else window.history.pushState(null, document.title, location.href) // preventing back for next click
}

useEffect(() => {
  window.history.pushState(null, document.title, location.href); // preventing back initially
  window.addEventListener('popstate', confirmBack);
  return () => { window.removeEventListener('popstate', confirmBack) };
}, [])


  return (
    <>
      <div className="navbar2">
        <p>Visio Admin</p>
        <button
          className="logout-btn"
          onClick={handleLogout}>
          Logout
        </button>
      </div>

      <div className="container">
        <div className="container-item">
          <AddTenants userId={location.state.userId} />
        </div>
        <div className="container-item">
          <ViewTenants />
        </div>
      </div>
    </>
  );
}
