// @ts-nocheck
import {
  Cesium3DTileset,
  Cartesian3,
  Transforms,
  HeadingPitchRoll,
  Matrix4,
  Model,
} from "cesium";

/**
 * Loads a 3D tile into Preview Window
 * @param { Viewer } viewer Cesium Viewer object
 * @param { Resource } tileResource Cesium Resource object
 * @category Assets
 */
const load3DTile = async (viewer, tileResource) => {
  // .. Flush primitives ..
  viewer.scene.primitives._primitives =
    viewer.scene.primitives._primitives.filter((primitive) => {
      if (primitive instanceof Cesium3DTileset || primitive instanceof Model) {
        return false; // Exclude this primitive from the filtered array
      }
    });
  // ... Variables ...

  let georeferenced;
  let position;
  let orientation;
  let scaling;

  // ... Tile loader ...
  try {
    // .. Get the tileset.json file ..
    const response = await fetch(tileResource.url, {
      method: "GET",
      headers: tileResource.headers,
    });
    if (!response.ok) {
      throw new Error("Error while fetching Tileset");
    }
    const data = await response.json();

    // .. Read the json file for metadata ..

    // . If tileset was georeferenced. Read for the georef properties .

    georeferenced = data.extras.georeferenced;

    if (georeferenced) {
      if (georeferenced === "cgml") {
        // console.log(" Loading CityGML");
      } else {
        // console.log("TILE IS GEOREFERENCED");
        position = Cartesian3.fromDegrees(
          data.extras.position.longitude,
          data.extras.position.latitude,
          data.extras.position.height
        );

        orientation = Transforms.headingPitchRollQuaternion(
          position,
          new HeadingPitchRoll.fromDegrees(
            data.extras.hpr.h,
            data.extras.hpr.p,
            data.extras.hpr.r
          )
        );

        scaling = new Cartesian3(
          data.extras.scale.x,
          data.extras.scale.y,
          data.extras.scale.z
        );
      }
    }
    // . Else set default georeferencing properties .
    else {
      // console.log("TILE AIN'T GEOREF");
      position = Cartesian3.fromDegrees(
        76.61758113143246,
        28.192182356673207,
        0
      );
      orientation = Transforms.headingPitchRollQuaternion(
        position,
        new HeadingPitchRoll.fromDegrees(0, 0, 0)
      );
      scaling = new Cartesian3(1, 1, 1);
    }

    const newTileset = await Cesium3DTileset.fromUrl(tileResource);

    viewer.scene.primitives.add(newTileset);

    // . Neutralize tileset's root transform .
    if (georeferenced == "cgml") {
      // console.log("CGML ASSET");
    } else {
      const modelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
        position,
        orientation,
        scaling
      );
      newTileset.root.transform = Matrix4.IDENTITY;
      newTileset.modelMatrix = modelMatrix;
      newTileset.pointCloudShading.attenuation = true;
      newTileset.pointCloudShading.eyeDomeLighting = true;
    }
    // console.log("TILE SET : ", newTileset);
    viewer.flyTo(newTileset);

    // newTileset.debugShowBoundingVolume = true;
  } catch (e) {
    console.log("Got an error while loading tileset : ", e);
  }
};

export default load3DTile;
