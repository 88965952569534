// @ts-nocheck
// ... React Imports ...
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// ... Misc ...
import secureLocalStorage from "react-secure-storage";
import ImageryProvider from "./imageryProvider";
import SaveProject from "../editor/editorFunctions/save/saveProject";
import SaveGeolocation from "../editor/editorFunctions/geolocate/components/saveGeolocation/saveGeolocation";

/**
 * TODO : To be reviewed
 * @param {*} param0
 * @returns
 */
const TopBar = ({
  handleEditorModeChange,
  editorMode,
  handleAssetDropDown,
  resourceType,
  viewer,
  toggleVisibility,
  splitViewEnabled,
  setAssetDropDown,
  selectedResourceType,
  selectedResource,
  resourceObj,
  setDeviceList
}) => {
  /* State declaration */
  const [viewerActive, setViewerActive] = useState(true);
  const [editorActive, setEditorActive] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeImagery, setActiveImagery] = useState(1);
  const [activeTerrain, setActiveTerrain] = useState(0);
  const navigation = useNavigate();
  const userLevel = secureLocalStorage.getItem("userLevel");

  /**
   * Sets the editor mode active
   */
  const editorModeOn = () => {
    console.log("EDITOR : ", true);
    handleEditorModeChange(true);
    setEditorActive(true);
    setViewerActive(false);
  };

  /**
   * Sets the viewer mode active
   */
  const editorModeOff = () => {
    console.log("EDITOR : ", false);
    handleEditorModeChange(false);
    setEditorActive(false);
    setViewerActive(true);
    setAssetDropDown(false);
  };

  /**
   * Toggles the fullscreen mode of a web page and updates the isFullScreen state accordingly.
   */
  const toggleFullScreen = () => {
    const element = document.documentElement;
    setAssetDropDown(false);
    setDeviceList(false)

    toggleVisibility();
    if (!isFullScreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  // Event listeners for fullscreen changes and clean them up on component unmount
  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);
    document.addEventListener("mozfullscreenchange", fullscreenChangeHandler);
    document.addEventListener(
      "webkitfullscreenchange",
      fullscreenChangeHandler
    );
    document.addEventListener("msfullscreenchange", fullscreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
      document.removeEventListener(
        "mozfullscreenchange",
        fullscreenChangeHandler
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        fullscreenChangeHandler
      );
      document.removeEventListener(
        "msfullscreenchange",
        fullscreenChangeHandler
      );
    };
  }, []);

  return (
    <>
      <div className="visioWindow-topbar-container">
        {!splitViewEnabled && (
          <>
            {editorMode &&
              (selectedResourceType === "project" ? (
                <SaveProject
                  viewer={viewer}
                  selectedResource={selectedResource}
                />
              ) : (
                <SaveGeolocation
                  viewer={viewer}
                  selectedResource={selectedResource}
                  resourceObj={resourceObj}
                />
              ))}
            {editorMode && (
              <svg
                id="separartion-bar"
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="14"
                viewBox="0 0 2 14"
                fill="none"
              >
                <path d="M1 1L1 13" stroke="#707070" strokeLinecap="round" />
              </svg>
            )}
            <div
              className="visioWindow-home-btn"
              onClick={() => {
                navigation(-1);
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <mask
                  id="mask0_2035_3507"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2035_3507)">
                  <path
                    d="M6 19H9V13H15V19H18V10L12 5.5L6 10V19ZM4 21V9L12 3L20 9V21H13V15H11V21H4Z"
                    fill="white"
                  />
                </g>
              </svg>
            </div>
            <svg
              id="separartion-bar"
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="14"
              viewBox="0 0 2 14"
              fill="none"
            >
              <path d="M1 1L1 13" stroke="#707070" strokeLinecap="round" />
            </svg>
          </>
        )}
        {(userLevel === "Tenant" || userLevel === "Editor") && (
          <>
            {!splitViewEnabled && editorMode && resourceType === "project" && (
              <div
                className="visioWindow_btn-insert-asset"
                onClick={() => {
                  handleAssetDropDown();
                }}
              >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <mask
                    id="mask0_2082_518"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="25"
                  >
                    <rect y="0.0170898" width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2082_518)">
                    <path
                      d="M11 13.0171H5V11.0171H11V5.01709H13V11.0171H19V13.0171H13V19.0171H11V13.0171Z"
                      fill="white"
                    />
                  </g>
                </svg>
                Insert Asset
              </div>
            )}

            {!splitViewEnabled &&
              editorMode &&
              selectedResourceType === "project" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="14"
                  viewBox="0 0 2 14"
                  fill="none"
                >
                  <path d="M1 1L1 13" stroke="#707070" strokeLinecap="round" />
                </svg>
              )}
            <div className="viewer-editor-btn-container">
              <div
                className={viewerActive ? "active" : "visioWindow_btn-viewer"}
                onClick={() => {
                  editorModeOff();
                }}
              >
                Viewer
              </div>
              <div
                className={editorActive ? "active" : "visioWindow_btn-editor"}
                onClick={() => {
                  editorModeOn();
                }}
              >
                Editor
              </div>
            </div>
          </>
        )}
        {(userLevel === "Tenant" || userLevel === "Editor") && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2"
            height="14"
            viewBox="0 0 2 14"
            fill="none"
          >
            <path d="M1 1L1 13" stroke="#707070" strokeLinecap="round" />
          </svg>
        )}

        <ImageryProvider
          viewer={viewer}
          activeImagery={activeImagery}
          setActiveImagery={setActiveImagery}
          activeTerrain={activeTerrain}
          setActiveTerrain={setActiveTerrain}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2"
          height="14"
          viewBox="0 0 2 14"
          fill="none"
        >
          <path d="M1 1L1 13" stroke="#707070" strokeLinecap="round" />
        </svg>
        <div className="visioWindow_btn-full" onClick={toggleFullScreen}>
          {isFullScreen ? (
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path
                d="M3 14V11H0V9H5V14H3ZM9 14V9H14V11H11V14H9ZM0 5V3H3V0H5V5H0ZM9 5V0H11V3H14V5H9Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <mask
                id="mask0_2035_3474"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2035_3474)">
                <path
                  d="M5 19V14H7V17H10V19H5ZM5 10V5H10V7H7V10H5ZM14 19V17H17V14H19V19H14ZM17 10V7H14V5H19V10H17Z"
                  fill="white"
                />
              </g>
            </svg>
          )}
        </div>
      </div>
    </>
  );
};

export default TopBar;
