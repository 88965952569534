import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './projectDetails.css'
import left_arrow from '../../../../assets/left_arrow.png'
import Line_one from '../../../../assets/Line_one.png'
import add_black from '../../../../assets/add_black.png'

const ProjectDetails = ({ selectedResourceName }) => {
    const navigation = useNavigate();

    return (
        <div>
            <div className='projectManager_upper-details-container'>
                <div className='projectManager_project-name' onClick={() => {
                    navigation(-1);
                }}>
                    <img src={left_arrow} id='projectManager_back-button' />
                    <p>{selectedResourceName}</p>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetails