// @ts-nocheck
// ... React Imports ...
import React from 'react'

// ... Components ...
import AddTenantPopup from './components/addTenantPopup/addTenantPopup';
import DeleteTenantPopup from './components/deleteTenantPopup/deleteTenantPopup';
import UploadSuccessFailurePopup from './components/uploadSuccessFailurePopup/uploadSuccessFailurePopup';

/**
 * @description `Popups`: A component for pop-up components in the admin dashboard.
 * @param {boolean} showAddTenantPopup Indicates whether the add tenant popup should be displayed.
 * @param {string} newTenantName The input value for the new tenant's name.
 * @param {string} newTenantEmail The input value for the new tenant's email.
 * @param {Function} handleNameChange A function to handle changes in the tenant name input.
 * @param {Function} handleEmailChange A function to handle changes in the tenant email input.
 * @param {Function} handleTenantCreation A function to handle the creation of a new tenant.
 * @param {string} failureMessage A message to display in case of a failure.
 * @param {boolean} failure Indicates whether the operation resulted in a failure.
 * @param {string} successMsg A message to display upon successful operation.
 * @param {boolean} success Indicates whether the operation was successful.
 * @param {Function} handleDeleteTenant A function to handle tenant deletion.
 * @param {boolean} showDeleteTenantPopup Indicates whether the delete tenant popup should be displayed.
 * @param {Function} setShowDeleteTenantPopup A function to control the visibility of the delete tenant popup.
 * @category Admin Dashboard
 * @returns {JSX.Element} The rendered popups component
 */
const Popups = ({
    showAddTenantPopup,
    newTenantName,
    newTenantEmail,
    handleNameChange,
    handleEmailChange,
    handleTenantCreation,
    failureMessage,
    failure,
    successMsg,
    success,
    handleDeleteTenant,
    showDeleteTenantPopup,
    setShowDeleteTenantPopup }) => {
    return (
        <div>
            <AddTenantPopup
                showAddTenantPopup={showAddTenantPopup}
                newTenantName={newTenantName}
                newTenantEmail={newTenantEmail}
                handleNameChange={handleNameChange}
                handleEmailChange={handleEmailChange}
                handleTenantCreation={handleTenantCreation}
            />

            <DeleteTenantPopup
                handleDeleteTenant={handleDeleteTenant}
                showDeleteTenantPopup={showDeleteTenantPopup}
                setShowDeleteTenantPopup={setShowDeleteTenantPopup}
            />

            <UploadSuccessFailurePopup
                success={success}
                successMsg={successMsg}
                failureMessage={failureMessage}
                failure={failure}
            />
        </div>
    )
}

export default Popups