// @ts-nocheck

// ... React Imports ...
import { Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";

// ... Pages ...
import Login from "./pages/login/login";
import NotFound from "./pages/notFound/notfound";
import NoAuth from "./pages/noAuth/noAuth";
import VisioAdmin from "./pages/visioAdmin/visioAdmin";
import NetworkError from "./pages/networkError/networkError";
import ProjectManager from "./pages/projectManager/projectManager";
import ProjectDashboard from "./pages/projectDashboard/projectDashboard";
import ViewerDashboard from "./pages/viewerDashboard/viewerDashboard";
import EditorDashboard from "./pages/editorDashboard/editorDashboard";
import VisioWindow from "./pages/visioWindow/visioWindow";
import Testing from "./pages/testing/testing";
import DemoWindow from "./pages/demoWindow/demoWindow";
import ContainerFarm from "./pages/containerFarm/containerFarm";
import AdminDashboard from "./pages/adminDashboard/adminDashboard";
import ForgotPassword from "./pages/login/components/forgotPassword/forgotPassword";
import ConfirmPassword from "./pages/login/components/confirmPassword/confirmPassword";

// ... Style script ...
import "./App.css";
import DemoWindow2 from "./pages/demoWindow2/demoWindow2";

/**
 * The `App` component serves as the entry point for your React application.
 * It uses React Router DOM to manage navigation between different routes in the application.
 * The component defines a hierarchy of routes, including authentication, admin, and page-specific routes.
 * The component structure ensures that the appropriate page components are rendered based on the current URL path.
 * @category Router
 * @returns {JSX.Element} The root component rendering React Routes for navigation.
 * @example
 * // Usage in the index.js file:
 * ReactDOM.render(
 *     <BrowserRouter>
 *       <App />
 *     </BrowserRouter>
 *   document.getElementById('root')
 * );
 */
function App() {
  return (
    <>
      <Suspense fallback="...loading">
        <Routes>
          <Route path="/" element={<Login />} />
          {/** Admin Routes */}
          <Route path="/visioAdmin" element={<VisioAdmin />} />
          <Route path="/projectManager" element={<ProjectManager />} />

          {/** Page Routes */}
          <Route path="/adminDashboard" element={<AdminDashboard />} />
          <Route path="/projectDashboard" element={<ProjectDashboard />} />
          <Route path="/viewerDashboard" element={<ViewerDashboard />} />
          <Route path="/editorDashboard" element={<EditorDashboard />} />
          <Route path="/visioWindow" element={<VisioWindow />} />
          <Route path="/demoWindow" element={<DemoWindow />} />
          <Route path="/demoWindow2" element={<DemoWindow2 />} />
          <Route path="/containerFarm" element={<ContainerFarm />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/confirmPassword" element={<ConfirmPassword />} />

          <Route path="/noAuth" element={<NoAuth />} />
          <Route path="/networkError" element={<NetworkError />} />
          <Route path="/testing" element={<Testing />} />

          {/** catch all */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
